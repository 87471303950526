import createAction from '../../lib/redux/create-action';

export const hydrate = createAction('DynamicConfig:HYDRATE');
export const update = createAction('DynamicConfig:UPDATE');
export const clear = createAction('DynamicConfig:CLEAR');
export const save = createAction('DynamicConfig:SAVE');

export const focusInput = createAction('DynamicConfig:FOCUS_INPUT');
export const setCellRangeValue = createAction(
  'DynamicConfig:SET_CELL_RANGE_VALUE',
);
export const setValue = createAction('DynamicConfig:SET_VALUE');
