import {
  capitalize,
  chain,
  fill,
  find,
  findIndex,
  isNull,
  last,
  max,
  unionWith,
  unzip,
} from 'lodash';

import { getBuckets } from '../../lib/timespan';

export const SPLIT_TYPES = ['enum', 'string'];

// TODO be able to use i18n here..
export const DEFAULT_LABEL = 'No value';

export const getMainColumnField = (config) => {
  const { type, queryMetas: [{ select = [] } = {}] = [] } = config;

  switch (type) {
    case 'number':
    case 'geckometer':
      return select[0];
    case 'bar':
    case 'column':
    case 'line':
    case 'leaderboard':
      return last(select);
    default:
      return {};
  }
};

/*
 * ensureTimespan
 *
 * Given a time series where the x-axis is a datetime and
 * the y-axis is a numeric value it ensures that missing
 * buckets are filled in with null y-axis values.
 */
export const ensureTimespan = (series, timezone, qty, unit, bucket) => {
  const buckets = getBuckets(timezone, qty, unit, bucket);

  return unionWith(
    series,
    buckets.map((b) => [b, null]),
    ([t], [t1]) => t === t1,
  );
};

export const getEnumHumanValue = (key, values) => {
  // On numeric keys when using splitBy, keys are converted to strings
  // so convert all keys to strings before comparing
  const stringKey = key.toString();
  const { value } =
    find(values, ({ key: valueKey }) => valueKey.toString() === stringKey) ||
    {};
  return value || stringKey;
};

const getSeriesName = ({ aggregate, name } = {}) => {
  if (!name) return undefined;
  if (!aggregate || aggregate === 'count') return name;
  return `(${capitalize(aggregate)}) ${name}`;
};

const sortSeries = (name, values) => {
  if (!values) return name;
  return findIndex(values, (v) => v.key === name);
};

export const getMultilineScatterSeries = (
  payload,
  selectMeta,
  isTimespanComparisonOn,
) => {
  // Check if first data point has multiple series
  if (payload[0].length < 3) {
    return null;
  }

  // If using split by the second select is categorical
  if (
    !isTimespanComparisonOn &&
    SPLIT_TYPES.indexOf(selectMeta[1].type) !== -1
  ) {
    const { values } = selectMeta[1];
    // Push default label at the tail of series
    const allValues = values && [...values, { key: DEFAULT_LABEL }];
    return chain(payload)
      .reduce((result, point) => {
        const [x, name, y] = point;
        const label = name || DEFAULT_LABEL;
        (result[label] || (result[label] = [])).push([x, y]);
        return result;
      }, {})
      .map((data, key) => ({ data, key }))
      .sortBy((s) => sortSeries(s.key, allValues))
      .map(({ data, key }) => ({
        data,
        name: getEnumHumanValue(key, allValues),
      }))
      .value();
  }

  // Multiple metrics
  return chain(payload)
    .reduce((result, point) => {
      const [xAxis, ...yAxis] = point;

      yAxis.forEach((y, index) => {
        (result[index] || (result[index] = [])).push([xAxis, y]);
      });

      return result;
    }, [])
    .map((data, index) => ({
      name: getSeriesName(selectMeta[index + 1]),
      data,
      type: isTimespanComparisonOn && index === 1 ? 'secondary' : undefined,
    }))
    .value();
};

export const getMultilineSeriesAndLabels = (payload, selectMeta) => {
  let labels, series;

  // Check if first data point has multiple series
  if (payload[0].length < 3) {
    return { labels, series };
  }

  // If using split by the second select is categorical
  if (SPLIT_TYPES.indexOf(selectMeta[1].type) !== -1) {
    const { values } = selectMeta[1];
    // Push default label at the tail of series
    const allValues = values && [...values, { key: DEFAULT_LABEL }];

    labels = chain(payload)
      .map((point) => point[0])
      .uniq()
      .value();
    const numXPoints = labels.length;

    series = chain(payload)
      .reduce((result, point) => {
        const [labelX, name, y] = point;
        const label = name || DEFAULT_LABEL;

        if (!result[label]) {
          // Create array with space for each x-axis point
          result[label] = fill(Array(numXPoints), null);
        }

        const index = labels.indexOf(labelX);
        result[label][index] = y;

        return result;
      }, {})
      .map((data, key) => ({ data, key }))
      .sortBy((s) => sortSeries(s.key, allValues))
      .map(({ data, key }) => ({
        data,
        name: getEnumHumanValue(key, allValues),
      }))
      .value();

    return { labels, series };
  }

  // Multiple metrics
  [labels, ...series] = unzip(payload);

  series = series.map((s, index) => ({
    name: getSeriesName(selectMeta[index + 1]),
    data: s,
  }));

  return { labels, series };
};

export const getDefaultGoal = (data, format) => {
  const dimensions = unzip(data);
  // Take first series if 2 dimensions
  const values = dimensions.length > 1 ? dimensions[1] : dimensions[0];
  const maxValue = max(values);
  const goalValue = maxValue * (1 - 0.2);
  const goal = format === 'percent' ? goalValue * 100 : goalValue;

  return Math.round(goal);
};

export const getFormat = (mainColumn, config, useRelativeTime = false) => {
  const { type, unit } = mainColumn;

  if (type === 'duration') {
    return { format: type, unit };
  } else if (type === 'datetime') {
    return { format: type, useRelativeTime };
  } else if (type === 'percent' || type === 'image') {
    return { format: type };
  } else if (type === 'currency') {
    const { queryOptions: { currency } = {} } = config;
    return { format: type, unit: currency || 'USD' };
  }
  return { format: 'decimal' };
};

// Always return `null` or `[42]`
export const parseNumberValue = (wrappedValue = [[null]]) => {
  if (!wrappedValue.length) return null;
  // When on a time metric and there is no data available,
  // the value returned from the backend is an array with null ([null])
  // In this case set value to null (instead of default [null]) to render empty component.
  return isNull(wrappedValue[0]) || isNull(wrappedValue[0][0])
    ? null
    : wrappedValue[0];
};

/*
 * fillInValues
 *
 * For a time series we may not always get values from the backend
 * for every time bucket. In this case the y-axis value will be null.
 * For a numeric value like "CSAT" it makes sense to differentiate
 * between null and 0, but for a count null means 0.
 */
export const fillInValues = (series, seriesType) => {
  if (seriesType !== 'count') {
    return series;
  }

  return series.map(([x, y]) => (isNull(y) ? [x, 0] : [x, y]));
};
