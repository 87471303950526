import { isUndefined } from 'lodash';

import { parseNumberBasedOnFormat } from '../universal/lib/parse-number';
import { roundToPowerOfTen } from '../universal/lib/round-to-power-of-ten';
import { getDurationMaxValue } from '../universal/transformers/bauhaus/helpers';

import { getPlaceholder } from './placeholder';

// Returns a default min and max with the placeholder prefix or suffix
const getGeckometerDefaultMinMax = (value, format, unit) => {
  const { _minDefault, _maxDefault } = getGeckometerDefaultMinMaxValue(
    value,
    format,
    unit,
  );

  return {
    _minDefault: getPlaceholder(_minDefault, format),
    _maxDefault: getPlaceholder(_maxDefault, format),
  };
};

// Returns a default min and max value without the placeholder prefix/suffix
// This is useful for cases that we want to ensure a min/max config value exists
// without the placeholder so that its a valid value for input
const getGeckometerDefaultMinMaxValue = (value, format, unit) => {
  const minDefault = '0';
  let maxDefault;

  if (format === 'percent') {
    maxDefault = '100';
  } else if (format === 'duration') {
    maxDefault = getDurationMaxValue(value, unit).toString();
  } else if (value > 1) {
    maxDefault = roundToPowerOfTen(value).toString();
  } else {
    maxDefault = '10';
  }

  return {
    _minDefault: minDefault,
    _maxDefault: maxDefault,
  };
};

const setExtremum = (key, { _default, _typed }) => {
  // Value is overriden by user
  if (!isUndefined(parseNumberBasedOnFormat(_typed))) {
    return { [`${key}`]: _typed };
  }

  return { [`${key}`]: _default };
};

const setTypedExtremum = (key, extremum) => {
  if (extremum) {
    return { [`_${key}Typed`]: extremum };
  }
  return { [`_${key}Typed`]: undefined };
};

export {
  getGeckometerDefaultMinMax,
  getGeckometerDefaultMinMaxValue,
  setExtremum,
  setTypedExtremum,
};
