import ColourBrewer from '../../../lib/colour-brewer';
import { hasDisabledSelection } from '../selection-helpers';

const getDefaultConfig = (selections = []) => {
  const [xAxis, ...series] = selections;

  return {
    type: 'line',
    goal: undefined,
    numberFormat: {},
    xAxis,
    series,
  };
};

const getSelectionsFromConfig = (config) => [config.xAxis, ...config.series];

const isValid = (cfg) => {
  const requiredSelections = [cfg.xAxis].concat(cfg.series);

  return !hasDisabledSelection(requiredSelections);
};

const headerPaths = () => {
  return ['xAxis', 'series'];
};

export const headerColours = (cfg) => {
  const { series = [] } = cfg;
  if (!series.length) {
    return { series };
  }

  const brewery = new ColourBrewer();
  const colours = series.map(() => brewery.getNextColour());
  return { series: colours };
};

const configMappingRules = {
  column: [
    'dataRange',
    'goal',
    'reverseGoalDirection',
    'numberFormat',
    'legends',
    'title',
  ],
  number: [
    'dataRange',
    'goal',
    'numberFormat',
    [
      'comparison.thresholdType',
      (type) => 'lower' === type,
      'reverseGoalDirection',
    ],
    'title',
  ],
  text: ['dataRange', 'title'],
  leaderboard: ['dataRange', 'numberFormat', 'title'],
  geckometer: [
    'dataRange',
    ['min', (min) => (min ? min.value : undefined), 'minYAxis'], // eslint-disable-line no-confusing-arrow
    ['max', (max) => (max ? max.value : undefined), 'maxYAxis'], // eslint-disable-line no-confusing-arrow
    'numberFormat',
    'title',
  ],
  bar: ['dataRange', 'goal', 'reverseGoalDirection', 'numberFormat', 'title'],
  table: ['dataRange', 'title'],
};

export default {
  getDefaultConfig,
  getSelectionsFromConfig,
  isValid,
  headerPaths,
  headerColours,
  configMappingRules,
};
