import { getDashboardQuery } from '@Hooks/use-dashboard-timezone-query';

import apolloClient from './apollo-client';

export const getDashboardTimezone = async (
  id: string | number,
): Promise<string> => {
  const client = apolloClient.create();
  const query = getDashboardQuery(id);

  const { data } = await client.query({
    query,
    variables: {
      id: id.toString(),
    },
  });

  return data.dashboard.timezone.name;
};
