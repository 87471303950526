import config from '../universal/config';
import {
  fetchAllWithCredentials,
  fetchJSONWithCredentials,
} from '../universal/fetch';

function inviteUsersToGroup(groupId, users) {
  const URL = `${config.get().ManagementBaseURL}/groups/${groupId}/members`;

  return fetchAllWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(users),
  });
}

function removeUserFromGroup(groupId, userId) {
  const URL = `${
    config.get().ManagementBaseURL
  }/groups/${groupId}/members/${userId}`;

  return fetchAllWithCredentials(URL, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
    },
  });
}

// TODO: Refactor this to not require a groupId
// https://app.clubhouse.io/geckoboard/story/9355/new-endpoint-for-to-support-removing-users
function deleteUser(groupId, userId) {
  const URL = `${
    config.get().ManagementBaseURL
  }/groups/${groupId}/users/${userId}`;

  return fetchJSONWithCredentials(URL, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
    },
  });
}

function setRole(groupId, userId, role) {
  const URL = `${
    config.get().ManagementBaseURL
  }/groups/${groupId}/members/${userId}`;

  return fetchAllWithCredentials(URL, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ role }),
  });
}

function makeAccountOwner(userId) {
  const URL = `${config.get().ManagementBaseURL}/owners`;

  return fetchAllWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user_id: userId }),
  });
}

function revokeAccountOwner(userId, demoteToGroupId = null) {
  let url = `${config.get().ManagementBaseURL}/owners/${userId}`;

  if (demoteToGroupId !== null) {
    url = `${url}?demote_to_group_id=${demoteToGroupId}`;
  }

  return fetchAllWithCredentials(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function promoteToMember(email, groupId) {
  const users = [{ type: 'user', role: 'read_only', email }];
  return inviteUsersToGroup(groupId, users);
}

function resendInvite(groupId, userId) {
  const URL = `${
    config.get().ManagementBaseURL
  }/groups/${groupId}/users/${userId}/regenerate_invite`;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

export {
  deleteUser,
  inviteUsersToGroup,
  makeAccountOwner,
  promoteToMember,
  removeUserFromGroup,
  resendInvite,
  revokeAccountOwner,
  setRole,
};
