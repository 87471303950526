import { find } from 'lodash';

import config from '../config';
import { fetchAllWithCredentials, fetchJSONWithCredentials } from '../fetch';
import extendableBuiltinGen from '../lib/extendable-builtin-gen';
import { mergeQueryResults } from '../lib/queries-universal';
import { mapSubQueryResult } from '../query-helpers';

import * as metadataIntegrations from './metadata';
import * as presetIntegrations from './presets';

export const DEFAULT_DELIVERY = {
  mode: 'sync',
};
export const DISABLED_CACHE = {
  enabled: false,
};

class InquisitorError extends extendableBuiltinGen(Error) {
  constructor({ message, type, ...other }) {
    super(message);

    Object.assign(this, other);
    this.name = 'InquisitorError';
    this.message = message;
    this.type = type || 'uncategorized';
  }
}

function fetchMetadata(integration) {
  const metadata = metadataIntegrations[integration];

  return Promise.resolve(metadata);
}

function getPresets(integration, allPresets = presetIntegrations) {
  const presets = allPresets[integration] || [];
  return presets;
}

function fetchPreset(integration, uid, allPresets = presetIntegrations) {
  const presets = allPresets[integration];
  return Promise.resolve(find(presets, { id: uid }));
}

function runQuery(
  query,
  delivery,
  cache,
  timezone = '',
  queryOptions = {},
  requestHeaders,
) {
  const { InquisitorBaseURL } = config.get();

  const URL = `${InquisitorBaseURL}/query`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...requestHeaders,
  };

  const options = {
    timezone,
    ...queryOptions,
  };

  return fetchAllWithCredentials(URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({ query, delivery, cache, options }),
  })
    .then((res) => res.json())
    .then((json) => {
      // Inquisitor stream payload and therefore can append an error on an
      // along a data body. Error takes priority
      if (json.error) {
        throw new InquisitorError(json.error);
      }
      return json.data || json;
    });
}

function cancelQuery(queryId, destination, reason) {
  const { InquisitorBaseURL } = config.get();

  const URL = `${InquisitorBaseURL}/query`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  return fetchJSONWithCredentials(URL, {
    method: 'DELETE',
    headers,
    body: JSON.stringify({ query_id: queryId, destination, reason }),
  });
}

function get(
  {
    config: { queries, type, queryOptions = {} },
    dashboard: { timezone },
    service_account: { id: saId },
  },
  requestHeaders,
) {
  return Promise.all(
    queries.map((q) => {
      const query = {
        ...q,
        source: { ...q.source, service_account_id: `${saId}` },
      };
      return universalService.runQuery(
        query,
        DEFAULT_DELIVERY,
        DISABLED_CACHE,
        timezone,
        queryOptions,
        requestHeaders,
      );
    }),
  ).then((qr) => mergeQueryResults(queries, qr, type));
}

function getConfig(
  { config: cfg, dashboard: { timezone }, service_account: { id: saId } },
  requestHeaders,
) {
  const { queryMetas = [], queryOptions = {} } = cfg;

  // Fetch values for dynamic enums
  return Promise.all(
    queryMetas.map((queryMeta) => {
      const { select = [] } = queryMeta;
      return Promise.all(
        select.map((selectMeta) => {
          const { query_custom_values: customQuery } = selectMeta;
          if (customQuery) {
            const query = {
              ...customQuery,
              source: {
                ...customQuery.source,
                service_account_id: `${saId}`,
              },
            };
            return universalService
              .runQuery(
                query,
                DEFAULT_DELIVERY,
                DISABLED_CACHE,
                timezone,
                queryOptions,
                requestHeaders,
              )
              .then((result) => {
                return mapSubQueryResult(query, result);
              })
              .then((values) => {
                return values.map((value) => ({
                  key: value.value || value.id,
                  value: value.name,
                }));
              });
          }
          return null;
        }),
      );
    }),
  ).then((transformedValues) => {
    return {
      ...cfg,
      queryMetas: cfg.queryMetas.map((queryMeta, i) => {
        const { select = [] } = queryMeta;

        return {
          ...queryMeta,
          select: select.map((selectMeta, j) => {
            const values = transformedValues[i][j] || selectMeta.values;
            return values
              ? {
                  ...selectMeta,
                  values,
                }
              : selectMeta;
          }),
        };
      }),
    };
  });
}

const universalService = {
  fetchMetadata,
  getPresets,
  fetchPreset,
  runQuery,
  get,
  getConfig,
  cancelQuery,
  InquisitorError,
};
export default universalService;
