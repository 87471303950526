import { getInstrumentIdFromWidgetKey } from './graphql-dashboard-helpers';

export const pollForInstrumentIdFromWidgetKey = async (
  widgetKey: string,
  maxRetries = 5,
  delay = 500,
) => {
  let retries = 0;
  while (retries <= maxRetries) {
    try {
      const instrumentId = await getInstrumentIdFromWidgetKey(widgetKey);
      if (instrumentId) {
        return instrumentId;
      }
    } catch (ex) {
      // If the final attempt then throw the error
      if (retries === maxRetries - 1) throw ex;
    }

    retries++;

    await new Promise((resolve) => setTimeout(resolve, delay));
  }

  throw new Error('Failed to retrieve instrument ID after maximum retries');
};
