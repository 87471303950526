import moment from 'moment';

import * as whatsNewActions from '../../whats-new/whats-new-actions/whats-new-actions';
import * as userActions from '../actions/user-actions';

const initialState = {};

const userReducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case userActions.getCurrentUserSuccess.type:
    case whatsNewActions.markAsReadAction.type: {
      const { trial_end: trialEnd, ...userProps } = payload;
      const trialDaysRemaining = moment
        .utc(trialEnd)
        .diff(moment.utc(), 'days');

      return {
        ...state,
        user: {
          ...userProps,
          trialDaysRemaining,
        },
      };
    }
    case userActions.getCurrentUserFailed.type:
      return { ...state, error };

    case userActions.setUserGroups.type:
      return {
        ...state,
        groups: payload,
      };

    case userActions.setUser.type:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };

    case userActions.getUserOrganizationSuccess.type:
    case userActions.setUserOrganization.type: {
      return {
        ...state,
        organization: { ...state.organization, ...payload },
      };
    }

    default:
      return state;
  }
};

export default userReducer;
