import { composeTransformers } from '../../compose-transformers';
import { transform as goalTransform } from '../../shared/goal';
import { transform as numberFormatTransform } from '../../shared/number-format';
import { transform as statusIndicators } from '../../shared/status-indicators';

import { transform as minMaxTransform } from './minmax';

const transform = composeTransformers(
  numberFormatTransform,
  goalTransform,
  minMaxTransform,
  statusIndicators,
);

export { transform };
