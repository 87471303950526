import { isValidSelection } from '../selection-helpers';

const getDefaultConfig = (selections = []) => {
  return {
    type: 'text',
    value: selections[0],
  };
};

const getSelectionsFromConfig = (config) => [config.value];

const isValid = (cfg) => {
  return isValidSelection(cfg.value);
};

// targets cells with values
const getSelectionTargets = ({ isLoop }) => {
  if (isLoop) {
    return [-5, -4, -3, -2, -1];
  }
  return [-1];
};

const configMappingRules = {
  number: ['dataRange', 'title'],
  geckometer: ['dataRange', 'title'],
  leaderboard: ['dataRange', 'title'],
  line: ['dataRange', 'title'],
  column: ['dataRange', 'title'],
  bar: ['dataRange', 'title'],
  table: ['dataRange', 'title'],
};

export default {
  getDefaultConfig,
  getSelectionsFromConfig,
  isValid,
  getSelectionTargets,
  configMappingRules,
};
