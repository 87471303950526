import BarChartSerializer from './bar-chart';
import ColumnChartSerializer from './column-chart';
import GeckometerSerializer from './geckometer-serializer';
import LeaderboardSerializer from './leaderboard-serializer';
import LineChartSerializer from './line-chart';
import NumberSerializer from './number-serializer';
import TableSerializer from './table-serializer';
import TextSerializer from './text-serializer';

export default function getSerializer(visualisation) {
  switch (visualisation) {
    case 'column':
      return new ColumnChartSerializer();
    case 'bar':
      return new BarChartSerializer();
    case 'line':
      return new LineChartSerializer();
    case 'number':
      return new NumberSerializer();
    case 'leaderboard':
      return new LeaderboardSerializer();
    case 'geckometer':
      return new GeckometerSerializer();
    case 'text':
      return new TextSerializer();
    case 'table':
      return new TableSerializer();
    default:
      throw new Error(`No serializer for the visualisation "${visualisation}"`);
  }
}
