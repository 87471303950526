import Serializer from './default';

class TableSerializer extends Serializer {
  serialize(state) {
    const { columns, hasHeader, title, numberFormat } = state;

    return {
      config: {
        template: {
          type: 'table',
          version: 1,
        },
        title,
        hasHeader,
        columns,
        numberFormat,
      },
    };
  }

  deserialize(json) {
    const { columns, title, hasHeader, numberFormat } = json.config;

    return {
      type: 'table',
      columns,
      hasHeader,
      title,
      numberFormat,
    };
  }
}

export default TableSerializer;
