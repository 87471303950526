import * as containerLayoutActions from '../actions/container-layout-actions';

const initialState = {
  resizingContainer: null,
  emptySpaces: null,
  hoveredArea: null,
  selectedContainer: null,
};

const containerLayoutReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case containerLayoutActions.setSelectedContainer.type:
      return {
        ...state,
        selectedContainer: payload,
      };

    case containerLayoutActions.deselectContainer.type:
      return {
        ...state,
        selectedContainer: null,
      };
    case containerLayoutActions.resizeContainerStart.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const isSelectedContainer = state.selectedContainer === payload;

      return {
        ...state,
        resizingContainer: payload,
        selectedContainer: isSelectedContainer ? state.selectedContainer : null,
      };
    case containerLayoutActions.resizeContainerEnd.type:
      return {
        ...state,
        resizingContainer: null,
      };
    case containerLayoutActions.setEmptySpaces.type:
      return {
        ...state,
        emptySpaces: payload,
      };
    case containerLayoutActions.removeEmptySpaces.type:
      return {
        ...state,
        emptySpaces: null,
      };
    default:
      return state;
  }
};

export default containerLayoutReducer;
export { initialState };
