import { chain, map } from 'lodash';

const VALUES_QUERY_TEMPLATE =
  '{"select": [ { "resource": "reports", "path": "<%= key %>" } ], "filter": [ { "resource": "reports", "path": "date", "operator": "timespan", "operands": [7, "day"] } ], "group_by": [ { "resource": "reports", "path": "<%= key %>", "order": "desc" } ], "order_by": [ { "resource": "reports", "path": "metric.sessions", "order": "desc" } ], "limit": 20 }';
export default {
  resources: [
    {
      name: 'reports',
      human_readable_name: 'reports',
      fields: [
        {
          key: 'custom_filter',
          name: 'Custom filter',
          type: 'custom',
          category: 'custom',
        },
        {
          key: 'date',
          name: 'Date',
          type: 'datetime',
          category: 'Datetime',
        },
        {
          key: 'accountId',
          name: 'Account',
          type: 'string',
          resource: 'accounts',
          foreign_key: 'accountId',
          target_key: 'name',
          hidden: true,
          restrict_single_value: true,
          equivalents: {
            accountSummaries: 'accountId',
            goals: 'accountId',
            customMetrics: 'accountId',
            customDimensions: 'accountId',
          },
        },
        {
          key: 'propertyId',
          name: 'Property',
          type: 'string',
          hidden: true,
          equivalents: {
            goals: 'propertyId',
            customMetrics: 'propertyId',
            customDimensions: 'propertyId',
          },
        },
        {
          key: 'viewId',
          name: 'View',
          type: 'string',
          resource: 'accountSummaries',
          foreign_key: 'viewId',
          target_key: [
            'viewId',
            'viewName',
            'propertyName',
            'propertyId',
            'accountName',
            'accountId',
          ],
          formatValue: ({ viewName }) => viewName,
          groupValues: ({ propertyName }) => propertyName,
          combined_filters: [
            ({ propertyId }) => {
              return {
                primary_resource: 'reports',
                key: 'propertyId',
                value: propertyId,
              };
            },
          ],
          restrict_single_value: true,
          is_global: true,
          exclude_from_groupby: true,
          equivalents: {
            goals: 'viewId',
            views: 'id',
          },
        },
        {
          key: 'segment',
          name: 'Segment',
          type: 'string',
          resource: 'segments',
          category: 'Segments',
          foreign_key: 'segmentId',
          target_key: 'name',
          exclude_from_groupby: true,
          restrict_single_value: true,
        },
        {
          key: 'metric.users',
          type: 'int',
          name: 'Users',
          aggregated: true,
          category: 'User',
        },
        {
          key: 'metric.newUsers',
          type: 'int',
          name: 'New users',
          aggregated: true,
          category: 'User',
        },
        {
          key: 'metric.percentNewSessions',
          type: 'percent',
          name: 'Percentage of new sessions',
          aggregated: true,
          category: 'User',
        },
        {
          key: 'metric.sessionsPerUser',
          type: 'float',
          name: 'Number of sessions per user',
          aggregated: true,
          category: 'User',
        },
        {
          key: 'metric.1dayUsers',
          type: 'int',
          name: '1 day active users',
          aggregated: true,
          supported_visualisations: ['line'],
          show_expanded: true,
          category: 'User',
        },
        {
          key: 'metric.7dayUsers',
          type: 'int',
          name: '7 day active users',
          aggregated: true,
          supported_visualisations: ['line'],
          show_expanded: true,
          category: 'User',
        },
        {
          key: 'metric.14dayUsers',
          type: 'int',
          name: '14 day active users',
          aggregated: true,
          supported_visualisations: ['line'],
          show_expanded: true,
          category: 'User',
        },
        {
          key: 'metric.28dayUsers',
          type: 'int',
          name: '28 day active users',
          aggregated: true,
          supported_visualisations: ['line'],
          show_expanded: true,
          category: 'User',
        },
        {
          key: 'metric.30dayUsers',
          type: 'int',
          name: '30 day active users',
          aggregated: true,
          supported_visualisations: ['line'],
          show_expanded: true,
          category: 'User',
        },
        {
          key: 'metric.sessions',
          type: 'int',
          name: 'Sessions',
          aggregated: true,
          category: 'Session',
        },
        {
          key: 'metric.bounceRate',
          type: 'percent',
          name: 'Bounce rate',
          aggregated: true,
          category: 'Session',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgSessionDuration',
          type: 'duration',
          name: 'Average session duration',
          unit: 'seconds',
          aggregated: true,
          category: 'Session',
        },
        {
          key: 'metric.bounces',
          type: 'int',
          name: 'Bounces',
          aggregated: true,
          show_expanded: true,
          category: 'Session',
          reverse_comparison: true,
        },
        {
          key: 'metric.uniqueDimensionCombinations',
          type: 'int',
          name: 'Unique dimension combinations',
          aggregated: true,
          show_expanded: true,
          category: 'Session',
        },
        {
          key: 'metric.hits',
          type: 'int',
          name: 'Hits',
          aggregated: true,
          show_expanded: true,
          category: 'Session',
        },
        {
          key: 'metric.pageviews',
          type: 'int',
          name: 'Pageviews',
          aggregated: true,
          category: 'Page',
        },
        {
          key: 'metric.uniquePageviews',
          type: 'int',
          name: 'Unique pageviews',
          aggregated: true,
          category: 'Page',
        },
        {
          key: 'metric.avgTimeOnPage',
          type: 'duration',
          name: 'Average time on page',
          unit: 'seconds',
          aggregated: true,
          category: 'Page',
        },
        {
          key: 'metric.pageviewsPerSession',
          type: 'float',
          name: 'Pageviews per session',
          aggregated: true,
          category: 'Page',
        },
        {
          key: 'metric.pageValue',
          type: 'currency',
          name: 'Page value',
          aggregated: true,
          category: 'Page',
        },
        {
          key: 'metric.entrances',
          type: 'int',
          name: 'Entrances',
          aggregated: true,
          category: 'Page',
        },
        {
          key: 'metric.entranceRate',
          type: 'percent',
          name: 'Entrance rate',
          aggregated: true,
          category: 'Page',
        },
        {
          key: 'metric.exits',
          type: 'int',
          name: 'Exits',
          aggregated: true,
          category: 'Page',
          reverse_comparison: true,
        },
        {
          key: 'metric.exitRate',
          type: 'percent',
          name: 'Exit rate',
          aggregated: true,
          category: 'Page',
          reverse_comparison: true,
        },
        {
          key: 'metric.totalEvents',
          type: 'int',
          name: 'Events',
          aggregated: true,
          category: 'Events',
        },
        {
          key: 'metric.uniqueEvents',
          type: 'int',
          name: 'Unique events',
          aggregated: true,
          category: 'Events',
        },
        {
          key: 'metric.avgEventValue',
          type: 'float',
          name: 'Average event value',
          aggregated: true,
          category: 'Events',
        },
        {
          key: 'metric.eventValue',
          type: 'int',
          name: 'Event value',
          aggregated: true,
          category: 'Events',
        },
        {
          key: 'metric.eventsPerSessionWithEvent',
          type: 'float',
          name: 'Events per session',
          aggregated: true,
          category: 'Events',
        },
        {
          key: 'metric.sessionsWithEvent',
          type: 'int',
          name: 'Sessions with event',
          aggregated: true,
          category: 'Events',
        },
        {
          key: 'metric.goalStartsAll',
          type: 'int',
          name: 'Goal starts',
          aggregated: true,
          category: 'Goals',
        },
        {
          key: 'metric.goalCompletionsAll',
          type: 'int',
          name: 'Goal completions',
          aggregated: true,
          category: 'Goals',
        },
        {
          key: 'metric.goalConversionRateAll',
          type: 'percent',
          name: 'Goal conversion rate',
          aggregated: true,
          category: 'Goals',
        },
        {
          key: 'metric.goalAbandonsAll',
          type: 'int',
          name: 'Goal abandons',
          aggregated: true,
          category: 'Goals',
        },
        {
          key: 'metric.goalAbandonRateAll',
          type: 'percent',
          name: 'Goal abandonment rate',
          aggregated: true,
          category: 'Goals',
        },
        {
          key: 'metric.goalValueAll',
          type: 'currency',
          name: 'Goal value',
          aggregated: true,
          category: 'Goals',
        },
        {
          key: 'metric.goalValuePerSession',
          type: 'currency',
          name: 'Goal value per session',
          aggregated: true,
          category: 'Goals',
        },
        {
          key: 'metric.transactionRevenue',
          type: 'currency',
          name: 'Revenue',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.itemRevenue',
          type: 'currency',
          name: 'Product revenue',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.transactions',
          type: 'int',
          name: 'Transactions',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.itemQuantity',
          type: 'int',
          name: 'Number of items purchased',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.uniquePurchases',
          type: 'int',
          name: 'Number of unique products purchased',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.transactionsPerSession',
          type: 'percent',
          name: 'Ecommerce conversion rate',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.revenuePerTransaction',
          type: 'currency',
          name: 'Average order value',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.itemsPerPurchase',
          type: 'float',
          name: 'Average number of items per purchase',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.revenuePerUser',
          type: 'currency',
          name: 'Average revenue per user',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.transactionRevenuePerSession',
          type: 'currency',
          name: 'Average revenue per session',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.revenuePerItem',
          type: 'currency',
          name: 'Average revenue per item',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.productRevenuePerPurchase',
          type: 'currency',
          name: 'Average product revenue per purchase',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.transactionsPerUser',
          type: 'float',
          name: 'Average transactions per user',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.totalValue',
          type: 'currency',
          name: 'Total value',
          aggregated: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.transactionTax',
          type: 'currency',
          name: 'Tax',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.transactionShipping',
          type: 'currency',
          name: 'Shipping',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.refundAmount',
          type: 'currency',
          name: 'Refund amount',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
          reverse_comparison: true,
        },
        {
          key: 'metric.productRefundAmount',
          type: 'currency',
          name: 'Refund amount (by product)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
          reverse_comparison: true,
        },
        {
          key: 'metric.totalRefunds',
          type: 'int',
          name: 'Number of refunds',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
          reverse_comparison: true,
        },
        {
          key: 'metric.quantityRefunded',
          type: 'int',
          name: 'Number of refunds (by unit)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
          reverse_comparison: true,
        },
        {
          key: 'metric.productRefunds',
          type: 'int',
          name: 'Number of refunds (by product)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
          reverse_comparison: true,
        },
        {
          key: 'metric.buyToDetailRate',
          type: 'percent',
          name: 'Buy to detail rate',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.cartToDetailRate',
          type: 'percent',
          name: 'Cart to detail rate',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.productDetailViews',
          type: 'int',
          name: 'Product page views (by product)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.productAddsToCart',
          type: 'int',
          name: 'Added to cart (by product)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.quantityAddedToCart',
          type: 'int',
          name: 'Added to cart (by unit)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.productRemovesFromCart',
          type: 'int',
          name: 'Removed from cart (by product)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
          reverse_comparison: true,
        },
        {
          key: 'metric.quantityRemovedFromCart',
          type: 'int',
          name: 'Removed from cart (by unit)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
          reverse_comparison: true,
        },
        {
          key: 'metric.productCheckouts',
          type: 'int',
          name: 'Checked out (by product)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.quantityCheckedOut',
          type: 'int',
          name: 'Checked out (by unit)',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.productListCTR',
          type: 'percent',
          name: 'Product list - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.productListClicks',
          type: 'int',
          name: 'Product list - Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.productListViews',
          type: 'int',
          name: 'Product list - Views',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.internalPromotionCTR',
          type: 'percent',
          name: 'Internal promotion - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.internalPromotionClicks',
          type: 'int',
          name: 'Internal promotion - Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.internalPromotionViews',
          type: 'int',
          name: 'Internal Promotion - Views',
          aggregated: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'metric.screenviews',
          type: 'int',
          name: 'Screen views',
          aggregated: true,
          category: 'App tracking',
        },
        {
          key: 'metric.uniqueScreenviews',
          type: 'int',
          name: 'Unique screen views',
          aggregated: true,
          category: 'App tracking',
        },
        {
          key: 'metric.screenviewsPerSession',
          type: 'float',
          name: 'Average screen views per session',
          aggregated: true,
          category: 'App tracking',
        },
        {
          key: 'metric.avgScreenviewDuration',
          type: 'duration',
          name: 'Average time on screen',
          unit: 'seconds',
          aggregated: true,
          category: 'App tracking',
        },
        {
          key: 'metric.socialInteractions',
          type: 'int',
          name: 'Social interactions',
          aggregated: true,
          category: 'Social interactions',
        },
        {
          key: 'metric.socialInteractionsPerSession',
          type: 'float',
          name: 'Social interactions per session',
          aggregated: true,
          category: 'Social interactions',
        },
        {
          key: 'metric.uniqueSocialInteractions',
          type: 'int',
          name: 'Unique social interactions',
          aggregated: true,
          category: 'Social interactions',
        },
        {
          key: 'metric.avgPageLoadTime',
          type: 'duration',
          name: 'Average page load time',
          unit: 'seconds',
          aggregated: true,
          category: 'Site speed',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgDomainLookupTime',
          type: 'duration',
          name: 'Average domain lookup time',
          unit: 'seconds',
          aggregated: true,
          show_expanded: true,
          category: 'Site speed',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgPageDownloadTime',
          type: 'duration',
          name: 'Average page download time',
          unit: 'seconds',
          aggregated: true,
          show_expanded: true,
          category: 'Site speed',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgRedirectionTime',
          type: 'duration',
          name: 'Average redirection time',
          unit: 'seconds',
          aggregated: true,
          show_expanded: true,
          category: 'Site speed',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgServerConnectionTime',
          type: 'duration',
          name: 'Average server connection time',
          unit: 'seconds',
          aggregated: true,
          show_expanded: true,
          category: 'Site speed',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgServerResponseTime',
          type: 'duration',
          name: 'Average server response time',
          unit: 'seconds',
          aggregated: true,
          show_expanded: true,
          category: 'Site speed',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgDomInteractiveTime',
          type: 'duration',
          name: 'Average document interactive time',
          unit: 'seconds',
          aggregated: true,
          show_expanded: true,
          category: 'Site speed',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgDomContentLoadedTime',
          type: 'duration',
          name: 'Average document content loaded time',
          unit: 'seconds',
          aggregated: true,
          show_expanded: true,
          category: 'Site speed',
          reverse_comparison: true,
        },
        {
          key: 'metric.avgUserTimingValue',
          type: 'duration',
          name: 'Average user timing',
          unit: 'seconds',
          aggregated: true,
          category: 'User timings',
          reverse_comparison: true,
        },
        {
          key: 'metric.exceptions',
          type: 'int',
          name: 'Exceptions',
          aggregated: true,
          category: 'Exceptions',
          reverse_comparison: true,
        },
        {
          key: 'metric.exceptionsPerScreenview',
          type: 'percent',
          name: 'Exceptions per screen',
          aggregated: true,
          category: 'Exceptions',
          reverse_comparison: true,
        },
        {
          key: 'metric.fatalExceptions',
          type: 'int',
          name: 'Crashes',
          aggregated: true,
          category: 'Exceptions',
          reverse_comparison: true,
        },
        {
          key: 'metric.fatalExceptionsPerScreenview',
          type: 'percent',
          name: 'Crashes per screen',
          aggregated: true,
          category: 'Exceptions',
          reverse_comparison: true,
        },
        {
          key: 'metric.searchUniques',
          type: 'int',
          name: 'Searches',
          aggregated: true,
          category: 'Site search',
        },
        {
          key: 'metric.searchSessions',
          type: 'int',
          name: 'Sessions with search',
          aggregated: true,
          category: 'Site search',
        },
        {
          key: 'metric.percentSessionsWithSearch',
          type: 'percent',
          name: 'Percentage of sessions with search',
          aggregated: true,
          category: 'Site search',
        },
        {
          key: 'metric.searchResultViews',
          type: 'int',
          name: 'Search result views',
          aggregated: true,
          category: 'Site search',
        },
        {
          key: 'metric.avgSearchResultViews',
          type: 'float',
          name: 'Search result views per search',
          aggregated: true,
          category: 'Site search',
        },
        {
          key: 'metric.avgSearchDepth',
          type: 'float',
          name: 'Average search depth',
          aggregated: true,
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'metric.avgSearchDuration',
          type: 'duration',
          name: 'Average time after search',
          unit: 'seconds',
          aggregated: true,
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'metric.searchExits',
          type: 'int',
          name: 'Search exits',
          aggregated: true,
          show_expanded: true,
          category: 'Site search',
          reverse_comparison: true,
        },
        {
          key: 'metric.searchExitRate',
          type: 'percent',
          name: 'Search exit rate',
          aggregated: true,
          show_expanded: true,
          category: 'Site search',
          reverse_comparison: true,
        },
        {
          key: 'metric.searchRefinements',
          type: 'int',
          name: 'Search refinements',
          aggregated: true,
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'metric.percentSearchRefinements',
          type: 'percent',
          name: 'Search refinement rate',
          aggregated: true,
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'metric.goalValueAllPerSearch',
          type: 'currency',
          name: 'Average goal value per search',
          aggregated: true,
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'metric.searchGoalConversionRateAll',
          type: 'percent',
          name: 'Site search goal conversion rate',
          aggregated: true,
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'metric.impressions',
          type: 'int',
          name: 'Impressions',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'metric.adClicks',
          type: 'int',
          name: 'Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'metric.adCost',
          type: 'currency',
          name: 'Cost',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
          reverse_comparison: true,
        },
        {
          key: 'metric.CPC',
          type: 'currency',
          name: 'Cost per click',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
          reverse_comparison: true,
        },
        {
          key: 'metric.CTR',
          type: 'percent',
          name: 'Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'metric.costPerConversion',
          type: 'currency',
          name: 'Cost per conversion',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
          reverse_comparison: true,
        },
        {
          key: 'metric.costPerGoalConversion',
          type: 'currency',
          name: 'Cost per goal conversion',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
          reverse_comparison: true,
        },
        {
          key: 'metric.CPM',
          type: 'currency',
          name: 'Cost per thousand impressions',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
          reverse_comparison: true,
        },
        {
          key: 'metric.costPerTransaction',
          type: 'currency',
          name: 'Cost per transaction',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
          reverse_comparison: true,
        },
        {
          key: 'metric.ROAS',
          type: 'percent',
          name: 'Return on ad spend',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'metric.RPC',
          type: 'currency',
          name: 'Revenue per click',
          aggregated: true,
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'metric.adsenseRevenue',
          type: 'currency',
          name: 'AdSense - Revenue',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
        },
        {
          key: 'metric.adsenseAdUnitsViewed',
          type: 'int',
          name: 'AdSense - Ad units viewed',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
        },
        {
          key: 'metric.adsenseAdsViewed',
          type: 'int',
          name: 'AdSense - Ad viewed',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
        },
        {
          key: 'metric.adsenseAdsClicks',
          type: 'int',
          name: 'AdSense - Ads clicked',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
        },
        {
          key: 'metric.adsensePageImpressions',
          type: 'int',
          name: 'AdSense - Page impressions',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
        },
        {
          key: 'metric.adsenseCTR',
          type: 'percent',
          name: 'AdSense - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
        },
        {
          key: 'metric.adsenseECPM',
          type: 'currency',
          name: 'AdSense - Effective cost per thousand page impressions',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
          reverse_comparison: true,
        },
        {
          key: 'metric.adsenseExits',
          type: 'int',
          name: 'AdSense - Exits',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
          reverse_comparison: true,
        },
        {
          key: 'metric.adsenseViewableImpressionPercent',
          type: 'percent',
          name: 'AdSense - Viewable impression rate',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
        },
        {
          key: 'metric.adsenseCoverage',
          type: 'percent',
          name: 'AdSense - Coverage',
          aggregated: true,
          show_expanded: true,
          category: 'Adsense',
        },
        {
          key: 'metric.adxImpressions',
          type: 'int',
          name: 'AdX - Impressions',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.adxImpressionsPerSession',
          type: 'float',
          name: 'AdX - Impressions per session',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.adxCoverage',
          type: 'percent',
          name: 'AdX - Coverage',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.adxMonetizedPageviews',
          type: 'int',
          name: 'AdX - Monetized Pageviews',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.adxClicks',
          type: 'int',
          name: 'AdX - Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.adxCTR',
          type: 'percent',
          name: 'AdX - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.adxECPM',
          type: 'currency',
          name: 'AdX - Effective cost per thousand page impressions',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
          reverse_comparison: true,
        },
        {
          key: 'metric.adxRevenue',
          type: 'currency',
          name: 'AdX - Revenue',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.adxRevenuePer1000Sessions',
          type: 'currency',
          name: 'AdX - Revenue per thousand sessions',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.adxViewableImpressionsPercent',
          type: 'percent',
          name: 'AdX - Viewable impression rate',
          aggregated: true,
          show_expanded: true,
          category: 'Ad Exchange',
        },
        {
          key: 'metric.dcmImpressions',
          type: 'int',
          name: 'DCM - Impressions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'metric.dcmClicks',
          type: 'int',
          name: 'DCM - Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'metric.dcmCost',
          type: 'currency',
          name: 'DCM - Cost',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
          reverse_comparison: true,
        },
        {
          key: 'metric.dcmCPC',
          type: 'currency',
          name: 'DCM - Cost per click',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
          reverse_comparison: true,
        },
        {
          key: 'metric.dcmCTR',
          type: 'percent',
          name: 'DCM - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'metric.dcmFloodlightQuantity',
          type: 'int',
          name: 'DCM - Conversions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'metric.dcmFloodlightRevenue',
          type: 'currency',
          name: 'DCM - Revenue',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'metric.dcmROAS',
          type: 'percent',
          name: 'DCM - Return on ad spend',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'metric.dcmRPC',
          type: 'currency',
          name: 'DCM - Revenue per click',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'metric.dfpImpressions',
          type: 'int',
          name: 'DFP - Impressions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dfpImpressionsPerSession',
          type: 'float',
          name: 'DFP - Impressions per session',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dfpClicks',
          type: 'int',
          name: 'DFP - Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dfpCTR',
          type: 'percent',
          name: 'DFP - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dfpCoverage',
          type: 'percent',
          name: 'DFP - Coverage',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dfpMonetizedPageviews',
          type: 'int',
          name: 'DFP - Monetized pageviews',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dfpECPM',
          type: 'currency',
          name: 'DFP - Effective cost per thousand page impressions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
          reverse_comparison: true,
        },
        {
          key: 'metric.dfpRevenue',
          type: 'currency',
          name: 'DFP - Revenue',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dfpRevenuePer1000Sessions',
          type: 'currency',
          name: 'DFP - Revenue per thousand sessions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dfpViewableImpressionsPercent',
          type: 'percent',
          name: 'DFP - Viewable impression rate',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillImpressions',
          type: 'int',
          name: 'DFP Backfill - Impressions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillImpressionsPerSession',
          type: 'float',
          name: 'DFP Backfill - Impressions per session',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillClicks',
          type: 'int',
          name: 'DFP Backfill - Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillCTR',
          type: 'percent',
          name: 'DFP Backfill - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillCoverage',
          type: 'percent',
          name: 'DFP Backfill - Coverage',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillMonetizedPageviews',
          type: 'int',
          name: 'DFP Backfill - Monetized Pageviews',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillECPM',
          type: 'currency',
          name: 'DFP Backfill - Effective cost per thousand page impressions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
          reverse_comparison: true,
        },
        {
          key: 'metric.backfillRevenue',
          type: 'currency',
          name: 'DFP Backfill - Revenue',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillRevenuePer1000Sessions',
          type: 'currency',
          name: 'DFP Backfill - Revenue per thousand sessions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.backfillViewableImpressionsPercent',
          type: 'percent',
          name: 'DFP Backfill - Viewable impression rate',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick for Publishers',
        },
        {
          key: 'metric.dbmCPA',
          type: 'currency',
          name: 'DBM - Effective cost per action',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
          reverse_comparison: true,
        },
        {
          key: 'metric.dbmCPC',
          type: 'currency',
          name: 'DBM - Effective cost per click',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
          reverse_comparison: true,
        },
        {
          key: 'metric.dbmCPM',
          type: 'currency',
          name: 'DBM - Effective cost per thousand page impressions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
          reverse_comparison: true,
        },
        {
          key: 'metric.dbmCTR',
          type: 'percent',
          name: 'DBM - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'metric.dbmClicks',
          type: 'int',
          name: 'DBM - Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'metric.dbmConversions',
          type: 'int',
          name: 'DBM - Conversions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'metric.dbmCost',
          type: 'currency',
          name: 'DBM - Cost',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
          reverse_comparison: true,
        },
        {
          key: 'metric.dbmImpressions',
          type: 'int',
          name: 'DBM - Impressions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'metric.dbmROAS',
          type: 'percent',
          name: 'DBM - Return on ad spend',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'metric.dsImpressions',
          type: 'int',
          name: 'DS - Impressions',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'metric.dsClicks',
          type: 'int',
          name: 'DS - Clicks',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'metric.dsCTR',
          type: 'percent',
          name: 'DS - Click through rate',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'metric.dsCPC',
          type: 'currency',
          name: 'DS - Cost per click',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Search',
          reverse_comparison: true,
        },
        {
          key: 'metric.dsRevenuePerClick',
          type: 'currency',
          name: 'DS - Revenue per click',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'metric.dsCost',
          type: 'currency',
          name: 'DS - Cost',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Search',
          reverse_comparison: true,
        },
        {
          key: 'metric.dsProfit',
          type: 'currency',
          name: 'DS - Profit',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'metric.dsReturnOnAdSpend',
          type: 'percent',
          name: 'DS - Return on ad spend',
          aggregated: true,
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'dimension.pagePath',
          type: 'string',
          name: 'Page path',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.pagePath'],
          formatValue: (value) => value['dimension.pagePath'],
          category: 'Page',
        },
        {
          key: 'dimension.pageTitle',
          type: 'string',
          name: 'Page title',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.pageTitle'],
          formatValue: (value) => value['dimension.pageTitle'],
          category: 'Page',
        },
        {
          key: 'dimension.landingPagePath',
          type: 'string',
          name: 'Landing page',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.landingPagePath'],
          formatValue: (value) => value['dimension.landingPagePath'],
          category: 'Page',
        },
        {
          key: 'dimension.exitPagePath',
          type: 'string',
          name: 'Exit page',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.exitPagePath'],
          formatValue: (value) => value['dimension.exitPagePath'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.hostname',
          type: 'string',
          name: 'Hostname',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.hostname'],
          formatValue: (value) => value['dimension.hostname'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.pageDepth',
          type: 'string',
          name: 'Page depth',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.pageDepth'],
          formatValue: (value) => value['dimension.pageDepth'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.pagePathLevel1',
          type: 'string',
          name: 'Page path level 1',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.pagePathLevel1'],
          formatValue: (value) => value['dimension.pagePathLevel1'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.pagePathLevel2',
          type: 'string',
          name: 'Page path level 2',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.pagePathLevel2'],
          formatValue: (value) => value['dimension.pagePathLevel2'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.pagePathLevel3',
          type: 'string',
          name: 'Page path level 3',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.pagePathLevel3'],
          formatValue: (value) => value['dimension.pagePathLevel3'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.pagePathLevel4',
          type: 'string',
          name: 'Page path level 4',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.pagePathLevel4'],
          formatValue: (value) => value['dimension.pagePathLevel4'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.previousPagePath',
          type: 'string',
          name: 'Previous page path',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.previousPagePath'],
          formatValue: (value) => value['dimension.previousPagePath'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.secondPagePath',
          type: 'string',
          name: 'Second page',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.secondPagePath'],
          formatValue: (value) => value['dimension.secondPagePath'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.channelGrouping',
          type: 'string',
          name: 'Channel grouping',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.channelGrouping'],
          formatValue: (value) => value['dimension.channelGrouping'],
          show_expanded: true,
          category: 'Page',
        },
        {
          key: 'dimension.userType',
          type: 'string',
          name: 'User type',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.userType'],
          formatValue: (value) => value['dimension.userType'],
          category: 'Audience',
        },
        {
          key: 'dimension.userAgeBracket',
          type: 'string',
          name: 'Age',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.userAgeBracket'],
          formatValue: (value) => value['dimension.userAgeBracket'],
          category: 'Audience',
        },
        {
          key: 'dimension.userGender',
          type: 'string',
          name: 'Gender',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.userGender'],
          formatValue: (value) => value['dimension.userGender'],
          category: 'Audience',
        },
        {
          key: 'dimension.city',
          type: 'string',
          name: 'City',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.city'],
          formatValue: (value) => value['dimension.city'],
          category: 'Audience',
        },
        {
          key: 'dimension.country',
          type: 'string',
          name: 'Country',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.country'],
          formatValue: (value) => value['dimension.country'],
          category: 'Audience',
        },
        {
          key: 'dimension.continent',
          type: 'string',
          name: 'Continent',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.continent'],
          formatValue: (value) => value['dimension.continent'],
          category: 'Audience',
        },
        {
          key: 'dimension.subContinent',
          type: 'string',
          name: 'Sub continent',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.subContinent'],
          formatValue: (value) => value['dimension.subContinent'],
          category: 'Audience',
        },
        {
          key: 'dimension.region',
          type: 'string',
          name: 'Region',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.region'],
          formatValue: (value) => value['dimension.region'],
          category: 'Audience',
        },
        {
          key: 'dimension.metro',
          type: 'string',
          name: 'Designated market area',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.metro'],
          formatValue: (value) => value['dimension.metro'],
          show_expanded: true,
          category: 'Audience',
        },
        {
          key: 'dimension.interestAffinityCategory',
          type: 'string',
          name: 'Interests (general affinity)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.interestAffinityCategory'],
          formatValue: (value) => value['dimension.interestAffinityCategory'],
          show_expanded: true,
          category: 'Audience',
        },
        {
          key: 'dimension.interestInMarketCategory',
          type: 'string',
          name: 'Interests (actively in market)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.interestInMarketCategory'],
          formatValue: (value) => value['dimension.interestInMarketCategory'],
          show_expanded: true,
          category: 'Audience',
        },
        {
          key: 'dimension.interestOtherCategory',
          type: 'string',
          name: 'Interests (other)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.interestOtherCategory'],
          formatValue: (value) => value['dimension.interestOtherCategory'],
          show_expanded: true,
          category: 'Audience',
        },
        {
          key: 'dimension.userDefinedValue',
          type: 'string',
          name: 'User defined value',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.userDefinedValue'],
          formatValue: (value) => value['dimension.userDefinedValue'],
          show_expanded: true,
          category: 'Audience',
        },
        {
          key: 'dimension.deviceCategory',
          type: 'string',
          name: 'Device',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.deviceCategory'],
          formatValue: (value) => value['dimension.deviceCategory'],
          category: 'Device',
        },
        {
          key: 'dimension.operatingSystem',
          type: 'string',
          name: 'Operating system',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.operatingSystem'],
          formatValue: (value) => value['dimension.operatingSystem'],
          category: 'Device',
        },
        {
          key: 'dimension.browser',
          type: 'string',
          name: 'Browser',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.browser'],
          formatValue: (value) => value['dimension.browser'],
          category: 'Device',
        },
        {
          key: 'dimension.language',
          type: 'string',
          name: 'Language',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.language'],
          formatValue: (value) => value['dimension.language'],
          category: 'Device',
        },
        {
          key: 'dimension.dataSource',
          type: 'string',
          name: 'Data source',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dataSource'],
          formatValue: (value) => value['dimension.dataSource'],
          show_expanded: true,
          category: 'Device',
        },
        {
          key: 'dimension.mobileDeviceBranding',
          type: 'string',
          name: 'Brand of mobile device',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.mobileDeviceBranding'],
          formatValue: (value) => value['dimension.mobileDeviceBranding'],
          show_expanded: true,
          category: 'Device',
        },
        {
          key: 'dimension.mobileDeviceInfo',
          type: 'string',
          name: 'Mobile device infomation',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.mobileDeviceInfo'],
          formatValue: (value) => value['dimension.mobileDeviceInfo'],
          show_expanded: true,
          category: 'Device',
        },
        {
          key: 'dimension.mobileDeviceMarketingName',
          type: 'string',
          name: 'Mobile device marketing name',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.mobileDeviceMarketingName'],
          formatValue: (value) => value['dimension.mobileDeviceMarketingName'],
          show_expanded: true,
          category: 'Device',
        },
        {
          key: 'dimension.mobileDeviceModel',
          type: 'string',
          name: 'Mobile device model',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.mobileDeviceModel'],
          formatValue: (value) => value['dimension.mobileDeviceModel'],
          show_expanded: true,
          category: 'Device',
        },
        {
          key: 'dimension.campaign',
          type: 'string',
          name: 'Campaign',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.campaign'],
          formatValue: (value) => value['dimension.campaign'],
          category: 'Traffic source',
        },
        {
          key: 'dimension.medium',
          type: 'string',
          name: 'Medium',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.medium'],
          formatValue: (value) => value['dimension.medium'],
          category: 'Traffic',
        },
        {
          key: 'dimension.source',
          type: 'string',
          name: 'Source',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.source'],
          formatValue: (value) => value['dimension.source'],
          category: 'Traffic',
        },
        {
          key: 'dimension.sourceMedium',
          type: 'string',
          name: 'Source and medium',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.sourceMedium'],
          formatValue: (value) => value['dimension.sourceMedium'],
          show_expanded: true,
          category: 'Traffic',
        },
        {
          key: 'dimension.adContent',
          type: 'string',
          name: 'Ad content',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adContent'],
          formatValue: (value) => value['dimension.adContent'],
          show_expanded: true,
          category: 'Traffic',
        },
        {
          key: 'dimension.fullReferrer',
          type: 'string',
          name: 'Full referrer',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.fullReferrer'],
          formatValue: (value) => value['dimension.fullReferrer'],
          show_expanded: true,
          category: 'Traffic',
        },
        {
          key: 'dimension.hasSocialSourceReferral',
          type: 'string',
          name: 'Social source referral',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.hasSocialSourceReferral'],
          formatValue: (value) => value['dimension.hasSocialSourceReferral'],
          show_expanded: true,
          category: 'Traffic',
        },
        {
          key: 'dimension.keyword',
          type: 'string',
          name: 'Keyword',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.keyword'],
          formatValue: (value) => value['dimension.keyword'],
          show_expanded: true,
          category: 'Traffic',
        },
        {
          key: 'dimension.referralPath',
          type: 'string',
          name: 'Referral path',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.referralPath'],
          formatValue: (value) => value['dimension.referralPath'],
          show_expanded: true,
          category: 'Traffic',
        },
        {
          key: 'dimension.socialNetwork',
          type: 'string',
          name: 'Social network',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.socialNetwork'],
          formatValue: (value) => value['dimension.socialNetwork'],
          show_expanded: true,
          category: 'Traffic',
        },
        {
          key: 'dimension.acquisitionCampaign',
          type: 'string',
          name: 'Acquisition campaign',
          aggregated: true,
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.acquisitionCampaign'],
          formatValue: (value) => value['dimension.acquisitionCampaign'],
          show_expanded: true,
          category: 'Acquisition',
        },
        {
          key: 'dimension.acquisitionTrafficChannel',
          type: 'string',
          name: 'Acquisition channel',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.acquisitionTrafficChannel'],
          formatValue: (value) => value['dimension.acquisitionTrafficChannel'],
          show_expanded: true,
          category: 'Acquisition',
        },
        {
          key: 'dimension.acquisitionMedium',
          type: 'string',
          name: 'Acquisition medium',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.acquisitionMedium'],
          formatValue: (value) => value['dimension.acquisitionMedium'],
          show_expanded: true,
          category: 'Acquisition',
        },
        {
          key: 'dimension.acquisitionSource',
          type: 'string',
          name: 'Acquisition source',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.acquisitionSource'],
          formatValue: (value) => value['dimension.acquisitionSource'],
          show_expanded: true,
          category: 'Acquisition',
        },
        {
          key: 'dimension.acquisitionSourceMedium',
          type: 'string',
          name: 'Acquisition source and medium',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.acquisitionSourceMedium'],
          formatValue: (value) => value['dimension.acquisitionSourceMedium'],
          show_expanded: true,
          category: 'Acquisition',
        },
        {
          key: 'dimension.cohort',
          type: 'string',
          name: 'Cohort',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.cohort'],
          formatValue: (value) => value['dimension.cohort'],
          show_expanded: true,
          category: 'Cohort',
        },
        {
          key: 'dimension.productName',
          type: 'string',
          name: 'Product',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.productName'],
          formatValue: (value) => value['dimension.productName'],
          category: 'Ecommerce',
        },
        {
          key: 'dimension.productSku',
          type: 'string',
          name: 'Product SKU',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.productSku'],
          formatValue: (value) => value['dimension.productSku'],
          category: 'Ecommerce',
        },
        {
          key: 'dimension.productCategory',
          type: 'string',
          name: 'Product category',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.productCategory'],
          formatValue: (value) => value['dimension.productCategory'],
          category: 'Ecommerce',
        },
        {
          key: 'dimension.productCategoryHierarchy',
          type: 'string',
          name: 'Product category (enhanced ecommerce)',
          supports_contains_operators: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.productBrand',
          type: 'string',
          name: 'Product brand',
          supports_contains_operators: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.affiliation',
          type: 'string',
          name: 'Affiliation',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.affiliation'],
          formatValue: (value) => value['dimension.affiliation'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.checkoutOptions',
          type: 'string',
          name: 'Checkout options',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.checkoutOptions'],
          formatValue: (value) => value['dimension.checkoutOptions'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.currencyCode',
          type: 'string',
          name: 'Currency code',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.currencyCode'],
          formatValue: (value) => value['dimension.currencyCode'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.daysToTransaction',
          type: 'string',
          name: 'Days to transaction',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.daysToTransaction'],
          formatValue: (value) => value['dimension.daysToTransaction'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.internalPromotionCreative',
          type: 'string',
          name: 'Internal promotion creative',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.internalPromotionCreative'],
          formatValue: (value) => value['dimension.internalPromotionCreative'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.internalPromotionName',
          type: 'string',
          name: 'Internal Promotion Name',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.internalPromotionName'],
          formatValue: (value) => value['dimension.internalPromotionName'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.internalPromotionPosition',
          type: 'string',
          name: 'Internal promotion position',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.internalPromotionPosition'],
          formatValue: (value) => value['dimension.internalPromotionPosition'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.orderCouponCode',
          type: 'string',
          name: 'Order coupon code',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.orderCouponCode'],
          formatValue: (value) => value['dimension.orderCouponCode'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.productCouponCode',
          type: 'string',
          name: 'Product coupon code',
          supports_contains_operators: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.productListName',
          type: 'string',
          name: 'Product List Name',
          supports_contains_operators: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.productListPosition',
          type: 'string',
          name: 'Product list position',
          supports_contains_operators: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.productVariant',
          type: 'string',
          name: 'Product variant',
          supports_contains_operators: true,
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.sessionsToTransaction',
          type: 'string',
          name: 'Sessions to transaction',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.sessionsToTransaction'],
          formatValue: (value) => value['dimension.sessionsToTransaction'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.shoppingStage',
          type: 'string',
          name: 'Shopping stage',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.shoppingStage'],
          formatValue: (value) => value['dimension.shoppingStage'],
          show_expanded: true,
          category: 'Ecommerce',
        },
        {
          key: 'dimension.goalCompletionLocation',
          type: 'string',
          name: 'Goal completion location',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.goalCompletionLocation'],
          formatValue: (value) => value['dimension.goalCompletionLocation'],
          show_expanded: true,
          category: 'Goals',
        },
        {
          key: 'dimension.goalPreviousStep1',
          type: 'string',
          name: 'Goal previous step - 1',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.goalPreviousStep1'],
          formatValue: (value) => value['dimension.goalPreviousStep1'],
          show_expanded: true,
          category: 'Goals',
        },
        {
          key: 'dimension.goalPreviousStep2',
          type: 'string',
          name: 'Goal previous step - 2',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.goalPreviousStep2'],
          formatValue: (value) => value['dimension.goalPreviousStep2'],
          show_expanded: true,
          category: 'Goals',
        },
        {
          key: 'dimension.goalPreviousStep3',
          type: 'string',
          name: 'Goal previous step - 3',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.goalPreviousStep3'],
          formatValue: (value) => value['dimension.goalPreviousStep3'],
          show_expanded: true,
          category: 'Goals',
        },
        {
          key: 'dimension.eventAction',
          type: 'string',
          name: 'Event action',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.eventAction'],
          formatValue: (value) => value['dimension.eventAction'],
          category: 'Events',
        },
        {
          key: 'dimension.eventCategory',
          type: 'string',
          name: 'Event category',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.eventCategory'],
          formatValue: (value) => value['dimension.eventCategory'],
          category: 'Events',
        },
        {
          key: 'dimension.eventLabel',
          type: 'string',
          name: 'Event label',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.eventLabel'],
          formatValue: (value) => value['dimension.eventLabel'],
          category: 'Events',
        },
        {
          key: 'dimension.appName',
          type: 'string',
          name: 'App Name',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.appName'],
          formatValue: (value) => value['dimension.appName'],
          category: 'App tracking',
        },
        {
          key: 'dimension.appVersion',
          type: 'string',
          name: 'App version',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.appVersion'],
          formatValue: (value) => value['dimension.appVersion'],
          category: 'App tracking',
        },
        {
          key: 'dimension.screenName',
          type: 'string',
          name: 'Screen Name',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.screenName'],
          formatValue: (value) => value['dimension.screenName'],
          category: 'App tracking',
        },
        {
          key: 'dimension.exitScreenName',
          type: 'string',
          name: 'Exit Screen',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.exitScreenName'],
          formatValue: (value) => value['dimension.exitScreenName'],
          show_expanded: true,
          category: 'App tracking',
        },
        {
          key: 'dimension.landingScreenName',
          type: 'string',
          name: 'Landing Screen',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.landingScreenName'],
          formatValue: (value) => value['dimension.landingScreenName'],
          show_expanded: true,
          category: 'App tracking',
        },
        {
          key: 'dimension.screenDepth',
          type: 'string',
          name: 'Screen depth',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.screenDepth'],
          formatValue: (value) => value['dimension.screenDepth'],
          show_expanded: true,
          category: 'App tracking',
        },
        {
          key: 'dimension.appInstallerId',
          type: 'string',
          name: 'App installer ID',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.appInstallerId'],
          formatValue: (value) => value['dimension.appInstallerId'],
          show_expanded: true,
          category: 'App tracking',
        },
        {
          key: 'dimension.exceptionDescription',
          type: 'string',
          name: 'Exception description',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.exceptionDescription'],
          formatValue: (value) => value['dimension.exceptionDescription'],
          show_expanded: true,
          category: 'Exceptions',
        },
        {
          key: 'dimension.userTimingCategory',
          type: 'string',
          name: 'User timing category',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.userTimingCategory'],
          formatValue: (value) => value['dimension.userTimingCategory'],
          show_expanded: true,
          category: 'User timings',
        },
        {
          key: 'dimension.userTimingLabel',
          type: 'string',
          name: 'User timing label',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.userTimingLabel'],
          formatValue: (value) => value['dimension.userTimingLabel'],
          show_expanded: true,
          category: 'User timings',
        },
        {
          key: 'dimension.userTimingVariable',
          type: 'string',
          name: 'User timing variable',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.userTimingVariable'],
          formatValue: (value) => value['dimension.userTimingVariable'],
          show_expanded: true,
          category: 'User timings',
        },
        {
          key: 'dimension.searchUsed',
          type: 'string',
          name: 'Site search used',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.searchUsed'],
          formatValue: (value) => value['dimension.searchUsed'],
          show_expanded: true,
          category: 'Internal',
        },
        {
          key: 'dimension.searchKeyword',
          type: 'string',
          name: 'Site search keyword',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.searchKeyword'],
          formatValue: (value) => value['dimension.searchKeyword'],
          show_expanded: true,
          category: 'Internal',
        },
        {
          key: 'dimension.searchDestinationPage',
          type: 'string',
          name: 'Site search destination page',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.searchDestinationPage'],
          formatValue: (value) => value['dimension.searchDestinationPage'],
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'dimension.searchCategory',
          type: 'string',
          name: 'Site search category',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.searchCategory'],
          formatValue: (value) => value['dimension.searchCategory'],
          show_expanded: true,
          category: 'Search site',
        },
        {
          key: 'dimension.searchStartPage',
          type: 'string',
          name: 'Site search start page',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.searchStartPage'],
          formatValue: (value) => value['dimension.searchStartPage'],
          show_expanded: true,
          category: 'Internal',
        },
        {
          key: 'dimension.searchAfterDestinationPage',
          type: 'string',
          name: 'Site search after destination page',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.searchAfterDestinationPage'],
          formatValue: (value) => value['dimension.searchAfterDestinationPage'],
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'dimension.searchKeywordRefinement',
          type: 'string',
          name: 'Site search keyword refinment',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.searchKeywordRefinement'],
          formatValue: (value) => value['dimension.searchKeywordRefinement'],
          show_expanded: true,
          category: 'Site search',
        },
        {
          key: 'dimension.adDestinationUrl',
          type: 'string',
          name: 'Destination URL',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adDestinationUrl'],
          formatValue: (value) => value['dimension.adDestinationUrl'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adDisplayUrl',
          type: 'string',
          name: 'Display URL',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adDisplayUrl'],
          formatValue: (value) => value['dimension.adDisplayUrl'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adDistributionNetwork',
          type: 'string',
          name: 'Ad Distribution Network',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adDistributionNetwork'],
          formatValue: (value) => value['dimension.adDistributionNetwork'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adFormat',
          type: 'string',
          name: 'Ad Format',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adFormat'],
          formatValue: (value) => value['dimension.adFormat'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adGroup',
          type: 'string',
          name: 'AdWords Ad Group',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adGroup'],
          formatValue: (value) => value['dimension.adGroup'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adKeywordMatchType',
          type: 'string',
          name: 'Keyword Match Type',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adKeywordMatchType'],
          formatValue: (value) => value['dimension.adKeywordMatchType'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adMatchType',
          type: 'string',
          name: 'Query Match Type',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adMatchType'],
          formatValue: (value) => value['dimension.adMatchType'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adMatchedQuery',
          type: 'string',
          name: 'Search Query',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adMatchedQuery'],
          formatValue: (value) => value['dimension.adMatchedQuery'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adPlacementDomain',
          type: 'string',
          name: 'Placement Domain',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adPlacementDomain'],
          formatValue: (value) => value['dimension.adPlacementDomain'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adPlacementUrl',
          type: 'string',
          name: 'Placement URL',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adPlacementUrl'],
          formatValue: (value) => value['dimension.adPlacementUrl'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adQueryWordCount',
          type: 'string',
          name: 'Query Word Count',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adQueryWordCount'],
          formatValue: (value) => value['dimension.adQueryWordCount'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adSlot',
          type: 'string',
          name: 'AdWords Ad Slot',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adSlot'],
          formatValue: (value) => value['dimension.adSlot'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adTargetingOption',
          type: 'string',
          name: 'Placement Type',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adTargetingOption'],
          formatValue: (value) => value['dimension.adTargetingOption'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.adTargetingType',
          type: 'string',
          name: 'Targeting Type',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.adTargetingType'],
          formatValue: (value) => value['dimension.adTargetingType'],
          show_expanded: true,
          category: 'Adwords',
        },
        {
          key: 'dimension.dbmClickAdvertiser',
          type: 'string',
          name: 'DBM Advertiser (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmClickAdvertiser'],
          formatValue: (value) => value['dimension.dbmClickAdvertiser'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmClickExchange',
          type: 'string',
          name: 'DBM Exchange (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmClickExchange'],
          formatValue: (value) => value['dimension.dbmClickExchange'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmClickInsertionOrder',
          type: 'string',
          name: 'DBM Insertion Order (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmClickInsertionOrder'],
          formatValue: (value) => value['dimension.dbmClickInsertionOrder'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmClickLineItem',
          type: 'string',
          name: 'DBM Line Item NAME (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmClickLineItem'],
          formatValue: (value) => value['dimension.dbmClickLineItem'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmClickSite',
          type: 'string',
          name: 'DBM Site (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmClickSite'],
          formatValue: (value) => value['dimension.dbmClickSite'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmLastEventAdvertiser',
          type: 'string',
          name: 'DBM Advertiser (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmLastEventAdvertiser'],
          formatValue: (value) => value['dimension.dbmLastEventAdvertiser'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmLastEventExchange',
          type: 'string',
          name: 'DBM Exchange (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmLastEventExchange'],
          formatValue: (value) => value['dimension.dbmLastEventExchange'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmLastEventInsertionOrder',
          type: 'string',
          name: 'DBM Insertion Order (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmLastEventInsertionOrder'],
          formatValue: (value) => value['dimension.dbmLastEventInsertionOrder'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmLastEventLineItem',
          type: 'string',
          name: 'DBM Line Item (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmLastEventLineItem'],
          formatValue: (value) => value['dimension.dbmLastEventLineItem'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dbmLastEventSite',
          type: 'string',
          name: 'DBM Site (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dbmLastEventSite'],
          formatValue: (value) => value['dimension.dbmLastEventSite'],
          show_expanded: true,
          category: 'DoubleClick Bid Manager',
        },
        {
          key: 'dimension.dcmClickAd',
          type: 'string',
          name: 'DCM Ad (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickAd'],
          formatValue: (value) => value['dimension.dcmClickAd'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmClickAdType',
          type: 'string',
          name: 'DCM Ad Type (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickAdType'],
          formatValue: (value) => value['dimension.dcmClickAdType'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmClickAdvertiser',
          type: 'string',
          name: 'DCM Advertiser (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickAdvertiser'],
          formatValue: (value) => value['dimension.dcmClickAdvertiser'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmClickCampaign',
          type: 'string',
          name: 'DCM Campaign (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickCampaign'],
          formatValue: (value) => value['dimension.dcmClickCampaign'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmClickCreative',
          type: 'string',
          name: 'DCM Creative (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickCreative'],
          formatValue: (value) => value['dimension.dcmClickCreative'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmClickCreativeType',
          type: 'string',
          name: 'DCM Creative Type (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickCreativeType'],
          formatValue: (value) => value['dimension.dcmClickCreativeType'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmClickCreativeVersion',
          type: 'string',
          name: 'DCM Creative Version (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickCreativeVersion'],
          formatValue: (value) => value['dimension.dcmClickCreativeVersion'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmClickSite',
          type: 'string',
          name: 'DCM Site (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickSite'],
          formatValue: (value) => value['dimension.dcmClickSite'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmClickSitePlacement',
          type: 'string',
          name: 'DCM Placement (GA Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmClickSitePlacement'],
          formatValue: (value) => value['dimension.dcmClickSitePlacement'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmFloodlightActivity',
          type: 'string',
          name: 'DCM Activity',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmFloodlightActivity'],
          formatValue: (value) => value['dimension.dcmFloodlightActivity'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmFloodlightActivityAndGroup',
          type: 'string',
          name: 'DCM Activity and Group',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) =>
            value['dimension.dcmFloodlightActivityAndGroup'],
          formatValue: (value) =>
            value['dimension.dcmFloodlightActivityAndGroup'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmFloodlightActivityGroup',
          type: 'string',
          name: 'DCM Activity Group',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmFloodlightActivityGroup'],
          formatValue: (value) => value['dimension.dcmFloodlightActivityGroup'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventAd',
          type: 'string',
          name: 'DCM Ad',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventAd'],
          formatValue: (value) => value['dimension.dcmLastEventAd'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventAdType',
          type: 'string',
          name: 'DCM Ad Type (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventAdType'],
          formatValue: (value) => value['dimension.dcmLastEventAdType'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventAdvertiser',
          type: 'string',
          name: 'DCM Advertiser (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventAdvertiser'],
          formatValue: (value) => value['dimension.dcmLastEventAdvertiser'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventAttributionType',
          type: 'string',
          name: 'DCM Attribution Type (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventAttributionType'],
          formatValue: (value) =>
            value['dimension.dcmLastEventAttributionType'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventCampaign',
          type: 'string',
          name: 'DCM Campaign (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventCampaign'],
          formatValue: (value) => value['dimension.dcmLastEventCampaign'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventCreative',
          type: 'string',
          name: 'DCM Creative (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventCreative'],
          formatValue: (value) => value['dimension.dcmLastEventCreative'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventCreativeType',
          type: 'string',
          name: 'DCM Creative Type (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventCreativeType'],
          formatValue: (value) => value['dimension.dcmLastEventCreativeType'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventCreativeVersion',
          type: 'string',
          name: 'DCM Creative Version (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventCreativeVersion'],
          formatValue: (value) =>
            value['dimension.dcmLastEventCreativeVersion'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventSite',
          type: 'string',
          name: 'DCM Site (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventSite'],
          formatValue: (value) => value['dimension.dcmLastEventSite'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dcmLastEventSitePlacement',
          type: 'string',
          name: 'DCM Placement (DCM Model)',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dcmLastEventSitePlacement'],
          formatValue: (value) => value['dimension.dcmLastEventSitePlacement'],
          show_expanded: true,
          category: 'DoubleClick Campaign Manager',
        },
        {
          key: 'dimension.dsAdGroup',
          type: 'string',
          name: 'DS Ad Group',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dsAdGroup'],
          formatValue: (value) => value['dimension.dsAdGroup'],
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'dimension.dsAdvertiser',
          type: 'string',
          name: 'DS Advertiser',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dsAdvertiser'],
          formatValue: (value) => value['dimension.dsAdvertiser'],
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'dimension.dsAgency',
          type: 'string',
          name: 'DS Agency',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dsAgency'],
          formatValue: (value) => value['dimension.dsAgency'],
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'dimension.dsCampaign',
          type: 'string',
          name: 'DS Campaign',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dsCampaign'],
          formatValue: (value) => value['dimension.dsCampaign'],
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'dimension.dsEngineAccount',
          type: 'string',
          name: 'DS Engine Account',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dsEngineAccount'],
          formatValue: (value) => value['dimension.dsEngineAccount'],
          show_expanded: true,
          category: 'DoubleClick Search',
        },
        {
          key: 'dimension.dsKeyword',
          type: 'string',
          name: 'DS Keyword',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['dimension.dsKeyword'],
          formatValue: (value) => value['dimension.dsKeyword'],
          show_expanded: true,
          category: 'DoubleClick Search',
        },
      ],
      custom_fields_queries: [
        {
          query: {
            select: [
              { path: 'id', resource: 'goals' },
              { path: 'name', resource: 'goals' },
            ],
            filter: [
              {
                path: 'active',
                resource: 'goals',
                operator: 'in',
                operands: [true],
              },
            ],
          },
          field_templates: [
            {
              key: 'custom_metric.goal<%= id %>Starts',
              name: 'Goal starts (Goal <%= id %>: <%= name %>)',
              type: 'int',
              aggregated: true,
              category: 'Goals',
            },
            {
              key: 'custom_metric.goal<%= id %>Completions',
              name: 'Goal completions (Goal <%= id %>: <%= name %>)',
              type: 'int',
              aggregated: true,
              category: 'Goals',
            },
            {
              key: 'custom_metric.goal<%= id %>ConversionRate',
              name: 'Goal conversion rate (Goal <%= id %>: <%= name %>)',
              type: 'percent',
              aggregated: true,
              category: 'Goals',
            },
            {
              key: 'custom_metric.goal<%= id %>Abandons',
              name: 'Goal abandons (Goal <%= id %>: <%= name %>)',
              type: 'int',
              aggregated: true,
              category: 'Goals',
            },
            {
              key: 'custom_metric.goal<%= id %>AbandonRate',
              name: 'Goal abandonment rate (Goal <%= id %>: <%= name %>)',
              type: 'percent',
              aggregated: true,
              category: 'Goals',
            },
            {
              key: 'custom_metric.goal<%= id %>Value',
              name: 'Goal value (Goal <%= id %>: <%= name %>)',
              type: 'currency',
              aggregated: true,
              category: 'Goals',
            },
            {
              key: 'custom_metric.searchGoal<%= id %>ConversionRate',
              name: 'Search goal conversion rate (Goal <%= id %>: <%= name %>)',
              type: 'percent',
              aggregated: true,
              category: 'Goals',
            },
          ],
        },
        {
          query: {
            select: [
              { path: 'index', resource: 'customMetrics' },
              { path: 'name', resource: 'customMetrics' },
            ],
            filter: [
              {
                path: 'active',
                resource: 'customMetrics',
                operator: 'in',
                operands: [true],
              },
            ],
          },
          field_templates: [
            {
              key: 'custom_metric.metric<%= index %>',
              name: 'Custom metric <%= name %> value',
              type: 'int',
              category: 'custom_metrics',
            },
            {
              key: 'custom_metric.contentGroupUniqueViews<%= index %>',
              name: 'Unique Views <%= name %>',
              type: 'int',
              category: 'custom_metrics',
            },
          ],
        },
        {
          query: {
            select: [
              { path: 'index', resource: 'customDimensions' },
              { path: 'name', resource: 'customDimensions' },
            ],
            filter: [
              {
                path: 'active',
                resource: 'customDimensions',
                operator: 'in',
                operands: [true],
              },
            ],
          },
          field_templates: [
            {
              key: 'custom_dimension.contentGroup<%= index %>',
              name: 'Page Group <%= name %>',
              type: 'string',
              supports_contains_operators: true,
              category: 'Custom dimensions',
            },
            {
              key: 'custom_dimension.customVarName<%= index %>',
              name: 'Custom Variable (Key <%= name %>)',
              type: 'string',
              supports_contains_operators: true,
              category: 'Custom dimensions',
            },
            {
              key: 'custom_dimension.customVarValue<%= index %>',
              name: 'Custom Variable (Value <%= name %>)',
              type: 'string',
              supports_contains_operators: true,
              category: 'Custom dimensions',
            },
            {
              key: 'custom_dimension.dimension<%= index %>',
              name: 'Custom Dimension <%= name %>',
              type: 'string',
              supports_contains_operators: true,
              category: 'Custom dimensions',
            },
            {
              key: 'custom_dimension.landingContentGroup<%= index %>',
              name: 'Landing Page Group <%= name %>',
              type: 'string',
              supports_contains_operators: true,
              category: 'Custom dimensions',
            },
            {
              key: 'custom_dimension.previousContentGroup<%= index %>',
              name: 'Previous Page Group <%= name %>',
              type: 'string',
              supports_contains_operators: true,
              category: 'Custom dimensions',
            },
            {
              key: 'custom_dimension.productCategoryLevel<%= index %>',
              name: 'Product Category Level <%= name %>',
              type: 'string',
              supports_contains_operators: true,
              category: 'Custom dimensions',
            },
          ],
        },
      ],
    },
    {
      name: 'accountSummaries',
      primary_key: 'viewId',
      fields: [
        {
          key: 'accountId',
          type: 'string',
          hidden: true,
          equivalents: {
            reports: 'accountId',
            goals: 'accountId',
            customMetrics: 'accountId',
            customDimensions: 'accountId',
          },
        },
      ],
    },
    {
      name: 'accounts',
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Name', type: 'string' }],
    },
    {
      name: 'properties',
      primary_key: 'id',
      fields: [
        {
          key: 'id',
          type: 'string',
        },
        {
          key: 'name',
          type: 'string',
        },
      ],
    },
    {
      name: 'views',
      primary_key: 'id',
      fields: [
        {
          key: 'id',
          type: 'string',
          equivalents: {
            reports: 'viewId',
            goals: 'viewId',
          },
        },
        {
          key: 'name',
          type: 'string',
        },
        {
          key: 'accountId',
          type: 'string',
          equivalents: {
            reports: 'accountId',
            accountSummaries: 'accountId',
            goals: 'accountId',
            customMetrics: 'accountId',
            customDimensions: 'accountId',
          },
        },
        {
          key: 'propertyId',
          type: 'string',
          equivalents: {
            reports: 'propertyId',
            goals: 'propertyId',
            customMetrics: 'propertyId',
            customDimensions: 'propertyId',
          },
        },
      ],
    },
    {
      name: 'segments',
      private: true,
      primary_key: 'segmentId',
      fields: [{ key: 'name', name: 'Segment name', type: 'string' }],
    },
    {
      name: 'goals',
      fields: [
        {
          key: 'accountId',
          type: 'string',
          hidden: true,
          equivalents: {
            reports: 'accountId',
            accountSummaries: 'accountId',
            customMetrics: 'accountId',
            customDimensions: 'accountId',
          },
        },
        {
          key: 'viewId',
          type: 'string',
          hidden: true,
          equivalents: {
            reports: 'viewId',
            views: 'id',
          },
        },
        {
          key: 'propertyId',
          type: 'string',
          hidden: true,
          equivalents: {
            reports: 'propertyId',
            customMetrics: 'propertyId',
            customDimensions: 'propertyId',
          },
        },
      ],
    },
    {
      name: 'customMetrics',
      fields: [
        {
          key: 'accountId',
          type: 'string',
          hidden: true,
          equivalents: {
            reports: 'accountId',
            accountSummaries: 'accountId',
            goals: 'accountId',
            customDimensions: 'accountId',
          },
        },
        {
          key: 'propertyId',
          type: 'string',
          hidden: true,
          equivalents: {
            reports: 'propertyId',
            goals: 'propertyId',
            customDimensions: 'propertyId',
          },
        },
      ],
    },
    {
      name: 'customDimensions',
      fields: [
        {
          key: 'accountId',
          type: 'string',
          hidden: true,
          equivalents: {
            reports: 'accountId',
            accountSummaries: 'accountId',
            goals: 'accountId',
            customMetrics: 'accountId',
          },
        },
        {
          key: 'propertyId',
          type: 'string',
          hidden: true,
          equivalents: {
            reports: 'propertyId',
            goals: 'propertyId',
            customMetrics: 'propertyId',
          },
        },
      ],
    },
  ],
  initialQueries: {
    number: {
      query: {
        select: [
          {
            resource: 'reports',
            path: 'metric.sessions',
          },
        ],
        filter: [
          {
            resource: 'reports',
            path: 'accountId',
            operator: 'in',
          },
          {
            resource: 'reports',
            path: 'viewId',
            operator: 'in',
          },
          {
            resource: 'reports',
            path: 'propertyId',
            operator: 'in',
          },
          {
            resource: 'reports',
            path: 'date',
            operator: 'timespan',
            operands: [7, 'day'],
          },
        ],
      },
    },
  },
  options: [
    {
      keys: ['currency', 'account_timezone'],
      query: {
        select: [
          {
            resource: 'views',
            path: 'currency',
          },
          {
            resource: 'views',
            path: 'timezone',
          },
        ],
      },
    },
  ],
  prerequisites: {
    service_account_filters: [
      {
        resource: 'reports',
        path: 'accountId',
        exclude_from_query: true,
      },
      {
        resource: 'reports',
        path: 'propertyId',
        exclude_from_query: true,
      },
      {
        resource: 'reports',
        path: 'viewId',
      },
    ],
    build_prerequisite_options: (serviceAccounts) => {
      const prerequisites = map(serviceAccounts, (sa) => {
        const { accountSummaries = [] } = sa.values;

        let accounts = chain(accountSummaries)
          .uniqBy((summ) => summ.accountId)
          .map((o) => {
            return {
              id: o.accountId,
              name: o.accountName,
            };
          })
          .value();

        accounts = map(accounts, (account) => {
          // Move all of the properties under accounts
          account.children = chain(accountSummaries)
            .filter((summ) => summ.accountId === account.id)
            .uniqBy((summ) => summ.propertyId)
            .map((o) => {
              return { id: o.propertyId, name: o.propertyName };
            })
            .value();

          // Move all of the views under properties
          account.children = map(account.children, (property) => {
            property.children = chain(accountSummaries)
              .filter((summ) => summ.propertyId === property.id)
              .map((o) => {
                return { id: o.viewId, name: o.viewName };
              })
              .value();

            return property;
          });

          return account;
        });

        return {
          id: sa.id,
          name: sa.name,
          children: accounts,
        };
      });

      return prerequisites;
    },
  },
};
