import * as colors from '../../../src/ui/style/colors.js';

const neutralVisualisationColor = '#46476E';
const containerHighlightColor = '#dadada';

export default {
  // variables only for edit mode UI
  '--container-border-focus-color': '#1F9FFF',
  '--container-hover-color': containerHighlightColor,
  '--dropbox-color': 'rgba(8, 119, 255, 0.2)',
  '--dropbox-border-color': containerHighlightColor,
  '--title-placeholder-color': '#82839D',
  '--frame-resize-handle-color': containerHighlightColor,
  '--frame-resize-handle-dragging-color': '#b7b7b7',
  '--resize-icon-bar-color': '#ececec',
  '--resize-icon-handle-color': '#f6f6f6',
  '--full-container-overlay-color': 'rgba(22,17,58, 0.95)', // matches the dashboard-background-color, but increased transparency
  '--full-container-overlay-text-container-color': 'rgb(22,17,58)', // matches the dashboard-background color

  // dashboard colors
  '--dashboard-background-color': colors.Black100,
  '--container-background-color': '#272953',
  '--text-color': 'rgba(255, 255, 255, 0.86)',
  '--secondary-text-color': '#C0C9DE',
  '--title-color': 'rgba(255, 255, 255, 0.92)', // Title color is only used for instrument title. it should use the text color at some point

  // visualisation colors
  '--primary-visualisation-color': colors.GeckoboardBlue,
  '--neutral-color': neutralVisualisationColor,
  '--positive-color': colors.Positive,
  '--negative-color': colors.NegativeOnDark,
  '--caution-color': colors.Warning,
  '--chart-threshold-color': neutralVisualisationColor,
  '--chart-axis-color': 'rgba(255, 255, 255, 0.13)',
  '--chart-axis-color-strong': 'rgba(255, 255, 255, 0.2)',
  '--map-subunit-color': 'rgba(255,255,255, 0.4)',
  '--map-single-point-color': '#38cdff',
  '--gauge-needle-color': '#e1e1e1',
  '--map-background-color': 'rgba(255, 255, 255, 0.86)',

  // mobile UI colours
  '--mobile-footer-background-color': colors.BackgroundDark,
  '--mobile-footer-text-color': colors.Black100,

  // override dark theme colors for config pages
  '--background-color': colors.Black100,
  '--widget-background-color': '#272953',

  // series colors
  '--series2-color': '#E5C852',
  '--series3-color': '#C552E5',
  '--series4-color': '#52E5D4',
  '--series5-color': '#E57452',
  '--series6-color': '#B7E552',
  '--series7-color': '#E5528D',
  '--series8-color': '#52E56A',
  '--series9-color': '#9252E5',
};
