import { useContext } from 'react';

import FeatureFlagContext from './feature-flag-context';

const useFeatureFlagClient = () => {
  const { ffClient } = useContext(FeatureFlagContext);
  return ffClient;
};

export default useFeatureFlagClient;
