import * as colors from '../../../src/ui/style/colors.js';

const neutralVisualisationColor = '#ECEFF5';
const containerHighlightColor = '#4a4a4a';

export default {
  // variables only for edit mode UI
  '--container-border-focus-color': '#1571F1',
  '--container-hover-color': containerHighlightColor,
  '--dropbox-color': 'rgba(8, 119, 255, 0.1)',
  '--dropbox-border-color': '#0e0e0e',
  '--title-placeholder-color': '#8b9092',
  '--frame-resize-handle-color': containerHighlightColor,
  '--frame-resize-handle-dragging-color': '#6e6e6e',
  '--resize-icon-bar-color': containerHighlightColor,
  '--resize-icon-handle-color': '#0e0e0e',
  '--full-container-overlay-color': 'rgba(255, 255, 255, 0.95)', // matches the container-background-color, with increased transparency
  '--full-container-overlay-text-container-color': 'rgb(255, 255, 255)', // matches the container-background-color

  // dashboard colors
  '--dashboard-background-color': colors.BackgroundDark,
  '--container-background-color': '#FFFFFF',
  '--text-color': '#666666',
  '--secondary-text-color': '#8b8b8b',
  '--title-color': '#8b8b8b', // Title color is only used for instrument title. it should use the text color at some point

  // visualisation colors
  '--primary-visualisation-color': '#00C4EF',
  '--neutral-color': neutralVisualisationColor,
  '--positive-color': colors.Positive,
  '--negative-color': colors.Negative,
  '--caution-color': colors.Warning,
  '--chart-threshold-color': neutralVisualisationColor,
  '--chart-axis-color': 'rgba(0, 0, 0, 0.13)',
  '--chart-axis-color-strong': 'rgba(0, 0, 0, 0.2)',
  '--map-background-color': '#D4DAE6',
  '--map-single-point-color': '#b940ff',
  '--gauge-needle-color': '#8b9092',

  // mobile UI colours
  '--mobile-footer-background-color': colors.Black100,
  '--mobile-footer-text-color': '#FFFFFF',

  // series colors
  '--series2-color': '#E5C852',
  '--series3-color': '#C552E5',
  '--series4-color': '#52E5D4',
  '--series5-color': '#E57452',
  '--series6-color': '#B7E552',
  '--series7-color': '#E5528D',
  '--series8-color': '#52E56A',
  '--series9-color': '#9252E5',
};
