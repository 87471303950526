import defineEvent from '@Tracking/define-event';

export const expandSelectionModalDisplayed = defineEvent(
  'Spreadsheets - Expand selection modal - Displayed',
);
export const expandSelectionModalExpand = defineEvent(
  'Spreadsheets - Expand selection modal - Expand',
);
export const expandSelectionModalKeepSingleCell = defineEvent(
  'Spreadsheets - Expand selection modal - Keep single cell',
);
