import {
  chain,
  clone,
  compact,
  every,
  filter as lodashFilter,
  find,
  first,
  flatten,
  includes,
  isEmpty,
  isNil,
  isNull,
  isUndefined,
  last,
  map,
  omit,
  omitBy,
  reduce,
  some,
  times,
  xor,
} from 'lodash';

import { getMetricsOrder } from '../../../universal/lib/queries-universal';
import {
  buildBaseSubquery,
  getChildrenFromEntry,
  getDetailsNode,
  getNodeFromSubquery,
} from '../../graph';
import { TIMESPAN_OPERATORS } from '../../ui-field-options';
import { isDetailsMetricSelected } from '../../universal-config-helpers';

const getExtra = (subquery) =>
  omit(subquery, 'path', 'resource', 'custom', 'multi_index');

export const DEFAULT_TIMESPAN_OPERANDS = [7, 'day'];

export const getBucket = (operands) => {
  switch (operands.toString()) {
    case '1,hour':
      return 'minute';
    case '1,day':
      return 'hour';
    case '1,week':
    case '1,month':
      return 'day';
    case '1,year':
      return 'month';
    default:
      return operands[1];
  }
};

export const getQueryMetas = (state, subQueryResults) => {
  const { metrics = [], groupBy, splitBy } = state;

  let metricMeta;
  if (isDetailsMetricSelected(state)) {
    const { detailsColumns = [] } = state;
    metricMeta = detailsColumns.map((columnNode) =>
      omitBy(
        {
          type: columnNode.node.type,
          unit: columnNode.node.unit,
          name: columnNode.node.name,
          values: columnNode.node.getValues(subQueryResults),
          iconSuffix: columnNode.node.iconSuffix,
          primary_resource: columnNode.node.primary_resource,
          key: columnNode.node.key,
        },
        isNil,
      ),
    );
  } else {
    metricMeta = metrics.map((metric) => ({
      type: metric.node.type,
      unit: metric.node.unit,
      name: metric.node.name,
      aggregate: metric.extra && metric.extra.aggregate,
      hasReverseComparison: !!metric.node.reverse_comparison,
    }));
  }
  const groupByMeta =
    groupBy &&
    groupBy.length &&
    groupBy[0].node &&
    omitBy(
      {
        type: groupBy[0].node.type,
        name: groupBy[0].node.name,
        values:
          groupBy[0].node.type === 'enum'
            ? groupBy[0].node.getValues(subQueryResults)
            : undefined,
        query_custom_values:
          groupBy[0].node.type === 'enum'
            ? groupBy[0].node.query_custom_values
            : undefined,
      },
      isUndefined,
    );
  const splitByMeta =
    splitBy &&
    splitBy.length &&
    omitBy(
      {
        type: splitBy[0].node.type,
        values:
          splitBy[0].node.type === 'enum'
            ? splitBy[0].node.getValues(subQueryResults)
            : undefined,
        query_custom_values:
          splitBy[0].node.type === 'enum'
            ? splitBy[0].node.query_custom_values
            : undefined,
      },
      isUndefined,
    );

  // TODO: multi - Store meta for all queries if required
  return [{ select: compact([groupByMeta, splitByMeta, ...metricMeta]) }];
};

export const resolveNodesCrossResource = (
  state,
  mainGraph,
  entryResource,
  keepExisting = true,
) => {
  const nodes = getChildrenFromEntry(mainGraph, entryResource);
  const uiQuery = { ...state };
  [
    'groupBy',
    'orderBy',
    'timespans',
    'filters',
    'splitBy',
    'globalFilters',
    'vipFilters',
    'prerequisites',
  ].forEach((key) => {
    const value = uiQuery[key];
    if (!value) {
      return;
    }
    const newValue = value.map((uiSubQuery) => {
      if (!uiSubQuery || !uiSubQuery.node) {
        return uiSubQuery;
      }
      let node = nodes.find((field) => field.isEqualTo(uiSubQuery.node));

      if (!node) {
        if (keepExisting) {
          node = uiSubQuery.node;
        } else {
          return null;
        }
      }

      return {
        ...uiSubQuery,
        node,
      };
    });

    if (keepExisting) {
      uiQuery[key] = newValue;
    } else {
      uiQuery[key] = compact(newValue);
    }
  });

  return uiQuery;
};

export const groupIndices = (data) => {
  const {
    indices,
    state: { timespans, boundToMetricFilters = [] },
  } = data;

  // An "index" in this context represents a query that will be generated
  // Sometimes, if these queries share bound filters and timespans, they can
  // just be grouped into a single query

  // When we build up our index groupings, we create a "key" that's an array
  // of all the relevant timespan nodes and filter nodes. For each index, we
  // check if another index already exists with that collection of nodes.
  const hasKey = (key, keyToCompare) => {
    return isEmpty(xor(key, keyToCompare));
  };

  const tmp = [];
  indices.forEach((i) => {
    const timespan = timespans[i].node;
    const filters = map(boundToMetricFilters[i], (f) => f.node);
    const key = [timespan, ...filters];

    // If we don't already have an index with this collection of nodes, create it
    if (!find(tmp, (item) => hasKey(item.key, key))) {
      tmp.push({
        key,
        indices: [],
      });
    }

    // Push the index into the relevant grouping
    const group = find(tmp, (item) => hasKey(item.key, key));
    group.indices.push(i);
  });

  // Push the grouped indices into an array to return
  // eg. [[0, 1], [2], [3, 4]]
  return map(tmp, (v) => v.indices);
};

export const getFilterQueryFromUiQuery = (mainGraph, mainEntry, filters) => {
  return chain(filters)
    .filter((filter) =>
      // this will check that both node and extra are present and not empty
      every(['node', 'extra'], (key) => !isEmpty(filter[key])),
    )
    .map((filter) => ({
      ...buildBaseSubquery(mainGraph, mainEntry, filter.node, true),
      ...filter.extra,
    }))
    .value();
};

const buildQueryForDetailsTable = (state, graph, entry) => {
  const {
    filters = [],
    vipFilters: vipFilters = [],
    globalFilters = [],
    detailsColumns,
    limit,
    orderBy,
  } = state;

  if (
    !isDetailsMetricSelected(state) ||
    !detailsColumns ||
    !detailsColumns.length
  ) {
    return [];
  }

  const mainIndex = 0;
  const orderByQ = orderBy &&
    orderBy[mainIndex] && {
      ...buildBaseSubquery(graph, entry, orderBy[mainIndex].node),
      ...orderBy[mainIndex].extra,
    };
  const select = detailsColumns.map((c) => ({
    ...buildBaseSubquery(graph, entry, c.node),
  }));

  const filterQ = getFilterQueryFromUiQuery(graph, entry, [
    ...filters,
    ...vipFilters,
    ...globalFilters,
  ]);

  return [
    omitBy(
      {
        select,
        order_by: [orderByQ],
        filter: filterQ,
        limit,
      },
      isUndefined,
    ),
  ];
};

const LARGER_UNIT = {
  day: 'week',
  week: 'month',
  month: 'year',
  quarter: 'year',
  year: null,
};

const addTimespanComparison = (
  state,
  entries,
  mainEntry,
  timespanComparison,
) => {
  const _state = { ...state }; // Let's try to avoid mutating the original just so it's not confusing
  let _entries = [...entries];

  const applyComparison = !isUndefined(timespanComparison);

  const { metrics, timespans, groupBy, orderBy } = state;

  // We add the comparison query here to avoid syncing all part of our ui state
  // on all actions..
  if (applyComparison) {
    // Duplicate the main entry, as we're comparing to a previous timescale for
    // the same metric
    _entries = [mainEntry, mainEntry];
    const { operator: timespanOperator } = timespanComparison;
    let timespanOperands = [...timespans[0].extra.operands];

    // if we have a "same time last xxx" comparison we need to
    // use a unit that's a step larger for the comparison query.
    // However, because of "quarter", we need to map a list of unit against
    // larger ones (LARGER_UNITS)
    if (timespanOperator === TIMESPAN_OPERATORS.PREV_SAME_TIMESPAN) {
      const quantity = timespanOperands.shift();
      const unit = timespanOperands.pop();
      timespanOperands = [quantity, LARGER_UNIT[unit]];
    }

    // Comparisons only have a single metric, so we duplicate it for the comparison
    _state.metrics = [metrics[0], metrics[0]];
    // Duplicate the first timespan, using the comparison timespan operator for
    // the second timespan node
    _state.timespans = [
      timespans[0],
      {
        node: timespans[0].node,
        extra: {
          ...timespans[0].extra,
          operands: timespanOperands,
          operator: timespanOperator,
        },
      },
    ];

    // Duplicate the groupBy as well, using the timeshift from the timespan operator
    _state.groupBy = groupBy && [
      groupBy[0],
      {
        ...groupBy[0],
        extra: { ...groupBy[0].extra, timeshift: timespanOperands },
      },
    ];

    // Duplicate the orderBy
    _state.orderBy = orderBy && [orderBy[0], orderBy[0]];
  }

  return { state: _state, entries: _entries };
};

const groupEntries = (state, graph, entries, mainEntry, applyComparison) => {
  const { splitBy } = state;

  const store = new Map();
  entries.forEach((e, i) => {
    // If the entry isn't already in the store, we set an object against the entry
    if (!store.has(e)) {
      // If it's the main entry, use the normal state, otherwise we resolve any nodes
      // we need to to make sure we have all the relevant nodes available in state
      const newState =
        mainEntry === e ? state : resolveNodesCrossResource(state, graph, e);

      // Pull all of the filters out of the new state
      const {
        filters = [],
        vipFilters: vipFilters = [],
        globalFilters = [],
        prerequisites = [],
      } = newState;

      const prerequisiteKeys = map(
        prerequisites,
        (prerequisite) => prerequisite.node.key,
      );

      // Remove any matching filters from saFilters and globalFilters if they appear in prerequisites
      // The reason for this is that prerequisites is replacing all of the functionality provided by saFilters,
      // and some of the functionality provided by global filters. For now, we want to do this, to make sure
      // we can temporarily run both in parallel without breaking anything
      const stripPrerequisitesFromFilters = (filtersToStrip) =>
        lodashFilter(
          filtersToStrip,
          (filter) => !includes(prerequisiteKeys, filter.node.key),
        );

      // Nodes are sometimes marked as `is_sa_filter`, `is_global` or `is_combined` in queries, but for prerequisites,
      // we want to strip this out as we won't need it here in the future
      const stripObsoleteProperties = (prerequisitesToStrip) => {
        return map(prerequisitesToStrip, (prerequisite) => {
          return {
            ...prerequisite,
            extra: {
              ...omit(prerequisite.extra, [
                'is_global',
                'is_sa_filter',
                'is_combined',
              ]),
            },
          };
        });
      };

      // And build the filter query
      const filterQ = getFilterQueryFromUiQuery(graph, e, [
        ...filters,
        ...vipFilters,
        ...stripPrerequisitesFromFilters(globalFilters),
        ...stripObsoleteProperties(prerequisites),
      ]);

      // Build a collection of entries, with associated state (for cross resource nodes),
      // filter queries, and split by / split by select
      store.set(e, {
        state: newState,
        indices: [],
        boundFilterQs: [],
        query: {
          filterQ: filterQ.length ? filterQ : undefined,
          splitByQ: splitBy &&
            newState.splitBy.length && {
              ...buildBaseSubquery(graph, e, newState.splitBy[0].node, true),
            },
          splitBySelectQ: splitBy &&
            newState.splitBy.length && {
              ...buildBaseSubquery(graph, e, newState.splitBy[0].node),
            },
        },
      });
    }

    // We need to keep track of the bound filters that we'll need to apply for each query
    const value = store.get(e);
    const { boundToMetricFilters = [] } = value.state;

    const boundFilterQs = map(boundToMetricFilters[i], (filter) => {
      return getFilterQueryFromUiQuery(graph, e, [filter]);
    });

    value.boundFilterQs.push(boundFilterQs);

    // We also keep track of if there are more entries that share the same resource
    // via the indices
    value.indices.push(i);
  });

  // Group queries sharing same timespan for each entries
  store.forEach((value) => {
    // When applying comparison we split in two queries even if they've got the
    // same timespan node. Otherwise, we group indices if we can.
    value.indices = applyComparison ? [[0], [1]] : groupIndices(value);
  });

  return store;
};

const constructQueries = (store, graph, mainEntry) => {
  // Build the queries from the store
  const hasMultiQueries =
    store.size > 1 || store.get(mainEntry).indices.length > 1;
  const queries = [];

  store.forEach((value, entry) => {
    value.indices.forEach((qIndices) => {
      // Select first index as a main. We can rely on all the others metrics to
      // share same groupBy, sortBy, and timespan for now.
      const [mainIndex] = qIndices;
      const { metrics, timespans, groupBy, orderBy, limit } = value.state;
      const { filterQ, splitByQ, splitBySelectQ } = value.query;

      // Map all metrics
      const currentMetricsQ = qIndices.map((i) => {
        const select = {
          ...buildBaseSubquery(graph, entry, metrics[i].node),
          ...metrics[i].extra,
        };

        // Keep index of each metric in case of multiqueries to restore correct
        // order when editing widget
        if (hasMultiQueries) {
          select.multi_index = i;
        }

        return select;
      });

      const groupByQ = groupBy && {
        ...buildBaseSubquery(graph, entry, groupBy[mainIndex].node, true),
        ...groupBy[mainIndex].extra,
      };
      const xaxisQ = groupBy && {
        ...buildBaseSubquery(graph, entry, groupBy[mainIndex].node),
      };
      const timespanQ = timespans &&
        timespans[mainIndex].node && {
          ...buildBaseSubquery(graph, entry, timespans[mainIndex].node),
          ...timespans[mainIndex].extra,
        };

      const orderByQ = orderBy &&
        orderBy[mainIndex] && {
          ...buildBaseSubquery(graph, entry, orderBy[mainIndex].node),
          ...orderBy[mainIndex].extra,
        };
      const select = groupByQ
        ? compact([xaxisQ, splitBySelectQ, ...currentMetricsQ])
        : currentMetricsQ;
      let filter = filterQ;
      if (timespanQ) {
        filter = filterQ ? [timespanQ, ...filterQ] : [timespanQ];
      }

      if (!isEmpty(value.boundFilterQs[mainIndex])) {
        // Apply `bound_to_metric` filters as appropriate to query
        filter = filter || [];

        filter = flatten([...filter, ...value.boundFilterQs[mainIndex]]);
      }

      queries.push(
        omitBy(
          {
            select,
            filter,
            group_by: groupByQ && compact([groupByQ, splitByQ]),
            order_by: orderByQ && [orderByQ],
            limit,
          },
          isUndefined,
        ),
      );
    });
  });

  return queries;
};

// _state : the current UI Query state
// graph : the main graph
// _entries : all of the relevant root nodes for the selected metrics etc.
// timespanComparison : an object { operator, type }, defining a
//                      possible comparison with a previous timespan
export const getQueriesFromUiQuery = (
  _state,
  graph,
  _entries,
  timespanComparison,
) => {
  let entries = _entries;
  let state = { ..._state };
  const applyComparison = !isUndefined(timespanComparison);

  // The "entry" is which node we are choosing as our "entry" point into the graph, ie.
  // the root node. When we resolve nodes across resource (via joins), we need to choose a
  // starting point (eg. we start at "user", and join it with "organization" to also get
  // fields from that resource, so we go user.organization.name). Here, we just pick the
  // first resource in the list of resources as our "main entry point".
  const mainEntry = entries[0];

  // "Details metric" functionality is specific to the table widget, so we can just handle
  // that separately here
  if (isDetailsMetricSelected(state)) {
    return buildQueryForDetailsTable(state, graph, mainEntry);
  }

  // Handle any comparison with a previous time period, if it needs to be handled
  ({ state, entries } = addTimespanComparison(
    state,
    entries,
    mainEntry,
    timespanComparison,
  ));

  // Work out how many queries we need to create, by grouping entries by timespan
  // We create a store here with all the state / information needed for each entry to
  // build the queries
  const store = groupEntries(state, graph, entries, mainEntry, applyComparison);

  // Build the queries, using the store as the basis
  return constructQueries(store, graph, mainEntry);
};

// When we edit a widget, we want to pull the values for the prerequisites out of the queries
// used to create the widget, and set them into state.
export const getPrerequisitesFromQueries = (
  graph,
  queries,
  serviceAccountFilters,
) => {
  // Extract global filters from first query (sample)
  const { filter = [] } = queries[0];
  const prerequisitesSubQ = [];

  if (isEmpty(filter)) {
    return null;
  }

  serviceAccountFilters.forEach((sa) => {
    prerequisitesSubQ.push(
      find(filter, (f) => sa.resource === f.resource && sa.path === f.path),
    );
  });

  return prerequisitesSubQ.map((filterSubQ) => ({
    node: getNodeFromSubquery(graph, filterSubQ, true),
    extra: getExtra(filterSubQ),
  }));
};

export const getFilterNodesFromQueries = (graph, queries) => {
  // Extract global filters from first query (sample)
  const { filter = [] } = queries[0];
  const globalFiltersSubQ = [];

  filter.forEach((f) => {
    if (f.is_global) {
      globalFiltersSubQ.push(f);
    }
  });

  // Extract bound to metric filters from queries (as they are per query)
  const filterSets = map(queries, (q) => q.filter || []);
  const boundToMetricFiltersSubQ = [];

  filterSets.forEach((filters) => {
    const filtersSubQ = [];

    filters.forEach((f) => {
      if (f.bound_to_metric) {
        filtersSubQ.push(f);
      }
    });

    boundToMetricFiltersSubQ.push(filtersSubQ);
  });

  return {
    globalFilters: globalFiltersSubQ.map((filterSubQ) => ({
      node: getNodeFromSubquery(graph, filterSubQ, true),
      extra: getExtra(filterSubQ),
    })),

    boundToMetricFilters: boundToMetricFiltersSubQ.map((filterSetSubQ) =>
      map(filterSetSubQ, (filterSubQ) => ({
        node: getNodeFromSubquery(graph, filterSubQ, true),
        extra: getExtra(filterSubQ),
      })),
    ),
  };
};

export const getNodesFromQueries = (
  graph,
  _queries,
  uiOptions,
  applyComparison,
  detailsMetricResource,
  prerequisites,
) => {
  let sharedState;
  let queries;

  // When using comparison style, we don't want to populate our state with
  // a second query. buildQueriesFromUi will take care of that when saving
  if (applyComparison) {
    queries = [_queries[0]];
  } else {
    queries = _queries;
  }

  const isPrerequisite = (filter) => {
    return some(
      prerequisites,
      (prerequisite) =>
        prerequisite.node.primary_resource === filter.resource &&
        prerequisite.node.key === filter.path,
    );
  };

  const queriesState = reduce(
    queries,
    (multiQueryState, query, index) => {
      const {
        select = [],
        filter: _filter = [],
        group_by: [groupBySubQ, splitBySubQ] = [],
        order_by: [orderBySubQ] = [],
        limit,
      } = query;

      const vipFiltersSubQ = [];
      const filter = [];

      _filter.forEach((f) => {
        if (f.is_vip) {
          vipFiltersSubQ.push(f);
          // Exclude sa, global, prerequisites and bound_to_metric filters as they are parsed in
          // getFilterNodesFromQueries
        } else if (!f.is_global && !f.bound_to_metric && !isPrerequisite(f)) {
          filter.push(f);
        }
      });

      let metrics = [];
      let detailsColumns = [];

      if (detailsMetricResource) {
        // On details table, map select queries to columns
        metrics = [{ node: getDetailsNode(graph, detailsMetricResource) }];
        detailsColumns = select.map((selectSubQ) => ({
          node: getNodeFromSubquery(graph, selectSubQ),
        }));
      } else {
        // Map select queries to metrics
        let metricsSubQ = [first(select)];
        if (select.length > 1) {
          [, ...metricsSubQ] = select;
        }
        if (splitBySubQ) {
          metricsSubQ = [last(select)];
        }

        metrics = metricsSubQ.map((metricSubQ) => ({
          node: getNodeFromSubquery(graph, metricSubQ),
          extra: getExtra(metricSubQ),
        }));
      }

      const nbOfMetrics = metrics.length;

      let timespanSubQ;
      let filtersSubQ;
      let timespans;
      // time_fields rules set to null means no timespan for that query.
      const [{ node: mainMetricNode } = {}] = metrics;
      if (
        mainMetricNode.rules &&
        mainMetricNode.rules.time_fields &&
        isNull(mainMetricNode.rules.time_fields[0])
      ) {
        [...filtersSubQ] = filter;
        timespans = [
          {
            extra: {
              operator: 'timespan',
              operands: DEFAULT_TIMESPAN_OPERANDS,
            },
          },
        ];
      } else {
        [timespanSubQ, ...filtersSubQ] = filter;
        // We compute the baseTimespans once
        const baseTimespans = {
          node: getNodeFromSubquery(graph, timespanSubQ),
          extra: getExtra(timespanSubQ),
        };
        // We fill timespans with the number of metrics available but make sure to
        // clone the object. We cannot use Array.fill here.
        timespans = times(nbOfMetrics, () => clone(baseTimespans));
      }

      const filters = filtersSubQ.map((filterSubQ) => ({
        node: getNodeFromSubquery(graph, filterSubQ, true),
        extra: getExtra(filterSubQ),
      }));

      const vipFilters = vipFiltersSubQ.map((filterSubQ) => ({
        node: getNodeFromSubquery(graph, filterSubQ, true),
        extra: getExtra(filterSubQ),
      }));

      let groupBy = [];
      if (groupBySubQ) {
        const groupByBase = {
          node: getNodeFromSubquery(graph, groupBySubQ, true),
          extra: getExtra(groupBySubQ),
        };
        groupBy = times(nbOfMetrics, () => clone(groupByBase));
      }
      let orderBy = [];
      if (orderBySubQ) {
        const orderByBase = {
          node: getNodeFromSubquery(graph, orderBySubQ),
          extra: getExtra(orderBySubQ),
        };
        orderBy = times(nbOfMetrics, () => clone(orderByBase));
      }

      const splitBy = splitBySubQ && [
        {
          node: getNodeFromSubquery(graph, splitBySubQ, true),
        },
      ];

      if (index === 0) {
        sharedState = {
          filters,
          vipFilters,
          limit,
          splitBy,
          bucket: getBucket(timespans[0].extra.operands),
        };
      }

      return {
        metrics: [...multiQueryState.metrics, ...metrics],
        timespans: [...multiQueryState.timespans, ...timespans],
        groupBy: [...multiQueryState.groupBy, ...groupBy],
        orderBy: [...multiQueryState.orderBy, ...orderBy],
        detailsColumns,
      };
    },
    {
      metrics: [],
      timespans: [],
      groupBy: [],
      orderBy: [],
    },
  );

  // Keep retro-compatible with old queries generated from forced entryResource
  // We need to find the actual matching metric from the primary_resource
  const allMetrics = flatten(uiOptions.metrics.map((g) => g.options));
  queriesState.metrics = queriesState.metrics.map((m) => {
    let node = find(allMetrics, (n) => m.node === n || n.isEqualTo(m.node));
    // TODO Remove this nasty hardcoded fallback for retro compatiblity on some
    // widgets created during first weeks of our alpha integration. ..
    // the change of graph introduced here:
    // https://github.com/geckoboard/polecat/pull/2643
    // We need to refactor the entry logic in our main reducer to be more retro
    // compatible instead of hitting that code to fallback..
    if (!node) {
      node = find(graph, { root: 'tickets' });
    }
    return { ...m, node };
  });

  if (queries.length > 1) {
    // restore metrics' order for metrics, timespans, groupBy and orderBy
    const metricsOrder = chain(getMetricsOrder(queries))
      .flatten()
      .uniq()
      .value();

    // We need to use a tmp array here to sort based on indices collected with
    // all metrics
    ['metrics', 'timespans', 'groupBy', 'orderBy'].forEach((key) => {
      const queryState = queriesState[key];
      if (queryState.length > 0) {
        const sorted = new Array(queryState);
        queryState.forEach((val, i) => {
          sorted[metricsOrder[i + 1] - 1] = val;
        });
        queriesState[key] = sorted;
      }
    });
  }

  return {
    ...sharedState,
    ...omitBy(queriesState, (q) => q.length === 0),
  };
};

export const getGlobalFilters = (filters) =>
  lodashFilter(filters, (f) => f.is_global);

export const resetGlobalFilters = (queries, filterTypes = []) => {
  return queries.map((q) => {
    if (!q.filter) return q;
    const { filter } = q;
    const updatedFilter = filter.map((f) => {
      if (filterTypes.includes('globalFilters') && f.is_global) {
        return omit(f, ['operator', 'operands']);
      }
      return f;
    });

    return { ...q, filter: updatedFilter };
  });
};
