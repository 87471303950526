export default [
  {
    id: '0b239587-f60f-4e53-9c68-2204aa7fbf65',
    title: 'Sessions',
    description: 'Number of visitors to your website.',
    category: 'Traffic',
    type: 'line',
    icon: {
      type: 'icon',
      value: 'line',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        group_by: [
          {
            bucket_by: 'day',
            path: 'date',
            resource: 'reports',
          },
        ],
        select: [
          {
            path: 'date',
            resource: 'reports',
          },
          {
            multi_index: 0,
            path: 'metric.sessions',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: '8c7fd15a-bb59-4c51-927a-3c1a3e9a5cad',
    title: 'Users',
    description: 'Number of visitors to your website.',
    category: 'Traffic',
    type: 'line',
    icon: {
      type: 'icon',
      value: 'line',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        group_by: [
          {
            bucket_by: 'day',
            path: 'date',
            resource: 'reports',
          },
        ],
        select: [
          {
            path: 'date',
            resource: 'reports',
          },
          {
            multi_index: 0,
            path: 'metric.users',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: '78317450-f8bf-4640-81f8-8e7bd9bb5974',
    title: 'Page views',
    description: 'Number of pages people visit on your website.',
    category: 'Traffic',
    type: 'line',
    icon: {
      type: 'icon',
      value: 'line',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        group_by: [
          {
            bucket_by: 'day',
            path: 'date',
            resource: 'reports',
          },
        ],
        select: [
          {
            path: 'date',
            resource: 'reports',
          },
          {
            multi_index: 0,
            path: 'metric.pageviews',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: '4d4d5e1b-47d2-4cdb-9913-db940a5a4c45',
    title: 'Bounce rate',
    description:
      'Percentage of visitors who leave after viewing only one page.',
    category: 'Engagement',
    type: 'number',
    icon: {
      type: 'text',
      value: '2%',
    },
    queries: [
      {
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        select: [
          {
            multi_index: 0,
            path: 'metric.bounceRate',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: 'edbddc57-fc96-4fe1-8c9a-591079d6d00d',
    title: 'Session duration',
    description: 'Average length of sessions.',
    category: 'Engagement',
    type: 'number',
    icon: {
      type: 'text',
      value: '52m',
    },
    queries: [
      {
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        select: [
          {
            path: 'metric.avgSessionDuration',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: '01f97489-b5ea-4689-a4b4-21c6d834d3de',
    title: 'Pages per session',
    description:
      'Average number of pages viewed during a session on your website.',
    category: 'Engagement',
    type: 'number',
    icon: {
      type: 'text',
      value: '42',
    },
    queries: [
      {
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        select: [
          {
            multi_index: 0,
            path: 'metric.pageviewsPerSession',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: 'b3689527-ac5d-4031-ba6b-642eaea61e6b',
    title: 'New sessions',
    description: 'Percentage of first-time visitors on your website.',
    category: 'Engagement',
    type: 'number',
    icon: {
      type: 'text',
      value: '87%',
    },
    queries: [
      {
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        select: [
          {
            multi_index: 0,
            path: 'metric.percentNewSessions',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: '94140f3b-4649-4c50-8ceb-0d37caa02182',
    title: 'Goal completions',
    description: 'Number of times visitors complete a goal on your website.',
    category: 'Goals',
    type: 'line',
    icon: {
      type: 'icon',
      value: 'line',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        group_by: [
          {
            bucket_by: 'day',
            path: 'date',
            resource: 'reports',
          },
        ],
        select: [
          {
            path: 'date',
            resource: 'reports',
          },
          {
            multi_index: 0,
            path: 'metric.goalCompletionsAll',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: 'b9c30633-abfc-41ef-9d09-5bce87218084',
    title: 'Goal conversion rate',
    description: 'Percentage of sessions which result in a goal completion.',
    category: 'Goals',
    type: 'number',
    icon: {
      type: 'text',
      value: '89%',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        select: [
          {
            multi_index: 0,
            path: 'metric.goalConversionRateAll',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: 'ee0a8b99-71d1-4f3b-96f2-8556455abc2a',
    title: 'Revenue',
    description: 'The total revenue from web ecommerce or in-app transactions.',
    category: 'Ecommerce',
    type: 'number',
    icon: {
      type: 'text',
      value: '$100',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        select: [
          {
            multi_index: 0,
            path: 'metric.transactionRevenue',
            resource: 'reports',
          },
        ],
      },
    ],
  },
  {
    id: '23bd5af7-a2f5-42e8-b954-73f3ef364191',
    title: 'Conversion rate',
    description: 'Percentage of users who take a desired ecommerce action.',
    category: 'Ecommerce',
    type: 'number',
    icon: {
      type: 'text',
      value: '34%',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'date',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'accountId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'viewId',
            resource: 'reports',
          },
          {
            operator: 'in',
            path: 'propertyId',
            resource: 'reports',
          },
        ],
        select: [
          {
            path: 'metric.transactionsPerSession',
            resource: 'reports',
          },
        ],
      },
    ],
  },
];
