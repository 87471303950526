import { isArray } from 'lodash';

import createAction from '../../lib/redux/create-action';
import { getServices } from '../../services/management-service';
import { sortByTitle } from '../data-sources-helpers';

// Actions
export const set = createAction('DataSources:SET');
export const update = createAction('DataSources:UPDATE');

export const fetchSources =
  (opts = {}, organizationCreated) =>
  (dispatch) => {
    const { includeRecentlyConnected = false } = opts;
    const query = `includes_recently_connected=${includeRecentlyConnected}`;

    getServices(query).then((data) => {
      if (isArray(data)) {
        dispatch(
          set({
            services: sortByTitle(data),
            organizationCreated,
          }),
        );
      } else {
        const { services, ...rest } = data;
        dispatch(
          set({
            services: sortByTitle(services),
            ...rest,
            organizationCreated,
          }),
        );
      }
    });
  };
