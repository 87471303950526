import * as pickerActions from '../../file-picker/actions/picker-actions';
import * as dataSourceConnectorActions from '../actions/data-source-connector-actions';
import * as initDataSourceActions from '../actions/init-data-source-action';

const initialState = {
  isServiceAccountExpired: false,
  // errorType is only used by the spreadsheets-connector at the moment.
  // It's used to get better error messages. The `errorMessage` state
  // is currently being set is often a techincal one, so we don't want
  // to use that. We also want to be able to add links (ie "contact
  // customer support") and using some generic error types to choose
  // which error to display makes this easier.
  // We can make other connectors in the future use this to get better
  // error messages too.
  errorType: null,
};

const dataSourceConnectorReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case initDataSourceActions.fileFetchStart.type:
      return {
        ...state,
        isSwitchingService: true,
      };

    case pickerActions.fetchFilesSuccess.type:
      return {
        ...state,
        isSwitchingService: false,
        isServiceAccountExpired: false,
        isLoading: false,
      };

    case initDataSourceActions.initDataSourceSuccessful.type:
    case dataSourceConnectorActions.switchServiceSuccessful.type:
      return {
        ...state,
        serviceName: payload.serviceName,
        serviceAccountId: payload.serviceAccountId,
        errorMessage: null,
        errorType: null,
        isLoading: false,
        isSwitchingService: false,
        isServiceAccountExpired: false,
      };

    case dataSourceConnectorActions.switchServiceStart.type:
      return {
        ...state,
        serviceName: payload.serviceName,
        isSwitchingService: true,
        isServiceAccountExpired: false,
        serviceAccountId: payload.serviceAccountId,
      };

    case dataSourceConnectorActions.accountAddSuccessful.type:
      return {
        ...state,
        serviceName: payload.serviceName,
        serviceAccountId: payload.serviceAccountId,
        errorMessage: null,
        errorType: null,
        isSwitchingAccount: false,
      };

    case dataSourceConnectorActions.switchAccountStart.type:
      return {
        ...state,
        serviceAccountId: payload.serviceAccountId,
        isSwitchingAccount: true,
        isServiceAccountExpired: false,
      };

    case dataSourceConnectorActions.switchAccountSuccessful.type:
      return {
        ...state,
        serviceAccountId: payload.serviceAccountId,
        errorMessage: null,
        errorType: null,
        isSwitchingAccount: false,
        isServiceAccountExpired: false,
      };

    case initDataSourceActions.initDataSourceFailed.type:
    case dataSourceConnectorActions.accountAddFailed.type:
      return {
        ...state,
        errorMessage: payload.message,
        errorType: payload.errorType,
        isLoading: false,
        isSwitchingService: false,
        isSwitchingAccount: false,
        serviceName: payload.serviceName,
      };

    case pickerActions.fetchFilesFailed.type:
      return {
        ...state,
        isLoading: false,
        isSwitchingService: false,
        isSwitchingAccount: false,
        isServiceAccountExpired: payload.isServiceAccountExpired,
      };

    case initDataSourceActions.initDataSourceStart.type:
      return {
        ...state,
        isLoading: true,
      };

    case dataSourceConnectorActions.setState.type:
      return {
        ...state,
        ...payload,
      };

    case pickerActions.closeFilePicker.type:
      return {
        ...state,
        isFileSelected: false,
      };
    case dataSourceConnectorActions.connectionCancelled.type:
      return {
        ...state,
        isPanelVisible: undefined,
      };

    case dataSourceConnectorActions.createConnectionFailed.type: {
      const { payload: errorMessage } = action;
      return {
        ...state,
        errorMessage,
      };
    }
    default:
      return state;
  }
};

export default dataSourceConnectorReducer;
