import { isUndefined } from 'lodash';

function transform(payload, { isLoop } = {}, timezone, initialContext = {}) {
  const context = {};

  if (!isUndefined(isLoop)) {
    context.isLoop = isLoop;
  }

  return { ...initialContext, ...context };
}

export { transform };
