import delay from '../../lib/delay';
import { redirect } from '../../lib/global';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import {
  exchangeDeviceToken,
  getPairingCode,
  getPairingStatus,
} from '../../services/management-service';
import { FetchError } from '../../universal/fetch';
import {
  ERROR_MESSAGE,
  MAX_CODE_COUNT,
  POLL_INTERVAL,
} from '../constants/tv-page-constants';

const _pollFetch = async (doFetch, interval, limit) => {
  let count = 1;
  let response = await doFetch();

  while (response.status === 202) {
    if (count >= limit) {
      throw new FetchError('POLL_FETCH_LIMIT_EXCEEDED');
    }

    await delay(interval);

    count += 1;
    response = await doFetch();
  }

  return response;
};

const _handleStatusError = (error) => {
  const { status } = error;

  switch (status) {
    case 400:
    case 404:
    case 410:
      // Code has expired, is invalid or the
      // device token has already been retrieved
      return null;
    default:
      throw error;
  }
};

const _pollForPairingStatus = async (code) => {
  const MAX_POLL_COUNT = 1000;
  try {
    const response = await _pollFetch(
      () => getPairingStatus(code),
      POLL_INTERVAL,
      MAX_POLL_COUNT,
    );
    const { token } = await response.json();
    return token;
  } catch (error) {
    return _handleStatusError(error);
  }
};

// Actions
export const setCode = createAction('TvPage:SET_CODE');
export const setDevice = createAction('TvPage:SET_DEVICE');
export const setError = createErrorAction('TvPage:SET_ERROR');

// Async Actions
export const init = () => async (dispatch) => {
  try {
    let codeCount = 0;
    while (codeCount < MAX_CODE_COUNT) {
      const { code } = await getPairingCode();

      dispatch(setCode(code));

      const token = await _pollForPairingStatus(code);
      if (token) {
        const { url } = await exchangeDeviceToken(token);
        redirect(url);
        return;
      }
      // else expired, get a new code
      codeCount += 1;
    }

    // We have hit the max code count
    dispatch(setError(new Error('MAX_CODE_COUNT_REACHED'), ERROR_MESSAGE));
  } catch (error) {
    dispatch(setError(error, ERROR_MESSAGE));
  }
};
