import { pick } from 'lodash';

import { applyAndMerge } from '@Lib/config-mapper';

import ColumnChartHelpers from '../column-chart-form/helpers';

const DEFAULT_AGGREGATE = 'sum';

const ensureConfig = (currentConfig) => {
  const config = ColumnChartHelpers.ensureConfig(currentConfig);
  delete config.legends;

  return config;
};

const NUMBER_AND_GECKOMETER_MAPPINGS = [
  ['field', (field) => [{ field, aggregate: DEFAULT_AGGREGATE }], 'metrics'],
  // Override the default `aggregate` when available
  ['aggregate', null, 'metrics[0].aggregate'],
  ['filters'],
];

const LEADERBOARD_MAPPINGS = [
  ['label', null, 'x_axis.field'],
  ['metric', (field) => [{ field, aggregate: DEFAULT_AGGREGATE }], 'metrics'],
  // Override the default `aggregate` when available
  ['aggregate', null, 'metrics[0].aggregate'],
  ['filters'],
];

const QUERY_MAPPING_RULES = {
  geckometer: NUMBER_AND_GECKOMETER_MAPPINGS,
  number: NUMBER_AND_GECKOMETER_MAPPINGS,
  leaderboard: LEADERBOARD_MAPPINGS,
  line: [
    [
      'metrics',
      ([metric]) => [pick(metric, 'field', 'aggregate', '_aggregate')],
    ],
    ['x_axis'],
    ['filters'],
  ],
  column: [
    [
      'metrics',
      ([metric]) => [pick(metric, 'field', 'aggregate', '_aggregate')],
    ],
    ['x_axis'],
    [
      'order_by',
      (orderBy) => {
        if (
          orderBy &&
          (orderBy._query_path === 'x_axis' ||
            orderBy._query_path === 'metrics[0]')
        ) {
          return orderBy;
        }
        return undefined;
      },
    ],
    ['filters'],
  ],
  table: [['filters']],
};

const mapQuery = (fromType, ...rest) =>
  applyAndMerge(QUERY_MAPPING_RULES[fromType], ...rest);

const visualisationHelpers = {
  ...ColumnChartHelpers,
  ensureConfig,
  mapQuery,
};

export default visualisationHelpers;
