import createAction from '../../lib/redux/create-action';
import * as managementService from '../../services/management-service';

export const setBillingStatus = createAction(
  'BillingStatus:SET_BILLING_STATUS',
);
export const setCheckoutError = createAction(
  'BillingStatus:SET_CHECKOUT_ERROR',
);

export const fetchBillingStatus = () => async (dispatch) => {
  const billingStatus = await managementService.getBillingStatus();

  dispatch(setBillingStatus(billingStatus));
};
