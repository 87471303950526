const __defineType = (definition) => {
  const type = () => ({ definition, required: false });
  type.isRequired = () => ({ definition, required: true });

  return type;
};

const number = __defineType({ type: 'number' });

const bool = __defineType({ type: 'boolean' });

const string = __defineType({ type: 'string' });

const listOfStrings = __defineType({
  type: 'array',
  items: {
    type: 'string',
  },
});

function oneOf(values = []) {
  return __defineType({ enum: values });
}

function oneOfType(types = []) {
  const allTypes = types.map((type) => type().definition.type);

  return __defineType({
    type: allTypes,
  });
}

const SchemaTypes = {
  bool,
  listOfStrings,
  number,
  oneOf,
  oneOfType,
  string,
};

export default SchemaTypes;
