class Serializer {
  serialize() {
    return {};
  }

  deserialize() {
    return {};
  }
}

export default Serializer;
