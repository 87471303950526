export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';
export const NAVY_THEME = 'navy';
export const PURPLE_THEME = 'purple';
export const USER_THEME = 'user';
export const CUSTOM_THEME = 'custom';
export const CUSTOM_COLORS_THEME = 'CustomColors';
export const DASHIES_DARK_THEME = 'dashiesDark';
export const DASHIES_LIGHT_THEME = 'dashiesLight';
export const VALID_THEMES = [
  DARK_THEME,
  LIGHT_THEME,
  NAVY_THEME,
  PURPLE_THEME,
  USER_THEME,
  CUSTOM_THEME,
];
export const VALID_THEMES_INCLUDING_DASHIES = [
  ...VALID_THEMES,
  DASHIES_DARK_THEME,
  DASHIES_LIGHT_THEME,
  CUSTOM_COLORS_THEME,
];
export const DARK_WIDGET_STYLE = 'dark';
export const LIGHT_WIDGET_STYLE = 'light';
export const AUTO_WIDGET_STYLE = 'auto';
export const VALID_WIDGET_STYLES = [
  DARK_WIDGET_STYLE,
  LIGHT_WIDGET_STYLE,
  AUTO_WIDGET_STYLE,
];
