export default {
  api: {
    max_time_period: 10368000000,
  },
  resources: [
    {
      name: 'tickets',
      human_readable_name: 'tickets',
      countable: true,
      fields: [
        {
          custom: 'created_tickets',
          name: 'Received tickets',
          base: { resource: 'tickets' },
          rules: {
            time_fields: [{ primary_resource: 'tickets', key: 'created_at' }],
          },
          reverse_comparison: true,
        },
        {
          custom: 'resolved_tickets',
          name: 'Resolved tickets',
          base: { resource: 'tickets' },
          rules: {
            time_fields: [
              { primary_resource: 'tickets', key: 'stats.resolved_at' },
            ],
          },
        },
        {
          key: 'first_reply_time',
          name: 'First response time (calendar)',
          type: 'duration',
          unit: 'seconds',
          default_aggregation: 'avg',
          rules: {
            time_fields: [{ primary_resource: 'tickets', key: 'created_at' }],
          },
          reverse_comparison: true,
          additional_info: {
            content:
              "First response time is calculated based on the total difference between a ticket’s created time and the time it’s first replied to.<br/><br/>This differs from what you see in Freshdesk as they take your business hours into account. We’re not currently able to get this data from Freshdesk, but you can <a href='mailto:support@freshdesk.com' target='_blank'>let them know</a> that you’d like to see support added.",
          },
        },
        {
          key: 'resolution_time',
          name: 'Resolution time (calendar)',
          default_aggregation: 'avg',
          type: 'duration',
          unit: 'seconds',
          rules: {
            time_fields: [
              { primary_resource: 'tickets', key: 'stats.resolved_at' },
            ],
          },
          reverse_comparison: true,
          additional_info: {
            content:
              "Resolution time is calculated based on the total difference between a ticket’s created time and the time it’s resolved.<br/><br/>This differs from what you see in Freshdesk as they take your business hours into account. We’re not currently able to get this data from Freshdesk, but you can <a href='mailto:support@freshdesk.com' target='_blank'>let them know</a> that you’d like to see support added.",
          },
        },
        {
          key: 'type',
          name: 'Type',
          type: 'string',
          category: 'ticket_fields',
          supports_null_values: true,
          values_from: { resource: 'ticket_types', key: 'name' },
        },
        {
          key: 'priority',
          name: 'Priority',
          type: 'enum',
          category: 'ticket_fields',
          query_custom_values: {
            select: [
              {
                path: 'id',
                resource: 'ticket_priorities',
              },
              {
                path: 'name',
                resource: 'ticket_priorities',
              },
            ],
          },
        },
        {
          key: 'status',
          name: 'Status',
          type: 'enum',
          category: 'ticket_fields',
          query_custom_values: {
            select: [
              {
                path: 'id',
                resource: 'ticket_statuses',
              },
              {
                path: 'name',
                resource: 'ticket_statuses',
              },
            ],
          },
        },
        {
          key: 'source',
          name: 'Source',
          type: 'enum',
          category: 'ticket_fields',
          query_custom_values: {
            select: [
              {
                path: 'id',
                resource: 'ticket_sources',
              },
              {
                path: 'name',
                resource: 'ticket_sources',
              },
            ],
          },
        },
        {
          key: 'responder',
          name: 'Agent',
          type: 'string',
          category: 'ticket_fields',
          resource: 'agents',
          foreign_key: 'responder_id',
          target_key: 'contact.name',
          supports_null_values: true,
          equivalents: {
            satisfaction_ratings: 'responder',
          },
        },
        {
          key: 'group',
          name: 'Group',
          type: 'string',
          category: 'ticket_fields',
          resource: 'groups',
          foreign_key: 'group_id',
          target_key: 'name',
          supports_null_values: true,
          equivalents: {
            satisfaction_ratings: 'group',
          },
        },
        {
          key: 'company',
          name: 'Company',
          type: 'string',
          category: 'ticket_fields',
          resource: 'companies',
          foreign_key: 'company_id',
          target_key: 'name',
          supports_null_values: true,
        },
        {
          key: 'product',
          name: 'Product',
          type: 'string',
          category: 'ticket_fields',
          resource: 'products',
          foreign_key: 'product_id',
          target_key: 'name',
          supports_null_values: true,
        },
        {
          key: 'created_at',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updated_at',
          name: 'Updated at',
          type: 'datetime',
        },
        {
          key: 'stats.resolved_at',
          name: 'Resolved at',
          type: 'datetime',
        },
      ],
      custom_fields_queries: [
        {
          query: {
            select: [
              {
                path: 'name',
                resource: 'ticket_custom_fields',
              },
              {
                path: 'label',
                resource: 'ticket_custom_fields',
              },
              {
                path: 'choices',
                resource: 'ticket_custom_fields',
              },
            ],
          },
          field_templates: [
            {
              key: '<%= name %>',
              name: '<%= label %>',
            },
          ],
        },
      ],
    },
    {
      name: 'agents',
      private: true,
      primary_key: 'id',
      fields: [
        { key: 'contact.name', name: 'Name', type: 'string' },
        { key: 'contact.email', name: 'Email', type: 'string' },
        { key: 'contact.created_at', name: 'Created At', type: 'string' },
      ],
    },
    {
      name: 'groups',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Name', type: 'string' }],
    },
    {
      name: 'companies',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Name', type: 'string' }],
    },
    {
      name: 'products',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Name', type: 'string' }],
    },
    {
      name: 'satisfaction_ratings',
      human_readable_name: 'satisfaction ratings',
      primary_key: 'id',
      rel: [
        {
          res: 'tickets',
          name: 'ticket',
        },
      ],
      fields: [
        {
          key: 'sat_score',
          name: '% positive ratings',
          type: 'percent',
          aggregated: true,
          rules: {
            time_fields: [
              { primary_resource: 'satisfaction_ratings', key: 'created_at' },
            ],
          },
        },
        {
          key: 'created_at',
          name: 'Created at (Sat Ratings)',
          type: 'datetime',
        },
        {
          key: 'responder',
          name: 'Agent',
          type: 'string',
          category: 'ticket_fields',
          resource: 'agents',
          foreign_key: 'agent_id',
          target_key: 'contact.name',
          equivalents: {
            tickets: 'responder',
          },
        },
        {
          key: 'group',
          name: 'Group',
          type: 'string',
          category: 'ticket_fields',
          resource: 'groups',
          foreign_key: 'group_id',
          target_key: 'name',
          equivalents: {
            tickets: 'group',
          },
        },
      ],
    },
    {
      name: 'ticket_types',
      private: true,
      fields: [],
    },
    {
      name: 'ticket_priorities',
      private: true,
      fields: [],
    },
    {
      name: 'ticket_statuses',
      private: true,
      fields: [],
    },
    {
      name: 'ticket_sources',
      private: true,
      fields: [],
    },
    {
      name: 'ticket_custom_fields',
      private: true,
      fields: [],
    },
  ],
  equivalent_fields: {
    satisfaction_ratings: {
      tickets: { group: 'group', responder: 'responder' },
    },
  },
  initialQueries: {
    number: {
      query: {
        select: [
          {
            resource: 'tickets',
            aggregate: 'count',
            custom: 'created_tickets',
          },
        ],
        filter: [
          {
            resource: 'tickets',
            path: 'created_at',
            operator: 'timespan',
            operands: [7, 'day'],
          },
        ],
      },
    },
  },
};
