import delay from '../../lib/delay';

export const POLL_INTERVAL = 10;

const waitForState = async (getState, predicate) => {
  let result = predicate(getState());
  while (!result) {
    await delay(POLL_INTERVAL);
    result = predicate(getState());
  }
};

export default waitForState;
