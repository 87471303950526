import * as loopAction from '../actions/loop-actions';

const initialState = {
  isLoop: false,
  currentIndex: 0,
};

const loopReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case loopAction.setCurrentIndex.type:
      return {
        ...state,
        currentIndex: payload,
      };
    case loopAction.setIsLoop.type:
      return {
        ...state,
        isLoop: true,
      };
    default:
      return state;
  }
};

export default loopReducer;
