import { isUndefined, last } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericLineTransform } from '../per-visualisation/line';
import { getIncompleteFromNow } from '../shared/helpers';

import {
  ensureTimespan,
  fillInValues,
  getFormat,
  getMainColumnField,
  getMultilineScatterSeries,
} from './helpers';

const lineTransform = (payload, config = {}, timezone, initialContext = {}) => {
  if (!payload || !payload.length) {
    return {};
  }

  const lineColumn = getMainColumnField(config);
  const {
    queryMetas: [{ select: selectMeta = [] } = {}] = [],
    queries = [],
    timespanComparison,
  } = config;
  const [
    {
      filter: [{ operands: [qty, unitTs] = [] } = {}] = [],
      group_by: [{ bucket_by: bucket } = {}] = [],
    } = {},
  ] = queries;
  const format = getFormat(lineColumn, config);

  let series = getMultilineScatterSeries(
    payload,
    selectMeta,
    !isUndefined(timespanComparison),
  ) || [{ data: payload }];

  if (qty && unitTs) {
    series = series.map((s) => {
      return {
        ...s,
        data: fillInValues(
          ensureTimespan(s.data, timezone, qty, unitTs, bucket),
          last(selectMeta).type,
        ),
        incomplete_from: getIncompleteFromNow(timezone, bucket),
      };
    });
  }

  const context = {
    x_axis: { type: 'datetime' },
    y_axis: format,
    series,
    format: format.format,
  };

  return { ...initialContext, ...context };
};

const transform = composeTransformers(lineTransform, genericLineTransform);
export { transform };
