import { DocumentNode, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql';
import { Dashboard } from '../generated/graphql';

export const DashboardByLegacyIdQuery = graphql(`
  query DashboardTimezoneByLegacyId($id: ID!) {
    dashboard: dashboardByLegacyId(legacyId: $id) {
      id
      legacyId
      title
      timezone {
        name
      }
    }
  }
`);
export const DashboardQuery = graphql(`
  query DashboardTimezone($id: ID!) {
    dashboard(id: $id) {
      id
      title
      timezone {
        name
      }
    }
  }
`);

export const getDashboardQuery = (
  dashboardId: string | number,
): DocumentNode => {
  // In some parts of our code base where we handle legacy IDs, the ID
  // is a number. In some parts it's a string, so we need to support both.
  // New style IDs are always strings.
  const idIsNumber = typeof dashboardId === 'number';
  const isLegacyId = idIsNumber || !dashboardId.startsWith('dash_');
  return isLegacyId ? DashboardByLegacyIdQuery : DashboardQuery;
};

const useDashboardTimezoneQuery = (dashboardId: string | number) => {
  const query = getDashboardQuery(dashboardId);
  return useQuery<{ dashboard: Partial<Dashboard> }, { id: string }>(query, {
    variables: { id: dashboardId.toString() },
  });
};

export default useDashboardTimezoneQuery;
