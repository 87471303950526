import _, {
  chain,
  first,
  isArray,
  isEmpty,
  isMatch,
  isUndefined,
  last,
  omitBy,
  pick,
  reduce,
  set,
} from 'lodash';

import { humaniseNumber } from '../../lib/humanise-number';
import {
  getSelectedCells,
  getSelectionHeaderIndices,
  greedilyParseFloat,
  INDEX_BASED,
  isValidSelection,
  toBackendIndex,
  toFrontendIndex,
} from '../../universal/spreadsheet-helpers';
import {
  getMixedInputValue,
  getNumericCells,
  getSelectionFormat,
} from '../../universal/transformers/spreadsheet/helpers';

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const cellRegex = /([a-zA-Z]+)?(\d+)?/;

// Return excel like colum string with a default 0 based index
// From http://stackoverflow.com/a/1237333/2259520
function getColumnLetter(col) {
  let columnString = '';
  let columnNumber = col + INDEX_BASED;

  while (columnNumber > 0) {
    const currentLetterNumber = (columnNumber - 1) % 26;
    const currentLetter = ALPHABET[currentLetterNumber];
    columnString = currentLetter + columnString;
    columnNumber = (columnNumber - (currentLetterNumber + 1)) / 26;
  }
  return columnString;
}

// Return 0 based index of the excel column string
// From http://stackoverflow.com/a/1237333/2259520
const getNumberFromLetter = function (col) {
  let i;
  let retVal = 0;
  col = col.toUpperCase();
  for (i = col.length - 1; i >= 0; i--) {
    const colPiece = col[i];
    const colNum = _.indexOf(ALPHABET, colPiece) + 1;
    retVal += colNum * Math.pow(26, col.length - (i + 1));
  }
  return retVal - 1; // 0 based
};

const getSelectionSize = function (selection) {
  const [start, end] = selection;

  const colSize = start[0] === -1 ? -1 : Math.abs(end[0] - start[0]) + 1;
  const rowSize = start[1] === -1 ? -1 : Math.abs(end[1] - start[1]) + 1;

  return [colSize, rowSize];
};

/**
 *
 * @return {import('../types.ts').Range}
 */
const convertSelectionToObjectFormat = (selection) => {
  const [start, end] = selection;

  return {
    firstCell: start,
    start,
    end,
    size: getSelectionSize(selection),
  };
};

const convertSelectionToArrayFormat = ({ start, end }) => {
  return [start, end];
};

/*
 *  updateSelection(startSelection, endCell, options)
 *
 *  - startSelection: the start of a selection [start, end] that comes with a first mouse
 *  interaction (normally, a mousedown)
 *  The shape is { start, end, size }
 *
 *  - endCell: the new ending cell of a selection, shape is [number, number]
 *
 *  - options: { restrictToSingleDimension, maxRows, maxCols }
 *
 *  This function will return a newly shaped selection merging the start of
 *  startSelection, the ending cell, in a way that there cannot be
 *  a negative direction (i.e. start@row 3 and end@row 2 => start@row 2
 *  and end@row 3).
 *  It also limits to a single direction when the option is passed
 *
 *  Examples:
 *
 *  A selection of { start: [0, 2] } with a end cell of [1, 2] will become
 *  a selection of { start: [0, 2], end: [1, 2] }
 *
 *  But a selection of { start: [1, 2] } with a end cell of [0, 2] will also
 *  become a selection { start: [0, 2], end: [1, 2] }
 *
 *  A restricted selection of { start: [0, 0] } with a end cell of [4, 8] will
 *  become a selection of { start: [0, 0], end: [4, 0] }
 *
 *  A restricted selection of { start: [4, 8] } with a end cell of [0, 0] will
 *  become a selection of { start: [0, 0], end: [4, 0] }
 */
const updateSelection = (startSelection, endCell, opts) => {
  /*
   * making copy to avoid altering the state
   */
  const options = { ...opts };
  const newSelection = { ...startSelection };

  const {
    start: [startCol, startRow],
  } = startSelection;
  let [endCol, endRow] = endCell;

  /*
   * we calculate the difference between the ending cell and the start
   * of the selection, this will allow us to know directions and distances
   */
  const colDiff = endCol - startCol;
  const rowDiff = endRow - startRow;
  /*
   * we need to reason about absolute column and row differences as they are
   * always positive; it does not matter if they are negative, we just need to
   * make sure they are higher than 0
   */
  const absoluteColDif = Math.abs(colDiff);
  const absoluteRowDif = Math.abs(rowDiff);

  /*
   * When restricted to a single dimension and an entire row/column have been
   * selected, we don't need to go on with updating the selection
   */
  if (
    options.restrictToSingleDimension &&
    (startCol === -1 || startRow === -1)
  ) {
    return {
      ...newSelection,
      end: [startCol, startRow],
    };
  }

  /*
   * the legacy bit about the restriction
   *
   * there we're going to update the options following what difference exists
   * with rows and columns
   */
  if (options.restrictToSingleDimension) {
    if (absoluteRowDif > 0) {
      options.maxCols = 0;
      options.maxRows = Infinity;
    }

    if (absoluteColDif > 0) {
      options.maxCols = Infinity;
      options.maxRows = 0;
    }
  }

  /*
   * if we are in restricted mode, and we're restricting on the columns, we just
   * add 0 (because it's been modified l. 133); if it's not restricted, the max
   * cols value is actually the ending cell column value
   *
   * Same applies for rows.
   */
  if (absoluteColDif > options.maxCols) {
    endCol = startCol + options.maxCols;
  }

  if (absoluteRowDif > options.maxRows) {
    endRow = startRow + options.maxRows;
  }

  const start = [startCol, startRow];
  const end = [endCol, endRow];

  /*
   * if the row difference is negative, we swap rows:
   * Ex: { start: [0, 3], end: [0, 2] } => { start: [0, 2], end: [0, 3] }
   *
   * if the column difference is negative, we swap columns:
   * Ex: { start: [6, 0], end: [5, 0] } => { start: [5, 0], end: [6, 0] }
   *
   * if both difference are negative, we swap ALL!:
   * Ex: { start: [6, 3], end: [5, 2] } => { start: [5, 2], end: [6, 3] }
   */
  if (rowDiff < 0) {
    start[1] = endRow;
    end[1] = startRow;
  }

  if (colDiff < 0) {
    start[0] = endCol;
    end[0] = startCol;
  }

  return {
    ...newSelection,
    start,
    end,
  };
};

function selectToIndices(selection) {
  return selection
    .split(':')
    .map((cell) => cell.match(cellRegex))
    .map(([, alpha, number]) => {
      return [
        alpha ? getNumberFromLetter(alpha) : undefined,
        number ? toFrontendIndex(parseInt(number, 10)) : undefined,
      ];
    });
}

function inputToSelection(selection) {
  let [start, end] = selection.split(':');
  let [, alpha, number] = start.match(cellRegex);

  if (start) {
    start = [
      alpha ? getNumberFromLetter(alpha) : -1,
      number ? toFrontendIndex(parseInt(number, 10)) : -1,
    ];
  }
  if (end) {
    [, alpha, number] = end.match(cellRegex);
    end = [
      alpha ? getNumberFromLetter(alpha) : -1,
      number ? toFrontendIndex(parseInt(number, 10)) : -1,
    ];
  }

  return [start, end];
}

const selectionToString = function (selection) {
  const [
    [startCol = 0, startRow = 0],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [endCol = startCol, endRow = startRow],
  ] = selection;

  const flatSelection = _.flatten(selection);

  // return empty string if selection array is empty or a infinite selection
  if (flatSelection.length === 0 || flatSelection.every((v) => v === -1)) {
    return '';
  }

  return selection
    .map((value) => {
      return value
        .map((val, index) => {
          if (val === -1) {
            return '';
          }
          index++;
          if (index % 2 === 0) {
            return val + 1;
          }
          return getColumnLetter(val);
        })
        .join('');
    })
    .join(':');
};

const getWindowSize = function () {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  return [width, height];
};

function formatCellValue(cell) {
  if (cell.human) {
    return cell.human;
  }

  let value = cell.value;

  const floatVal = parseFloat(cell.value);

  if (cell.type === 'percentage' && isFinite(floatVal)) {
    value = humaniseNumber(floatVal, { format: 'percent' });
  }

  return value || '';
}

function hasDisabledSelection(selection) {
  const [xAxis, ...series] = selection;
  // xaxis and at least one of series must be a valid selection
  return (
    !isValidSelection(xAxis) || series.filter(isValidSelection).length === 0
  );
}

function stripInteger(value) {
  const intValue = Math.floor(value);
  if (intValue !== parseFloat(value)) {
    return value;
  }
  return intValue.toString();
}

function unzipPathSelections(selections, parentPath) {
  return reduce(
    selections,
    (memo, selection, path) => {
      // Ignore empty selections (When the form is first initialised)
      if (selection.some(isUndefined)) {
        return memo;
      }

      const [[sampleIndex]] = selection;
      if (isArray(sampleIndex)) {
        // if multiple selection
        const child = unzipPathSelections(selection, path);

        const [childPaths, childSelections] = child;
        const [paths, flatSelections] = memo;
        return [
          paths.concat(childPaths),
          flatSelections.concat(childSelections),
        ];
      }

      const [paths, flatSelections] = memo;
      if (parentPath) {
        paths.push(`${parentPath}[${path}]`);
      } else {
        paths.push(path);
      }
      flatSelections.push(selection);
      return memo;
    },
    [[], []],
  );
}

// zipObject with _.set to handle key as path
function zipObjectPath(paths, values) {
  return chain(paths)
    .zip(values)
    .reduce((memo, [key, value]) => {
      set(memo, key, value);
      return memo;
    }, {})
    .value();
}

function swapSelection(selection) {
  if (!selection) return null;
  const [[startCol, startRow], [endCol, endRow]] = selection;
  return [
    [startRow, startCol],
    [endRow, endCol],
  ];
}

function isEntireColumnSelection(selection) {
  const [[, startRow], [endCol]] = selection;
  // Full column and valid selection
  return startRow === -1 && endCol !== -1;
}

function isEntireRowSelection(selection) {
  return isEntireColumnSelection(swapSelection(selection));
}

function isColumnSelection(selection) {
  if (isEntireColumnSelection(selection)) {
    return true;
  }

  const [[startCol, startRow], [endCol, endRow]] = selection;
  // Column shape and not a 1x1 cell selection
  return startCol === endCol && startRow !== endRow;
}

function isRowSelection(selection) {
  return isColumnSelection(swapSelection(selection));
}

function getFullRowOrColumnHeaderIndex(selections, allSelectedCells) {
  const filteredSelections = selections.filter(isValidSelection);

  if (!filteredSelections.length) {
    return null;
  }
  if (filteredSelections.every(isEntireColumnSelection)) {
    let row = chain(allSelectedCells).flatten().map('row').min().value();
    row = toFrontendIndex(row);
    return { row };
  }
  if (filteredSelections.every(isEntireRowSelection)) {
    let column = chain(allSelectedCells).flatten().map('column').min().value();
    column = toFrontendIndex(column);
    return { column };
  }
  return null;
}

function getFullHeaderFromCfg(config, cells, headerPaths) {
  const selections = pick(config, headerPaths);
  const definedSelections = omitBy(selections, isUndefined);
  const [, flatSelections] = unzipPathSelections(definedSelections);
  const allSelectedCells = getSelectedCells(cells, flatSelections);

  return getFullRowOrColumnHeaderIndex(flatSelections, allSelectedCells);
}

/**
 * getEndOfSelection
 *
 * given a single selection returns the [x, y] coordinates
 * of the cell at the bottom right of the selection.
 *
 * If the selection is bounded it will return the bottom
 * right of the selection regardless of if there is a cell
 * value in that position.
 *
 * If the selection is unbounded it will return the [x, y]
 * coordinates of the last cell in the selection.
 */
function getEndOfSelection(selection, cells) {
  if (isEntireColumnSelection(selection)) {
    const selected = getSelectedCells(cells, [selection])[0];
    const lastSelected = last(selected);
    const column = selection[1][0];
    const row = lastSelected ? lastSelected.row - 1 : 0;

    return [column, row];
  }

  if (isEntireRowSelection(selection)) {
    const selected = getSelectedCells(cells, [selection])[0];
    const lastSelected = last(selected);
    const row = selection[1][1];
    const column = lastSelected ? lastSelected.column - 1 : 0;

    return [column, row];
  }

  return selection[1];
}

function getHeaderCells(cells, selection) {
  const allSelectedCells = getSelectedCells(cells, selection);
  const headerIndices = getSelectionHeaderIndices(selection, allSelectedCells);

  return chain(allSelectedCells)
    .map(first)
    .zip(headerIndices)
    .map(([cell, headerIndex]) => {
      if (isMatch(cell, headerIndex)) {
        return cell;
      }
      return headerIndex;
    })
    .value();
}

function getHeaderCellsFromCfg(config, cells, headerPaths) {
  const selections = pick(config, headerPaths);
  const definedSelections = omitBy(selections, isUndefined);
  const [paths, flatSelections] = unzipPathSelections(definedSelections);
  const flatHeaderCells = getHeaderCells(cells, flatSelections);

  return zipObjectPath(paths, flatHeaderCells);
}

// Return visible subset
function getCanvasCells(cells, viewport, scrolls) {
  if (!cells || !viewport) {
    return [];
  }
  const [scrollCol, scrollRow] = scrolls;
  const { columns, rows } = viewport;
  const maxRow = scrollRow + rows;
  const maxCol = scrollCol + columns;
  let i;
  let j;
  const ret = [];
  for (i = scrollRow; i <= maxRow; i++) {
    const row = [];
    for (j = scrollCol; j <= maxCol; j++) {
      const cellValue = chain(cells)
        .find({ row: i + INDEX_BASED, column: j + INDEX_BASED })
        .thru((cell) => formatCellValue(cell || {})) // eslint-disable-line no-loop-func
        .value();

      row.push(cellValue);
    }
    ret.push(row);
  }

  return ret;
}

const setMinMaxFromDefaults = (state) => {
  const { config, cells } = state;
  const { value, type, min, max, _minDefault, _maxDefault } = config;

  if (type !== 'geckometer') {
    return state;
  }

  const [selectedCells] = getSelectedCells(cells, [value]);
  const numericCells = getNumericCells(selectedCells);
  const [format] = getSelectionFormat(numericCells);

  let minValue;
  if (
    isUndefined(min) ||
    isUndefined(getMixedInputValue(cells, min.value, format))
  ) {
    minValue = _minDefault;
  } else {
    minValue = min.value;
  }

  let maxValue;
  if (
    isUndefined(max) ||
    isUndefined(getMixedInputValue(cells, max.value, format))
  ) {
    maxValue = _maxDefault;
  } else {
    maxValue = max.value;
  }

  const updatedConfig = {
    ...config,
    min: { value: minValue },
    max: { value: maxValue },
  };
  return { ...state, config: updatedConfig };
};

const toRenderableState = (state) => {
  return setMinMaxFromDefaults(state);
};

const _convertRangeToColumns = (range) => {
  const { start, end } = range;
  const [firstColumn, firstRow] = start;
  const [lastColumn, lastRow] = end;

  const selection = [];

  for (let i = firstColumn; i <= lastColumn; i++) {
    selection.push([
      [i, firstRow],
      [i, lastRow],
    ]);
  }

  return selection;
};

const _convertRangeToRows = (range) => {
  //   {
  //     firstCell: [0, 1],
  //     start: [0, 1],
  //     end: [4, 5],
  //     size: [5, 5]
  //   }

  // [
  //   [ [0, 1], [4, 1] ],
  //   [ [0, 2], [4, 2] ],
  //   [ [0, 3], [4, 3] ],
  // ];

  const { start, end } = range;
  const [firstColumn, firstRow] = start;
  const [lastColumn, lastRow] = end;

  const selection = [];

  for (let i = firstRow; i <= lastRow; i++) {
    selection.push([
      [firstColumn, i],
      [lastColumn, i],
    ]);
  }

  return selection;
};

const convertSelectionRangesToColumns = (ranges) =>
  chain(ranges).map(_convertRangeToColumns).flatten().value();

const convertSelectionRangesToRows = (ranges) =>
  chain(ranges).map(_convertRangeToRows).flatten().value();

const splitSelectionIntoColumns = (selection) => {
  return _convertRangeToColumns(convertSelectionToObjectFormat(selection));
};

const splitSelectionIntoRows = (selection) => {
  return _convertRangeToRows(convertSelectionToObjectFormat(selection));
};

const RANGE_SEPARATOR = ',';

/*
 * makeSelection transforms a string into an array of selections.
 * it splits the string with RANGE_SEPARATOR then call inputToSelection on
 * every entries.
 * Eventually, if start or end are missing, we fill with the corresponding
 * value (if start is A but end is missing - user typed A or A: - it means
 * A:A, same for end)
 *
 * example:
 * - input: A,:B4, A1:B5
 * - ouput: [{
 *    start: [1, -1],
 *    firstCell: [1, -1],
 *    end: [1, -1],
 *   }, {
 *    start: [1, 3],
 *    firstCell: [1, 3],
 *    end: [1, 3],
 *   }, {
 *    start: [0, 0],
 *    firstCell: [0, 0],
 *    end: [1, 4],
 *  }]
 *
 * firstCell is needed, seems to be legacy.
 */
const makeSelections = (string) =>
  string
    .split(RANGE_SEPARATOR)
    .map((inputValue) => {
      if (inputValue === '') return {};

      const selection = inputToSelection(inputValue);
      let [start, end] = selection;

      // if the selection has an end but no start (ie, typing :B4)
      if (!start && !!end) start = end;
      // if the selection has a start but no end (ie, typing B4:)
      if (!end && !!start) end = start;

      return { start, firstCell: start, end };
    })
    .filter((sel) => !isEmpty(sel));

export {
  convertSelectionRangesToColumns,
  convertSelectionRangesToRows,
  convertSelectionToArrayFormat,
  convertSelectionToObjectFormat,
  formatCellValue,
  getCanvasCells,
  getColumnLetter,
  getEndOfSelection,
  getFullHeaderFromCfg,
  getFullRowOrColumnHeaderIndex,
  getHeaderCells,
  getHeaderCellsFromCfg,
  getNumberFromLetter,
  getSelectedCells,
  getSelectionHeaderIndices,
  getSelectionSize,
  getWindowSize,
  greedilyParseFloat,
  hasDisabledSelection,
  inputToSelection,
  isColumnSelection,
  isEntireColumnSelection,
  isEntireRowSelection,
  isRowSelection,
  isValidSelection,
  makeSelections,
  selectionToString,
  selectToIndices,
  splitSelectionIntoColumns,
  splitSelectionIntoRows,
  stripInteger,
  swapSelection,
  toBackendIndex,
  toFrontendIndex,
  toRenderableState,
  unzipPathSelections,
  updateSelection,
  zipObjectPath,
};
