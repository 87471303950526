import { chain, has, isArray, pick, set } from 'lodash';

import { applyAndMerge } from '../../../lib/config-mapper';
import {
  getGeckometerDefaultMinMax,
  setExtremum,
} from '../../../lib/geckometer';
import * as transformers from '../../../universal/transformers';

const getDefaultQuery = (fieldGroups) => {
  if (!has(fieldGroups, 'numeric[0]')) {
    return {
      aggregate: 'count',
    };
  }

  const query = {
    field: fieldGroups.numeric[0].key,
    slice: 1,
  };

  if (has(fieldGroups, 'datetime[0]')) {
    query.order_by = fieldGroups.datetime[0].key;
  }

  return query;
};

const ensureConfig = (currentConfig, queryResult) => {
  let config = pick(
    currentConfig,
    'dataSetId',
    'type',
    'min',
    'max',
    'title',
    'goal', // Remove goal once status indicators for geckometers have been migrated properly in the db
    'reverseGoalDirection',
    'indicators',
    '_saved',
    '_minTyped',
    '_maxTyped',
    'numberFormat',
    'juno',
  );

  const { item, format, unit } = transformers.datasets.geckometer.transform(
    queryResult,
    config,
  );

  config = { ...config, ...getGeckometerDefaultMinMax(item, format, unit) };

  const { _minTyped, _minDefault, min, _maxTyped, _maxDefault, max } = config;

  if (has(currentConfig, 'comparison.thresholdType')) {
    config.reverseGoalDirection =
      currentConfig.comparison.thresholdType === 'lower';
  }

  return {
    ...config,
    ...setExtremum('min', {
      _typed: _minTyped,
      _default: _minDefault,
      extremum: min,
    }),
    ...setExtremum('max', {
      _typed: _maxTyped,
      _default: _maxDefault,
      extremum: max,
    }),
    numberFormat: !isArray(currentConfig.numberFormat)
      ? currentConfig.numberFormat
      : {},
  };
};

const updateQuery = (currentQuery, path, value) => {
  if ('filters' === path) {
    return set(currentQuery, path, value);
  }

  if ('aggregate:count' === value) {
    return chain(currentQuery)
      .mapKeys((_value, key) => `_${key}`)
      .pick('_order_by', '_aggregate')
      .tap((query) => {
        query.aggregate = 'count';
      })
      .value();
  }

  return chain(currentQuery)
    .clone()
    .set(path, value)
    .tap((query) => {
      // omitBy would be great lodash@4
      // Always remove the aggregate count or latest
      if (['count', 'latest'].includes(query.aggregate)) {
        delete query.aggregate;
      }
    })
    .mapKeys((_value, key) => {
      return '_' === key[0] ? key.slice(1) : key;
    })
    .tap((query) => {
      // Insertion order means no explicit ordering
      if (query.order_by === 'order_by:insertion') {
        delete query.order_by;
      }

      // An aggregate cannot have an order
      if (query.aggregate && query.order_by) {
        query._order_by = query.order_by;
        delete query.order_by;
      }
    })
    .set('slice', 1)
    .value();
};

const MAP_METRICS = [
  ['metrics[0].field', null, 'field'],
  // Map latest aggregate to undefined because on geckometers we don't send an aggregate to use "latest"
  [
    'metrics[0].aggregate',
    (aggregate) => (aggregate !== 'latest' ? aggregate : undefined),
    'aggregate',
  ],
  ['filters'],
];

const LEADERBOARD_MAPPINGS = [
  ['metric', null, 'field'],
  ['aggregate', null, 'aggregate'],
  ['filters'],
];

const QUERY_MAPPING_RULES = {
  line: MAP_METRICS,
  column: MAP_METRICS,
  bar: MAP_METRICS,
  number: ['field', 'aggregate', 'filters'],
  leaderboard: LEADERBOARD_MAPPINGS,
  table: [['filters']],
};

const mapQuery = (fromType, ...rest) => {
  const query = applyAndMerge(QUERY_MAPPING_RULES[fromType], ...rest);

  // `aggregate: count` is incompatible with `field`
  if ('count' === query.aggregate) {
    delete query.field;
  }
  if (query.aggregate) {
    query.slice = 1;
    delete query.order_by;
  }

  return query;
};

const visualisationHelpers = {
  ensureConfig,
  getDefaultQuery,
  updateQuery,
  mapQuery,
};

export default visualisationHelpers;
