import * as customizeActions from '../../customize/actions/customize-actions';
import * as sharingMenuActions from '../../masthead/sharing-menu/actions/sharing-menu-actions';
import * as widgetActions from '../../widget/actions/widget-actions';
import * as dashboardActions from '../actions/dashboard-actions';
import { FRAME_WIDTH } from '../box-layout/box-layout-constants';

export const DASHBOARD_MODES = {
  UNKNOWN: 'UNKNOWN',
  SHARING: 'SHARING',
  EDIT: 'EDIT',
};

const ERRORS = {
  error: null,
  inviteError: null,
  sharingError: null,
  regenerateLinkError: null,
};

const initialState = {
  dashboard: undefined,
  addressInput: {},
  working: false,
  dashboards: null,
  draggingWidget: undefined,
  areWidgetsLoaded: false,
  isMobile: false,
  mode: DASHBOARD_MODES.UNKNOWN,
  ...ERRORS,
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case dashboardActions.setSharingMode.type:
      return {
        ...state,
        ...ERRORS,
        mode: DASHBOARD_MODES.SHARING,
      };
    case dashboardActions.setEditMode.type:
      return {
        ...state,
        ...ERRORS,
        mode: DASHBOARD_MODES.EDIT,
      };
    case dashboardActions.setDashboard.type:
      return {
        ...state,
        ...ERRORS,
        dashboard: payload,
        gridSize: FRAME_WIDTH / payload.columns,
        scaleFactor: undefined,
        classicboardScaleFactor: undefined,
        dashboardWidth: undefined,
      };

    case dashboardActions.changeDashboard.type:
      return {
        ...state,
        ...ERRORS,
        dashboard: payload,
      };

    case customizeActions.saveSuccessful.type:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          ...payload.dashboard,
        },
        gridSize: FRAME_WIDTH / payload.dashboard.columns,
      };

    case dashboardActions.dashboardError.type:
      return {
        ...state,
        ...ERRORS,
        error: payload,
      };

    case dashboardActions.setCurrentDashboardSharingLink.type:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          sharing_hash: payload,
        },
      };

    case sharingMenuActions.regenerateSharingLinkSuccess.type:
      return {
        ...state,
        ...ERRORS,
        regenerateLinkError: null,
      };

    case sharingMenuActions.regenerateSharingLinkFailure.type:
      return {
        ...state,
        ...ERRORS,
        regenerateLinkError: 'share.regenerateError',
      };

    case dashboardActions.updateSharingError.type:
      return {
        ...state,
        ...ERRORS,
        sharingError: payload,
      };

    case dashboardActions.setDashboardTitleStart.type:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          title: payload,
        },
      };

    case dashboardActions.setDashboardScaleFactor.type:
      return {
        ...state,
        scaleFactor: payload.scale,
        dashboardWidth: payload.dashboardWidth,
      };

    case dashboardActions.setClassicboardScaleFactor.type:
      return {
        ...state,
        classicboardScaleFactor: payload,
      };

    case widgetActions.widgetDragStart.type:
      return {
        ...state,
        draggingWidget: payload,
      };

    case widgetActions.widgetDragEnd.type:
      return {
        ...state,
        draggingWidget: undefined,
      };

    case widgetActions.setWidgets.type: {
      return {
        ...state,
        areWidgetsLoaded: true,
      };
    }

    case dashboardActions.setIsMobile.type: {
      return {
        ...state,
        isMobile: payload,
      };
    }

    case dashboardActions.setImportingService.type: {
      return {
        ...state,
        importingService: payload,
        isImportingModalOpen: true,
      };
    }
    case dashboardActions.toggleImportingModal.type: {
      return {
        ...state,
        isImportingModalOpen: payload.open,
        openModalService: payload.service,
      };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
