import * as whatsNewActions from '../whats-new-actions/whats-new-actions';

const initialState = {
  items: null,
  error: null,
  visible: false,
  loading: false,
  showNotification: false,
  etag: null,
};

const whatsNewReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case whatsNewActions.toggleModalAction.type:
      return {
        ...state,
        visible: payload,
      };
    case whatsNewActions.toggleNotification.type:
      return {
        ...state,
        showNotification: payload,
      };
    case whatsNewActions.fetchDataStart.type:
      return {
        ...state,
        loading: true,
      };
    case whatsNewActions.fetchDataSuccess.type:
      return {
        ...state,
        items: payload.items,
        error: null,
        loading: false,
        etag: payload.etag,
      };
    case whatsNewActions.fetchDataFailed.type:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default whatsNewReducer;
