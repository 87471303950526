export default [
  {
    id: '2fb0cabe-55d4-4d2c-8c64-5cd2cc7011b1',
    title: 'Chat volume',
    description: 'Number of chats started each day.',
    category: 'Volume',
    type: 'line',
    icon: {
      type: 'icon',
      value: 'line',
    },
    queries: [
      {
        select: [
          { resource: 'chats', path: 'timestamp' },
          { resource: 'chats', custom: 'created_chats', aggregate: 'count' },
        ],
        filter: [
          {
            resource: 'chats',
            path: 'timestamp',
            operator: 'timespan',
            operands: [7, 'day'],
          },
        ],
        group_by: [{ resource: 'chats', path: 'timestamp', bucket_by: 'day' }],
        source: { integration: 'zendeskchat' },
      },
    ],
  },
  {
    id: '60307a8e-55bc-46e6-9245-6b7fa2623edf',
    title: 'Missed chats today',
    description:
      'Number of chats where the visitor left before receiving an answer.',
    category: 'Volume',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          { resource: 'chats', custom: 'missed_chats', aggregate: 'count' },
        ],
        filter: [
          {
            resource: 'chats',
            path: 'timestamp',
            operator: 'timespan',
            operands: [1, 'day'],
          },
          {
            resource: 'chats',
            path: 'missed',
            operator: 'in',
            operands: [true],
            bound_to_metric: true,
          },
        ],
        source: { integration: 'zendeskchat' },
      },
    ],
  },
  {
    id: '2636f6a2-576d-4697-9365-5f199079e2e0',
    title: 'Satisfaction rating',
    description: 'Percentage of good satisfaction ratings.',
    category: 'Customer satisfaction',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [{ resource: 'chats', path: 'sat_score' }],
        filter: [
          {
            resource: 'chats',
            path: 'timestamp',
            operator: 'timespan',
            operands: [7, 'day'],
          },
        ],
        source: { integration: 'zendeskchat' },
      },
    ],
  },
  {
    id: '423a6568-bb46-49ab-8e00-a23a9d418546',
    title: 'Chats by tag',
    description: 'The most frequent tags.',
    category: 'Topics',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        select: [
          { resource: 'chats', path: 'tags' },
          { resource: 'chats', custom: 'created_chats', aggregate: 'count' },
        ],
        filter: [
          {
            resource: 'chats',
            path: 'timestamp',
            operator: 'timespan',
            operands: [7, 'day'],
          },
        ],
        group_by: [{ resource: 'chats', path: 'tags' }],
        order_by: [
          { resource: 'chats', custom: 'created_chats', order: 'desc' },
        ],
        source: { integration: 'zendeskchat' },
      },
    ],
  },
  {
    id: '19fc4425-42a8-439f-b17c-37d26d93b93f',
    title: 'Current queue',
    description: 'The number of visitors currently waiting to start a chat.',
    category: 'Real time (requires Chat Enterprise)',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [{ resource: 'real_time_chats', path: 'queue' }],
        source: { integration: 'zendeskchat' },
      },
    ],
  },
  {
    id: '12f2595e-4d6c-4dcc-9a5e-0ad2b97d47c7',
    title: 'Average wait time',
    description: 'The time visitors wait for a chat to start.',
    category: 'Real time (requires Chat Enterprise)',
    type: 'number',
    icon: {
      type: 'text',
      value: '1m20s',
    },
    queries: [
      {
        select: [{ resource: 'real_time_chats', path: 'waiting_time_avg' }],
        source: { integration: 'zendeskchat' },
      },
    ],
  },
  {
    id: 'f7e55d0e-991a-44e6-995f-9314cdffafa0',
    title: 'Currently served',
    description: 'The number of chats currently in progress.',
    category: 'Real time (requires Chat Enterprise)',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [{ resource: 'real_time_chats', path: 'active_chats' }],
        source: { integration: 'zendeskchat' },
      },
    ],
  },
];
