import { has } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericGeckometerTransform } from '../per-visualisation/geckometer';

import { pickFormat } from './helpers';

const geckometerTransform = (payload, timezone, initialContext = {}) => {
  if (!has(payload, 'values[0]')) {
    return {};
  }

  const { metric_type: metricType, metric_unit: metricUnit } = payload;
  const format = pickFormat(metricType);

  const context = {
    item: payload.values[0],
    format,
  };

  if (metricUnit) {
    context.unit = metricUnit;
  }

  return { ...initialContext, ...context };
};

const transform = composeTransformers(
  geckometerTransform,
  genericGeckometerTransform,
);

export { transform };
