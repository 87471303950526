import { getWindow } from '../../lib/global';

// Duplicate of gecko-js function
// https://github.com/geckoboard/gecko-js/blob/master/src/base/tracking_id.js#L3-L10
export const getRandomTrackingId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// Duplicate of gecko-js function
// https://github.com/geckoboard/gecko-js/blob/master/src/base/tracker.js#L20-L23
export const isTrackingEnabled = () => {
  // on dev or while impersonating, we are disabling external trackers
  return !!getWindow().TRACKING_ENABLED;
};
