const getDefaultConfig = () => {
  return {
    type: 'number',
    numberToDisplay: 'grandTotal',
  };
};

const isValid = (cfg) => {
  const { config: { yAxis } = {} } = cfg;

  return !!yAxis;
};

// We map the goal direction from line, column and geckometer
// to a sensible goal type
const GOAL_DIRECTION_TO_THRESHOLD_TYPE = [
  'reverseGoalDirection',
  (isReversed) => {
    if (isReversed) {
      return { type: 'goal', thresholdType: 'lower' };
    }

    return { type: 'goal', thresholdType: 'percentage' };
  },
  'comparison',
];

const configMappingRules = {
  line: ['yAxis', 'goal', 'numberFormat', GOAL_DIRECTION_TO_THRESHOLD_TYPE],
  column: ['yAxis', 'goal', 'numberFormat', GOAL_DIRECTION_TO_THRESHOLD_TYPE],
  bar: ['yAxis', 'goal', 'numberFormat', GOAL_DIRECTION_TO_THRESHOLD_TYPE],
  leaderboard: ['yAxis', 'numberFormat'],
  geckometer: ['yAxis', 'numberFormat'],
};

export default { getDefaultConfig, isValid, configMappingRules };
