import { formatMessage } from '../../lib/i18n';
import createAction from '../../lib/redux/create-action';
import * as toastStorage from '../toast-storage';

export const showToast = createAction('Toast:SHOW_TOAST');
export const showSuccessToast = createAction('Toast:SHOW_SUCCESS_TOAST');
export const showErrorToast = createAction('Toast:SHOW_ERROR_TOAST');
export const showGenericErrorToast = () =>
  showErrorToast(formatMessage('generic.request.failure'));
export const hideToast = createAction('Toast:HIDE_TOAST');

// Thunk actions
export const showScheduledToast = () => (dispatch) => {
  const toast = toastStorage.loadToastFromStorage();

  if (!toast) {
    return;
  }

  dispatch(showToast(toast));
};

export const scheduleToast = (toast) => (dispatch, getState) => {
  toastStorage.scheduleToast(toast, getState);
};
