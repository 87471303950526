import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';

const sharedScreenSchemaProperties = {
  'Screen ID': SchemaTypes.string.isRequired,
  'Screen content type': SchemaTypes.oneOf(['DASHBOARD', 'SHARING_LOOP']),
};

export const screenChangedContent = defineEvent(
  'Screen - Changed content',
  sharedScreenSchemaProperties,
);

export const screenConnected = defineEvent(
  'Screen - Connected',
  sharedScreenSchemaProperties,
);

export const screenDeleted = defineEvent(
  'Screen - Deleted',
  sharedScreenSchemaProperties,
);

export const screenRefreshed = defineEvent(
  'Screen - Refreshed',
  sharedScreenSchemaProperties,
);

export const screenRenamed = defineEvent(
  'Screen - Renamed',
  sharedScreenSchemaProperties,
);
