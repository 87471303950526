import { gql } from '@apollo/client';
import { isNil, omitBy } from 'lodash';

import SchemaTypes from '../../schema-types';

const timestampToISO = (timestamp) => {
  return new Date(timestamp * 1000).toISOString();
};

const filterNilValues = (valuesToFilter) => {
  return omitBy(valuesToFilter, isNil);
};

const userIsOnFreePlan = (organization) => {
  return organization.is_on_free_plan;
};

const OrgDashboardAllowanceQuery = gql`
  query OrgDashboardAllowance {
    currentUser {
      id
      organization {
        id
        allowances {
          dashboards {
            usage
          }
        }
      }
    }
  }
`;

const organizationContext = {
  schema: {
    'Account name': SchemaTypes.string.isRequired,
    Billing: SchemaTypes.bool.isRequired,
    'Dashboard count': SchemaTypes.number.isRequired,
    'Day in lifetime': SchemaTypes.number.isRequired,
    'Organization ID': SchemaTypes.string.isRequired,
    Plan: SchemaTypes.string.isRequired,
    'Signup form': SchemaTypes.string,
    'Trial days remaining': SchemaTypes.number,
    'Billing frequency': SchemaTypes.oneOf(['Monthly', 'Annual']),
    'Billing type': SchemaTypes.oneOf(['Manual', 'Automatic']),
    'Organization created date': SchemaTypes.string.isRequired,
    'Trial end date': SchemaTypes.string.isRequired,
    'Latest trial start date': SchemaTypes.string,
    'Pro trial count': SchemaTypes.number.isRequired,
    'First subscription date': SchemaTypes.string,
    'Initial referrer': SchemaTypes.string,
    'UTM campaign': SchemaTypes.string,
    'UTM content': SchemaTypes.string,
    'UTM medium': SchemaTypes.string,
    'UTM name': SchemaTypes.string,
    'UTM term': SchemaTypes.string,
  },
  resolve: async ({ reduxState, apolloClient }) => {
    const {
      user: { user },
      user: { organization },
    } = reduxState;

    const { data } = await apolloClient.query({
      query: OrgDashboardAllowanceQuery,
    });
    return {
      'Account name': organization.account,
      Billing: organization.billing === 'y',
      'Dashboard count':
        data?.currentUser?.organization.allowances.dashboards.usage,
      'Day in lifetime': organization.day_in_lifetime,
      'Organization ID': `${organization.id}`,
      Plan: organization.plan,
      'Billing type': organization.is_subscription_manual
        ? 'Manual'
        : 'Automatic',
      ...omitBy(
        {
          'Billing frequency': organization.annual_subscription
            ? 'Annual'
            : 'Monthly',
        },
        () => userIsOnFreePlan(organization),
      ),
      'Organization created date': timestampToISO(organization.date),
      'Trial end date': timestampToISO(organization.trial),
      'Pro trial count': organization.number_of_trials_taken,
      ...filterNilValues({
        'Trial days remaining':
          organization.trial_days_remaining <= -1
            ? null
            : organization.trial_days_remaining,
        'Signup form': user.signup_form,
        'Latest trial start date': organization.latest_trial_started_at,
        'First subscription date': user.subscription_date,
        'Initial referrer': user.initial_referrer,
        'UTM campaign': user.utm_campaign,
        'UTM content': user.utm_content,
        'UTM medium': user.utm_medium,
        'UTM name': user.utm_name,
        'UTM term': user.utm_term,
      }),
    };
  },
};

export default organizationContext;
