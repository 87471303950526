import { getWindow } from '../global';

function send(error, opts = {}) {
  const _window = getWindow();
  const { metaData = {} } = opts;

  // TODO: remove this when we figure out why bugsnag is receiving weird error values it can't handle
  metaData['error debugging'] = {
    'raw error': error,
    typeof: typeof error,
    keys: typeof error === 'object' ? Object.keys(error) : [],
  };

  opts.metaData = metaData;

  if (_window.bugsnagClient) {
    _window.bugsnagClient.notify(error, opts);
  } else if (_window.console) {
    _window.console.error(error);
  }
}

export default { send };
