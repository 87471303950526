import { sortBy } from 'lodash';

export const UNIVERSAL_SERVICES = [
  'facebookadsUniversal',
  'freshdesk',
  'gaUniversal',
  'hubspot3',
  'intercom3',
  'pipedrive2',
  'spacecargo',
  'zendeskUniversal',
  'zendeskchat',
];

export const SPREADSHEET_SERVICE_NAMES = [
  'gsheets',
  'dropbox',
  'onedrivebusiness',
  'hostedexcel',
  'sharepoint',
] as const;

export const BAUHAUS_SERVICES = [
  'stripe2',
  'shopify2',
  'intercom4',
  'ga4',
  'facebookads2',
  'spacecargobauhaus',
  'zendesk3',
  'google_search_console',
  'youtube5',
];

export const PLATFORM_NAMES = {
  UNIVERSAL: 'universal',
  BAUHAUS: 'bauhaus',
  HEIRLOOM: 'heirloom',
  INTEGRATION_DATASETS: 'integrationDatasets',
  DATASETS: 'datasets',
  DATABASE: 'database',
  SALESFORCE: 'salesforce',
};

export const sortByTitle = <T extends { title: string }>(
  services: T[],
): T[] => {
  return sortBy(services, ({ title = '' }) => title.toLowerCase());
};
