import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import style from './select-dropdown-style.scss';

class SelectDropdownComponent extends Component {
  renderOptions({ value, label }) {
    return (
      <option value={value} key={value}>
        {label}
      </option>
    );
  }

  render() {
    const { layoutClassName, name, options, onChange, selected } = this.props;
    const classes = classNames(layoutClassName, style.wrapper);
    if (!options) {
      return null;
    }

    return (
      <div className={classes}>
        <select
          name={name}
          className={style.select}
          value={selected}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        >
          {options.map(this.renderOptions)}
        </select>
      </div>
    );
  }
}

SelectDropdownComponent.propTypes = {
  layoutClassName: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default SelectDropdownComponent;
