import { mapValues, without } from 'lodash';

import { trackEvent } from '@Tracking';
import { dashboardSavedCustomization } from '@Tracking/events';

import { FRAME_WIDTH } from '../../dashboard/box-layout/box-layout-constants';
import { isWidgetOutOfFrame } from '../../dashboard/box-layout/helpers';
import { USER_THEME } from '../../dashboard/themes/theme-constants';
import { dispatchToGeckoJS } from '../../lib/gecko-view';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { track } from '../../lib/tracker';
import waitForState from '../../lib/wait-for-state';
import { router } from '../../router';
import * as conciergeService from '../../services/concierge-service/screens';
import { uploadCustomCSS as serviceUploadCustomCSS } from '../../services/custom-css-upload-service';
import { updateDashboard } from '../../services/dashboard-service';
import {
  showGenericErrorToast,
  showToast,
} from '../../toast/actions/toast-actions';
import { FOOTER_SWITCHED_ON } from '../../toast/toast-constants';
import { moveOffCanvas } from '../../widget/actions/widget-actions';
import {
  UPLOAD_CUSTOM_CSS_STATE_FAILED,
  UPLOAD_CUSTOM_CSS_STATE_STARTED,
  UPLOAD_LOGO_STATE_FAILED,
  UPLOAD_LOGO_STATE_STARTED,
} from '../customize-constants';
import filterByAllowances from '../filter-by-allowances';

export const loading = createAction('Customize:LOADING');
export const initialiseValues = createAction('Customize:INITIALISE_VALUES');
export const cancel = createAction('Customize:CANCEL');
export const setTheme = createAction('Customize:SET_THEME');
export const setThemeBackground = createAction(
  'Customize:SET_THEME_BACKGROUND',
);
export const setThemeWidgetStyle = createAction(
  'Customize:SET_THEME_WIDGET_STYLE',
);
export const setShowGridPreview = createAction(
  'Customize:SET_SHOW_GRID_PREVIEW',
);
export const setScaleToFit = createAction('Customize:SET_SCALE_TO_FIT');
export const setTvMode = createAction('Customize:SET_TV_MODE');
export const setGridSize = createAction('Customize:SET_GRID_SIZE');
export const setLargeTitles = createAction('Customize:SET_LARGE_TITLES');
export const setShowFooter = createAction('Customize:SET_SHOW_FOOTER');
export const setShowClock = createAction('Customize:SET_SHOW_CLOCK');
export const setShowLogo = createAction('Customize:SET_SHOW_LOGO');
export const setLogoSuccessful = createAction('Customize:SET_LOGO_SUCCESSFUL');
export const setLogoFailed = createErrorAction('Customize:SET_LOGO_FAILED');
export const setLogoValidationFailed = createAction(
  'Customize:SET_LOGO_VALIDATION_FAILED',
);
export const saveStart = createAction('Customize:SAVE_START');
export const saveSuccessful = createAction('Customize:SAVE_SUCCESSFUL');
export const saveFailed = createErrorAction('Customize:SAVE_FAILED');
export const uploadLogoStart = createAction('Customize:UPLOAD_LOGO_START');

export const uploadLogoFailed = createErrorAction(
  'Customize:UPLOAD_LOGO_FAILED',
);
export const setCustomCSSValidationFailed = createAction(
  'Customize:SET_CUSTOM_CSS_VALIDATION_FAILED',
);
export const uploadCustomCSSStart = createAction(
  'Customize:UPLOAD_CUSTOM_CSS_START',
);
export const uploadCustomCSSSuccessful = createAction(
  'Customize:UPLOAD_CUSTOM_CSS_SUCCESSFUL',
);
export const uploadCustomCSSFailed = createErrorAction(
  'Customize:UPLOAD_CUSTOM_CSS_FAILED',
);
export const setCustomCSSLoaded = createErrorAction(
  'Customize:SET_CUSTOM_CSS_LOADED',
);
export const setColumns = createAction('Customize:SET_COLUMNS');

export const mount = () => async (dispatch, getState) => {
  dispatch(loading());

  await waitForState(
    getState,
    (state) =>
      !!state.dashboard.dashboard && !!state.dashboard.areWidgetsLoaded,
  );

  const {
    dashboard: { dashboard },
    widgets,
  } = getState();

  dispatch(initialiseValues({ dashboard, widgets }));
};

const waitForLogoUpload = async (getState) => {
  let { uploadLogoState } = getState().customize;

  if (uploadLogoState === UPLOAD_LOGO_STATE_STARTED) {
    await waitForState(
      getState,
      (state) => state.customize.uploadLogoState !== UPLOAD_LOGO_STATE_STARTED,
    );

    ({ uploadLogoState } = getState().customize);
    if (uploadLogoState === UPLOAD_LOGO_STATE_FAILED) {
      throw new Error('Save cancelled due to logo upload failed.');
    }
  }
};

const waitForCustomCSSUpload = async (getState) => {
  let { uploadCustomCSSState } = getState().customize;

  if (uploadCustomCSSState === UPLOAD_CUSTOM_CSS_STATE_STARTED) {
    await waitForState(
      getState,
      (state) =>
        state.customize.uploadCustomCSSState !==
        UPLOAD_CUSTOM_CSS_STATE_STARTED,
    );

    ({ uploadCustomCSSState } = getState().customize);
    if (uploadCustomCSSState === UPLOAD_CUSTOM_CSS_STATE_FAILED) {
      throw new Error('Save cancelled due to Custom CSS upload failed.');
    }
  }
};

export const save = (dashboardId) => async (dispatch, getState) => {
  try {
    dispatch(saveStart());

    await Promise.all([
      waitForLogoUpload(getState),
      waitForCustomCSSUpload(getState),
    ]);

    const {
      dashboard: {
        dashboard,
        dashboard: { tv_mode: currentTvMode, id, toggles },
      },
      customize,
      user: { user: { allowances = {} } = {} },
    } = getState();

    const values = filterByAllowances(customize.values, allowances);

    const {
      theme,
      themeBackgroundColor,
      themeWidgetStyle,
      scaleToFit,
      tvMode,
      showFooter,
      showClock,
      showLogo,
      logoSrc,
      widgets: { largeTitles },
    } = values;

    // Map `largeTitles` to a dashboard toggles
    const newToggles = without(toggles, 'large_widget_titles');
    if (largeTitles) {
      newToggles.push('large_widget_titles');
    }

    const isGridLayout = newToggles.includes('grid');
    const columns = isGridLayout
      ? FRAME_WIDTH / values.gridSize
      : values.columns;

    const updatedDashboard = await updateDashboard(id, {
      ...dashboard,
      theme,
      columns,
      scale_when_fullscreen: scaleToFit,
      tv_mode: tvMode,
      toggles: newToggles,
      customization: {
        ...dashboard.customization,
        themeBackgroundColor,
        themeWidgetStyle,
        showFooter,
        showClock,
        showLogo,
        logoSrc,
      },
    });

    // Move any off-frame widgets into the off-canvas area
    if (isGridLayout) {
      mapValues(customize.widgets, (widget) => {
        if (isWidgetOutOfFrame(widget.layout)) {
          dispatch(moveOffCanvas(widget.widgetKey, false));
        }
      });
    }

    dispatch(
      saveSuccessful({
        dashboard: updatedDashboard,
        widgets: customize.widgets,
      }),
    );

    if (!currentTvMode && tvMode) {
      track('Customize Appearance saved with Fonts = Large');
    }
    if (currentTvMode && !tvMode) {
      track('Customize Appearance saved with Fonts = Small');
    }

    trackEvent(
      dashboardSavedCustomization({
        Theme: theme,
        ...(theme === USER_THEME && {
          'Custom Theme Background Color': themeBackgroundColor,
          'Custom Theme Widget Style': themeWidgetStyle,
        }),
        'Scale to fit': scaleToFit,
        'TV font': tvMode,
        'Show footer': showFooter,
        'Show clock': showClock,
        'Show logo': showLogo,
        'Number of columns': columns,
      }),
    );

    router.navigate(`/edit/dashboards/${id}`);

    // Show toast if footer is shown
    if (values.showFooter && !isGridLayout) {
      dispatch(showToast({ type: FOOTER_SWITCHED_ON }));
    }

    // reload remote dashboards and current edit dashboard
    conciergeService.refreshDashboard(dashboardId);
    dispatchToGeckoJS('dashboard:refresh');
  } catch (error) {
    dispatch(saveFailed(error));
    dispatch(showGenericErrorToast());
  }
};

const uploadCustomCSS = (file) => async (dispatch, getState) => {
  dispatch(uploadCustomCSSStart());

  try {
    const {
      dashboard: {
        dashboard: { id },
      },
    } = getState();
    const url = await serviceUploadCustomCSS(id, file);

    dispatch(uploadCustomCSSSuccessful(url));
  } catch (error) {
    dispatch(uploadCustomCSSFailed(error));
  }
};

export const setCustomCSS = (file) => (dispatch) => {
  try {
    if (!['text/css'].includes(file.type)) {
      dispatch(
        setCustomCSSValidationFailed('customize.validationFileTypeInvalidCSS'),
      );
      return;
    }

    if (file.size > 2000000) {
      dispatch(
        setCustomCSSValidationFailed('customize.validationFileTooLarge'),
      );
      return;
    }

    dispatch(uploadCustomCSS(file));
  } catch (error) {
    dispatch(uploadCustomCSSFailed(error));
  }
};
