import { trackEvent } from '@Tracking';
import {
  spreadsheetsUrlFailed,
  spreadsheetsUrlSucceeded,
} from '@Tracking/events';

import actionPicker from '../../config-wrapper/action-picker';
import { setState as setDataSourceConnectorState } from '../../data-source-connector/actions/data-source-connector-actions';
import fetchFiles from '../../data-source-connector/actions/fetch-files-action';
import DebouncePromise from '../../lib/debounce-promise';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { router } from '../../router';
import servicePicker from '../../services/service-picker';
import datasetService from '../../universal/services/dataset-service';

// Redux actions
function debouncify(fn) {
  const queryManager = new DebouncePromise();
  return (...args) => {
    return queryManager.push(fn(...args));
  };
}

export const closeFilePicker = createAction('File:CLOSE_FILE_PICKER');
export const fetchFilesStart = createAction('File:FETCH_FILES_START');
export const fetchFilesFailed = createErrorAction('File:FETCH_FILES_ERROR');
export const fetchFilesSuccess = createAction('File:FETCH_FILES_SUCCESS');
export const fetchFoldersSuccess = createAction('File:FETCH_FOLDERS_SUCCESS');
export const resetSelectedFolder = createAction('File:RESET_SELECTED_FOLDER');
export const filter = createAction('File:FILTER');
export const folderSelected = createAction('File:FOLDER_SELECTED');
export const obtainUUIDError = createErrorAction('File:OBTAIN_UUID_ERROR');
export const clearImportError = createErrorAction('File:CLEAR_IMPORT_ERROR');

const spreadsheetsAuthType = 'null';
const spreadsheetsIntegrationName = 'Spreadsheets';
const spreadsheetsIntegrationSlug = 'spreadsheets';

const trackSpreadsheetsFileSuccess = (serviceName) => {
  if (serviceName === 'hostedexcel') {
    trackEvent(
      spreadsheetsUrlSucceeded({
        'Auth type': spreadsheetsAuthType,
        'Integration name': spreadsheetsIntegrationName,
        'Integration slug': spreadsheetsIntegrationSlug,
        'Data source': serviceName,
      }),
    );
  }
};

const trackSpreadsheetsFileFailed = (serviceName, error, url) => {
  if (serviceName === 'hostedexcel') {
    trackEvent(
      spreadsheetsUrlFailed({
        'Auth type': spreadsheetsAuthType,
        'Integration name': spreadsheetsIntegrationName,
        'Integration slug': spreadsheetsIntegrationSlug,
        'Data source': serviceName,
        'URL failure reason': error,
        'Failure Spreadsheet URL': url,
      }),
    );
  }
};

const debouncedFetchList = debouncify((service, ...args) => {
  return service.fetchList(...args);
});

export const searchFiles =
  (serviceName, serviceAccountId, query) => async (dispatch) => {
    try {
      const service = servicePicker(serviceName);
      dispatch(fetchFilesStart());

      const params = {
        serviceAccountId,
        query: encodeURIComponent(query),
      };

      const files = await debouncedFetchList(service, serviceName, params);
      if (!files) {
        throw Error('There was an error fetching your data.');
      }

      dispatch(fetchFilesSuccess({ files }));
    } catch (err) {
      dispatch(
        fetchFilesFailed(err, {
          isServiceAccountExpired: 'ErrOAuthTokenExpiredOrInvalid' === err.type,
          message: err.message,
        }),
      );
    }
  };

export const filterSearch = (attribute, query) => (dispatch) => {
  dispatch(filter({ attribute, query }));
};

// This use used by both datasets and spreadsheets. For spreadsheets it's possible
// to pass a uuid in the fileOptions. This will avoid triggering a re-import (using
// obtainUUID()) and instead use another endpoint to refetch the file. This happens
// when choosing an imported spreadsheet rather than importing a new one.
export const openFile =
  (serviceName, fileOptions, getRedirectPath) => async (dispatch) => {
    try {
      const service = servicePicker(serviceName);

      const uuid =
        fileOptions.uuid ??
        (await service.obtainUUID(serviceName, fileOptions)).uuid;

      trackSpreadsheetsFileSuccess(serviceName);

      dispatch(closeFilePicker());

      router.navigate(getRedirectPath(uuid));
    } catch (err) {
      const failureUrl = fileOptions.spreadsheetUrl;

      trackSpreadsheetsFileFailed(serviceName, err.toString(), failureUrl);

      dispatch(setDataSourceConnectorState({ isFileSelected: false }));
      dispatch(
        obtainUUIDError(err, {
          message: err.toString(),
          type: err.type,
        }),
      );
    }
  };

// This use used by both datasets and spreadsheets. For spreadsheets it's possible
// to pass a uuid in the fileOptions. This will avoid triggering a re-import (using
// obtainUUID()) and instead use another endpoint to refetch the file. This happens
// when choosing an imported spreadsheet rather than importing a new one.
export const changeFile =
  (serviceName, fileOptions, polecatService, getRedirectPath) =>
  async (dispatch) => {
    try {
      const service = servicePicker(serviceName);
      const uuid =
        fileOptions.uuid ??
        (await service.obtainUUID(serviceName, fileOptions)).uuid;

      trackSpreadsheetsFileSuccess(serviceName);

      dispatch(closeFilePicker());

      dispatch(actionPicker(polecatService).changeFile(uuid));

      if (getRedirectPath) {
        const redirectionPath = getRedirectPath(uuid);

        if (redirectionPath !== '') {
          router.navigate(redirectionPath);
        }
      }
    } catch (err) {
      const failureUrl = fileOptions.spreadsheetUrl;

      trackSpreadsheetsFileFailed(serviceName, err.toString(), failureUrl);

      dispatch(setDataSourceConnectorState({ isFileSelected: false }));
      dispatch(
        obtainUUIDError(err, {
          message: err.toString(),
        }),
      );
    }
  };

export const setSelectedFolder = (folder) => (dispatch) => {
  dispatch(folderSelected(folder));
};

export const deleteDataset = (dataSetId) => async (dispatch) => {
  await datasetService.deleteDataset(dataSetId);
  dispatch(fetchFiles('datasets', {}));
};
