/* eslint camelcase:0 */
import { isEmpty, isNil, omit } from 'lodash';

import {
  getSelectedCells,
  getSelectionHeaderIndices,
} from '../../spreadsheet-helpers';
import { composeTransformers } from '../compose-transformers';
import { transform as numberFormatTransform } from '../shared/number-format';

import {
  alignSeries,
  getMixedInputValue,
  getSelectionFormat,
  indexSeries,
  indexXAxis,
  padXAxis,
} from './helpers';
import { getLabels, getSeries } from './series-helpers';

const barTransform = (spreadsheet, config, timezone, initialContext = {}) => {
  const context = {};
  const {
    hasHeader,
    xAxis,
    series = [],
    goal,
    reverseGoalDirection,
    legends,
  } = config;

  if (!xAxis) {
    return context;
  }

  const { cells } = spreadsheet;
  const seriesCells = getSelectedCells(cells, series);
  const [xAxisCells] = getSelectedCells(cells, [xAxis]);
  let format, unit, headerXAxisIndices, headerseriesKeys;

  if (xAxisCells.length) {
    // We have labels at least
    if (hasHeader) {
      const selections = [xAxis, ...series];
      const selectedCells = [xAxisCells, ...seriesCells];

      [headerXAxisIndices, ...headerseriesKeys] = getSelectionHeaderIndices(
        selections,
        selectedCells,
      );
    }

    // pad out the x axis if we're missing the cell relative to the series headers
    const paddedXAxisCells = padXAxis(xAxisCells, headerXAxisIndices);
    // Build an index from the cells we have found in our X-axis selection
    const indexedXAxis = indexXAxis(paddedXAxisCells, xAxis);
    // Build a corresponding index of our series cells
    const indexedSeries = seriesCells.map((sCells, i) => {
      return indexSeries(sCells, series[i]);
    });

    // Match our series up with our X-axis cells to create a correctly
    // aligned array of series. This ensures that any missing values are
    // filled with nulls
    const alignedSeries = alignSeries(indexedXAxis, indexedSeries);

    const seriesOfSelection = getSeries(
      alignedSeries,
      headerseriesKeys,
      legends,
    );

    [format, unit] = getSelectionFormat(seriesOfSelection);

    let seriesValues = seriesOfSelection.map((line) =>
      omit(line, 'type', 'currency'),
    );

    if (isEmpty(seriesValues)) {
      // If no series has been selected we would still like to render the chart for the config page
      // We can do this by passing fake values for the series down
      const name = 'Series A';
      const data = [];
      xAxisCells.forEach(() => data.push(0));
      seriesValues = hasHeader ? [{ name, data }] : [{ data }];
    } else {
      // We also need to ensure that the data array within each series object does not contain only null values
      // This occurence can happen if a user selects a Series then clears the input
      seriesValues = seriesValues.map((seriesObj) => {
        const { name } = seriesObj;
        let { data } = seriesObj;

        // Here we are checking that if every element in the array is null, if so we pass down fake values for the series
        if (data.every((element) => isNil(element))) {
          data = [];
          xAxisCells.forEach(() => data.push(0));
        }

        return { name, data };
      });
    }

    context.x_axis = getLabels(xAxisCells, headerXAxisIndices);
    context.series = seriesValues;
    context.y_axis = { format, unit };

    // Bar does not currently support anything but date time formatting on the x-axis
    if ('datetime' !== context.x_axis.type) {
      context.x_axis.type = 'standard';
    }
  }

  context.threshold = getMixedInputValue(cells, goal, format);
  context.reverseGoalDirection = reverseGoalDirection;

  return { ...initialContext, ...context };
};

const transform = composeTransformers(barTransform, numberFormatTransform);

export { transform };
