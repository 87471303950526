import {
  cloneDeep,
  get,
  has,
  includes,
  isArray,
  pick,
  set,
  times,
} from 'lodash';

import { applyAndMerge } from '@Lib/config-mapper';

const DEFAULT_AGGREGATE = 'sum';
const DATETIME_BUCKETING_ONLY = ['minute', 'hour'];
const MAX_METRICS = 5;

const ensureConfig = (currentConfig) => {
  const config = pick(
    currentConfig,
    'title',
    'type',
    'dataSetId',
    'legends',
    'goal',
    '_saved',
    'reverseGoalDirection',
    'juno',
  );

  if (has(currentConfig, 'comparison.thresholdType')) {
    config.reverseGoalDirection =
      currentConfig.comparison.thresholdType === 'lower';
  }
  config.numberFormat = !isArray(currentConfig.numberFormat)
    ? currentConfig.numberFormat
    : {};

  if (config.legends) {
    // If coming from a line chart, it's possible there are more
    // legends than there are metrics because line charts supports
    // up to 9 metrics and column charts up to 5.
    config.legends = config.legends.slice(0, MAX_METRICS);
  }

  return config;
};

const getDefaultQuery = (fieldGroups) => {
  const firstXAxis = get(fieldGroups, 'groupable[0]');
  const firstNumericField = get(fieldGroups, 'numeric[0].key');

  if (!firstXAxis) {
    throw new Error(
      'Your dataset does not contain any date / datetime or string fields, which are required for this visualisation.',
    );
  }

  const { key: xAxisField, type: xAxisType } = firstXAxis;
  const xAxis = { field: xAxisField };
  const metric = {};

  if (firstNumericField) {
    metric.field = firstNumericField;
    metric.aggregate = DEFAULT_AGGREGATE;
  } else {
    // Apply record count aggregation when there are no numeric fields
    metric.aggregate = 'count';
    metric._aggregate = DEFAULT_AGGREGATE;
  }

  if (xAxisType !== 'string') {
    xAxis.bucket_by = 'day';
  }

  return { x_axis: xAxis, metrics: [metric] };
};

const getUpdatedOrderBy = (query, fields) => {
  if (!query.order_by) {
    return undefined;
  }

  const orderBy = { ...query.order_by };
  const xAxisField = get(query, 'x_axis.field');
  const xAxisType = fields[xAxisField].type;
  const numberOfMetrics = query.metrics.length;

  // Remove order_by when we're not on a string x-axis.
  if (xAxisType !== 'string') {
    return undefined;
  }

  if (!orderBy.direction) {
    orderBy.direction = 'asc';
  }

  // The order_by field has to exist in either metrics or be the x-axis.
  // So we the set the order_by by passing a _query_path so that
  // we can map the current field in the query at that path to the order_by.
  let orderByQueryField = get(query, orderBy._query_path);

  // If orderByQueryField doesn't exist, it means it was pointing
  // to a metric that's been deleted. Pick the last metric instead.
  if (!orderByQueryField) {
    const index = numberOfMetrics - 1;
    orderByQueryField = query.metrics[index];
    orderBy._query_path = `metrics[${index}]`;
  }

  // Don't spread orderBy so that when we switch from
  // metric to x-axis, it doesn't carry over aggregate.
  return {
    ...orderByQueryField,
    direction: orderBy.direction,
    _query_path: orderBy._query_path,
  };
};

const updateQuery = (currentQuery, path, value, fields) => {
  const newQuery = cloneDeep(currentQuery);
  set(newQuery, path, value);
  const { metrics = [] } = newQuery;
  const numberOfMetrics = metrics.length || 1;
  const xAxisField = get(newQuery, 'x_axis.field');
  const newXAxisType = fields[xAxisField].type;
  const switchFromStringType =
    path === 'x_axis.field' && newXAxisType !== 'string';
  const bucketBy = get(newQuery, 'x_axis.bucket_by');

  // When switching to a date type from a datetime - 'hour' is no longer a valid bucketing option
  if (includes(DATETIME_BUCKETING_ONLY, bucketBy) && 'date' === newXAxisType) {
    newQuery.x_axis.bucket_by = 'day';
  }

  // Switch xAxis type to date / datetime
  if (!bucketBy && switchFromStringType) {
    newQuery.x_axis.bucket_by = 'day';
  }

  // Remove bucketing for string xAxis
  if ('string' === newXAxisType) {
    delete newQuery.x_axis.bucket_by;
  }

  newQuery.metrics = times(numberOfMetrics, (i) => {
    const metric = get(newQuery, `metrics[${i}]`, {});

    // Latest aggregate is only supported on datetime x-axis
    if ('string' === newXAxisType) {
      if (metric.aggregate === 'latest') {
        metric.aggregate = DEFAULT_AGGREGATE;
      }
      if (metric._aggregate === 'latest') {
        metric._aggregate = DEFAULT_AGGREGATE;
      }
    }

    // Default aggregate to sum
    if (!metric.aggregate) {
      metric.aggregate = DEFAULT_AGGREGATE;
    }

    // Map a field value of `aggregate:count` to record count aggregation
    if ('aggregate:count' === metric.field) {
      delete metric.field;
      metric._aggregate = metric.aggregate;
      metric.aggregate = 'count';
    }

    // When a field is applied and we're currently aggregating by count
    // then we must revert to the original aggregate
    if (metric.field && 'count' === metric.aggregate) {
      metric.aggregate = metric._aggregate || DEFAULT_AGGREGATE;
      delete metric._aggregate;
    }

    // Remove split_by when we have more than 1 metric or the user has selected the none option
    if (numberOfMetrics > 1 || 'split_by:none' === value) {
      delete metric.split_by;
    }

    return metric;
  });

  newQuery.order_by = getUpdatedOrderBy(newQuery, fields);

  return newQuery;
};

const ensureMappedQuery = (query, fields) => {
  return {
    ...query,
    order_by: getUpdatedOrderBy(query, fields),
  };
};

const NUMBER_AND_GECKOMETER_MAPPINGS = [
  ['field', (field) => [{ field, aggregate: DEFAULT_AGGREGATE }], 'metrics'],
  // Override the default `aggregate` when available
  ['aggregate', null, 'metrics[0].aggregate'],
  ['filters'],
];

const LEADERBOARD_MAPPINGS = [
  ['label', null, 'x_axis.field'],
  ['metric', (field) => [{ field, aggregate: DEFAULT_AGGREGATE }], 'metrics'],
  // Override the default `aggregate` when available
  ['aggregate', null, 'metrics[0].aggregate'],
  ['filters'],
];

const QUERY_MAPPING_RULES = {
  geckometer: NUMBER_AND_GECKOMETER_MAPPINGS,
  number: NUMBER_AND_GECKOMETER_MAPPINGS,
  leaderboard: LEADERBOARD_MAPPINGS,
  line: [
    ['metrics', (metrics) => metrics.slice(0, MAX_METRICS)],
    ['x_axis'],
    ['filters'],
  ],
  bar: ['metrics', 'x_axis', 'order_by', 'filters'],
  table: [['filters']],
};

const mapQuery = (fromType, ...rest) =>
  applyAndMerge(QUERY_MAPPING_RULES[fromType], ...rest);

const visualisationHelpers = {
  ensureConfig,
  getDefaultQuery,
  updateQuery,
  ensureMappedQuery,
  mapQuery,
};

export default visualisationHelpers;
