import { getWindow } from '@Lib/global';

export const isGraphQLPublicDashboard = () => {
  return getWindow().location.pathname.includes('/v5/');
};

export const isGraphQLEditMode = () => {
  return getWindow().location.pathname.includes('/edit/dashboards/dash_');
};

export const isGraphQLDashboard = () => {
  return isGraphQLPublicDashboard() || isGraphQLEditMode();
};

export const isGraphQLLoop = () => {
  return (
    getWindow().location.pathname.includes('/v5/') &&
    getWindow().location.pathname.includes('/loop/')
  );
};
