import update from 'immutability-helper';
import { find, first, get, omit, pick } from 'lodash';

import * as customizeActions from '../../customize/actions/customize-actions';
import * as universalConfigSaveActions from '../../universal-config/actions/universal-config-save-actions';
import * as editWidgetActions from '../../widget/edit-widget/actions/edit-widget-actions';
import * as widgetDataActions from '../../widget-data/actions/widget-data-actions';
import * as widgetMenuActions from '../../widget-menu/actions/widget-menu-actions';
import * as widgetActions from '../actions/widget-actions';

/*
 This reducer/state is not used by dashies. The state is sometimes
 updated on dashies though we we dispatch some of the actions
 it listens to.
*/

const initialState = {};

const getVisualisationDetails = (widgetType = {}) => {
  const template = widgetType.template || {};
  // Clock, text and image don't use template data so we get their
  // type from the legacy `widget` attribute
  // then set the version to a default of 1
  const type = widgetType.widget;

  return {
    type: template.type || type,
    version: template.version || 1,
  };
};

// exported for use in custom-css-scrooge-helpers
export const getVisualisationClassName = (visualisation) => {
  switch (visualisation.type) {
    case 'bullet':
      return 'bulletchart-widget';
    case 'image':
      return 'image-widget';
    case 'clock':
      return 'clock-widget';
    case 'text':
      return 'polymorphic-widget text-widget';
    case 'map':
      return 'polymorphic-widget map-widget';
    case 'monitoring':
      return 'monitoring-widget';
    case 'pie':
      return 'piechart-widget';
    case 'rag':
      return 'polymorphic-widget rag-widget';
    case 'ragcolumn':
      return 'ragcolumn-widget';
    case 'funnel':
      return 'funnelchart-widget';
    case 'highcharts':
      return 'highchart-widget';
    case 'line':
    case 'polymorphic':
    case 'number':
    case 'geckometer':
    case 'leaderboard':
    case 'bar':
    case 'column':
    case 'table':
      return 'polymorphic-widget';
    default:
      return undefined;
  }
};

const addWidgetType = (item, widgetType) => {
  const visualisation = getVisualisationDetails(widgetType);
  const bodyClassName = getVisualisationClassName(visualisation);

  // Unfortunately the visualisation type is not in a consistent place for different types of
  // widgets, so we're pulling it from certain places depending on whether it's polymorphic /
  // non-polymorphic, also Spreadsheets also has it stored somewhere different.
  const getVisualisationType = () => {
    if (visualisation.type === 'polymorphic') {
      if (widgetType.service_name === 'spreadsheets') {
        return get(item, 'widget.config.template.type');
      }
      return get(item, 'widget.config.type');
    }
    return visualisation.type;
  };

  const visualisationType = getVisualisationType(item, visualisation);

  return {
    ...item.widget,
    id: item.id,
    widgetKey: item.key,
    serviceName: widgetType.service_name,
    serviceTitle: widgetType.service_title,
    widgetType,
    serviceId: first(widgetType.services),
    visualisation: {
      ...visualisation,
      vizType: visualisationType,
    },
    bodyClassName,
    isDataRequired: !widgetType.no_data,
  };
};

const widgetsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case widgetActions.setWidgets.type: {
      const { widgets, widgetTypes } = payload;

      return widgets.reduce((newState, item) => {
        const widgetType = find(widgetTypes, { id: item.widget.widget_type });
        const widgetWithProps = addWidgetType(item, widgetType);

        newState[item.key] = widgetWithProps;
        return newState;
      }, {});
    }

    case widgetActions.widgetChanged.type: {
      const { widget, widgetType } = payload;
      const widgetWithProps = addWidgetType(widget, widgetType);

      return {
        ...state,
        [widget.key]: widgetWithProps,
      };
    }

    case widgetActions.widgetRemoved.type:
      return omit(state, payload);

    case widgetActions.widgetAdded.type: {
      const { widget, widgetType } = payload;

      return {
        ...state,
        [widget.key]: addWidgetType(widget, widgetType),
      };
    }

    case widgetActions.widgetConfigChanged.type: {
      return update(state, {
        [payload.key]: { config: { $set: payload.config } },
      });
    }

    case widgetMenuActions.saveNewNumberFormat.type: {
      const { widgetKey, numberFormat } = payload;

      return {
        ...state,
        [widgetKey]: {
          ...state[widgetKey],
          config: {
            ...state[widgetKey].config,
            numberFormat,
          },
        },
      };
    }

    case widgetMenuActions.saveNewIndicator.type: {
      const { widgetKey, indicators } = payload;

      return {
        ...state,
        [widgetKey]: {
          ...state[widgetKey],
          config: {
            ...state[widgetKey].config,
            indicators,
          },
        },
      };
    }

    case customizeActions.saveSuccessful.type: {
      return Object.keys(payload.widgets).reduce((widgets, widgetKey) => {
        widgets[widgetKey] = {
          ...state[widgetKey],
          layout: payload.widgets[widgetKey].layout,
        };

        return widgets;
      }, {});
    }

    case widgetActions.widgetLocationChanged.type: {
      return {
        ...state,
        [payload.widgetKey]: {
          ...state[payload.widgetKey],
          location: payload.location,
          layout: payload.layout,
          stableOrder: payload.stableOrder,
        },
      };
    }

    case widgetActions.locationChangeStart.type: {
      return {
        ...state,
        [payload]: {
          ...state[payload],
          anticipateHide: true,
        },
      };
    }

    case widgetActions.locationChangeAccomplished.type: {
      return {
        ...state,
        [payload]: {
          ...state[payload],
          anticipateHide: false,
          anticipateShow: true,
        },
      };
    }

    case widgetActions.locationChangeEnd.type: {
      return {
        ...state,
        [payload]: {
          ...state[payload],
          anticipateShow: false,
        },
      };
    }

    case widgetDataActions.dataReceived.type:
      return {
        ...state,
        [payload.key]: omit(
          state[payload.key],
          'isAwaitingInitialServerSideRefresh',
        ),
      };

    case widgetDataActions.dataError.type:
      return {
        ...state,
        [payload]: omit(state[payload], 'isAwaitingInitialServerSideRefresh'),
      };

    case widgetActions.setAwaitingInitialServerSideRefresh.type: {
      return {
        ...state,
        [payload]: {
          ...state[payload],
          isAwaitingInitialServerSideRefresh: true,
        },
      };
    }

    case editWidgetActions.configUpdated.type:
    case universalConfigSaveActions.configUpdated.type: {
      const { widgetKey } = payload;
      const newWidgetAttributes = pick(payload, [
        'config',
        'refresh_interval',
        'service_account_id',
      ]);

      return {
        ...state,
        [widgetKey]: {
          ...state[widgetKey],
          ...newWidgetAttributes,
        },
      };
    }

    default:
      return state;
  }
};

export default widgetsReducer;
