/* eslint camelcase:0 */
class SpreadsheetsSerializer {
  serialize(state) {
    const {
      fileId: file_id,
      worksheetId: worksheet_id,
      service,
      warning,
      label,
      comparisonLabel,
    } = state;

    return {
      config: { file_id, worksheet_id, warning, label, comparisonLabel },
      service_name: service,
    };
  }

  deserialize(json) {
    const { file_id, worksheet_id, warning, label, comparisonLabel } =
      json.config;

    return {
      fileId: file_id,
      worksheetId: worksheet_id,
      warning,
      label,
      comparisonLabel,
    };
  }
}

export default SpreadsheetsSerializer;
