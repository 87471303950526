import LineChartSerializer from './line-chart';

class ColumnChartSerializer extends LineChartSerializer {}

ColumnChartSerializer.configDefaults = {
  template: {
    type: 'column',
    version: 1,
  },
};

ColumnChartSerializer.configKeys = [
  'hasHeader',
  'title',
  'xAxis',
  'series',
  'goal',
  'reverseGoalDirection',
  'legends',
  'numberFormat',
];

export default ColumnChartSerializer;
