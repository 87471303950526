const VALUES_QUERY_TEMPLATE =
  '{"select": [ { "resource": "orders", "path": "<%= key %>" } ], "filter": [ { "resource": "orders", "path": "order_time", "operator": "timespan", "operands": [7, "day"] } ], "group_by": [ { "resource": "orders", "path": "<%= key %>", "order": "desc" } ], "limit": 20 }';

export default {
  api: {
    max_time_period: 10368000000,
  },
  resources: [
    {
      name: 'orders',
      human_readable_name: 'orders',
      countable: true, // Number of orders
      primary_key: 'id', // Primary key used by other resource
      rel: [
        {
          res: 'order_metrics',
          name: 'metric_set',
        },
      ],
      details: {
        name: 'Order details',
        default_columns: [
          {
            primary_resource: 'orders',
            key: 'id',
          },
          {
            primary_resource: 'orders',
            key: 'comment',
          },
          {
            primary_resource: 'orders',
            key: 'order_time',
          },
        ],
        aggregated: true,
        supported_visualisations: ['table'],
        rules: {
          time_fields: [null],
          optimisation_order: [
            {
              primary_resource: 'orders',
              key: 'order_time',
            },
          ],
        },
      },
      fields: [
        {
          key: 'id',
          name: 'Order ID',
          type: 'string',
          exclude_from_filters: true,
          exclude_from_groupby: true,
        },
        {
          custom: 'all_orders',
          name: 'All orders',
          base: { resource: 'orders' }, // Extra field extending base resource
          // Set of rules
          rules: {
            time_fields: [null], // Null here mean this metric support no timespan
          },
          supported_visualisations: ['number', 'geckometer'], // Limit available visualisation
        },
        {
          custom: 'created_orders',
          name: 'Created orders',
          base: { resource: 'orders' },
          rules: {
            // Use the orders order_time as the default time selection
            time_fields: [{ primary_resource: 'orders', key: 'order_time' }],
          },
        },
        {
          key: 'revenue_usd',
          name: 'Revenue',
          type: 'currency',
          category: 'finance',
          default_aggregation: 'avg',
          rules: {
            time_fields: [{ primary_resource: 'orders', key: 'shipping_date' }],
          },
          additional_info: {
            content:
              'Space Cargo income from the sale of goods and services to customer.',
          },
        },
        {
          key: 'cost_usd',
          name: 'Cost',
          type: 'currency',
          category: 'finance',
          default_aggregation: 'avg',
          rules: {
            time_fields: [{ primary_resource: 'orders', key: 'order_time' }],
          },
        },
        {
          key: 'gross_margin_usd',
          name: 'Gross margin',
          type: 'currency',
          category: 'finance',
          show_expanded: true,
          rules: {
            time_fields: [{ primary_resource: 'orders', key: 'order_time' }],
          },
        },
        {
          key: 'profit_margin_percentage',
          name: 'Profit margin',
          type: 'percent',
          rules: {
            time_fields: [{ primary_resource: 'orders', key: 'order_time' }],
          },
        },
        {
          key: 'category',
          name: 'Category',
          type: 'enum',
          values: [
            { key: 'biological', value: 'Biological' },
            { key: 'mineral', value: 'Mineral' },
            { key: 'equipment', value: 'Equipment' },
          ],
        },
        {
          key: 'coupon',
          name: 'Coupon',
          type: 'enum',
          supports_null_values: true, // Enum where null values are possible
          values: [
            { key: '10OFF', value: '10% off' },
            { key: '20OFF', value: '20% off' },
            { key: '30OFF', value: '30% off' },
            { key: 'FREE_DELIVERY', value: 'Free delivery' },
          ],
        },
        {
          key: 'size.package',
          type: 'string',
          name: 'Package size',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['size.package'],
          formatValue: (value) => value['size.package'],
          category: 'Size',
        },
        {
          key: 'size.product',
          type: 'string',
          name: 'Product size',
          supports_contains_operators: true,
          allow_free_form: true,
          query_template_for_values: VALUES_QUERY_TEMPLATE,
          formatKey: (value) => value['size.product'],
          formatValue: (value) => value['size.product'],
          category: 'Size',
        },
        {
          key: 'destination',
          name: 'Destination',
          type: 'enum',
          resource: 'destinations',
          foreign_key: 'destination_id',
          target_key: 'name',
        },
        {
          key: 'container',
          name: 'Container',
          type: 'enum',
          resource: 'containers',
          foreign_key: 'container_id',
          target_key: 'name',
          restrict_single_value: true,
          exclude_from_groupby: true,
        },
        {
          key: 'factory',
          name: 'Factory',
          type: 'enum',
          resource: 'factories',
          foreign_key: 'factory_id',
          target_key: 'name',
          is_vip: true,
        },
        {
          key: 'segment_id',
          name: 'Segment',
          type: 'string',
          restrict_single_value: true,
          filter_exclusion_groups: ['group'],
          query_custom_values: {
            select: [
              {
                resource: 'segments',
                path: 'id',
              },
              {
                resource: 'segments',
                path: 'name',
              },
            ],
            filter: [
              {
                resource: 'segments',
                path: 'type',
                operator: '=',
                operands: ['company'],
              },
            ],
          },
        },
        {
          key: 'product',
          name: 'Product',
          type: 'enum',
          resource: 'products',
          foreign_key: 'product_id',
          target_key: 'name',
          filter_exclusion_groups: ['group'],
        },
        {
          key: 'customer',
          name: 'Customer',
          type: 'enum',
          resource: 'customers',
          foreign_key: 'customer_id',
          target_key: 'name',
        },
        {
          key: 'is_cancelled',
          name: 'Cancelled Order',
          type: 'boolean',
        },
        {
          key: 'order_time',
          name: 'Order time',
          type: 'datetime',
          equivalents: {
            audit: 'created_at',
          },
        },
        {
          key: 'shipping_date',
          name: 'Shipping date',
          type: 'datetime',
          equivalents: {
            audit: 'updated_at',
          },
          rules: {
            optimisation_order: [
              {
                primary_resource: 'orders',
                key: 'order_time',
              },
            ],
          },
        },
        {
          key: 'comment',
          name: 'Comment',
          type: 'string',
          exclude_from_filters: true,
          exclude_from_groupby: true,
        },
      ],
      custom_fields_queries: [
        {
          query: {
            select: [
              {
                path: 'id',
                resource: 'order_fields',
              },
              {
                path: 'name',
                resource: 'order_fields',
              },
            ],
          },
          field_templates: [
            {
              key: '<%= id %>',
              name: '<%= name %>',
              formatKey: ({ value }) => value,
              query_template_for_values:
                '{"select": [ { "path": "name", "resource": "order_field_options" }, { "path": "value", "resource": "order_field_options" } ]} ',
            },
          ],
        },
      ],
    },
    {
      name: 'order_metrics',
      human_readable_name: 'order metrics',
      rel: [
        {
          res: 'orders',
          name: 'order',
        },
      ],
      fields: [
        {
          key: 'order_metric_a',
          name: 'Order metric A',
          type: 'duration',
          unit: 'minutes',
          reverse_comparison: true,
          rules: {
            time_fields: [{ primary_resource: 'orders', key: 'order_time' }],
          },
        },
        {
          key: 'order_metric_b',
          name: 'Order metric B',
          type: 'int',
          reverse_comparison: true,
          rules: {
            time_fields: [{ primary_resource: 'orders', key: 'order_time' }],
          },
        },
      ],
    },
    {
      name: 'customers',
      human_readable_name: 'customers',
      countable: true,
      primary_key: 'id',
      fields: [
        {
          // This custom one removes the default No of customers
          // we need it because customers doesn't have a timespan
          // so are limited to number only viz
          custom: 'all_customers',
          name: 'All customers',
          base: { resource: 'customers' },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'name',
          name: 'Customer name',
          type: 'string',
          hidden: true, // Hides this field from UI
        },
      ],
    },
    {
      name: 'destinations',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Name', type: 'string' }],
    },
    {
      name: 'containers',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Name', type: 'string' }],
    },
    {
      name: 'factories',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Name', type: 'string' }],
    },
    {
      name: 'segments',
      private: true,
      primary_key: 'id',
      fields: [{ key: 'name', name: 'Segment', type: 'string' }],
    },
    {
      name: 'products',
      human_readable_name: 'products',
      countable: true,
      primary_key: 'id',
      fields: [
        {
          custom: 'all_products',
          name: 'All products',
          base: { resource: 'products' },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'name',
          name: 'Product name',
          type: 'string',
          hidden: true,
        },
        {
          key: 'tags',
          name: 'Tags',
          type: 'string',
          // The values are from tags but doesn't need to join to that model
          // this allows filters to fetch values from a specific endpoint
          values_from: { resource: 'tags', key: 'value' },
        },
      ],
    },
    {
      name: 'tags',
      fields: [
        {
          key: 'value',
          name: 'Value',
          type: 'string',
        },
      ],
    },
    {
      name: 'audit',
      primary_key: 'id',
      fields: [
        {
          key: 'created_at',
          name: 'Order time',
          type: 'string',
          equivalents: {
            // Audit created_at is equivalent to the orders order_time timestamp
            orders: 'order_time',
          },
        },
        {
          key: 'updated_at',
          name: 'Shipping date',
          type: 'string',
          enable_for_performance: true,
          equivalents: {
            // Audit updated_at is equivalent to the orders shipping_date timestamp
            orders: 'shipping_date',
          },
        },
        {
          key: 'created_to_packaged',
          name: 'Created to Packaged',
          type: 'duration',
          unit: 'minutes',
          rules: {
            time_fields: [{ primary_resource: 'audit', key: 'created_at' }],
          },
        },
        {
          key: 'created_to_shipped',
          name: 'Created to Shipped',
          type: 'duration',
          unit: 'minutes',
          rules: {
            time_fields: [
              // Use the audit updated_at field for the default time selection
              { primary_resource: 'audit', key: 'updated_at' },
            ],
          },
          reverse_comparison: true, // Longer created_to_shipped times are better in this case
        },
        {
          key: 'performance_change',
          name: 'Performance',
          type: 'percent',
          aggregated: true,
          rules: {
            time_fields: [{ primary_resource: 'audit', key: 'updated_at' }],
          },
          filter_associated_fields: (node) => node.enable_for_performance,
        },
      ],
    },
    {
      name: 'user_settings',
      private: true,
      fields: [],
    },
    {
      name: 'order_fields',
      private: true,
      fields: [],
    },
    {
      name: 'order_field_options',
      private: true,
      fields: [],
    },
  ],
  initialQueries: {
    number: {
      query: {
        select: [
          {
            resource: 'orders',
            aggregate: 'count',
            custom: 'all_orders',
          },
        ],
      },
    },
  },
  options: [
    {
      keys: ['currency'],
      query: {
        select: [
          {
            resource: 'user_settings',
            path: 'default_currency',
          },
        ],
      },
    },
  ],
};
