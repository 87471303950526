import { chain, has, isArray, isUndefined, omitBy, pick, set } from 'lodash';

import { applyAndMerge } from '../../../lib/config-mapper';

const getDefaultQuery = (fieldGroups) => {
  if (!has(fieldGroups, 'numeric[0]')) {
    return {
      aggregate: 'count',
      slice: 1,
    };
  }

  const query = {
    field: fieldGroups.numeric[0].key,
    slice: 20,
  };

  if (has(fieldGroups, 'datetime[0]')) {
    query.order_by = fieldGroups.datetime[0].key;
  }

  return query;
};

const ensureConfig = (currentConfig) => {
  const config = pick(
    currentConfig,
    'title',
    'type',
    'dataSetId',
    'comparison',
    'goal',
    'label',
    'comparisonLabel',
    'indicators',
    '_saved',
    'juno',
  );

  const { comparison = {} } = config;

  if (comparison.type === 'goal') {
    // Overwrite any options that have been set on goal comparison
    const newComparison = omitBy(
      {
        type: 'goal',
        startingValue: comparison.startingValue,
      },
      isUndefined,
    );
    config.comparison = newComparison;
  }

  // Ensure the comparison valueOption is set to previousValue
  // Currently this is the default and only value for datasets and integrationDatasets
  // When viewed on the dashboard we recieve the valueOption through the data payload via the megatron mapping
  // https://github.com/geckoboard/megatron/blob/491dba8124ce60d0802bd36b530fa8f4a9df4ef9/src/roadie-service/mappings/config.js#L70-L84
  //
  // However for the config we need to correctly set the default comparison valueOption
  // as its not stored in the config which is always previousValue otherwise editting
  // a number with comparison will incorrectly use the first value in the data array
  if (['percentage', 'number'].includes(comparison.type)) {
    comparison.valueOption = 'previousValue';
  }

  config.numberFormat = !isArray(currentConfig.numberFormat)
    ? currentConfig.numberFormat
    : {};

  return config;
};

const updateQuery = (currentQuery, path, value) => {
  if ('filters' === path) {
    return set(currentQuery, path, value);
  }

  if ('aggregate:count' === value) {
    const stored = ['order_by', 'aggregate'];
    return chain(currentQuery)
      .mapKeys((_value, key) => {
        return stored.includes(key) ? `_${key}` : key;
      })
      .omit('field', ...stored)
      .tap((query) => {
        query.aggregate = 'count';
        query.slice = 1;
      })
      .value();
  }

  return chain(currentQuery)
    .clone()
    .set(path, value)
    .tap((query) => {
      // omitBy would be great lodash@4
      // Always remove the aggregate count or latest
      if (['count', 'latest'].includes(query.aggregate)) {
        delete query.aggregate;
      }
    })
    .mapKeys((_value, key) => {
      return '_' === key[0] ? key.slice(1) : key;
    })
    .tap((query) => {
      // Insertion order means no explicit ordering
      if (query.order_by === 'order_by:insertion') {
        delete query.order_by;
      }

      // An aggregate cannot have an order
      if (query.aggregate && query.order_by) {
        query._order_by = query.order_by;
        delete query.order_by;
      }

      query.slice = query.aggregate ? 1 : 20;
    })
    .value();
};

const MAP_METRICS = [
  ['metrics[0].field', null, 'field'],
  // Map latest aggregate to undefined because on number widgets we don't send an aggregate to use "latest"
  [
    'metrics[0].aggregate',
    (aggregate) => (aggregate !== 'latest' ? aggregate : undefined),
    'aggregate',
  ],
  ['filters'],
];

const LEADERBOARD_MAPPINGS = [
  ['metric', null, 'field'],
  ['aggregate', null, 'aggregate'],
  ['filters'],
];

const QUERY_MAPPING_RULES = {
  line: MAP_METRICS,
  column: MAP_METRICS,
  bar: MAP_METRICS,
  geckometer: ['field', 'aggregate', 'filters'],
  leaderboard: LEADERBOARD_MAPPINGS,
  table: [['filters']],
};

const mapQuery = (fromType, ...rest) => {
  const query = applyAndMerge(QUERY_MAPPING_RULES[fromType], ...rest);

  // `aggregate: count` is incompatible with `field`
  if ('count' === query.aggregate) {
    delete query.field;
  }
  if (query.aggregate) {
    query.slice = 1;
    delete query.order_by;
  }

  return query;
};

const visualisationHelpers = {
  ensureConfig,
  getDefaultQuery,
  updateQuery,
  mapQuery,
};

export default visualisationHelpers;
