import {
  chain,
  find,
  isEmpty,
  isUndefined,
  map,
  reduce,
  some,
  zipObject,
} from 'lodash';

import { VIEWS as INSTRUMENT_MENU_VIEWS } from '../../instrument-menu/instrument-menu-constants';
import { intercom } from '../../lib/global';
import { formatMessage } from '../../lib/i18n';
import createAction from '../../lib/redux/create-action';
import { getDashboardTimezone } from '../../services/concierge-service/dashboard';
import {
  getAllServiceAccounts,
  getServiceByName,
} from '../../services/management-service';
import { extendableBuiltinGen } from '../../universal';
import universalService from '../../universal/services/universal-service';
import { WIDGET_MENU_VIEWS } from '../../widget-menu/constants';
import {
  addVirtualFields,
  buildGraph,
  getNodeFromSubquery,
  injectCustomFieldsInGraph,
} from '../graph';
import { getServiceAccountId } from '../persist-config-helpers';
import { getSubQueryResult, runQueryInContext } from '../query-helpers';
import {
  getPrerequisitesFromQueries,
  resetGlobalFilters,
} from '../reducer/ui-query-reducer/other-ui-query-reducer-methods';
import { getCombinedFiltersValues } from '../universal-config-helpers';

import {
  fetchAndUpdateSubquery,
  universalSetError,
} from './universal-config-actions';
import {
  setPrerequisites,
  setVIPFilterValue,
  toggleDataSourcePanel,
} from './universal-query-actions';

export const resetInitial = createAction('UniversalBootstrap:RESET_INITIAL');
export const resetGraph = createAction('UniversalBootstrap:RESET_GRAPH');
export const setAPISettings = createAction(
  'UniversalBootstrap:SET_API_SETTINGS',
);
export const setConfigReady = createAction(
  'UniversalBootstrap:SET_CONFIG_READY',
);
export const setTimezone = createAction('UniversalBootstrap:SET_TIMEZONE');
export const setGraph = createAction('UniversalBootstrap:SET_GRAPH');
export const setRawQueries = createAction('UniversalBootstrap:SET_RAW_QUERIES');
export const setInitialQueries = createAction(
  'UniversalBootstrap:SET_INITIAL_QUERIES',
);
export const setGlobalFiltersFromQueries = createAction(
  'UniversalBootstrap:SET_GLOBAL_FILTERS_FROM_QUERIES',
);
export const setPrerequisitesFromQueries = createAction(
  'UniversalBootstrap:SET_PREREQUISITES_FROM_QUERIES',
);
export const setQueryOptions = createAction(
  'UniversalBootstrap:SET_QUERY_OPTIONS',
);
export const setService = createAction('UniversalBootstrap:SET_SERVICE');
export const setServiceAccountId = createAction(
  'UniversalBootstrap:SET_SERVICE_ACCOUNT_ID',
);
export const setServiceAccounts = createAction(
  'UniversalBootstrap:SET_SERVICE_ACCOUNTS',
);
export const setGlobalFilter = createAction(
  'UniversalBootstrap:SET_GLOBAL_FILTER',
);
export const setUiOptions = createAction('UniversalBootstrap:SET_UI_OPTIONS');
export const setNoServiceAccounts = createAction(
  'UniversalBootstrap:SET_NO_SERVICE_ACCOUNTS',
);
export const setPrerequisiteOptions = createAction(
  'UniversalBootstrap:SET_PREREQUISITE_OPTIONS',
);

// Both actions will eventually open the panel but this action creator is here
// to distinguish between compact and normal universal config
export const setNoServiceAccountsAndOpenPanel = () => (dispatch, getState) => {
  const {
    widgetMenu: { view } = {},
    instrumentMenu: { view: instrumentMenuView } = {},
  } = getState();

  if (
    view === WIDGET_MENU_VIEWS.EDIT ||
    instrumentMenuView === INSTRUMENT_MENU_VIEWS.EDIT
  ) {
    dispatch(setNoServiceAccounts());
  } else {
    dispatch(
      toggleDataSourcePanel({
        showDataSourcePanel: true,
        addingNewConnection: true,
      }),
    );
  }
};

export class NoServiceAccountError extends extendableBuiltinGen(Error) {
  constructor(message) {
    super(message); // extendablebuiltingen is buggy and does not set message
    this.message = message;
  }
}
export class SilentlyChangedServiceAccountError extends extendableBuiltinGen(
  Error,
) {
  constructor(message) {
    super(message); // extendablebuiltingen is buggy and does not set message
    this.message = message;
  }
}
export class NoValuesForGlobalFilterError extends extendableBuiltinGen(Error) {
  constructor(message) {
    super(message); // extendablebuiltingen is buggy and does not set message
    this.message = message;
  }
}

const _getQueryOptions = (options, universalState) => {
  return Promise.all(
    options.map(({ query }) => {
      return runQueryInContext(query, universalState);
    }),
  ).then((qr) => {
    return reduce(
      qr,
      (queryOpts, r, i) => {
        const { keys } = options[i];
        const [values] = r;
        return {
          ...queryOpts,
          ...zipObject(keys, values),
        };
      },
      {},
    );
  });
};

const _withoutCombined = (filters) =>
  filters.filter(({ extra = {} }) => !extra.is_combined);

const _loadGlobalFilters = async (dispatch, getState) => {
  const { uiQuery: { globalFilters = [] } = {} } = getState().universal;
  const globalsToFetch = _withoutCombined(globalFilters);

  await Promise.all(
    globalsToFetch.map((gf) => dispatch(fetchAndUpdateSubquery(gf.node))),
  );
};

const _setGlobalFilters = (dispatch, getState) => {
  const { subQueryResults, uiQuery: { globalFilters = [] } = {} } =
    getState().universal;
  const globalsToFetch = _withoutCombined(globalFilters);

  globalsToFetch.forEach((f, index) => {
    const [firstOption] = f.node.getValues(subQueryResults);

    // If a filter is defined as global, but has no values, we throw a specific
    // error to handle this
    if (isUndefined(firstOption)) {
      const error = new NoValuesForGlobalFilterError(
        formatMessage('universal.config.compact.noValuesForGlobalFiltersError'),
      );
      dispatch(universalSetError(error));
      throw error;
    }

    const value = {
      operands: [firstOption.key],
      operator: 'in',
      is_global: true,
    };
    dispatch(setGlobalFilter({ value, index }));

    if (firstOption.combinedFilters) {
      getCombinedFiltersValues(
        firstOption.combinedFilters,
        globalFilters,
      ).forEach((payload) => {
        dispatch(setGlobalFilter(payload));
      });
    }
  });
};

export const loadVIPFilters = (setDefaults) => async (dispatch, getState) => {
  const { uiQuery: { vipFilters = [] } = {} } = getState().universal;
  await Promise.all(
    vipFilters.map((vf) => dispatch(fetchAndUpdateSubquery(vf.node))),
  );

  if (setDefaults) {
    const { subQueryResults } = getState().universal;
    vipFilters.forEach((f, index) => {
      const { extra } = f;
      // Set VIP filter default value when not set
      if (!extra || isEmpty(extra)) {
        const [firstOption] = f.node.getValues(subQueryResults);
        const value = { operands: [firstOption.key], operator: 'in' };
        dispatch(setVIPFilterValue(value, index, false));
      }
    });
  }
};

export const bootstrapUniversalService =
  (dashboardId, integrationSlug) => async (dispatch, getState) => {
    const {
      dashboard: { dashboard: currentDashboard } = {},
      dataSources: { services = [] } = {},
    } = getState();

    if (!currentDashboard) {
      const timezone = await getDashboardTimezone(dashboardId);
      dispatch(setTimezone(timezone));
    } else {
      dispatch(setTimezone(currentDashboard.timezone));
    }

    let service;
    if (services.length) {
      service = find(services, { name: integrationSlug });
    }
    if (!service) {
      service = await getServiceByName(integrationSlug);
    }
    dispatch(setService(service));
  };

export const bootstrapServiceAccounts =
  (currentAccountId, editWidget) => async (dispatch, getState) => {
    const {
      universal: {
        source: { integration },
      },
      serviceAccounts: allServiceAccounts = {},
    } = getState();

    let serviceAccounts;

    if (allServiceAccounts[integration]) {
      // If on the connection panel, the service accounts
      // have already been fetched
      serviceAccounts = allServiceAccounts[integration];
    } else {
      // This would only be called when opening the compact
      // config on a dashboard as the accounts aren't there.
      serviceAccounts = await getAllServiceAccounts(integration);
    }

    if (!serviceAccounts.length) {
      throw new NoServiceAccountError('No service accounts');
    }
    dispatch(setServiceAccounts(serviceAccounts));

    // Select current service account based on config, local storage or
    // default to first one available.
    let id = currentAccountId;
    if (!id) {
      // TODO if falls back on first one use switch account mechanism
      id = await getServiceAccountId(integration, serviceAccounts);
      // Reconnecting an existing widget
      if (editWidget) {
        dispatch(setServiceAccountId(id)); // Dispatch to set id first
        throw new SilentlyChangedServiceAccountError(
          'New service account selected',
        );
      }
    }
    dispatch(setServiceAccountId(id));
  };

export const loadPrerequisites =
  (graph, prerequisitesMetadata) => async (dispatch, getState) => {
    const { universal } = getState();
    const { serviceAccounts, source } = universal;

    const saFilters = chain(prerequisitesMetadata.service_account_filters)
      .filter((n) => !n.exclude_from_query)
      .map((f) => {
        return getNodeFromSubquery(graph, f, true);
      })
      .value();

    const allValues = await Promise.all(
      serviceAccounts.map(async (sa) => {
        const allSaFiltersValues = await Promise.all(
          saFilters.map(async (node) => {
            const resourceName = node.getResourceName();
            let saFilterValues;

            try {
              saFilterValues = await getSubQueryResult(
                node,
                {
                  ...universal,
                  source: { ...source, service_account_id: `${sa.id}` },
                },
                true,
              );
            } catch (err) {
              // If there are no values here, we swallow the error to avoid breaking
              // the entire compact config on account of one single set of filter values
              saFilterValues = [];
            }
            return { [resourceName]: saFilterValues };
          }),
        );

        // Flatten into a more readable structure
        const saFiltersKeyValues = reduce(
          allSaFiltersValues,
          (acc, v) => {
            return {
              ...acc,
              ...v,
            };
          },
          {},
        );

        return { id: sa.id, name: sa.name, values: saFiltersKeyValues };
      }),
    );

    const tree = prerequisitesMetadata.build_prerequisite_options(allValues);

    dispatch(setPrerequisiteOptions(tree));
  };

const firstValueAt = (selections, level) => {
  if (!selections) return undefined;

  if (level === 0) {
    return selections[0].id;
  }

  if (!selections[0].children || isEmpty(selections[0].children)) {
    return null;
  }

  return firstValueAt(selections[0].children, level - 1);
};

export const setDefaultPrerequisiteValues =
  (prerequisiteNodes) => (dispatch, getState) => {
    const {
      universal: {
        uiOptions: { prerequisites: prerequisiteOptions },
        source,
      },
    } = getState();

    const serviceAccountPrerequisites = find(
      prerequisiteOptions,
      (option) => option.id.toString() === source.service_account_id.toString(),
    );

    // We need to make sure that the original query used to create the widget gets
    // populated with the default prerequisite values

    // Note the `index + 1` below. We're starting at the second level down in the
    // tree because the first layer is the service account, and subsequent layers are
    // the prerequisites
    const newPrerequisiteNodes = map(prerequisiteNodes, (node, index) => {
      let operands = firstValueAt([serviceAccountPrerequisites], index + 1);
      operands = operands ? [operands] : undefined;

      return {
        ...node,
        extra: {
          ...node.extra,
          operands,
        },
      };
    });

    dispatch(setPrerequisites(newPrerequisiteNodes));
  };

const initialise = () => async (dispatch, getState) => {
  // Fetch & set relevant content from metadata: Api settings, graph, uiOptions
  const {
    universal: {
      source: { integration },
      queriesEnabled,
    },
  } = getState();
  const metadata = await universalService.fetchMetadata(integration);
  dispatch(setAPISettings(metadata.api));
  const graph = buildGraph(metadata);
  dispatch(setGraph(graph));

  return { metadata, graph, queriesEnabled };
};

const setUpPrerequisites = (graph, metadata, queries) => async (dispatch) => {
  await dispatch(loadPrerequisites(graph, metadata.prerequisites));

  // Pull the values for prerequisites from the query in the widget config
  const newPrerequisites = getPrerequisitesFromQueries(
    graph,
    queries,
    metadata.prerequisites.service_account_filters,
  );

  if (
    some(newPrerequisites, (prerequisite) =>
      isEmpty(prerequisite.extra.operands),
    )
  ) {
    // We may not have any values (if it's a new widget, for example), so we
    // also want to fill in some defaults if we don't find what we want
    dispatch(setDefaultPrerequisiteValues(newPrerequisites));
  } else {
    dispatch(setPrerequisites(newPrerequisites));
  }
};

const setUpAdditionalFields =
  (graph, metadata, queriesEnabled) => async (dispatch, getState) => {
    // We are now all with required filter values to fetch additional metadata,
    // queryOptions etc..
    if (metadata.options) {
      const queryOptions = await _getQueryOptions(
        metadata.options,
        getState().universal,
      );
      dispatch(setQueryOptions(queryOptions));
    }

    // Mutate the graph with new potential new nodes
    if (queriesEnabled) {
      await injectCustomFieldsInGraph(graph, getState().universal);
      addVirtualFields(graph);
    }

    // Update uiOptions metrics with custom metadata
    dispatch(setUiOptions(metadata.metricCategories));
  };

const fetchSubqueryData = (defaults) => (dispatch, getState) => {
  const {
    uiQuery: {
      globalFilters: _globalFilters = [],
      filters = [],
      groupBy = [],
      splitBy = [],
    } = {},
  } = getState().universal;

  // If we set defaults for globalfilters don't fetch them again
  let globalFilters = [];
  if (!defaults.includes('globalFilters')) {
    globalFilters = _globalFilters;
  }

  const arraysToFetch = [...globalFilters, ...filters, ...groupBy, ...splitBy];
  for (const entry of arraysToFetch) {
    const { node } = entry;
    dispatch(fetchAndUpdateSubquery(node));
  }
};

export const bootstrapGraph =
  (
    defaults = [], // set default filter value
    loadFilters = [], // load (fetch) filter values
    _resetGraph = false,
  ) =>
  async (dispatch, getState) => {
    let { queries } = getState().universal;

    if (_resetGraph) {
      dispatch(resetGraph());
      queries = resetGlobalFilters(queries, defaults);
    }
    intercom('update');

    const { metadata, graph, queriesEnabled } = await dispatch(initialise());

    dispatch(setUiOptions(metadata.metricCategories));

    // [2] - set global filters
    dispatch(setGlobalFiltersFromQueries({ queries, graph }));

    // [PREREQUISITES]
    if (metadata.prerequisites) {
      await dispatch(setUpPrerequisites(graph, metadata, queries));
    }

    // [3] - Get / set values for global universal filters
    try {
      if (loadFilters.includes('globalFilters')) {
        await _loadGlobalFilters(dispatch, getState);
      }

      if (defaults.includes('globalFilters')) {
        _setGlobalFilters(dispatch, getState);
      }
    } catch (err) {
      if (err instanceof NoValuesForGlobalFilterError) return;

      throw err;
    }

    // [4] - Mutate the graph with new potential new nodes
    await dispatch(setUpAdditionalFields(graph, metadata, queriesEnabled));

    // Graph might have changed, re-compute missing options
    dispatch(setInitialQueries(queries));

    await dispatch(loadVIPFilters(defaults.includes('vipFilters')));

    // Config is ready we can now reveal the config
    dispatch(setConfigReady());

    // Fetch (async) subquery data for filters
    dispatch(fetchSubqueryData(defaults));
  };
