import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { once } from 'lodash';

import config from '../../universal/config';

// Our app contains multiple React trees, each of them having their
// own FeatureFlagProvider to provide the ff context. We only want
// one instance of the launchdarkly client so that we don't make
// unnecessary network calls. The client instance can then be shared
// between all of the FeatureFlagProviders.
//
// FeatureFlagClientCreator is used to initialise the launchdarkly
// client. `create` is called from FeatureFlagSetup which
// is rendered by the routes-component. When the LD client has been
// created, we call the callbacks from each FeatureFlagProvider so
// that they can update the context with the LD client.

export class FeatureFlagClientCreator {
  ldClient?: LDClient;
  didStartCreatingClient: boolean;
  ffClientReadyCallbacks: { (ldClient: LDClient): void }[];

  constructor(ldClient?: LDClient) {
    this.ldClient = ldClient;
    this.didStartCreatingClient = false;
    this.ffClientReadyCallbacks = [];
  }

  async create(orgId: string) {
    if (this.didStartCreatingClient) {
      console.warn(
        'InitialFeatureFlagClient.init() has already been called. This function is only expected to be called once',
      );
      return;
    }

    if (this.ldClient !== undefined) {
      return;
    }

    const conf = config.get();
    const ld = conf.launchdarkly as Record<string, string>;
    const clientSideID: string = ld.clientside_id;

    // TODO: Check what happens in initialize fails, ie sending an incorrect clientSideID
    const ldClient = initialize(clientSideID, { key: orgId, kind: 'user' });

    this.didStartCreatingClient = true;

    try {
      await ldClient.waitForInitialization();
      this.ldClient = ldClient;

      // Pass ldClient to each FeatureFlagProvider so that they
      // can add it to the context.
      for (const callback of this.ffClientReadyCallbacks) {
        callback(ldClient);
      }
    } catch (e) {
      // TODO: Send error to bugsnag
    }
  }

  onFeatureFlagClientReady(callback: { (ldClient: LDClient): void }) {
    if (this.ldClient) {
      callback(this.ldClient);
      return;
    }
    this.ffClientReadyCallbacks.push(callback);
  }
}

export const getCreator = once(() => {
  return new FeatureFlagClientCreator();
});

export default FeatureFlagClientCreator;
