import { unzip } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericBarTransform } from '../per-visualisation/bar';

import { pickFormat } from './helpers';

const pickXAxisType = (type) => {
  switch (type) {
    case 'datetime':
      return type;
    default:
      return 'standard';
  }
};

const generateYAxis = (metricUnit, format) => {
  const yAxis = {
    format,
  };

  if (metricUnit) {
    yAxis.unit = metricUnit;
  }

  return yAxis;
};

const barTransform = (payload, config = {}, timezone, initialContext = {}) => {
  if (!payload) {
    return {};
  }

  const { series, metric_type: metricType, metric_unit: metricUnit } = payload;
  // Iris will return `null` for the `data` in each series if the dataset is empty
  // In that case we cannot render
  if (!series.length || !series[0].data) {
    return {};
  }

  const [labels] = unzip(payload.series[0].data);
  const orientation = config.type === 'bar' ? 'horizontal' : 'vertical'; // To be removed once migration is complete
  const xAxisType = pickXAxisType(payload.x_axis_type);
  const format = pickFormat(metricType);
  const yAxis = generateYAxis(metricUnit, format);
  const { legends = [] } = config;

  const isMultiSeriesDatetime = series.length > 1 && xAxisType === 'datetime';

  const context = {
    series: series.map(({ name, data }, index) => {
      if (isMultiSeriesDatetime) {
        // When using multi series & grouping by a datetime, we have to send
        // the labels as part of the series or the chart will only render the
        // labels from the first series. There is still a problem with string x-axis:
        // https://app.clubhouse.io/geckoboard/story/58203/column-chart-split-by-unexpected-results-when-using-a-string-x-axis
        return { name: legends[index] || name, data };
      }
      const [, dataArray] = unzip(data);
      return { name: legends[index] || name, data: dataArray };
    }),
    x_axis: {
      labels: isMultiSeriesDatetime ? undefined : labels,
      type: xAxisType,
    },
    y_axis: yAxis,
    orientation,
    format,
  };

  return { ...initialContext, ...context };
};

const transform = composeTransformers(barTransform, genericBarTransform);

export { transform };
