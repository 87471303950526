import { TypedUseSelectorHook, useSelector } from 'react-redux';

import {
  BarConfig,
  ColumnConfig,
  GeckometerConfig,
  graphQLToReduxConfig,
  LeaderboardConfig,
  LineConfig,
  NumberConfig,
  reduxConfigToGraphQL,
  TableConfig,
} from '@Lib/graphql-legacy-config-mappers';

import type {
  DatabaseDataSourceConfig,
  InquisitorDataSourceConfig,
  InstrumentForEditingQuery,
  ProgressIndicator,
  VisualizationInput,
} from '../generated/graphql';
import type {
  OpenUniversalActionPayload,
  UniversalNumberConfig,
  WidgetConfig,
} from '../universal-config/universal-types';
import { toWrapOptionsInput } from '../visualisation/getWrap';

type VisualizationConfig =
  | NumberConfig
  | GeckometerConfig
  | LineConfig
  | ColumnConfig
  | BarConfig
  | LeaderboardConfig
  | TableConfig;
type CompactConfigConfig = VisualizationConfig & {
  databaseId: string;
  query: string;
};

export type ReduxState = {
  editWidget: {
    config: CompactConfigConfig;
    instrumentId: string;
    refresh_interval: number;
    serviceName: 'database';
    transformedData: {
      data: {
        progressIndicator?: {
          targetValue: number;
          startingValue?: number;
        };
      };
    };
    widgetType: {
      integration: {
        name: 'Databases';
        slug: 'database';
      };
    };
  };
};

export type OpenEditWidgetActionPayload = {
  widget: {
    refresh_interval: number;
    serviceName: 'database';
    widgetType: ReduxState['editWidget']['widgetType'];
    config: ReduxState['editWidget']['config'];
  };
  instrumentId: string;
};

export const typedUseSelector: TypedUseSelectorHook<ReduxState> = useSelector;

export const mapDatabaseInstrumentToRedux = (
  instrument: InstrumentForEditingQuery['instrument'],
): OpenEditWidgetActionPayload => {
  if (!instrument) {
    throw new Error(
      'Instrument not defined when mapping to a database widget config',
    );
  }
  const dataSourceConfig = instrument.dataSource!
    .config as DatabaseDataSourceConfig;

  switch (instrument.__typename) {
    case 'Number':
      return {
        widget: {
          refresh_interval: dataSourceConfig.refreshIntervalSeconds,
          serviceName: 'database',
          widgetType: {
            integration: {
              name: 'Databases',
              slug: 'database',
            },
          },
          config: {
            type: 'number',
            title: instrument.title || '',
            label: instrument.label || '',
            databaseId: dataSourceConfig.databaseId,
            query: dataSourceConfig.query,
            numberFormat: graphQLToReduxConfig.mapNumberFormat(
              instrument.numberFormat,
            ),
            indicators: graphQLToReduxConfig.mapStatusIndicators(
              instrument.statusIndicator,
            ),
            ...graphQLToReduxConfig.mapProgressIndicator(
              instrument.progressIndicator,
            ),
          },
        },
        instrumentId: instrument.id!,
      };
    case 'Geckometer':
      return {
        widget: {
          refresh_interval: dataSourceConfig.refreshIntervalSeconds,
          serviceName: 'database',
          widgetType: {
            integration: {
              name: 'Databases',
              slug: 'database',
            },
          },
          config: {
            type: 'geckometer',
            title: instrument.title || '',
            databaseId: dataSourceConfig.databaseId,
            query: dataSourceConfig.query,
            numberFormat: graphQLToReduxConfig.mapNumberFormat(
              instrument.numberFormat,
            ),
            indicators: graphQLToReduxConfig.mapStatusIndicators(
              instrument.statusIndicator,
            ),
          },
        },
        instrumentId: instrument.id!,
      };
    case 'Line':
      return {
        widget: {
          refresh_interval: dataSourceConfig.refreshIntervalSeconds,
          serviceName: 'database',
          widgetType: {
            integration: {
              name: 'Databases',
              slug: 'database',
            },
          },
          config: {
            type: 'line',
            title: instrument.title || '',
            databaseId: dataSourceConfig.databaseId,
            query: dataSourceConfig.query,
            numberFormat: graphQLToReduxConfig.mapNumberFormat(
              instrument.numberFormat,
            ),
            ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
              instrument.statusIndicator,
            ),
          },
        },
        instrumentId: instrument.id!,
      };
    case 'Column':
    case 'Bar':
      return {
        widget: {
          refresh_interval: dataSourceConfig.refreshIntervalSeconds,
          serviceName: 'database',
          widgetType: {
            integration: {
              name: 'Databases',
              slug: 'database',
            },
          },
          config: {
            type: instrument.__typename === 'Bar' ? 'bar' : 'column',
            title: instrument.title || '',
            databaseId: dataSourceConfig.databaseId,
            query: dataSourceConfig.query,
            numberFormat: graphQLToReduxConfig.mapNumberFormat(
              instrument.numberFormat,
            ),
            ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
              instrument.statusIndicator,
            ),
          },
        },
        instrumentId: instrument.id!,
      };
    case 'Leaderboard':
      return {
        widget: {
          refresh_interval: dataSourceConfig.refreshIntervalSeconds,
          serviceName: 'database',
          widgetType: {
            integration: {
              name: 'Databases',
              slug: 'database',
            },
          },
          config: {
            type: 'leaderboard',
            title: instrument.title || '',
            databaseId: dataSourceConfig.databaseId,
            query: dataSourceConfig.query,
            numberFormat: graphQLToReduxConfig.mapNumberFormat(
              instrument.numberFormat,
            ),
          },
        },
        instrumentId: instrument.id!,
      };
    case 'Table':
      return {
        widget: {
          refresh_interval: dataSourceConfig.refreshIntervalSeconds,
          serviceName: 'database',
          widgetType: {
            integration: {
              name: 'Databases',
              slug: 'database',
            },
          },
          config: {
            type: 'table',
            title: instrument.title || '',
            databaseId: dataSourceConfig.databaseId,
            query: dataSourceConfig.query,
            columnWidths: instrument.columnWidths || [],
            numberFormat: instrument.numberFormats?.map(
              (nf) => graphQLToReduxConfig.mapNumberFormat(nf) || null,
            ),
            wrap: instrument.wrap ?? undefined,
          },
        },
        instrumentId: instrument.id!,
      };
    default:
      throw new Error(
        'Visualisation type is not a valid database visualisation',
      );
  }
};

const progressIndicatorToNumberGoal = (
  progressIndicator?: ProgressIndicator | null,
): { goal?: string; comparison?: UniversalNumberConfig['comparison'] } => {
  if (!progressIndicator) {
    return {};
  }

  const goal = graphQLToReduxConfig
    .mapConfigValue(progressIndicator.targetValue)!
    .toString();
  const startingValue = graphQLToReduxConfig.mapConfigValue(
    progressIndicator.startingValue,
  );

  return {
    goal,
    comparison: { type: 'goal', thresholdType: 'percentage', startingValue },
  };
};

type CommonConfig = Pick<
  WidgetConfig,
  'integrationName' | 'queries' | 'queryMetas' | 'detailsMetricResource'
>;

export const mapInquisitorInstrumentToRedux = (
  instrument: InstrumentForEditingQuery['instrument'],
): OpenUniversalActionPayload => {
  if (!instrument) {
    throw new Error(
      'Instrument not defined when mapping to a universal widget config',
    );
  }
  if (!instrument.dataSource?.integration) {
    throw new Error(
      'Datasource integration not defined when mapping to a universal widget config',
    );
  }

  const dataSourceConfig = instrument.dataSource
    .config as InquisitorDataSourceConfig;

  const sharedConfig: CommonConfig = {
    integrationName: instrument.dataSource.integration.slug,
    queries: JSON.parse(dataSourceConfig.queries),
    queryMetas: JSON.parse(dataSourceConfig.queryMetas),
    detailsMetricResource: dataSourceConfig.detailsMetricResource || undefined,
  };
  const serviceAccountId: number = parseInt(
    dataSourceConfig.legacyServiceAccountId!,
    10,
  );

  switch (instrument.__typename) {
    case 'Number': {
      const comparisonLabel =
        instrument.comparison?.__typename === 'DynamicComparison' &&
        instrument.comparison.label;
      return {
        service_account: {
          id: serviceAccountId,
        },
        config: {
          ...sharedConfig,
          type: 'number',
          title: instrument.title || '',
          label: instrument.label || '',
          comparisonLabel: comparisonLabel || undefined,
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          indicators: graphQLToReduxConfig.mapStatusIndicators(
            instrument.statusIndicator,
          ),
          ...progressIndicatorToNumberGoal(instrument.progressIndicator),
          // timespanComparison can't be null as we use isUndefined()
          // in mupltiple places to check if it's set.
          timespanComparison: !!dataSourceConfig.timespanComparison
            ? JSON.parse(dataSourceConfig.timespanComparison)
            : undefined,
        },
      };
    }
    case 'Geckometer':
      return {
        service_account: {
          id: serviceAccountId,
        },
        config: {
          ...sharedConfig,
          type: 'geckometer',
          title: instrument.title || '',
          min: graphQLToReduxConfig.mapConfigValue(instrument.min)?.toString(),
          max: graphQLToReduxConfig.mapConfigValue(instrument.max)?.toString(),
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          indicators: graphQLToReduxConfig.mapStatusIndicators(
            instrument.statusIndicator,
          ),
        },
      };
    case 'Line':
      return {
        service_account: {
          id: serviceAccountId,
        },
        config: {
          ...sharedConfig,
          type: 'line',
          title: instrument.title || '',
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
            instrument.statusIndicator,
          ),
        },
      };
    case 'Column':
    case 'Bar':
      return {
        service_account: {
          id: serviceAccountId,
        },
        config: {
          ...sharedConfig,
          type: instrument.__typename === 'Bar' ? 'bar' : 'column',
          title: instrument.title || '',
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
            instrument.statusIndicator,
          ),
        },
      };
    case 'Leaderboard':
      return {
        service_account: {
          id: serviceAccountId,
        },
        config: {
          ...sharedConfig,
          type: 'leaderboard',
          title: instrument.title || '',
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          showImages: instrument.showImages ? instrument.showImages : false,
        },
      };
    case 'Table':
      return {
        service_account: {
          id: serviceAccountId,
        },
        config: {
          ...sharedConfig,
          type: 'table',
          title: instrument.title || '',
          columnWidths: instrument.columnWidths || [],
          numberFormat: instrument.numberFormats?.map(
            (nf) => graphQLToReduxConfig.mapNumberFormat(nf) || null,
          ),
          showImages: instrument.showImages ? instrument.showImages : false,
          wrap: instrument.wrap ?? undefined,
        },
      };
    case 'Feed':
      return {
        service_account: {
          id: serviceAccountId,
        },
        config: {
          ...sharedConfig,
          type: 'feed',
          title: instrument.title || '',
        },
      };
    default:
      throw new Error(
        'Visualisation type is not a valid universal visualisation',
      );
  }
};

export const mapReduxToVisualizationInput = (
  config: ReduxState['editWidget']['config'],
): VisualizationInput => {
  switch (config.type) {
    case 'number':
      return {
        number: {
          title: config.title || '',
          label: config.label || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          progressIndicator: reduxConfigToGraphQL.mapProgressIndicator(
            config.goal,
            config.comparison,
          ),
          statusIndicator:
            config.indicators &&
            reduxConfigToGraphQL.mapStatusIndicators(config.indicators),
        },
      };
    case 'geckometer':
      return {
        geckometer: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            config.indicators &&
            reduxConfigToGraphQL.mapStatusIndicators(config.indicators),
        },
      };
    case 'line':
      return {
        line: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            reduxConfigToGraphQL.mapChartGoalToStatusIndicators(config),
        },
      };
    case 'column':
    case 'bar':
      return {
        [config.type]: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            reduxConfigToGraphQL.mapChartGoalToStatusIndicators(config),
        },
      };
    case 'leaderboard':
      return {
        leaderboard: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          sortOrder: 'DESCENDING',
          showImages: config.showImages,
        },
      };
    case 'table': {
      const numberFormats = config.numberFormat || [];
      return {
        table: {
          title: config.title || '',
          columnWidths: config.columnWidths || [],
          numberFormats: numberFormats.map((numberFormat) => {
            const mappedFormat =
              reduxConfigToGraphQL.mapNumberFormat(numberFormat);
            return mappedFormat || null;
          }),
          showImages: config.showImages,
          wrap: toWrapOptionsInput(config.wrap),
        },
      };
    }

    default:
      throw new Error(
        'Visualisation type is not a valid database visualisation',
      );
  }
};
