export default [
  {
    id: '579b4e7f-39f7-413c-a39f-e38d14c9afc5',
    title: 'First response time',
    description: 'Time customers are waiting until they receive a response.',
    category: 'Time',
    type: 'number',
    icon: {
      type: 'text',
      value: '52m',
    },
    queries: [
      {
        select: [
          {
            resource: 'tickets',
            path: 'first_reply_time',
            aggregate: 'avg',
          },
        ],
        filter: [
          {
            resource: 'tickets',
            path: 'created_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
        ],
      },
    ],
  },
  {
    id: '8f0ceb94-d8bc-4a95-ad20-34dc8efc4af5',
    title: 'Resolution time',
    description: 'Time it takes to solve a support ticket.',
    category: 'Time',
    type: 'number',
    icon: {
      type: 'text',
      value: '18h',
    },
    queries: [
      {
        select: [
          {
            resource: 'tickets',
            path: 'resolution_time',
            aggregate: 'avg',
          },
        ],
        filter: [
          {
            resource: 'tickets',
            path: 'stats.resolved_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
        ],
        orderBy: [
          {
            resource: 'ticket_metrics',
            path: 'updated_at',
          },
        ],
      },
    ],
  },
  {
    id: 'd7715d8f-77d4-44bb-927c-399b28a6fd0c',
    title: 'Ticket volume',
    description: 'Number of support tickets coming in each day.',
    category: 'Volume',
    type: 'line',
    icon: {
      type: 'icon',
      value: 'line',
    },
    queries: [
      {
        select: [
          { resource: 'tickets', path: 'created_at' },
          {
            resource: 'tickets',
            custom: 'created_tickets',
            aggregate: 'count',
          },
        ],
        filter: [
          {
            resource: 'tickets',
            path: 'created_at',
            operands: [28, 'day'],
            operator: 'timespan',
          },
        ],
        group_by: [
          { resource: 'tickets', path: 'created_at', bucket_by: 'day' },
        ],
      },
    ],
  },
  {
    id: 'ecf4279f-a9d5-47cf-8d5b-0d02ca38905f',
    title: 'Unassigned tickets',
    description: 'Number of support tickets yet to be assigned',
    category: 'Volume',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'tickets',
            custom: 'created_tickets',
            aggregate: 'count',
          },
        ],
        filter: [
          {
            resource: 'tickets',
            path: 'created_at',
            operands: [30, 'day'],
            operator: 'timespan',
          },
          { resource: 'tickets', path: 'responder_id', operator: 'is_null' },
        ],
      },
    ],
  },
  {
    id: '860a74c0-4531-4e9f-aa40-d9a7023303fd',
    title: 'Customer satisfaction',
    description: 'Percentage of good customer satisfaction ratings.',
    category: 'Quality',
    type: 'number',
    icon: {
      type: 'text',
      value: '94%',
    },
    queries: [
      {
        select: [{ resource: 'satisfaction_ratings', path: 'sat_score' }],
        filter: [
          {
            resource: 'satisfaction_ratings',
            path: 'created_at',
            operands: [28, 'day'],
            operator: 'timespan',
          },
        ],
      },
    ],
  },
  {
    id: 'cb1cc06d-86d0-4176-978a-7eabc5a58569',
    title: 'Tickets by category',
    description:
      'Number of tickets split by status, priority, or other category.',
    category: 'Topics',
    type: 'bar',
    icon: {
      type: 'icon',
      value: 'bar',
    },
    queries: [
      {
        select: [
          { resource: 'tickets', path: 'status' },
          {
            resource: 'tickets',
            custom: 'created_tickets',
            aggregate: 'count',
          },
        ],
        filter: [
          {
            resource: 'tickets',
            path: 'created_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
        ],
        group_by: [{ resource: 'tickets', path: 'status' }],
        order_by: [{ resource: 'tickets', path: 'status', order: 'asc' }],
        limit: 20,
      },
    ],
  },
  {
    id: '7ae5071d-21b4-4ed2-a8fc-f79422b26050',
    title: 'Top ticket solvers',
    description: 'Who is solving the most tickets.',
    category: 'People',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        select: [
          { resource: 'tickets', path: 'responder.contact.name' },
          {
            resource: 'tickets',
            custom: 'resolved_tickets',
            aggregate: 'count',
          },
        ],
        filter: [
          {
            resource: 'tickets',
            path: 'stats.resolved_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
        ],
        group_by: [{ resource: 'tickets', path: 'responder_id' }],
        order_by: [
          { resource: 'tickets', custom: 'resolved_tickets', order: 'desc' },
        ],
      },
    ],
  },
];
