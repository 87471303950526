export default {
  api: {
    max_time_period: 10368000000,
  },
  metricCategories: [
    'count',
    'chat_time',
    'satisfaction',
    'messages',
    'waiting_time',
    'real_time_response',
    'real_time_agents',
  ],
  resources: [
    {
      name: 'chats',
      human_readable_name: 'chats',
      countable: true,
      fields: [
        {
          custom: 'created_chats',
          name: 'Number of chats',
          category: 'Chats',
          base: { resource: 'chats' },
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
          },
        },
        {
          custom: 'missed_chats',
          name: 'Number of missed chats',
          category: 'Chats',
          base: { resource: 'chats' },
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
            filters: [
              {
                primary_resource: 'chats',
                key: 'missed',
                operator: 'in',
                operands: [true],
              },
            ],
          },
        },
        {
          key: 'count.total',
          name: 'Number of messages (all)',
          category: 'Chats',
          type: 'int',
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
          },
        },
        {
          key: 'count.agent',
          name: 'Number of messages (sent by agent)',
          category: 'Chats',
          type: 'int',
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
          },
        },
        {
          key: 'count.visitor',
          name: 'Number of messages (sent by visitor)',
          category: 'Chats',
          type: 'int',
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
          },
        },
        {
          key: 'response_time.first',
          name: 'First response time',
          default_aggregation: 'avg',
          category: 'Efficiency',
          type: 'duration',
          unit: 'seconds',
          reverse_comparison: true,
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
          },
        },
        {
          key: 'duration',
          name: 'Chat duration',
          default_aggregation: 'avg',
          category: 'Efficiency',
          type: 'duration',
          unit: 'seconds',
          reverse_comparison: true,
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
          },
        },
        {
          key: 'sat_score',
          name: 'Satisfaction rating',
          category: 'Satisfaction',
          type: 'percent',
          aggregated: true,
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
          },
        },
        {
          key: 'timestamp',
          name: 'Started at',
          type: 'datetime',
        },
        {
          key: 'end_timestamp',
          name: 'Ended at',
          type: 'datetime',
        },
        {
          key: 'started_by',
          name: 'Started by',
          type: 'enum',
          values: [
            { key: 'agent', value: 'Agent' },
            { key: 'visitor', value: 'Visitor' },
            { key: 'trigger', value: 'Trigger' },
          ],
        },
        {
          key: 'type',
          name: 'Type',
          type: 'enum',
          values: [
            { key: 'chat', value: 'Chat' },
            { key: 'offline_msg', value: 'Offline message' },
          ],
        },
        {
          key: 'rating',
          name: 'Rating',
          type: 'enum',
          supports_null_values: true,
          values: [
            { key: 'good', value: 'Good' },
            { key: 'bad', value: 'Bad' },
          ],
        },
        {
          key: 'acceptance_rate',
          name: 'Acceptance rate',
          category: 'Chats',
          aggregated: true,
          type: 'percent',
          rules: {
            time_fields: [{ primary_resource: 'chats', key: 'timestamp' }],
          },
        },
        {
          key: 'agents',
          name: 'Agent',
          type: 'string',
          resource: 'agents',
          foreign_key: 'agents.id',
          target_key: 'display_name',
        },
        {
          key: 'departments',
          name: 'Department',
          type: 'string',
          resource: 'departments',
          foreign_key: 'department_id',
          target_key: 'name',
          restrict_single_value: true,
          equivalents: {
            real_time_chats: 'departments',
            real_time_agents: 'departments',
          },
        },
        {
          key: 'tags',
          name: 'Tag',
          type: 'string',
          exclude_from_filters: true,
        },
        {
          key: 'triggered',
          name: 'Triggered',
          type: 'boolean',
        },
        {
          key: 'triggered_response',
          name: 'Triggered response',
          type: 'boolean',
        },
        {
          key: 'unread',
          name: 'Unread',
          type: 'boolean',
        },
        {
          key: 'missed',
          name: 'Missed',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'agents',
      private: true,
      primary_key: 'id',
      fields: [
        { key: 'display_name', name: 'Name', type: 'string' },
        { key: 'enabled', name: 'Agent enabled', type: 'boolean' },
      ],
    },
    {
      name: 'departments',
      private: true,
      primary_key: 'id',
      fields: [
        { key: 'name', name: 'Name', type: 'string' },
        { key: 'enabled', name: 'Department enabled', type: 'boolean' },
      ],
    },
    {
      name: 'roles',
      private: true,
      primary_key: 'id',
      fields: [
        { key: 'name', name: 'Role name', type: 'string' },
        { key: 'enabled', name: 'Role enabled', type: 'boolean' },
      ],
    },
    {
      name: 'skills',
      private: true,
      primary_key: 'id',
      fields: [
        { key: 'name', name: 'Skill  name', type: 'string' },
        { key: 'description', name: 'Description', type: 'string' },
        { key: 'enabled', name: 'Skill enabled', type: 'boolean' },
      ],
    },
    {
      name: 'real_time_chats',
      fields: [
        {
          key: 'incoming_chats',
          name: 'Current queue size (unassigned)',
          category: 'Efficiency',
          type: 'int',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'assigned_chats',
          name: 'Current queue size (assigned)',
          category: 'Efficiency',
          type: 'int',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'queue',
          name: 'Current queue size (all)',
          category: 'Efficiency',
          type: 'int',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'active_chats',
          name: 'Currently being served',
          category: 'Efficiency',
          type: 'int',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'waiting_time_avg',
          name: 'Current wait time (average)',
          category: 'Efficiency',
          type: 'duration',
          unit: 'seconds',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'waiting_time_max',
          name: 'Current wait time (longest)',
          category: 'Efficiency',
          type: 'duration',
          unit: 'seconds',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'response_time_avg',
          name: 'Current response time (average)',
          category: 'Efficiency',
          type: 'duration',
          unit: 'seconds',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'response_time_max',
          name: 'Current response time (longest)',
          category: 'Efficiency',
          type: 'duration',
          unit: 'seconds',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'chat_duration_avg',
          name: 'Current chat duration (average)',
          category: 'Efficiency',
          type: 'duration',
          unit: 'seconds',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'chat_duration_max',
          name: 'Current chat duration (longest)',
          category: 'Efficiency',
          type: 'duration',
          unit: 'seconds',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'departments',
          name: 'Department',
          type: 'string',
          resource: 'departments',
          foreign_key: 'department_id',
          target_key: 'name',
          restrict_single_value: true,
          equivalents: {
            chats: 'departments',
            real_time_agents: 'departments',
          },
        },
      ],
    },
    {
      name: 'real_time_agents',
      fields: [
        {
          key: 'agents_online',
          name: 'Agents currently online',
          category: 'Agent activity',
          type: 'int',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'agents_away',
          name: 'Agents currently away',
          category: 'Agent activity',
          type: 'int',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'agents_invisible',
          category: 'Agent activity',
          name: 'Agents currently invisible',
          type: 'int',
          aggregated: true,
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'departments',
          name: 'Department',
          type: 'string',
          resource: 'departments',
          foreign_key: 'department_id',
          target_key: 'name',
          restrict_single_value: true,
          equivalents: {
            chats: 'departments',
            real_time_chats: 'departments',
          },
        },
      ],
    },
  ],
  initialQueries: {
    number: {
      query: {
        select: [
          {
            resource: 'chats',
            custom: 'created_chats',
            aggregate: 'count',
          },
        ],
        filter: [
          {
            resource: 'chats',
            path: 'timestamp',
            operator: 'timespan',
            operands: [7, 'day'],
          },
        ],
      },
    },
  },
};
