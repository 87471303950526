const SERVICES_WITH_IMPORTS = [
  'aircall2',
  'hubspot4',
  'intercom3',
  'intercom4',
  'pipedrive3',
  'shopify2',
  'stripe2',
  'zendesk3',
];

/**
 * Returns wether the service name passed requires
 * an import step before we can query any data.
 *
 * @param {String} serviceName - The unique id string for the service
 * @returns {Boolean}
 */
export const requiresImport = (serviceName) =>
  SERVICES_WITH_IMPORTS.includes(serviceName);
