import type { Field } from '../../types';

export function isXAxisStillValid(
  selectedXAxisKey: number | undefined,
  fieldsForXAxis: Field[],
): boolean {
  let hasValidKey = true;
  const hasSelectdKey = selectedXAxisKey !== undefined;

  if (hasSelectdKey) {
    hasValidKey = fieldsForXAxis.some((xAxisField) => {
      // Ensure that the current key is still one of those suitable
      return selectedXAxisKey === xAxisField.key;
    });

    return hasValidKey;
  }

  return hasSelectdKey;
}
