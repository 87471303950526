import { trackGoal } from '@Components/instrument/instrument-tracking-helpers';
import {
  createInstrumentMutation,
  getInitialContainerArea,
} from '@Lib/create-instrument-helpers';
import {
  ConfigComparison,
  reduxConfigToGraphQL,
} from '@Lib/graphql-legacy-config-mappers';
import { instrumentConfigEdited, instrumentCreated } from '@Tracking/events';
import { trackEvent } from '@Tracking/index';

import { editInstrumentMutation } from '../../dashboard/container-layout/use-edit-compact-config-instrument-mutation';
import { VisualizationInput } from '../../generated/graphql';
import apolloClient from '../../services/concierge-service/apollo-client';
import { toWrapOptionsInput } from '../../visualisation/getWrap';
import { type WidgetConfig } from '../universal-types';

type OriginalGoal = {
  startingValue?: number;
  targetValue?: number;
};

type Goal = {
  goal?: string;
  comaprsion?: Pick<ConfigComparison, 'type' | 'startingValue'>;
};
interface WidgetData {
  config: WidgetConfig & Goal;
  service_account_id: string;
  service_name: string;
  service_title: string;
  originalGoal?: OriginalGoal;
}

export const createInstrument = async (
  dashboardId: string,
  widgetData: WidgetData,
) => {
  const client = apolloClient.create();
  const { config } = widgetData;
  const result = await client.mutate({
    mutation: createInstrumentMutation,
    variables: {
      dashboardId,
      dataSourceInput: {
        platform: 'INQUISITOR',
        integrationSlug: widgetData.service_name,
        config: {
          inquisitor: {
            queries: JSON.stringify(config.queries),
            queryMetas: JSON.stringify(config.queryMetas),
            queryOptions: JSON.stringify(config.queryOptions),
            legacyServiceAccountId: widgetData.service_account_id,
            detailsMetricResource: config.detailsMetricResource,
            timespanComparison:
              config.type === 'number' && config.timespanComparison
                ? JSON.stringify(config.timespanComparison)
                : undefined,
          },
        },
      },
      visualizationInput: vizInputFromWidgetConfig(config),
      areaInput: getInitialContainerArea(),
    },
  });

  const instrument = result.data?.instrumentCreate?.instrument;

  if (instrument) {
    trackEvent(
      instrumentCreated({
        'Instrument ID': instrument.id,
        Visualisation: config.type,
        'Integration name': widgetData.service_title,
        'Integration slug': widgetData.service_name,
      }),
    );
  }

  return instrument;
};

export const editInstrument = async (
  instrumentId: string,
  widgetData: WidgetData,
) => {
  const client = apolloClient.create();
  const { config } = widgetData;
  const result = await client
    .mutate({
      mutation: editInstrumentMutation,
      variables: {
        id: instrumentId,
        dataSourceConfigInput: {
          inquisitor: {
            queries: JSON.stringify(config.queries),
            queryMetas: JSON.stringify(config.queryMetas),
            queryOptions: JSON.stringify(config.queryOptions),
            legacyServiceAccountId: widgetData.service_account_id,
            detailsMetricResource: config.detailsMetricResource,
            timespanComparison:
              config.type === 'number' && config.timespanComparison
                ? JSON.stringify(config.timespanComparison)
                : undefined,
          },
        },
        visualizationInput: vizInputFromWidgetConfig(config),
      },
    })
    .then((data) => {
      const instrument = data.data?.instrumentEdit?.instrument;
      const trackingDetails = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        'Instrument ID': instrument?.id!,
        Visualisation: config.type,
        'Integration name': widgetData.service_title,
        'Integration slug': widgetData.service_name,
      };

      trackEvent(instrumentConfigEdited(trackingDetails));
      trackGoal(trackingDetails, widgetData.originalGoal, config);

      return instrument;
    });

  return result;
};

export const vizInputFromWidgetConfig = (
  config: WidgetConfig,
): VisualizationInput => {
  switch (config.type) {
    case 'number':
      return {
        number: {
          title: config.title || '',
          label: config.label,
          comparison: config.comparisonLabel
            ? {
                type: 'DYNAMIC',
                label: config.comparisonLabel,
              }
            : undefined,
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          progressIndicator: reduxConfigToGraphQL.mapProgressIndicator(
            config.goal,
            config.comparison,
          ),
          statusIndicator:
            config.indicators &&
            reduxConfigToGraphQL.mapStatusIndicators(config.indicators),
        },
      };
    case 'geckometer':
      return {
        geckometer: {
          title: config.title || '',
          min: config.min ? { floatValue: parseFloat(config.min) } : undefined,
          max: config.max ? { floatValue: parseFloat(config.max) } : undefined,
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            config.indicators &&
            reduxConfigToGraphQL.mapStatusIndicators(config.indicators),
        },
      };
    case 'line':
    case 'column':
    case 'bar':
      return {
        [config.type]: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            reduxConfigToGraphQL.mapChartGoalToStatusIndicators(config),
        },
      };
    case 'leaderboard':
      return {
        leaderboard: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
        },
      };
    case 'table':
      return {
        table: {
          title: config.title || '',
          columnWidths: config.columnWidths || [],
          numberFormats: config.numberFormat
            ? config.numberFormat.map((numberFormat) => {
                const mappedFormat =
                  reduxConfigToGraphQL.mapNumberFormat(numberFormat);
                return mappedFormat || null;
              })
            : [],
          wrap: toWrapOptionsInput(config.wrap),
        },
      };
    case 'feed':
      return {
        feed: {
          title: config.title || '',
        },
      };
    default:
      throw new Error(
        'Instrument type not supported when mapping visualizationInput from state to GraphQL',
      );
  }
};
