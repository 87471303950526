import { decode } from 'he';
import { forEachRight, includes, omit, pull } from 'lodash';

import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { getConfig } from '../../lib/widget-service';
import { deserialize } from '../../universal/serializers';
import universalService from '../../universal/services/universal-service';
import { getPreferences } from '../persist-config-helpers';
import {
  applyPreferences,
  ENABLED_CACHE,
  getSubQueryResult,
} from '../query-helpers';

import {
  bootstrapGraph,
  bootstrapServiceAccounts,
  bootstrapUniversalService,
  NoServiceAccountError,
  resetInitial,
  setNoServiceAccountsAndOpenPanel,
  setRawQueries,
  setService,
  setServiceAccountId,
  setServiceAccounts,
  SilentlyChangedServiceAccountError,
} from './universal-bootstrap-actions';
import { setVisualisationError, validate } from './universal-query-actions';
import {
  clearQueuedData,
  PusherError,
  startPusherChannel,
} from './universal-socket-actions';

export const clearQueryResults = createAction(
  'UniversalConfig:CLEAR_QUERY_RESULTS',
);
export const fetchVizDataStart = createAction(
  'UniversalConfig:FETCH_VIZ_DATA_START',
);
export const fetchVizDataSuccess = createAction(
  'UniversalConfig:FETCH_VIZ_DATA_SUCCESS',
);
export const setCancelledQueries = createAction(
  'UniversalConfig:SET_CANCELLED_QUERIES',
);
export const setConfig = createAction('UniversalConfig:SET_CONFIG');
export const setGraph = createAction('UniversalConfig:SET_GRAPH');
export const setAPISettings = createAction('UniversalConfig:SET_API_SETTINGS');
export const setPreset = createAction('UniversalConfig:SET_PRESET');
export const setQueryIds = createAction('UniversalConfig:SET_QUERY_IDS');
export const setSource = createAction('UniversalConfig:SET_SOURCE');
export const universalSetError = createErrorAction('UniversalConfig:SET_ERROR');
export const recomputeQueries = createErrorAction(
  'UniversalConfig:RECOMPUTE_QUERIES',
);
export const updateSubQueryResults = createAction(
  'UniversalConfig:UPDATE_SUBQUERY_RESULTS',
);
export const updateColumnNumberFormat = createAction(
  'UniversalConfig:UPDATE_COLUMN_NUMBER_FORMAT',
);
export const disableQueries = createAction('UniversalConfig:DISABLE_QUERIES');

export const cancelQueries =
  (reason, clearQueryIds, _queryIds) => (dispatch, getState) => {
    const {
      pusherChannel,
      queryIds: qIds,
      queriesFetchDone,
    } = getState().universal;
    const forceCancel = !!_queryIds;
    const queryIds = _queryIds || qIds;
    // Remove query IDs from state so they don't throw an error when cancelled
    if (clearQueryIds) dispatch(setQueryIds([]));

    dispatch(setCancelledQueries(queryIds));

    try {
      queryIds.forEach((queryId, index) => {
        if (forceCancel || !queriesFetchDone || !queriesFetchDone[index]) {
          universalService.cancelQuery(queryId, pusherChannel, reason);
        }
      });
    } catch (cancelErr) {
      // We silently fail on purpose here as our design is robust to not listen
      // to the query
    }
  };

export const fetchAndUpdateSubquery = (field) => async (dispatch, getState) => {
  const { universal } = getState();
  const { subQueryResults: currentSubQueryResults = {} } = universal;
  if (field.hasValues() && !field.getValues(currentSubQueryResults)) {
    const resourceName = field.getResourceName();
    const subQueryResult = await getSubQueryResult(field, universal);
    const cleanResult = subQueryResult.map(({ name, ...rest }) => ({
      ...rest,
      name: !!name ? decode(name) : null,
    }));
    dispatch(updateSubQueryResults({ [resourceName]: cleanResult }));
  }
};

const receivedQueryIds = (queryIds) => (dispatch, getState) => {
  dispatch(setQueryIds(queryIds));
  dispatch(clearQueryResults());

  const {
    universal: { queuedData },
  } = getState();
  const ids = [...queryIds];

  if (queuedData.length) {
    forEachRight(queuedData, (data) => {
      const { queryId } = data;

      if (ids.includes(queryId)) {
        if (data.error) {
          dispatch(setVisualisationError(data.error));
          return false;
        }

        dispatch(fetchVizDataSuccess(data));
        pull(ids, queryId);

        // Stop when there are no more query IDs
        if (!ids.length) return false;
      }
      return true;
    });

    dispatch(clearQueuedData());
  }
};

export const runQuery = () => async (dispatch, getState) => {
  const {
    pusherChannel,
    timezone,
    source = {},
    queriesEnabled,
    config = {},
  } = getState().universal;

  if (!queriesEnabled) {
    dispatch(recomputeQueries());
    return;
  }

  // For the Leaderboard, Table and Feed visualisations, without a limit, it creates a payload
  // with far too much data, which Tabloid will be very unhappy with. So for those visualisations,
  // we put a limit on the queries
  let queryLimit;
  const LIMITED_VISUALISATION_TYPES = ['leaderboard', 'table', 'feed'];
  const QUERY_RESULTS_LIMIT = 20;
  if (includes(LIMITED_VISUALISATION_TYPES, config.type)) {
    queryLimit = QUERY_RESULTS_LIMIT;
  }

  const delivery = {
    mode: 'async',
    provider: 'pusher',
    destination: pusherChannel,
  };
  try {
    dispatch(fetchVizDataStart());
    const {
      queries,
      queryFetchCursor,
      config: { queryOptions = {} },
    } = getState().universal;

    const payloads = await Promise.all(
      queries.map((query) => {
        const beQuery = {
          ...query,
          source,
          ...(queryLimit && { limit: queryLimit }),
        };

        return universalService.runQuery(
          beQuery,
          delivery,
          ENABLED_CACHE,
          timezone,
          queryOptions,
        );
      }),
    );

    const queryIds = payloads.map((r) => r.query_id);

    // Only process latest response
    if (queryFetchCursor === getState().universal.queryFetchCursor) {
      dispatch(receivedQueryIds(queryIds));
    } else {
      dispatch(cancelQueries('changed config option', false, queryIds));
    }
  } catch (err) {
    dispatch(setVisualisationError(err));
  }
};

export const submit = () => (dispatch, getState) => {
  dispatch(cancelQueries('changed config option', true));
  dispatch(validate());

  const { previewError } = getState().universal;

  if (!previewError) {
    dispatch(runQuery());
  }
};

const handleErrors = (error, dispatch) => {
  if (error instanceof NoServiceAccountError) {
    dispatch(setNoServiceAccountsAndOpenPanel());
    return;
  } else if (error instanceof PusherError) {
    dispatch(setVisualisationError(error));
  } else {
    dispatch(universalSetError(error));
  }
};

const _triggerFirstQueries = () => (dispatch, getState) => {
  // Check pusher is ready and pusher callback has not trigger run query yet
  const { isPusherReady, queryIds = [] } = getState().universal;
  if (isPusherReady && !queryIds.length) {
    dispatch(runQuery());
  }
};

export const initConfig =
  (serviceSlug, dashboardId, presetId, startPusher = true) =>
  async (dispatch, getState) => {
    try {
      dispatch(resetInitial());

      if (startPusher) {
        const {
          user: { user },
        } = getState();
        dispatch(startPusherChannel(user.id, dashboardId));
      }

      await dispatch(bootstrapUniversalService(dashboardId, serviceSlug));

      // This must be a separate call to getState() from the one that reads the
      // user because it needs to read the state set by the preceding action
      // dispatches.
      const {
        universal: {
          source: { integration },
        },
      } = getState();

      let queries;

      if (presetId) {
        const preset = await universalService.fetchPreset(
          integration,
          presetId,
        );
        if (!preset) {
          throw new Error(`Preset "${presetId}" not found`);
        }
        dispatch(setPreset(preset));
        queries = preset.queries;
      } else {
        // When creating a new custom widget
        // TODO update metadata to use `queries = []`  and remove type: 'number'
        const metadata = await universalService.fetchMetadata(integration);
        const { initialQueries } = metadata;
        const { query } = initialQueries.number;
        queries = [query];
      }

      // set queries before to set service accounts in case it throws an error
      // when no service accounts are set....
      dispatch(setRawQueries(queries));
      await dispatch(bootstrapServiceAccounts());

      let defaultsFor = ['globalFilters', 'vipFilters'];
      const preferences = await getPreferences(getState().universal);
      if (preferences) {
        // When preferences are applicable we do not override filter values
        // It acts the same way as getWidgetConfig in that case
        defaultsFor = ['vipFilters'];
        queries = applyPreferences(queries, preferences);
      }
      // Set queries again with preferences
      dispatch(setRawQueries(queries));

      await dispatch(bootstrapGraph(defaultsFor, ['globalFilters'], false));
      if (startPusher) {
        dispatch(_triggerFirstQueries());
      } else {
        // Because we did not run first queries we've never got the queries from
        // uiQueries and we might miss filters added automatically while
        // bootstraping
        dispatch(recomputeQueries());
      }
    } catch (error) {
      handleErrors(error, dispatch);
    }
  };

export const getWidgetConfig =
  (widgetId, dashboardId, rodsWidgetConfig) => async (dispatch, getState) => {
    try {
      const {
        user: { user },
      } = getState();

      dispatch(resetInitial());
      dispatch(startPusherChannel(user.id, dashboardId));
      let json;
      if (!rodsWidgetConfig) {
        json = await getConfig(widgetId);
      }
      const widgetConfig = rodsWidgetConfig || json;

      const {
        config: { integrationName },
        service_account: { id: accountId = null } = {},
      } = widgetConfig;

      const config = deserialize('universal', null, widgetConfig);
      const { queries: storedQueries } = config;
      const queries = storedQueries.map((q) => omit(q, 'source'));

      dispatch(setConfig(config));
      await dispatch(bootstrapUniversalService(dashboardId, integrationName));

      dispatch(setRawQueries(queries));

      try {
        await dispatch(bootstrapServiceAccounts(accountId, true));
        await dispatch(bootstrapGraph([], ['globalFilters'], false));
      } catch (err) {
        // When service account is not available anymore we need to reset queries
        // and to apply the new service account.
        // TODO explain changes to the user
        if (err instanceof SilentlyChangedServiceAccountError) {
          await dispatch(
            bootstrapGraph(['globalFilters'], ['globalFilters'], true),
          );
        } else {
          throw err;
        }
      }
      dispatch(_triggerFirstQueries());
    } catch (error) {
      handleErrors(error, dispatch);
    }
  };

export const initialiseDefaultValues =
  (service, serviceAccount) => async (dispatch, getState) => {
    try {
      dispatch(resetInitial());
      dispatch(disableQueries());
      dispatch(setService(service));
      dispatch(setServiceAccounts([serviceAccount]));
      const {
        universal: {
          source: { integration },
        },
      } = getState();
      const metadata = await universalService.fetchMetadata(integration);
      const { initialQueries } = metadata;
      const { query } = initialQueries.number;
      const queries = [query];
      dispatch(setRawQueries(queries));
      dispatch(setServiceAccountId(serviceAccount.id));
      await dispatch(
        bootstrapGraph(
          ['globalFilters', 'vipFilters'],
          ['globalFilters'],
          false,
        ),
      );
      dispatch(recomputeQueries());
    } catch (error) {
      handleErrors(error, dispatch);
    }
  };
