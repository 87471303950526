import { getWindow } from '../../lib/global';
import { fetchJSONWithoutCSRF } from '../../universal/fetch';

export const VIEW_TYPES = {
  MOBILE: 'Mobile',
  DESKTOP: 'Desktop',
};

// These values are only used by the legacy dashboard types
// and match those of the graphql tracking layout values for consistency
// used from src/dashboard/dashboard-layout/constants
export const DASHBOARD_TYPES = {
  FIXIE: 'wonderwall',
  CLASSIC: 'classic',
};

export const SESSION_TYPES = {
  LOOP: 'Loop',
  SHARING_URL: 'Sharing url',
  ADMIN: 'Admin',
  SCREEN: 'Screen',
  SCREEN_LOOP: 'Screen loop',
};

interface DashboardViewAttributes {
  legacyUserId: number | string;
  legacyDashboardId?: number | string;
  dashboardId?: string;
  dashboardType?: string;
  screenId?: string;
  sessionType: string;
  viewType: string;
}

/**
 * @function dashboardViewTracker
 * @param {Object} attributes - Attributes required for a dashboard view
 *
 * Following are the fields we expect to be present
 * @param {integer|string} attributes.legacyUserId
 * @param {integer|string} attributes.legacyDashboardId - optional when session type is loop or ROD only dashboard
 * @param {string} attributes.dashboardId
 * @param {string} attributes.screenId - required when session type is screen*
 * @param {string} attributes.dashboardType - uses the layout type of a dashboard
 * @param {string} attributes.sessionType - how is the dashboard viewed using SESSION_TYPES
 * @param {string} attributes.viewType - how the dashboarded is viewed using VIEW_TYPE
 * */
export const trackDashboardViewed = (
  attributes: DashboardViewAttributes,
): void => {
  const {
    legacyUserId,
    legacyDashboardId,
    dashboardId,
    dashboardType,
    screenId,
    sessionType,
    viewType,
  } = attributes;

  fetchJSONWithoutCSRF(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    `${(getWindow() as any).TRACKING_HOST}/dashboards/view`,
    {
      method: 'POST',
      body: JSON.stringify({
        user_id: legacyUserId,
        legacy_dashboard_id:
          legacyDashboardId === '0' ? undefined : legacyDashboardId,
        dashboard_id: dashboardId,
        screen_id: screenId,
        dashboard_type: dashboardType,
        session_type: sessionType,
        view_type: viewType,
      }),
    },
  );
};
