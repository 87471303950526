export default [
  {
    id: '728cecf1-0f37-4d76-b237-8d1c45ff8e01',
    title: 'Deals by lifecycle stage',
    description: 'Total number of deals broken down by lifecycle stage.',
    category: 'Deals',
    type: 'bar',
    icon: { type: 'icon', value: 'bar' },
    queries: [
      {
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
        ],
        group_by: [{ path: 'properties.dealstage.value', resource: 'deals' }],
        limit: 20,
        order_by: [{ order: 'asc', path: 'stage.label', resource: 'deals' }],
        select: [
          { path: 'stage.label', resource: 'deals' },
          { aggregate: 'count', custom: 'created_deals', resource: 'deals' },
        ],
      },
    ],
  },
  {
    id: '28b50ee4-eeed-4fc4-99ae-72755d3821e9',
    title: 'Value of deals by stage',
    description: 'Value of deals broken down by stage.',
    category: 'Deals',
    type: 'bar',
    icon: { type: 'icon', value: 'bar' },
    queries: [
      {
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
        ],
        group_by: [{ path: 'properties.dealstage.value', resource: 'deals' }],
        limit: 20,
        order_by: [{ order: 'asc', path: 'stage.label', resource: 'deals' }],
        select: [
          { path: 'stage.label', resource: 'deals' },
          {
            aggregate: 'sum',
            custom: 'value_started_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '87d19951-b486-4c2a-bfd1-93d66a8cb6c7',
    title: 'Deals by stage excluding won and lost',
    description: 'Number of deals in each stage excluding won and lost.',
    category: 'Deals',
    type: 'bar',
    icon: { type: 'icon', value: 'bar' },
    queries: [
      {
        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
          {
            operands: ['closedwon', 'closedlost'],
            operator: 'not_in',
            path: 'properties.dealstage.value',
            resource: 'deals',
          },
        ],
        group_by: [
          { path: 'properties.hubspot_owner_id.value', resource: 'deals' },
        ],
        limit: 20,
        order_by: [{ order: 'asc', path: 'owner.fullName', resource: 'deals' }],
        select: [
          { path: 'owner.fullName', resource: 'deals' },
          { aggregate: 'count', custom: 'created_deals', resource: 'deals' },
        ],
      },
    ],
  },
  {
    id: '384eeaca-4aca-47ad-b8f8-803804ea2d92',
    title: 'Value of closed won deals',
    description: 'The monetary value of recently closed won deals.',
    category: 'Deals',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'deals',
            path: 'properties.amount_in_home_currency.value',
            custom: 'value_closed_deals',
            aggregate: 'avg',
          },
        ],
        filter: [
          {
            resource: 'deals',
            path: 'properties.closedate.value',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'deals',
            path: 'stage.closedWon',
            operator: 'in',
            operands: [true],
          },
        ],
      },
    ],
  },
  {
    id: '00635304-868c-41ca-9aef-7feca5279f10',
    title: 'Value of closed lost deals',
    description: 'The monetary value of recently closed lost deals.',
    category: 'Deals',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'deals',
            path: 'properties.amount_in_home_currency.value',
            custom: 'value_closed_deals',
            aggregate: 'avg',
          },
        ],
        filter: [
          {
            resource: 'deals',
            path: 'properties.closedate.value',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'deals',
            path: 'stage.closedWon',
            operator: 'in',
            operands: [false],
          },
        ],
      },
    ],
  },
  {
    id: 'e89a9610-4dfd-4366-b320-0ce93127dfa9',
    title: 'Created deals by deal type',
    description: 'Count of deals for each deal type.',
    category: 'Deals',
    type: 'bar',
    icon: { type: 'icon', value: 'bar' },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
        ],
        group_by: [{ path: 'properties.dealtype.value', resource: 'deals' }],
        limit: 20,
        order_by: [{ order: 'asc', path: 'stage.label', resource: 'deals' }],
        select: [
          { path: 'properties.dealtype.value', resource: 'deals' },
          { aggregate: 'count', custom: 'created_deals', resource: 'deals' },
        ],
      },
    ],
  },
  {
    id: '1a21a566-11dd-4c99-b176-463c4724b8a2',
    title: 'Created deals overview',
    description:
      'Most recently created deals including lifecycle stage and owner.',
    category: 'Deals',
    type: 'table',
    icon: {
      type: 'icon',
      value: 'table',
    },
    config: {
      detailsMetricResource: 'deals',
    },
    queries: [
      {
        select: [
          { resource: 'deals', path: 'properties.dealname.value' },
          {
            resource: 'deals',
            path: 'properties.amount_in_home_currency.value',
          },
          { resource: 'deals', path: 'stage.label' },
          { resource: 'deals', path: 'owner.fullName' },
        ],
        order_by: [
          {
            resource: 'deals',
            path: 'properties.createdate.value',
            order: 'desc',
          },
        ],
        limit: 20,
      },
    ],
  },
  {
    id: '90f58769-88ae-4889-8362-8260aabb6b8d',
    title: 'Value of new deals created',
    description: 'Value of created deals over time.',
    category: 'Deals',
    type: 'bar',
    icon: { type: 'icon', value: 'bar' },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
        ],
        group_by: [
          {
            bucket_by: 'day',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
        ],
        order_by: [
          {
            order: 'asc',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
        ],
        select: [
          { path: 'properties.createdate.value', resource: 'deals' },
          {
            aggregate: 'sum',
            custom: 'value_started_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '32d51986-5be3-41cc-ac4d-894697ebe435',
    title: "Value of 'New Business' deals",
    description:
      "Value of recently created deals with deal type 'New Business'.",
    category: 'Deals',
    type: 'bar',
    icon: { type: 'icon', value: 'bar' },
    queries: [
      {
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
          {
            operands: ['newbusiness'],
            operator: 'in',
            path: 'properties.dealtype.value',
            resource: 'deals',
          },
        ],
        limit: 20,
        select: [
          { aggregate: 'count', custom: 'created_deals', resource: 'deals' },
        ],
      },
    ],
  },
  {
    id: 'b557005f-4d0c-4abc-bdbc-382d1f603de9',
    title: 'Value of open deals by owner',
    description: 'Deal owners ranked by value of their open deals.',
    category: 'Deals',
    type: 'leaderboard',
    icon: { type: 'icon', value: 'leaderboard' },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
          {
            operands: ['closedwon', 'closedlost'],
            operator: 'not_in',
            path: 'properties.dealstage.value',
            resource: 'deals',
          },
        ],
        group_by: [
          { path: 'properties.hubspot_owner_id.value', resource: 'deals' },
        ],
        limit: 20,
        order_by: [
          {
            custom: 'value_started_deals',
            order: 'desc',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
        select: [
          { path: 'owner.fullName', resource: 'deals' },
          {
            aggregate: 'sum',
            custom: 'value_started_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '1bcb2b97-0328-4171-a3ce-de7662eb2c0e',
    title: 'Value of deals by pipeline',
    description: 'Value of recently created deals in each pipeline.',
    category: 'Deals',
    type: 'table',
    icon: {
      type: 'icon',
      value: 'table',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
        ],
        group_by: [{ path: 'properties.pipeline.value', resource: 'deals' }],
        limit: 20,
        order_by: [
          { order: 'asc', path: 'deal_pipeline.label', resource: 'deals' },
        ],
        select: [
          { path: 'deal_pipeline.label', resource: 'deals' },
          {
            aggregate: 'sum',
            custom: 'value_started_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '043807c8-44f5-43b3-9687-bc4537d66e48',
    title: 'Average deal size',
    description: 'Average value of open deals over time.',
    category: 'Deals',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
          {
            operands: ['closedwon', 'closedlost'],
            operator: 'in',
            path: 'properties.dealstage.value',
            resource: 'deals',
          },
        ],
        group_by: [
          {
            bucket_by: 'day',
            path: 'properties.createdate.value',
            resource: 'deals',
          },
        ],
        select: [
          { path: 'properties.createdate.value', resource: 'deals' },
          {
            aggregate: 'avg',
            custom: 'value_started_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: 'bd1bad5f-c737-4b73-a99c-d8aa0a2bd798',
    title: 'Deal of the month',
    description: 'The highest value deal won this month.',
    category: 'Deals',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        filter: [
          {
            operands: [1, 'month'],
            operator: 'timespan',
            path: 'properties.closedate.value',
            resource: 'deals',
          },
          {
            bound_to_metric: true,
            operands: [true],
            operator: 'in',
            path: 'stage.closedWon',
            resource: 'deals',
          },
        ],
        select: [
          {
            aggregate: 'max',
            custom: 'value_closed_won_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: 'd6ca0aa0-e7e0-4f05-9fb8-b85a5edb1f61',
    title: 'Value of closed won by owner',
    description: 'Sum of Closed Won deal amounts for each HubSpot Owner.',
    category: 'Deals',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.closedate.value',
            resource: 'deals',
          },
          {
            bound_to_metric: true,
            operands: [true],
            operator: 'in',
            path: 'stage.closedWon',
            resource: 'deals',
          },
        ],
        group_by: [
          { path: 'properties.hubspot_owner_id.value', resource: 'deals' },
        ],
        limit: 20,
        order_by: [
          {
            custom: 'value_closed_won_deals',
            order: 'desc',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
        select: [
          { path: 'owner.fullName', resource: 'deals' },
          {
            aggregate: 'sum',
            custom: 'value_closed_won_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '17d82d7f-9032-49d4-8e75-4b2378e37131',
    title: 'Closed lost by reason',
    description: 'Reasons why recent deals were lost.',
    category: 'Deals',
    type: 'table',
    icon: {
      type: 'icon',
      value: 'table',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.closedate.value',
            resource: 'deals',
          },
          {
            operands: ['closedlost'],
            operator: 'in',
            path: 'properties.dealstage.value',
            resource: 'deals',
          },
        ],
        group_by: [
          { path: 'properties.closed_lost_reason.value', resource: 'deals' },
        ],
        limit: 20,
        order_by: [
          {
            order: 'asc',
            path: 'properties.closed_lost_reason.value',
            resource: 'deals',
          },
        ],
        select: [
          { path: 'properties.closed_lost_reason.value', resource: 'deals' },
          { aggregate: 'count', custom: 'closed_deals', resource: 'deals' },
        ],
      },
    ],
  },
  {
    id: '23d7dc2f-59eb-40b8-9578-054a87413af4',
    title: 'Closed deals by source',
    description: 'Deals won or lost grouped by source.',
    category: 'Deals',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'properties.closedate.value',
            resource: 'deals',
          },
          {
            operands: ['closedlost'],
            operator: 'in',
            path: 'properties.dealstage.value',
            resource: 'deals',
          },
        ],
        group_by: [
          { path: 'properties.hs_analytics_source.value', resource: 'deals' },
        ],
        limit: 20,
        order_by: [
          { custom: 'closed_deals', order: 'desc', resource: 'deals' },
        ],
        select: [
          { path: 'properties.hs_analytics_source.value', resource: 'deals' },
          { aggregate: 'count', custom: 'closed_deals', resource: 'deals' },
        ],
      },
    ],
  },
  {
    id: '50a95fcb-ef6c-4f8c-8282-5d197c27e038',
    title: 'Revenue closed this month',
    description: 'The sum of value of deals won this month.',
    category: 'Deals',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        filter: [
          {
            operands: [1, 'month'],
            operator: 'timespan',
            path: 'properties.closedate.value',
            resource: 'deals',
          },
          {
            bound_to_metric: true,
            operands: [true],
            operator: 'in',
            path: 'stage.closedWon',
            resource: 'deals',
          },
        ],
        select: [
          {
            aggregate: 'sum',
            custom: 'value_closed_won_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
    config: {
      max: 500000,
      min: 0,
    },
  },
  {
    id: '0262c843-d96b-4a37-9947-3063d654cdfa',
    title: 'Expected revenue this month',
    description: 'Value of deals with a Close Date this month.',
    category: 'Deals',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        filter: [
          {
            operands: [1, 'month'],
            operator: 'timespan',
            path: 'properties.closedate.value',
            resource: 'deals',
          },
        ],
        select: [
          {
            aggregate: 'sum',
            custom: 'value_all_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '18f07e56-7fde-49e2-bde0-8f5699eeb6af',
    title: 'Median value of closed won deals',
    description: 'Median value of deals Closed Won recently.',
    category: 'Deals',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'properties.closedate.value',
            resource: 'deals',
          },
        ],
        select: [
          {
            aggregate: 'median',
            custom: 'value_closed_won_deals',
            path: 'properties.amount_in_home_currency.value',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '52f4aeb6-93d2-4a0f-8cdf-90deeb1161b2',
    title: 'New contacts by source',
    description: 'Recently created contacts split by original source.',
    category: 'Contacts',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'addedAt',
            resource: 'contacts',
          },
        ],
        group_by: [
          {
            path: 'properties.hs_analytics_source.value',
            resource: 'contacts',
          },
        ],
        limit: 20,
        order_by: [
          { custom: 'created_contacts', order: 'desc', resource: 'contacts' },
        ],
        select: [
          {
            path: 'properties.hs_analytics_source.value',
            resource: 'contacts',
          },
          {
            aggregate: 'count',
            custom: 'created_contacts',
            resource: 'contacts',
          },
        ],
      },
    ],
  },
  {
    id: '610e67d2-22f3-44cf-964d-a35eef61c3ce',
    title: 'New contacts by first page seen',
    description: 'Recently created contacts split by first page seen.',
    category: 'Contacts',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'addedAt',
            resource: 'contacts',
          },
        ],
        group_by: [
          {
            path: 'properties.hs_analytics_first_url.value',
            resource: 'contacts',
          },
        ],
        limit: 20,
        order_by: [
          { custom: 'created_contacts', order: 'desc', resource: 'contacts' },
        ],
        select: [
          {
            path: 'properties.hs_analytics_first_url.value',
            resource: 'contacts',
          },
          {
            aggregate: 'count',
            custom: 'created_contacts',
            resource: 'contacts',
          },
        ],
      },
    ],
  },
  {
    id: 'd61ece06-f30a-4939-8af0-8548b887d3d7',
    title: 'Number of lead captures',
    description:
      'Number of recently created contacts who submitted their first form.',
    category: 'Contacts',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'addedAt',
            resource: 'contacts',
          },
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.first_conversion_date.value',
            resource: 'contacts',
          },
        ],
        select: [
          {
            aggregate: 'count',
            custom: 'created_contacts',
            resource: 'contacts',
          },
        ],
      },
    ],
  },
  {
    id: '0b3d2e3e-00cf-4eb5-b08a-9ff68bc81c70',
    title: 'New leads',
    description: "The number of recently added contacts in the 'Lead' stage.",
    category: 'Contacts',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'addedAt',
            resource: 'contacts',
          },
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.hs_lifecyclestage_lead_date.value',
            resource: 'contacts',
          },
        ],
        select: [
          {
            aggregate: 'count',
            custom: 'created_contacts',
            resource: 'contacts',
          },
        ],
      },
    ],
  },
  {
    id: 'e5a37381-4ba5-42ac-8993-6a283f463a60',
    title: 'New MQLs',
    description:
      "The number of recently added contacts in the 'Marketing Qualified Lead' stage.",
    category: 'Contacts',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'addedAt',
            resource: 'contacts',
          },
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.hs_lifecyclestage_marketingqualifiedlead_date.value',
            resource: 'contacts',
          },
        ],
        select: [
          {
            aggregate: 'count',
            custom: 'created_contacts',
            resource: 'contacts',
          },
        ],
      },
    ],
  },
  {
    id: '7ed8bf47-4821-4318-8b1e-ad7f06963e4f',
    title: 'MQLs by source',
    description: 'Sources bringing in the most marketing qualified leads.',
    category: 'Contacts',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'addedAt',
            resource: 'contacts',
          },
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.hs_lifecyclestage_marketingqualifiedlead_date.value',
            resource: 'contacts',
          },
        ],
        select: [
          {
            aggregate: 'count',
            custom: 'created_contacts',
            resource: 'contacts',
          },
        ],
      },
    ],
  },
  {
    id: '145bbc7a-a03d-4269-8792-9d4ae5f4a420',
    title: 'New SQLs',
    description:
      "The number of recently added contacts in the 'Sales Qualified Lead' stage.",
    category: 'Contacts',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'addedAt',
            resource: 'contacts',
          },
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.hs_lifecyclestage_salesqualifiedlead_date.value',
            resource: 'contacts',
          },
        ],
        select: [
          {
            aggregate: 'count',
            custom: 'created_contacts',
            resource: 'contacts',
          },
        ],
      },
    ],
  },
  {
    id: '9d724bc2-da55-429e-b2f4-73145737e4ed',
    title: 'New opportunities',
    description:
      "The number of contacts created recently in 'Opportunity' stage.",
    category: 'Contacts',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'addedAt',
            resource: 'contacts',
          },
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.hs_lifecyclestage_opportunity_date.value',
            resource: 'contacts',
          },
        ],
        select: [
          {
            aggregate: 'count',
            custom: 'created_contacts',
            resource: 'contacts',
          },
        ],
      },
    ],
  },
  {
    id: '50c83ea1-794a-42e2-88b6-22dd73af23a5',
    title: 'Recently closed companies',
    description: 'Companies with a close date in the recent past',
    category: 'Companies',
    type: 'feed',
    icon: {
      type: 'icon',
      value: 'feed',
    },
    config: {
      detailsMetricResource: 'companies',
    },
    queries: [
      {
        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'properties.recent_deal_close_date.value',
            resource: 'companies',
          },
        ],
        limit: 20,
        order_by: [
          {
            order: 'desc',
            path: 'properties.createdate.value',
            resource: 'companies',
          },
        ],
        select: [{ path: 'properties.name.value', resource: 'companies' }],
      },
    ],
  },
  {
    id: '324b8e9f-6bea-400a-a608-20ef38388a78',
    title: 'Created companies by country / region',
    description: 'Country/region where the most created companies are from',
    category: 'Companies',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'properties.createdate.value',
            resource: 'companies',
          },
        ],
        group_by: [{ path: 'properties.country.value', resource: 'companies' }],
        limit: 20,
        order_by: [
          { custom: 'created_companies', order: 'desc', resource: 'companies' },
        ],
        select: [
          { path: 'properties.country.value', resource: 'companies' },
          {
            aggregate: 'count',
            custom: 'created_companies',
            resource: 'companies',
          },
        ],
      },
    ],
  },
];
