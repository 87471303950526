import { chain, isEmpty, isNumber, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  getInputFromDuration,
  SUPPORTED_UNITS,
} from '../../../lib/humanise-number';
import { isEmptyDurationInput } from '../../../universal-config/ui-field-options';

import styles from './duration-input-styles.scss';

class DurationInput extends Component {
  constructor(props) {
    super(props);

    const units = this.getUnits(props);
    const durationValues = this.getDurationValues(props, units);
    this.state = {
      durationValues,
      units,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.controlInputs || isUndefined(nextProps.value)) {
      const newState = {};
      if (this.props.smallestInputUnit !== nextProps.smallestInputUnit) {
        newState.units = this.getUnits(nextProps);
        newState.durationValues = this.getDurationValues(
          nextProps,
          newState.units,
        );
      } else if (
        this.props.value !== nextProps.value ||
        this.props.unit !== nextProps.unit
      ) {
        newState.durationValues = this.getDurationValues(
          nextProps,
          this.state.units,
        );
      }

      if (!isEmpty(newState)) {
        this.setState(newState);
      }
    }
  }

  handleChange(e) {
    const { onChange, controlInputs } = this.props;

    const {
      target: { name, value },
    } = e;
    const newDuration = {
      ...this.state.durationValues,
      [name]: value,
    };

    if (onChange) {
      onChange({ value: newDuration });
    }

    if (controlInputs) {
      // The inputs are controlled from state instead of value prop.
      // This is so that when entering 70 seconds it doesn't immediately
      // convert it to 1 minute 10 seconds and so on.
      // TODO: We probably always want to do this when value is a number,
      // so look at removing this when we stop supporting duration object as value.
      this.setState({ durationValues: newDuration });
    }
  }

  getDurationValues(props, units) {
    const { value, unit, smallestInputUnit } = props;

    if (isUndefined(value)) {
      return units.reduce((total, u) => {
        total[u] = '';
        return total;
      }, {});
    }

    if (isNumber(value)) {
      return getInputFromDuration(value, unit, 3, smallestInputUnit);
    }
    return value || {};
  }

  getUnits(props) {
    const { smallestInputUnit } = props;
    return chain(SUPPORTED_UNITS)
      .dropRightWhile((u) => u !== smallestInputUnit)
      .takeRight(3)
      .value();
  }

  getPlaceholder(durationInput, units) {
    const { value, placeholder } = this.props;

    // If any of the inputs has a value use 0 as placeholder
    // for all values (used geckometer min/max)
    // TODO: remove isEmptyDurationInput when we stop supporting
    // value as duration object
    const usePlaceholder = isUndefined(value) || isEmptyDurationInput(value);

    return units.reduce((total, unit) => {
      total[unit] = '0';
      if (usePlaceholder && placeholder[unit]) {
        total[unit] = placeholder[unit];
      }
      return total;
    }, {});
  }

  renderInputs() {
    const { durationValues, units } = this.state;
    const placeholder = this.getPlaceholder(durationValues, units);

    return units.map((inputUnit) => {
      return (
        <div key={inputUnit} className={styles.durationInput}>
          <input
            className={styles.input}
            type="number"
            name={inputUnit}
            value={durationValues[inputUnit]}
            placeholder={placeholder[inputUnit]}
            onChange={this.handleChange}
            min="0"
            step="1"
          />
          <span className={styles.suffix}>
            {inputUnit.slice(0, 1).toUpperCase()}
          </span>
        </div>
      );
    });
  }

  render() {
    return (
      <div className={styles.durationInputContainer}>{this.renderInputs()}</div>
    );
  }
}

DurationInput.defaultProps = {
  controlInputs: false,
  placeholder: {},
  smallestInputUnit: 'minutes',
  unit: 'milliseconds',
};

DurationInput.propTypes = {
  // value should be passed as a number from now on.
  // duration object is still supported for old universal config (goal, min/max)
  // but should be removed from here when config is moved to the dashboard and
  // this component is no longer used in universal config.
  controlInputs: PropTypes.bool,
  placeholder: PropTypes.shape({
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string,
  }),
  smallestInputUnit: PropTypes.oneOf(SUPPORTED_UNITS),
  unit: PropTypes.oneOf(SUPPORTED_UNITS),
  value: PropTypes.oneOfType([
    PropTypes.shape({
      days: PropTypes.string,
      hours: PropTypes.string,
      minutes: PropTypes.string,
    }),
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
};

export default DurationInput;
