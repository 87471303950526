import { chain } from 'lodash';

import { transform as goalTransform } from '../../transformers/shared/goal';
import {
  composeApplyConfigTransformers,
  composeTransformers,
} from '../compose-transformers';
import cleanTransformedData from '../shared/clean-transformed-data';

import { getValuesFromRow } from './helpers';

const barTransform = ({ data }) => {
  if (!data || !data.databaseQuery) {
    return {};
  }

  // set values with default empty arrays in case array length is 0 after filter (unzip with returns undefined for both labels and series)
  const [labels = [], series = []] = chain(data.databaseQuery.rows)
    .map(({ values }) => getValuesFromRow(values))
    .filter(([label]) => label !== null)
    .unzip();

  const xAxis = data.databaseQuery.columns[0] || {};

  return {
    series: series.length ? [{ data: series }] : [],
    x_axis: {
      labels,
      type: xAxis.dataType === 'TIMESTAMP' ? 'datetime' : 'standard',
    },
    y_axis: { format: 'decimal' }, // We only support decimal values right now
    format: 'decimal',
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(barTransform, goalTransform);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data, config) => {
  const cleanedData = cleanTransformedData(data);
  return composeApplyConfigTransformers(goalTransform)(cleanedData, config);
};

export { applyConfig, transform };
