import type { Range } from '../../types';

/**
 * Calculate a tranpose direction if one hasn't been set
 */
export const calculateTransposeDirection = (selections: Range[]) => {
  if (selections.length === 0) {
    return 'columns';
  }

  // Check if any of the selections are columns so that we can decide with transpose direction to use
  for (let i = 0; i < selections.length; i++) {
    const { start, end } = selections[i];
    const [, rowStart] = start;
    const [, rowEnd] = end;

    if (rowStart !== rowEnd || (rowStart === -1 && rowEnd === -1)) {
      return 'columns';
    }
  }

  // All the selected cells must be rows
  return 'rows';
};
