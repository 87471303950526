import _ from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericLeaderboardTransform } from '../per-visualisation/leaderboard';

import { destructureSalesforceConfigWrap } from './helpers';

const xLabelToItem = (xLabel, index, values, axisMetadata) => {
  const item = {
    label: xLabel.label,
    value: values[index],
  };

  if (axisMetadata.y_type === 'currency') {
    item.unit = axisMetadata.y_unit;
    item.format = 'currency';
  }

  if (axisMetadata.y_type === 'percentage') {
    item.format = 'percent';
  }

  return item;
};

const leaderboardTransform = (
  { axis_metadata: axisMetadata, x_labels: xLabels, series },
  { reverseSort } = {},
  timezone,
  initialContext = {},
) => {
  if (
    !axisMetadata ||
    !xLabels ||
    !series ||
    xLabels.length < 1 ||
    series.length < 1
  ) {
    return {};
  }

  const context = {};
  const values = series[0].values;

  const items = _.chain(xLabels)
    .map((xLabel, index) => xLabelToItem(xLabel, index, values, axisMetadata))
    .sort(({ value: a }, { value: b }) => b - a)
    .value();

  context.items = items;

  if (reverseSort) {
    context.items = context.items.reverse();
  }

  return { ...initialContext, ...context };
};

const transform = destructureSalesforceConfigWrap(
  composeTransformers(leaderboardTransform, genericLeaderboardTransform),
);

export { transform };
