import { find, isEmpty, some } from 'lodash';

import * as importsActions from '../actions/imports-actions';

const initialState = {
  activeImports: [],
  polling: false,
  queuedServiceAccountId: undefined,
};

export const isPending = (i) => i.status.__typename === 'ImportStatusPending';
export const hasFailed = (i) => i.status.__typename === 'ImportStatusFailed';
export const hasCompleted = (i) =>
  i.status.__typename === 'ImportStatusCompleted';

const hasRemainingTries = (i) => i.status.remainingRetries > 0;

const shouldPoll = (i) =>
  some(i, isPending) || (some(i, hasFailed) && some(i, hasRemainingTries));

const getCurrentImports = (imports) =>
  imports.filter((i) => isPending(i) || hasFailed(i));

const getCompletedImports = (imports) => imports.filter((i) => hasCompleted(i));

const getImportBySA = (imports, serviceAccountId) =>
  imports.find((i) => i.serviceAccountId === serviceAccountId);

const importsReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case importsActions.setImports.type: {
      /*
       * If we currently have active imports we need check to
       * see if they have been completed in this session.
       * If not keep polling.
       */
      if (!isEmpty(state.activeImports)) {
        /*
         *  Let's map over the old state,
         *  Get the import ids,
         *  Then use the import ids to return the new imports from payload
         */
        const oldStateActiveImports = state.activeImports.map(({ id }) =>
          find(payload, { id }),
        );

        // Now we can accurately check if any of our old state's active imports are now complete
        const completedImports = getCompletedImports(oldStateActiveImports);

        let activeImports = getCurrentImports(payload);
        activeImports = [...activeImports, ...completedImports];

        return {
          ...state,
          activeImports,
          polling: !isEmpty(activeImports) && shouldPoll(activeImports),
        };
      }

      /*
       * If a service account has been queued for import check to
       * see if a pending import exists for that service account.
       * If not, we need to keep polling until it does exist.
       */
      if (state.queuedServiceAccountId) {
        const activeSaImport = getImportBySA(
          payload,
          state.queuedServiceAccountId,
        );

        if (!activeSaImport) {
          return state;
        }

        const activeImports = getCurrentImports(payload);

        /*
         * If a pending import does exist for the service
         * account we can unqueue the service account and
         * poll for updates to the import progress.
         */
        return {
          ...state,
          activeImports,
          polling: shouldPoll(activeImports),
          queuedServiceAccountId: undefined,
        };
      }

      /*
       * Check to see if there are any pending imports in
       * the list and if so, start polling for updates.
       */
      const activeImports = getCurrentImports(payload);

      return {
        ...state,
        activeImports,
        polling: !isEmpty(activeImports) && shouldPoll(activeImports),
      };
    }

    case importsActions.queueServiceAccountId.type: {
      return {
        ...state,
        queuedServiceAccountId: `${payload}`,
        polling: true,
      };
    }

    case importsActions.hideImport.type: {
      return {
        ...state,
        activeImports: state.activeImports.filter((i) => i.id !== payload),
      };
    }

    default: {
      return state;
    }
  }
};

export default importsReducer;
export { initialState };
