export default {
  api: {
    max_time_period: 97302600000,
  },
  resources: [
    {
      name: 'campaigns',
      private: true,
      primary_key: 'id',
      fields: [
        {
          key: 'account_id',
          type: 'string',
          hidden: true,
          equivalents: {
            custom_conversions: 'account_id',
            insights: 'account_id',
            adsets: 'account_id',
            ads: 'account_id',
          },
        },
        {
          key: 'name',
          name: 'Campaigns',
          type: 'string',
        },
        {
          key: 'effective_status',
          name: 'Effective Status',
          type: 'enum',
          category: 'campaign_fields',
          values: [
            { key: 'ACTIVE', value: 'Active' },
            { key: 'PAUSED', value: 'Paused' },
            { key: 'DELETED', value: 'Deleted' },
            { key: 'PENDING_REVIEW', value: 'Pending Review' },
            { key: 'DISAPPROVED', value: 'Disapproved' },
            { key: 'PREAPPROVED', value: 'Preapproved' },
            { key: 'PENDING_BILLING_INFO', value: 'Pending Billing Info' },
            { key: 'CAMPAIGN_PAUSED', value: 'Campaign Paused' },
            { key: 'ARCHIVED', value: 'Archived' },
            { key: 'ADSET_PAUSED', value: 'Adset Paused' },
            { key: 'WITH_ISSUES', value: 'With Issues' },
          ],
        },
      ],
    },
    {
      name: 'adsets',
      private: true,
      primary_key: 'id',
      fields: [
        {
          key: 'account_id',
          type: 'string',
          hidden: true,
          equivalents: {
            custom_conversions: 'account_id',
            insights: 'account_id',
            campaigns: 'account_id',
            ads: 'account_id',
          },
        },
        {
          key: 'name',
          name: 'Ad sets',
          type: 'string',
        },
        {
          key: 'effective_status',
          name: 'Effective Status',
          type: 'enum',
          category: 'adset_fields',
          values: [
            { key: 'ACTIVE', value: 'Active' },
            { key: 'PAUSED', value: 'Paused' },
            { key: 'DELETED', value: 'Deleted' },
            { key: 'PENDING_REVIEW', value: 'Pending Review' },
            { key: 'DISAPPROVED', value: 'Disapproved' },
            { key: 'PREAPPROVED', value: 'Preapproved' },
            { key: 'PENDING_BILLING_INFO', value: 'Pending Billing Info' },
            { key: 'CAMPAIGN_PAUSED', value: 'Campaign Paused' },
            { key: 'ARCHIVED', value: 'Archived' },
            { key: 'ADSET_PAUSED', value: 'Adset Paused' },
            { key: 'WITH_ISSUES', value: 'With Issues' },
          ],
        },
      ],
    },
    {
      name: 'insights',
      human_readable_name: 'insights',
      countable: false,
      fields: [
        {
          key: 'account_id',
          name: 'Account',
          type: 'string',
          resource: 'ad_accounts',
          foreign_key: 'account_id',
          target_key: 'name',
          hidden: true,
          restrict_single_value: true,
          equivalents: {
            campaigns: 'account_id',
            adsets: 'account_id',
            ads: 'account_id',
          },
        },
        {
          key: 'reach',
          name: 'Reach',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          aggregated: true,
          category: 'Performance',
        },
        {
          key: 'impressions',
          name: 'Impressions',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Performance',
        },
        {
          key: 'clicks',
          name: 'Clicks (all)',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Performance',
        },
        {
          key: 'unique_clicks',
          name: 'Unique clicks (all)',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          aggregated: true,
          category: 'Performance',
        },
        {
          key: 'ctr',
          name: 'CTR (all)',
          type: 'percent',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Performance',
        },
        {
          key: 'unique_ctr',
          name: 'Unique CTR (all)',
          type: 'percent',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          aggregated: true,
          category: 'Performance',
        },
        {
          key: 'spend',
          name: 'Amount Spent',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Costs',
        },
        {
          key: 'cpm',
          name: 'CPM (cost per 1000 impressions)',
          type: 'currency',
          aggregated: true,
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Costs',
        },
        {
          key: 'cpp',
          name: 'CPP (cost per 1000 people reached)',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Costs',
        },
        {
          key: 'cpc',
          name: 'CPC (all)',
          type: 'currency',
          aggregated: true,
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Costs',
        },
        {
          key: 'purchase_roas',
          name: 'Purchase ROAS',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Costs',
        },
        {
          key: 'actions_link_click',
          name: 'Link clicks',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Campaign Results',
        },
        {
          key: 'actions_mobile_app_install',
          name: 'App installs',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Campaign Results',
        },
        {
          key: 'actions_page_engagement',
          name: 'Page engagements',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Campaign Results',
        },
        {
          key: 'actions_post_engagement',
          name: 'Post engagements',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Campaign Results',
        },
        {
          key: 'actions_leadgen_other',
          name: 'Leads from lead form',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Campaign Results',
        },
        {
          key: 'actions_video_view',
          name: '3-second video views',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Campaign Results',
        },
        {
          key: 'actions_complete_registration',
          name: 'Number of completed registrations',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          aggregated: true,
          category: 'Conversions',
        },
        {
          key: 'actions_purchase',
          name: 'Number of purchases',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          aggregated: true,
          category: 'Conversions',
        },
        {
          key: 'actions_offsite_conversion_fb_pixel_purchase',
          name: 'Number of purchases (Facebook Pixel)',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          aggregated: true,
          category: 'Conversions',
        },
        {
          key: 'actions_lead',
          name: 'Number of leads',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          aggregated: true,
          category: 'Conversions',
        },
        {
          key: 'actions_subscribe',
          name: 'Number of subscribes',
          type: 'float',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          aggregated: true,
          category: 'Conversions',
        },
        {
          key: 'cost_per_complete_registration',
          name: 'Cost per completed registrations',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'cost_per_purchase',
          name: 'Cost per purchase',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'cost_per_offsite_conversion_fb_pixel_purchase',
          name: 'Cost per purchase (Facebook Pixel)',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'cost_per_lead',
          name: 'Cost per lead',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'cost_per_subscribe',
          name: 'Cost per subscribe',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'action_values_complete_registration',
          name: 'Value of completed registrations',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'action_values_purchase',
          name: 'Value of purchases',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'action_values_offsite_conversion_fb_pixel_purchase',
          name: 'Value of purchases (Facebook Pixel)',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'action_values_lead',
          name: 'Value of leads',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'action_values_subscribe',
          name: 'Value of subscribes',
          type: 'currency',
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          category: 'Conversions',
        },
        {
          key: 'date_start',
          name: 'date_start',
          type: 'datetime',
          exclude_from_filters: true,
        },
        {
          key: 'campaign',
          name: 'Campaign',
          type: 'string',
          category: 'insights_fields',
          resource: 'campaigns',
          foreign_key: 'campaign_id',
          target_key: 'name',
          restrict_single_value: true,
          exclude_from_groupby: true,
        },
        {
          key: 'adset',
          name: 'Ad set',
          type: 'string',
          category: 'insights_fields',
          resource: 'adsets',
          foreign_key: 'adset_id',
          target_key: 'name',
          restrict_single_value: true,
          exclude_from_groupby: true,
        },
        {
          key: 'ad',
          name: 'Ad',
          type: 'string',
          category: 'insights_fields',
          resource: 'ads',
          foreign_key: 'ad_id',
          target_key: 'name',
          restrict_single_value: true,
          exclude_from_groupby: true,
        },
        {
          key: 'publisher_platform',
          name: 'Publisher platform',
          type: 'enum',
          restrict_single_value: true,
          rules: {
            time_fields: [{ primary_resource: 'insights', key: 'date_start' }],
          },
          values: [
            { key: 'facebook', value: 'Facebook' },
            { key: 'instagram', value: 'Instagram' },
            { key: 'messenger', value: 'Messenger' },
            { key: 'audience_network', value: 'Audience Network' },
          ],
        },
      ],
      custom_fields_queries: [
        {
          query: {
            select: [
              {
                path: 'name',
                resource: 'custom_conversions',
              },
              {
                path: 'id',
                resource: 'custom_conversions',
              },
            ],
          },
          field_templates: [
            {
              key: 'actions_offsite_conversion.custom.<%= id %>',
              name: 'Number of <%= name %>',
              type: 'float',
              aggregated: true,
              category: 'Custom Conversions',
            },
            {
              key: 'action_values_offsite_conversion.custom.<%= id %>',
              name: 'Value of <%= name %>',
              type: 'currency',
              category: 'Custom Conversions',
            },
            {
              key: 'cost_per_offsite_conversion.custom.<%= id %>',
              name: 'Cost per <%= name %>',
              type: 'currency',
              category: 'Custom Conversions',
            },
          ],
        },
      ],
    },
    {
      name: 'custom_conversions',
      private: true,
      fields: [
        {
          key: 'account_id',
          type: 'string',
          hidden: true,
          equivalents: {
            insights: 'account_id',
            campaigns: 'account_id',
            adsets: 'account_id',
            ads: 'account_id',
          },
        },
      ],
    },
    {
      name: 'ads',
      private: true,
      primary_key: 'id',
      fields: [
        {
          key: 'account_id',
          type: 'string',
          hidden: true,
          equivalents: {
            custom_conversions: 'account_id',
            insights: 'account_id',
            adsets: 'account_id',
            campaigns: 'account_id',
          },
        },
        {
          key: 'name',
          name: 'Ads',
          type: 'string',
        },
      ],
    },
    {
      name: 'ad_accounts',
      primary_key: 'account_id',
      fields: [
        {
          key: 'name',
          name: 'Name',
          type: 'string',
        },
      ],
    },
  ],
  initialQueries: {
    number: {
      query: {
        select: [
          {
            resource: 'insights',
            path: 'clicks',
            aggregate: 'avg',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'account_id',
          },
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
        ],
      },
    },
  },
  options: [
    {
      keys: ['currency', 'account_timezone'],
      query: {
        select: [
          {
            resource: 'ad_accounts',
            path: 'currency',
          },
          {
            resource: 'ad_accounts',
            path: 'timezone_name',
          },
        ],
      },
    },
  ],
  prerequisites: {
    service_account_filters: [
      {
        resource: 'insights',
        path: 'account_id',
      },
    ],
    build_prerequisite_options: (serviceAccounts) => {
      const prerequisites = serviceAccounts.map((sa) => {
        const { ad_accounts: adAccounts = [] } = sa.values;

        return {
          id: sa.id,
          name: sa.name,
          children: adAccounts.map((account) => ({
            id: account.account_id,
            name: account.name,
          })),
        };
      });

      return prerequisites;
    },
  },
};
