import { isEqual } from 'lodash';

import type {
  Cell,
  ColumnRow,
  Field,
  FieldKey,
  Range,
  SpreadsheetsVisualizationConfig,
} from '../../types';
import { SpreadsheetDataSelectionState } from '../spreadsheet-data-selection-slice';

import { calculateTransposeDirection } from './calculateTransposeDirection';
import { createSpreadsheetMaps } from './createSpreadsheetMaps';
import { getFieldsFromRanges } from './getFieldsFromRanges';
import { getRangesFromConfig } from './getRangesFromConfig';
import { getVectorRanges } from './getVectorRanges';

type XAxisAndSeriesState = Pick<
  SpreadsheetDataSelectionState['widgetConfiguration'],
  'xAxisKey' | 'seriesKeys'
>;

/**
 * This is the old way of hydrating fields that is used by widgets that have not
 * saved the user selections in the raw form
 *
 * Calculates which x-axis/series fields need to be chosen in the config view to match
 * the choices the user made when they last configured this widget.
 *
 * If fieldRanges is provided then we have richer information saved about the users
 * selections and use this to hydrate fields etc instead.
 */
export function extractFieldsFromLegacyConfig(
  config: SpreadsheetsVisualizationConfig,
  cells: Cell[],
): XAxisAndSeriesState {
  const ranges = getRangesFromConfig(config);
  const map = createSpreadsheetMaps(cells);

  const transposeDirection = calculateTransposeDirection(ranges);

  const vectors = getVectorRanges(ranges, map, transposeDirection);
  const fields = getFieldsFromRanges(vectors, map, config.hasHeader);

  const seriesKeys: (FieldKey | undefined)[] = [];
  let xAxisKey: FieldKey | undefined;

  switch (config.type) {
    case 'line':
    case 'bar':
    case 'column':
      seriesKeys.push(...getFieldsKeys(fields, ranges, config.series));
      xAxisKey = getFieldKey(fields, ranges, config.xAxis);
      break;

    case 'table':
      seriesKeys.push(...getFieldsKeys(fields, ranges, config.columns));
      break;

    case 'leaderboard':
      seriesKeys.push(getFieldKey(fields, ranges, config.values));
      xAxisKey = getFieldKey(fields, ranges, config.labels);
      break;

    case 'text':
    case 'geckometer':
    case 'number':
      seriesKeys.push(getFieldKey(fields, ranges, config.value));
      break;

    default:
      // @ts-expect-error TS should error that `config.type` is `never` because we've already checked all the values.
      // If it's not `never`, then we're missing a case
      throw new Error(`unsupported viz type ${config.type}`);
  }

  return {
    xAxisKey,
    seriesKeys: seriesKeys.filter<FieldKey>(
      (f: FieldKey | undefined): f is FieldKey => f !== undefined,
    ),
  };
}

/**
 * Find the appropriate FieldKeys (indexes) for the given
 * selections that were saved in the visualization config
 */
const getFieldsKeys = (
  fields: Field[],
  ranges: Range[],
  selections: Array<[ColumnRow, ColumnRow]> | undefined | null,
): FieldKey[] => {
  return (selections ?? [])
    .map((s) => getFieldKey(fields, ranges, s))
    .filter<FieldKey>(
      (f: FieldKey | undefined): f is FieldKey => f !== undefined,
    );
};

/**
 * Find the appropriate FieldKey (index) for the given
 * selection that was saved in the visualization config
 */
const getFieldKey = (
  fields: Field[],
  ranges: Range[],
  selection: [ColumnRow, ColumnRow] | undefined,
): FieldKey | undefined => {
  const index = getFieldIndex(ranges, selection);
  if (index !== -1) {
    return fields[index].key;
  }

  return undefined;
};

/**
 * Find the appropriate field for the given selection. This is our
 * legacy hydration format which isn't required with our new
 * save format
 */
const getFieldIndex = (
  ranges: Range[],
  selection: [ColumnRow, ColumnRow] | undefined,
) => {
  // Legacy approach is to work out, given the selection
  // which of the fields matches it
  if (!selection) {
    return -1;
  }

  // The old way of hydrating, needs to look at all the original Ranges
  // that we've hydrated and find the one that matches the selection we've
  // been given.
  //
  // We do this operation on the Ranges (rather than the Fields) because
  // Fields are initialised with a VectorRange. There are subtle differences
  // between a Range and VectorRange, notably that you could provide a Range
  // of [0, -1] to represent the entire column, which would by represented by
  // [0, 9] (if the column had 10 rows) in the VectorRange. This difference
  // makes it impossible to take a value of [0, -1] that could have been saved in
  // the selection and looking up the appropriate field. So instead we use Ranges.
  const [start, end] = selection;
  return ranges.findIndex(
    (r) => isEqual(r.start, start) && isEqual(r.end, end),
  );
};
