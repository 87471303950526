import { has } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericLeaderboardTransform } from '../per-visualisation/leaderboard';
import { getDisplayValue } from '../shared/helpers';

import { pickFormat } from './helpers';

const leaderboardTransform = (
  payload,
  { reverseSort } = {},
  timezone,
  initialContext = {},
) => {
  if (!has(payload, 'values[0]')) {
    return {};
  }

  const items = payload.values.map(([label, value]) => ({
    label: getDisplayValue(label),
    value,
  }));
  const { metric_type: metricType, metric_unit: metricUnit } = payload;

  const visualisationConfig = {
    items,
    format: pickFormat(metricType),
  };

  if (metricUnit) {
    visualisationConfig.unit = metricUnit;
  }

  if (reverseSort) {
    visualisationConfig.items = visualisationConfig.items.reverse();
  }

  return { ...initialContext, ...visualisationConfig };
};

const transform = composeTransformers(
  leaderboardTransform,
  genericLeaderboardTransform,
);

export { transform };
