const getDefaultConfig = () => {
  return {
    type: 'leaderboard',
    reverseSort: false,
  };
};

const isValid = (cfg) => {
  const { config: { xAxis, yAxis } = {} } = cfg;

  return !!(xAxis && yAxis);
};

const configMappingRules = {
  line: ['xAxis', 'yAxis', 'numberFormat'],
  column: ['xAxis', 'yAxis', 'numberFormat'],
  bar: ['xAxis', 'yAxis', 'numberFormat'],
  number: ['yAxis', 'numberFormat'],
  geckometer: ['yAxis', 'numberFormat'],
};

export default { getDefaultConfig, isValid, configMappingRules };
