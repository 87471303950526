import { chain, isArray, isEmpty, isFinite } from 'lodash';

import { getSelectedCells } from '../../spreadsheet-helpers';

function transform(
  { cells },
  {
    indicators: { thresholds: originalThresholds = [] } = {},
    statusIndicator,
  } = {},
  __,
  initialContext = {},
) {
  const context = { ...initialContext };
  const { format } = initialContext;

  const getValue = (value) => {
    if (!isArray(value)) {
      return value;
    }

    const formatPercent = format === 'percent' ? (v) => v * 100 : (v) => v;

    return chain(cells)
      .thru((cells) => getSelectedCells(cells, [value])) // eslint-disable-line @typescript-eslint/no-shadow
      .head()
      .map('value')
      .last()
      .thru(parseFloat)
      .thru(formatPercent)
      .value();
  };

  if (originalThresholds.length) {
    const thresholds = originalThresholds.map(getValue);
    context.indicators = {
      thresholds,
    };
  }

  if (statusIndicator) {
    const mappedStatusIndicator = {};
    const lowerThreshold = parseFloat(getValue(statusIndicator.lowerThreshold));
    const upperThreshold = parseFloat(getValue(statusIndicator.upperThreshold));

    if (isFinite(lowerThreshold)) {
      mappedStatusIndicator.lowerThreshold = lowerThreshold;
    }

    if (isFinite(upperThreshold)) {
      mappedStatusIndicator.upperThreshold = upperThreshold;
    }

    if (!isEmpty(mappedStatusIndicator)) {
      mappedStatusIndicator.positiveDirection =
        statusIndicator.positiveDirection;
      context.statusIndicator = mappedStatusIndicator;
    }
  }

  return context;
}

export { transform };
