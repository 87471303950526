export interface ImportedFilePayload {
  uuid: string; // The geckoboard ID for the file
  spreadsheetUrl?: string; // Used for tracking linked spreadsheets
}
export interface HostedPayload {
  id?: string; // The ID for the file in its external service
  serviceAccountId?: string;
  spreadsheetUrl?: string;
}
export type FilePayload = ImportedFilePayload | HostedPayload;

export type ServiceAccount = {
  id: number;
  name: string;
  service: {
    auth_type: string;
  };
};

export type Service = {
  name: string;
  serviceAccounts: ServiceAccount[];
};

type LinkedFile = {
  id: string;
  title: string;
  slug: 'hostedexcel';
  spreadsheetUrl: string;
  auth_type: null;
};

type HostedFile = {
  id: string;
  title: string;
  slug: 'gsheets' | 'dropbox' | 'onedrivebusiness' | 'sharepoint';
  serviceAccountId: string;
  auth_type: string;
};

export type ImportedFile = LinkedFile | HostedFile;

export type ErrorType = 'generic' | 'accountConnectionFailed';

export const ERROR_TYPES: Record<string, ErrorType> = {
  GENERIC: 'generic',
  ACCOUNT_CONNECTION_FAILED: 'accountConnectionFailed',
};
