import { QueryClient } from 'react-query';

/**
 * Returns the react-query client for our app.
 *
 * By default react-query tries to keep data as up-to-date as possible.
 * So it will do background refreshes of data at certain intervals and
 * on certain events. For example if the user switches to a different
 * browser tab and then switches back, react-query will refetch all
 * queries it thinks are stale.
 *
 * To be safe we want to keep our API logic as similar as possible to
 * the current paradigm so we'll remove some of these behaviours by
 * overrideing a few default options.
 *
 * @returns {QueryClient}
 */
const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // Stop react-query from refetching when window is refocussed
        refetchOnWindowFocus: false,

        // Stop react-query from refetching when a faulty network
        // connection recovers
        refetchOnReconnect: false,

        // Stop react-query from retrying 3 times before returning errors
        retry: false,
      },
    },
  });
};

export default getQueryClient;
