const METHODS = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT', 'PATCH'];
const SAFE_METHODS = ['GET', 'HEAD'];

function getCSRFToken() {
  const hasWindow =
    typeof window === 'object' && window.window === window && window;
  if (!hasWindow || !window.document) {
    return undefined;
  }

  const metaToken =
    window.document.querySelector('meta[name="csrf-token"]') || {};
  return metaToken.content;
}

function applyCSRF(opts) {
  const { method = 'GET' } = opts || {};
  if (typeof __DEV__ !== 'undefined' && __DEV__ && !METHODS.includes(method)) {
    throw new Error(`wrong HTTP method name: ${method}`);
  }

  if (!SAFE_METHODS.includes(method)) {
    const CSRFToken = getCSRFToken();
    if (CSRFToken) {
      return {
        ...opts,
        headers: {
          ...opts.headers,
          'X-CSRF-Token': CSRFToken,
        },
      };
    }
  }

  return opts;
}

export { applyCSRF, getCSRFToken };
