import { clone, has, isArray, pick, set } from 'lodash';

import { applyAndMerge } from '../../../lib/config-mapper';

const DEFAULT_AGGREGATE = 'sum';
const getDefaultQuery = (fieldGroups) => {
  if (!has(fieldGroups, 'string[0]')) {
    throw new Error(
      'Your dataset does not contain any string fields, which are required for a leaderboard.',
    );
  }

  let metric;
  let aggregate = DEFAULT_AGGREGATE;

  if (has(fieldGroups, 'numeric[0]')) {
    metric = fieldGroups.numeric[0].key;
  } else {
    aggregate = 'count';
  }

  return {
    aggregate,
    label: fieldGroups.string[0].key,
    metric,
    slice: 30,
  };
};

const ensureConfig = (currentConfig) => {
  const config = pick(
    currentConfig,
    'title',
    'type',
    'dataSetId',
    'reverseSort',
    'wrap',
    'juno',
  );

  return {
    ...config,
    numberFormat: !isArray(currentConfig.numberFormat)
      ? currentConfig.numberFormat
      : {},
    wrap: config.wrap ?? undefined,
  };
};

const updateQuery = (currentQuery, path, value) => {
  const newQuery = clone(currentQuery);

  if (path === 'metric') {
    if (value === 'aggregate:count') {
      newQuery._aggregate = currentQuery.aggregate;
      newQuery.aggregate = 'count';
      newQuery.metric = undefined;
      return newQuery;
    }

    delete newQuery._aggregate;
    newQuery.aggregate =
      currentQuery._aggregate || currentQuery.aggregate || DEFAULT_AGGREGATE;
  }

  return set(newQuery, path, value);
};

const MULTI_METRICS_MAPPINGS = [
  ['metrics[0].field', null, 'metric'],
  [
    'metrics[0].aggregate',
    (aggregate) => (aggregate === 'latest' ? DEFAULT_AGGREGATE : aggregate),
    'aggregate',
  ],
  ['filters'],
];

const NUMBER_AND_GECKOMETER_MAPPING = [
  // Use field which always exists to set a default aggregate
  ['field', () => DEFAULT_AGGREGATE, 'aggregate'],
  ['field', null, 'metric'],
  // Override if one is actually set
  ['aggregate'],
  ['filters'],
];

const QUERY_MAPPING_RULES = {
  geckometer: NUMBER_AND_GECKOMETER_MAPPING,
  number: NUMBER_AND_GECKOMETER_MAPPING,
  line: MULTI_METRICS_MAPPINGS,
  column: MULTI_METRICS_MAPPINGS,
  bar: MULTI_METRICS_MAPPINGS,
  table: [['filters']],
};

const mapQuery = (fromType, ...rest) => {
  const query = applyAndMerge(QUERY_MAPPING_RULES[fromType], ...rest);

  if (!query.aggregate) {
    query.aggregate = DEFAULT_AGGREGATE;
  }

  // Remove metric not compatible with count
  if (query.aggregate === 'count') {
    query._aggregate = DEFAULT_AGGREGATE;
    delete query.metric;
  }

  return query;
};

const visualisationHelpers = {
  ensureConfig,
  getDefaultQuery,
  updateQuery,
  mapQuery,
};

export default visualisationHelpers;
