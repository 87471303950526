export const UPLOAD_LOGO_STATE_STARTED = 'Customize:UPLOAD_LOGO_STATE_STARTED';
export const UPLOAD_LOGO_STATE_SUCCEEDED =
  'Customize:UPLOAD_LOGO_STATE_SUCCEEDED';
export const UPLOAD_LOGO_STATE_FAILED = 'Customize:UPLOAD_LOGO_STATE_FAILED';

export const UPLOAD_CUSTOM_CSS_STATE_STARTED =
  'Customize:UPLOAD_CUSTOM_CSS_STATE_STARTED';
export const UPLOAD_CUSTOM_CSS_STATE_SUCCEEDED =
  'Customize:UPLOAD_CUSTOM_CSS_STATE_SUCCEEDED';
export const UPLOAD_CUSTOM_CSS_STATE_FAILED =
  'Customize:UPLOAD_CUSTOM_CSS_STATE_FAILED';
