import { isFinite } from 'lodash';

const getValueBasedOnFormat = (value, format) => {
  if (!isFinite(value)) {
    return undefined;
  }

  if (format === 'percent') {
    return value / 100;
  }

  return value;
};

function transform(
  payload,
  { progressIndicator } = {},
  timezone,
  initialContext = {},
) {
  const newContext = {};

  if (progressIndicator) {
    const { format } = initialContext;
    const targetValue = getValueBasedOnFormat(
      progressIndicator.targetValue,
      format,
    );

    if (isFinite(targetValue)) {
      const progressStartingValue = getValueBasedOnFormat(
        progressIndicator.startingValue || 0,
        format,
      );

      newContext.progressIndicator = {
        startingValue: progressStartingValue,
        targetValue,
      };
    }
  }

  return { ...initialContext, ...newContext };
}

export { transform };
