export default [
  {
    id: '4a207eef-4f1e-4613-9373-406de607779c',
    title: 'Cumulative sales this month',
    description: 'Total value of won deals.',
    category: 'Revenue',
    type: 'line',
    icon: {
      type: 'icon',
      value: 'line',
    },
    queries: [
      {
        select: [
          {
            resource: 'deals',
            path: 'won_time',
          },
          {
            aggregate: 'sum',
            custom: 'value_won_deals',
            path: 'value',
            resource: 'deals',
          },
        ],
        group_by: [{ bucket_by: 'day', path: 'won_time', resource: 'deals' }],
        filter: [
          {
            operands: [1, 'month'],
            operator: 'timespan',
            path: 'won_time',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '98affa05-e8e4-4bd0-91bf-601f905e00ce',
    title: 'Revenue this month',
    description: 'Value of won deals this month.',
    category: 'Revenue',
    type: 'number',
    icon: {
      type: 'text',
      value: '123',
    },
    queries: [
      {
        select: [
          {
            aggregate: 'sum',
            custom: 'value_won_deals',
            path: 'value',
            resource: 'deals',
          },
        ],
        filter: [
          {
            operands: [1, 'month'],
            operator: 'timespan',
            path: 'won_time',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: 'a5187f7a-9436-48e4-b755-23c3ff07e2ea',
    title: 'Deal of the month',
    description: 'Most valuable deal of the month.',
    category: 'Revenue',
    type: 'number',
    icon: {
      type: 'text',
      value: '123',
    },
    queries: [
      {
        select: [
          {
            aggregate: 'max',
            custom: 'value_won_deals',
            path: 'value',
            resource: 'deals',
          },
        ],
        filter: [
          {
            operands: [1, 'month'],
            operator: 'timespan',
            path: 'won_time',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '4310eae6-3d91-4b7c-8bfa-e26a24246926',
    title: 'Pipeline overview',
    description: 'Number of open deals in the different stages of a pipeline.',
    category: 'Deals',
    type: 'bar',
    icon: {
      type: 'icon',
      value: 'bar',
    },
    queries: [
      {
        select: [
          {
            resource: 'deals',
            path: 'stage.name',
          },
          {
            resource: 'deals',
            aggregate: 'count',
            custom: 'open_deals',
          },
        ],
        group_by: [{ resource: 'deals', path: 'stage_id' }],
        order_by: [{ resource: 'deals', path: 'stage.name', order: 'asc' }],
        filter: [
          {
            resource: 'deals',
            path: 'add_time',
            operands: [30, 'day'],
            operator: 'timespan',
          },
          {
            resource: 'deals',
            operands: ['open'],
            operator: 'in',
            path: 'status',
            bound_to_metric: true,
          },
        ],
        limit: 20,
      },
    ],
  },
  {
    id: '63abb0e1-2391-46b8-9442-3e2cadd2d352',
    title: 'Deals progress by value',
    description: 'Value of open deals in the different stages of a pipeline',
    category: 'Deals',
    type: 'bar',
    icon: {
      type: 'icon',
      value: 'bar',
    },
    queries: [
      {
        select: [
          { path: 'stage.name', resource: 'deals' },
          {
            path: 'value',
            resource: 'deals',
            custom: 'value_open_deals',
            aggregate: 'sum',
          },
        ],
        group_by: [{ path: 'stage_id', resource: 'deals' }],
        order_by: [{ path: 'stage.name', resource: 'deals', order: 'asc' }],
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'add_time',
            resource: 'deals',
          },
          {
            bound_to_metric: true,
            operands: ['open'],
            operator: 'in',
            path: 'status',
            resource: 'deals',
          },
        ],
        limit: 20,
      },
    ],
  },
  {
    id: '85da0b7a-ab05-11ea-bb37-0242ac130002',
    title: 'New deal overview',
    description: 'List of recently created deals with value, stage and owner.',
    category: 'Deals',
    type: 'table',
    icon: {
      type: 'icon',
      value: 'table',
    },
    config: {
      detailsMetricResource: 'deals',
    },
    queries: [
      {
        select: [
          { resource: 'deals', path: 'title' },
          { resource: 'deals', path: 'value' },
          { resource: 'deals', path: 'stage.name' },
          { resource: 'deals', path: 'user.name' },
        ],
        filter: [],
        order_by: [{ resource: 'deals', path: 'add_time', order: 'desc' }],
        limit: 20,
      },
    ],
  },
  {
    id: '75eedcac-2ae4-4f3d-a767-c86718614914',
    title: 'Average value of started deals',
    description: 'Value of started deals averaged over a time period.',
    category: 'Deals',
    type: 'number',
    icon: {
      type: 'text',
      value: '$100',
    },
    queries: [
      {
        select: [
          {
            aggregate: 'avg',
            custom: 'value_started_deals',
            path: 'value',
            resource: 'deals',
          },
        ],
        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'add_time',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '491ad743-bb4d-451d-acd7-b128b7087575',
    title: 'Started vs Closed deals',
    description: 'Comparing numbers of deals started and deals closed.',
    category: 'Deals',
    type: 'column',
    icon: {
      type: 'icon',
      value: 'column',
    },
    queries: [
      {
        select: [
          {
            path: 'add_time',
            resource: 'deals',
          },
          {
            aggregate: 'count',
            custom: 'open_deals',
            multi_index: 0,
            resource: 'deals',
          },
        ],
        group_by: [
          {
            bucket_by: 'day',
            path: 'add_time',
            resource: 'deals',
          },
        ],
        order_by: [{ order: 'asc', path: 'add_time', resource: 'deals' }],
        filter: [
          {
            bound_to_metric: true,
            operands: ['open'],
            operator: 'in',
            path: 'status',
            resource: 'deals',
          },
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'add_time',
            resource: 'deals',
          },
        ],
      },
      {
        select: [
          {
            path: 'close_time',
            resource: 'deals',
          },
          {
            aggregate: 'count',
            custom: 'close_deals',
            multi_index: 1,
            resource: 'deals',
          },
        ],
        group_by: [
          {
            bucket_by: 'day',
            path: 'close_time',
            resource: 'deals',
          },
        ],
        order_by: [{ order: 'asc', path: 'close_time', resource: 'deals' }],
        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'close_time',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '8cbe022f-cc35-49ba-89be-0ba41787fae3',
    title: 'Deals won by owner',
    description: 'Number of won deals split by owner.',
    category: 'Deals',
    type: 'column',
    icon: {
      type: 'icon',
      value: 'column',
    },
    queries: [
      {
        select: [
          {
            path: 'won_time',
            resource: 'deals',
          },
          {
            path: 'user.name',
            resource: 'deals',
          },
          {
            aggregate: 'count',
            custom: 'won_deals',
            resource: 'deals',
          },
        ],
        group_by: [
          { bucket_by: 'day', path: 'won_time', resource: 'deals' },
          {
            path: 'user_id.value',
            resource: 'deals',
          },
        ],
        order_by: [
          {
            order: 'asc',
            path: 'won_time',
            resource: 'deals',
          },
        ],
        filter: [
          {
            operands: [30, 'day'],
            operator: 'timespan',
            path: 'won_time',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: '9e63c277-bf87-419f-a46b-2ef221766099',
    title: 'Value won by owner',
    description: 'Value of won deals split by owner.',
    category: 'Deals',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        select: [
          {
            path: 'user.name',
            resource: 'deals',
          },
          {
            aggregate: 'sum',
            custom: 'value_won_deals',
            path: 'value',
            resource: 'deals',
          },
        ],
        group_by: [{ path: 'user_id.value', resource: 'deals' }],
        order_by: [
          {
            custom: 'value_won_deals',
            order: 'desc',
            path: 'value',
            resource: 'deals',
          },
        ],
        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'won_time',
            resource: 'deals',
          },
        ],
        limit: 20,
      },
    ],
  },
  {
    id: '50056f68-d997-41f5-a39e-fbf235f94ddd',
    title: 'Recent won deals',
    description: 'Value won and contact for recent deals.',
    category: 'Deals',
    type: 'table',
    icon: {
      type: 'icon',
      value: 'table',
    },
    queries: [
      {
        select: [
          {
            path: 'person.name',
            resource: 'deals',
          },
          {
            aggregate: 'sum',
            custom: 'value_won_deals',
            path: 'value',
            resource: 'deals',
          },
        ],
        group_by: [{ path: 'person_id.value', resource: 'deals' }],
        order_by: [
          {
            order: 'asc',
            path: 'person.name',
            resource: 'deals',
          },
        ],
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'won_time',
            resource: 'deals',
          },
        ],
        limit: 20,
      },
    ],
  },
  {
    id: '08a127fb-8276-4823-a755-85c07425f918',
    title: 'Lost vs won deals',
    description: 'Comparing number of lost and won deals over time.',
    category: 'Deals',
    type: 'line',
    icon: {
      type: 'icon',
      value: 'line',
    },
    queries: [
      {
        select: [
          {
            path: 'won_time',
            resource: 'deals',
          },
          {
            aggregate: 'count',
            custom: 'won_deals',
            multi_index: 1,
            resource: 'deals',
          },
        ],
        group_by: [{ bucket_by: 'day', path: 'won_time', resource: 'deals' }],

        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'won_time',
            resource: 'deals',
          },
        ],
      },
      {
        select: [
          {
            path: 'lost_time',
            resource: 'deals',
          },
          {
            aggregate: 'count',
            custom: 'lost_deals',
            multi_index: 0,
            resource: 'deals',
          },
        ],
        group_by: [{ bucket_by: 'day', path: 'lost_time', resource: 'deals' }],

        filter: [
          {
            operands: [14, 'day'],
            operator: 'timespan',
            path: 'lost_time',
            resource: 'deals',
          },
        ],
      },
    ],
  },
  {
    id: 'ca927708-ce35-4757-b1a1-e9582d42ec53',
    title: 'Activities added',
    description: 'Number of activities added by type.',
    category: 'Activities',
    type: 'bar',
    icon: {
      type: 'icon',
      value: 'bar',
    },
    queries: [
      {
        select: [
          {
            resource: 'activities',
            path: 'type.name',
          },
          {
            resource: 'activities',
            aggregate: 'count',
            custom: 'created_activities',
          },
        ],
        group_by: [
          {
            resource: 'activities',
            path: 'type',
          },
        ],
        order_by: [
          {
            resource: 'activities',
            path: 'type.name',
            order: 'asc',
          },
        ],
        filter: [
          {
            resource: 'activities',
            path: 'add_time',
            operands: [1, 'month'],
            operator: 'timespan',
          },
        ],
        limit: 20,
      },
    ],
  },
  {
    id: '45b2c6a6-7609-493f-a73d-2fbeb57c15ab',
    title: 'Activities completed',
    description: 'Number of activities completed by type.',
    category: 'Activities',
    type: 'bar',
    icon: {
      type: 'icon',
      value: 'bar',
    },
    queries: [
      {
        select: [
          {
            resource: 'activities',
            path: 'type.name',
          },
          {
            resource: 'activities',
            aggregate: 'count',
            custom: 'completed_activities',
          },
        ],
        group_by: [
          {
            resource: 'activities',
            path: 'type',
          },
        ],
        order_by: [
          {
            resource: 'activities',
            path: 'type.name',
            order: 'asc',
          },
        ],
        filter: [
          {
            resource: 'activities',
            path: 'marked_as_done_time',
            operands: [1, 'month'],
            operator: 'timespan',
          },
        ],
        limit: 20,
      },
    ],
  },
  {
    id: 'd4765f2f-4093-498b-9fa2-b619a006857c',
    title: 'Activities completed by owner',
    description: 'Number of activities completed by owner.',
    category: 'Activities',
    type: 'bar',
    icon: {
      type: 'icon',
      value: 'bar',
    },
    queries: [
      {
        select: [
          {
            path: 'user.name',
            resource: 'activities',
          },
          {
            resource: 'activities',
            aggregate: 'count',
            custom: 'completed_activities',
          },
        ],
        group_by: [
          {
            path: 'user_id',
            resource: 'activities',
          },
        ],
        order_by: [
          {
            order: 'asc',
            path: 'user.name',
            resource: 'activities',
          },
        ],
        filter: [
          {
            resource: 'activities',
            path: 'marked_as_done_time',
            operands: [1, 'day'],
            operator: 'timespan',
          },
        ],
        limit: 20,
      },
    ],
  },
  {
    id: '6feb2ba8-a074-4116-930e-1ae0c2cd78cf',
    title: 'Calls added recently',
    description: 'Number of calls added in a recent time period.',
    category: 'Activities',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        select: [
          {
            aggregate: 'count',
            custom: 'created_activities',
            resource: 'activities',
          },
        ],
        filter: [
          {
            operands: [7, 'day'],
            operator: 'timespan',
            path: 'add_time',
            resource: 'activities',
          },
          {
            operands: ['call'],
            operator: 'in',
            path: 'type',
            resource: 'activities',
          },
        ],
      },
    ],
  },
  {
    id: 'cf2d90c8-43ea-44a7-b26e-7b830db34842',
    title: 'Top creators',
    description: 'Who is opening the most deals.',
    category: 'Team',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        select: [
          {
            resource: 'deals',
            path: 'user.name',
          },
          {
            resource: 'deals',
            aggregate: 'count',
            custom: 'created_deals',
          },
        ],
        group_by: [
          {
            resource: 'deals',
            path: 'user_id.value',
          },
        ],
        order_by: [
          {
            resource: 'deals',
            custom: 'created_deals',
            order: 'desc',
          },
        ],
        filter: [
          {
            resource: 'deals',
            path: 'add_time',
            operands: [7, 'day'],
            operator: 'timespan',
          },
        ],
      },
    ],
  },
  {
    id: '0c8851a5-a31e-48cd-a4a5-43049dfd8c02',
    title: 'Top closers',
    description: 'Who is closing the most deals.',
    category: 'Team',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        select: [
          {
            path: 'user.name',
            resource: 'deals',
          },
          {
            aggregate: 'count',
            custom: 'close_deals',
            resource: 'deals',
          },
        ],
        group_by: [
          {
            path: 'user_id.value',
            resource: 'deals',
          },
        ],
        order_by: [
          {
            custom: 'close_deals',
            order: 'desc',
            resource: 'deals',
          },
        ],
        filter: [
          {
            resource: 'deals',
            path: 'close_time',
            operands: [7, 'day'],
            operator: 'timespan',
          },
        ],
      },
    ],
  },
];
