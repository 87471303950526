import immutableUpdate from 'immutability-helper';
import { findIndex } from 'lodash';

import { trackEvent } from '@Tracking';
import { widgetConfigEdited, widgetCreated } from '@Tracking/events';

import { getStateToRestore, getStateToSave } from '../../actions/helpers';
import { setDashboard } from '../../dashboard/actions/dashboard-actions';
import * as grapqhlDashboardHelpers from '../../dashboard/graphql-dashboard-helpers';
import { translate } from '../../lib/config-mapper';
import DebouncePromise from '../../lib/debounce-promise';
import { redirect } from '../../lib/global';
import * as pathHelpers from '../../lib/path';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { track, trackSwitchVisualisation } from '../../lib/tracker';
import {
  createWithAutoPosition as create,
  getConfig,
  update,
} from '../../lib/widget-service';
import widgetStorage from '../../lib/widget-storage';
import { getDashboard } from '../../services/management-service';
import { deserialize, serialize } from '../../universal/serializers';
import { salesforce } from '../../universal/services';
import { getHumanReadableName } from '../../visualisation/helpers';
import * as SalesforceConfigConstants from '../constants/salesforce-config-constants';
import REFRESH_INTERVALS, {
  ONE_HOUR,
} from '../constants/salesforce-refresh-intervals';
import getVisualisationHelpers from '../get-visualisation-helpers';
import { getSeries, isValidInterval, toRenderableState } from '../helpers';
import { getLastConfigForType } from '../reducer';
import { TABULAR_REPORT } from '../report-types';

import { getSalesforceInstrument } from './salesforce-instrument-helpers';

export const DEFAULT_TYPE = 'bar';
export const DEFAULT_TABULAR_TYPE = 'table';
const CACHE_PREFIX = 'widgetData:';

export const WIDGET_CREATION_ERROR =
  'An error occurred while we were saving your widget';
export const WIDGET_FETCH_ERROR =
  'An error occurred while we were loading your widget';
export const ERROR = 'Salesforce Error:';
export const DATA_FETCH_ERROR =
  'An error occurred while we were loading the data from Salesforce';
export const NO_GROUPS_OR_AGGREGATIONS_ERROR =
  'We are unable to create a widget using the chosen report because it is not grouped. Please edit the report in Salesforce to add a grouping.';
export const NO_GROUPS_OR_AGGREGATIONS_LINK =
  'https://support.geckoboard.com/hc/en-us/articles/217321107-Salesforce-Reporting-define-and-create-a-report-in-Salesforce-and-display-it-on-Geckoboard';
export const NO_GROUPS_OR_AGGREGATIONS_LINK_TEXT = 'Learn more.';

export class ErrorWithLink extends Error {
  constructor(message, link, linkText) {
    super(message);
    this.name = 'ErrorWithLink';
    this.message = message;
    this.link = link;
    this.linkText = linkText;
  }
}

export const set = createAction(SalesforceConfigConstants.UPDATE_CONFIG);
export const setStateProps = createAction(
  SalesforceConfigConstants.SET_STATE_PROPS,
);
export const initConfigLoading = createAction(
  SalesforceConfigConstants.INIT_CONFIG_LOADING,
);
export const initConfigSuccessful = createAction(
  SalesforceConfigConstants.INIT_CONFIG_SUCCESSFUL,
);
export const initConfigError = createErrorAction(
  SalesforceConfigConstants.INIT_CONFIG_ERROR,
);
export const dataFetchStart = createAction(
  SalesforceConfigConstants.DATA_FETCH_START,
);
export const dataFetchSuccessful = createAction(
  SalesforceConfigConstants.DATA_FETCH_SUCCESSFUL,
);
export const dataFetchError = createErrorAction(
  SalesforceConfigConstants.DATA_FETCH_ERROR,
);
export const widgetConfigFetchFailed = createErrorAction(
  SalesforceConfigConstants.WIDGET_CONFIG_FETCH_FAILED,
);
export const widgetCreationStart = createErrorAction(
  SalesforceConfigConstants.WIDGET_CREATION_START,
);
export const widgetCreationFailed = createErrorAction(
  SalesforceConfigConstants.WIDGET_CREATION_FAILED,
);
export const widgetUpdateStart = createErrorAction(
  SalesforceConfigConstants.WIDGET_UPDATE_START,
);
export const doSwitchVisualisationType = createAction(
  SalesforceConfigConstants.SWITCH_VISUALISATION_TYPE,
);
export const setConfig = set; // Alias `set` to `setConfig` to maintain compatibility with the other integrations

// Progress Goals are used on our number visualisations
export const setProgressGoal = createAction(
  SalesforceConfigConstants.SET_PROGRESS_GOAL,
);

// Goals are used on our line, column and bar visualisations
export const setGoal = createAction(SalesforceConfigConstants.SET_GOAL);

export const toggleTableHeaders = createAction(
  SalesforceConfigConstants.TOGGLE_TABLE_HEADERS,
);

let debouncedFetchDataRequest;
export const _fetchPreviewData = () => async (dispatch, getState) => {
  try {
    debouncedFetchDataRequest =
      debouncedFetchDataRequest || new DebouncePromise();

    dispatch(dataFetchStart());

    const {
      salesforce: { uuid, config },
    } = getState();
    const data = await debouncedFetchDataRequest.push(
      salesforce.fetchData(uuid, config),
    );

    dispatch(dataFetchSuccessful(data));
  } catch (error) {
    const message = `${DATA_FETCH_ERROR} (${error.message}).`;
    dispatch(dataFetchError(error, { message }));
  }
};

export const _initialise =
  (uuid, configOptions, { refreshInterval, refresh } = {}) =>
  async (dispatch) => {
    dispatch(initConfigLoading(uuid));

    try {
      const meta = await salesforce.getMeta(uuid, refresh);
      const { groups = [], aggregations = [], type: reportType } = meta;
      const visualisationType =
        reportType === TABULAR_REPORT ? DEFAULT_TABULAR_TYPE : DEFAULT_TYPE;

      if (
        reportType !== TABULAR_REPORT &&
        (!groups.length || !aggregations.length)
      ) {
        // we need to set the meta data to show the report title in the breadcrumbs
        dispatch(initConfigSuccessful({ meta }));

        throw new ErrorWithLink(
          NO_GROUPS_OR_AGGREGATIONS_ERROR,
          NO_GROUPS_OR_AGGREGATIONS_LINK,
          NO_GROUPS_OR_AGGREGATIONS_LINK_TEXT,
        );
      }

      await salesforce.refreshReport(uuid);
      const config =
        configOptions ||
        getVisualisationHelpers(visualisationType).getDefaultConfig();
      const validRefreshInterval = isValidInterval(
        REFRESH_INTERVALS,
        refreshInterval,
      )
        ? refreshInterval
        : ONE_HOUR;

      dispatch(
        initConfigSuccessful({
          meta,
          config,
          refreshInterval: validRefreshInterval,
        }),
      );

      dispatch(_fetchPreviewData());
    } catch (error) {
      const { message, link, linkText } = error;
      dispatch(initConfigError(error, { message, link, linkText }));
    }
  };

export const initConfig =
  (uuid, dashboardId, isOwnedByRoadie) => async (dispatch) => {
    try {
      if (!isOwnedByRoadie) {
        // It appears the dashboard isn't used, but keep it
        // here just in case on non-RODs.
        const dashboard = await getDashboard(dashboardId);
        dispatch(setDashboard(dashboard));
      }
      dispatch(_initialise(uuid));
    } catch (error) {
      const { message, link, linkText } = error;
      dispatch(initConfigError(error, { message, link, linkText }));
    }
  };

export const updateConfig =
  (updates = {}) =>
  (dispatch) => {
    dispatch(set(updates));
    dispatch(_fetchPreviewData());
  };

export const enableMultiSeries = () => (dispatch, getState) => {
  const { salesforce: state } = getState();
  const updates = { series: getSeries(state), savedSeries: undefined };

  dispatch(updateConfig(updates));
};

export const disableMultiSeries = () => (dispatch, getState) => {
  const { salesforce: state } = getState();
  const { config: { series } = {} } = state;
  const updates = { series: undefined, savedSeries: series };

  dispatch(updateConfig(updates));
};

export const restoreState = (paths, defaults) => (dispatch, getState) => {
  const { salesforce: state } = getState();
  const { config = {} } = state;
  const newConfig = getStateToRestore(config, paths, defaults);

  dispatch(set(newConfig));
};

export const saveState = (paths) => (dispatch, getState) => {
  const { salesforce: state } = getState();
  const { config = {} } = state;
  const newConfig = getStateToSave(config, paths);

  dispatch(set(newConfig));
};

export const getWidgetConfig =
  (widgetOrInstrumentId, dashboardId, isOwnedByRoadie) => async (dispatch) => {
    try {
      let data;
      if (isOwnedByRoadie) {
        data = await getSalesforceInstrument(widgetOrInstrumentId);
      } else {
        const json = await getConfig(widgetOrInstrumentId);

        const { template: { type = DEFAULT_TYPE } = {} } = json.config;
        const widgetKey = json.key;
        data = deserialize('salesforce', type, json);

        dispatch(setStateProps({ widgetKey }));

        const dashboard = await getDashboard(dashboardId);
        dispatch(setDashboard(dashboard));
      }

      dispatch(
        _initialise(data.uuid, data.config, {
          refreshInterval: data.refreshInterval,
          refresh: true,
        }),
      );
    } catch (err) {
      let message = `${WIDGET_FETCH_ERROR} (${err.message}).`;

      if (err.type === 'ErrResourceNotFound') {
        message = `${err.message}`;

        dispatch(setStateProps({ showFilePicker: true }));
      }

      dispatch(widgetConfigFetchFailed(err, { message, type: err.type }));
    }
  };

export const createWidget =
  (dashboardId, type, service = 'salesforce') =>
  async (dispatch, getState) => {
    const { salesforce: state } = getState();
    const renderableState = toRenderableState(state);
    const data = {
      dashboard_id: dashboardId,
      ...serialize(service, null, renderableState),
    };
    const visualisation = getHumanReadableName(type);

    dispatch(widgetCreationStart());

    track('Salesforce new - Click Add to Dashboard', {
      properties: { visualisation },
    });

    try {
      const widget = await create(data);

      track('Widget added', {
        properties: {
          widget_id: widget.id,
          widget_type_id: widget.widget_type_id,
          service_name: widget.services[0].name,
          service_id: widget.services[0].id,
          widget_type_name: visualisation,
          visualisation,
          salesforce_version: '2016',
        },
      });

      trackEvent(
        widgetCreated({
          'Integration name': widget.services[0].name,
          'Integration slug': service,
          'Legacy widget ID': `${widget.id}`,
          Visualisation: widget.configuration.type,
          'Widget type ID': `${widget.widget_type_id}`,
        }),
      );

      // add a delay for redirecting, to wait for mixpanel tracker to be finished
      redirect(
        pathHelpers.getDashboardPath(
          dashboardId.toString(),
          widget.id,
          service,
        ),
        300,
      );
    } catch (err) {
      const message = `${WIDGET_CREATION_ERROR} (${err.message}).`;
      dispatch(widgetCreationFailed(err, { message }));
    }
  };

export const updateWidget =
  (widgetId, dashboardId, type, widgetKey) => async (dispatch, getState) => {
    const { salesforce: state } = getState();
    const renderableState = toRenderableState(state);

    const data = serialize('salesforce', null, renderableState);

    dispatch(widgetUpdateStart());

    try {
      const widget = await update(widgetId, data);

      await trackEvent(
        widgetConfigEdited({
          'Integration name': widget.service.title,
          'Integration slug': widget.service.name,
          'Legacy widget ID': `${widget.id}`,
          Visualisation: widget.config.type,
        }),
      );

      const graphqlDashboard =
        grapqhlDashboardHelpers.getDashboardFromStorage(dashboardId) || {};
      const isContainerLayout =
        grapqhlDashboardHelpers.isContainerLayout(graphqlDashboard);

      if (isContainerLayout) {
        const instrumentId =
          await grapqhlDashboardHelpers.getInstrumentIdFromWidgetKey(widgetKey);
        widgetStorage.removeItem(`instrumentData:${instrumentId}`);
      } else {
        widgetStorage.removeItem(`${CACHE_PREFIX}${widgetKey}`);
      }

      redirect(pathHelpers.getDashboardPath(dashboardId.toString(), widget.id));
    } catch (err) {
      const message = `${WIDGET_CREATION_ERROR} (${err.message}).`;

      dispatch(widgetCreationFailed(err, { message }));
    }
  };

export const switchVisualisationType = (type) => (dispatch, getState) => {
  const { salesforce: state } = getState();
  const { config: currentConfig } = state;

  if (currentConfig.type === type) {
    return;
  }

  const visualisationHelpers = getVisualisationHelpers(type);
  const defaults = visualisationHelpers.getDefaultConfig();
  const lastConfigForType = getLastConfigForType(state, type);
  const mappedValues = translate(
    visualisationHelpers.configMappingRules,
    currentConfig,
    lastConfigForType,
  );
  const config = {
    ...defaults,
    ...lastConfigForType,
    ...mappedValues,
  };

  trackSwitchVisualisation('salesforce', currentConfig.type, type);
  dispatch(doSwitchVisualisationType(config));
  dispatch(_fetchPreviewData());
};

export const toggleFilePicker = (showFilePicker) =>
  setStateProps({ showFilePicker });

export const changeFile = (uuid) => (dispatch) => {
  dispatch(setStateProps({ showFilePicker: false }));
  dispatch(_initialise(uuid));
};

export const refreshFile = () => (dispatch, getState) => {
  const {
    salesforce: { uuid, config, refreshInterval },
  } = getState();
  dispatch(_initialise(uuid, config, { refreshInterval, refresh: true }));
};

export const updateNumberFormat = (format) => (dispatch, getState) => {
  const { salesforce: state } = getState();
  const { config: { numberFormat = {} } = {} } = state;
  const newFormat = {
    ...numberFormat,
    ...format,
  };

  dispatch(set({ numberFormat: newFormat }));
};

export const changeTableColumnField =
  ({ index, value }) =>
  (dispatch, getState) => {
    const { config = {} } = getState().salesforce;
    const { numberFormat = [], columns = [] } = config;

    const newNumberFormat = [...numberFormat];
    newNumberFormat[index] = null;

    const newColumns = [...columns];
    newColumns[index] = { ...columns[index], field: value };

    dispatch(
      updateConfig({ numberFormat: newNumberFormat, columns: newColumns }),
    );
  };

export const removeTableColumn = (index) => (dispatch, getState) => {
  const { config = {} } = getState().salesforce;
  const { numberFormat = [], columns = [] } = config;

  const newColumns = [...columns.slice(0, index), ...columns.slice(index + 1)];
  let newNumberFormat;

  if (numberFormat.length) {
    // Delete numberFormat for deleted column
    newNumberFormat = [
      ...numberFormat.slice(0, index),
      ...numberFormat.slice(index + 1),
    ];
  }

  dispatch(
    updateConfig({ numberFormat: newNumberFormat, columns: newColumns }),
  );
};

export const addTableColumn = () => (dispatch, getState) => {
  const {
    config: { columns = [] } = {},
    meta: { columns: metaColumns = [] } = {},
  } = getState().salesforce;

  const lastColumn = columns[columns.length - 1];
  const lastFieldIndex = findIndex(metaColumns, { name: lastColumn.field });
  // get next field to use as columns (and go back to start if we're already at end of array)
  const nextFieldIndex = (lastFieldIndex + 1) % metaColumns.length;
  const nextField = metaColumns[nextFieldIndex];

  const newColumns = [...columns, { field: nextField.name }];

  dispatch(updateConfig({ columns: newColumns }));
};

export const reorderTableColumn =
  ({ sourceIndex, targetIndex }) =>
  (dispatch, getState) => {
    const { config } = getState().salesforce;
    const { numberFormat = [], columns = [] } = config;
    const column = columns[sourceIndex];
    const currentFormat = numberFormat[sourceIndex] || null;

    const newColumns = immutableUpdate(columns, {
      $splice: [
        [sourceIndex, 1],
        [targetIndex, 0, column],
      ],
    });
    const newNumberFormat = immutableUpdate(numberFormat, {
      $splice: [
        [sourceIndex, 1],
        [targetIndex, 0, currentFormat],
      ],
    });

    dispatch(
      updateConfig({ columns: newColumns, numberFormat: newNumberFormat }),
    );
  };
