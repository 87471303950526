import * as colors from '../../../src/ui/style/colors.js';

const neutralVisualisationColor = '#f5f5f5';

export default {
  '--background-color': '#f0f0f0',
  '--title-color': '#8b8b8b',
  '--text-color': '#666666',
  '--secondary-text-color': '#828282',
  '--widget-background-color': '#ffffff',
  '--widget-active-background-color': '#ffffff',
  '--chart-axis-color': 'rgba(0, 0, 0, 0.13)',
  '--chart-axis-color-strong': 'rgba(0, 0, 0, 0.2)',
  '--selected-widget-header-color': '#8b9092',
  '--dragger-color':
    'rgba(0, 0, 0, 0.05)' /* This is the guide colour when dragging a widget */,
  '--widget-loader-color':
    'rgba(0, 0, 0, 0.08)' /* Color of the loading indicator in the visualisation */,
  '--widget-shimmer-color': 'rgba(0, 0, 0, 0.09)',
  '--widget-drag-blend-mode': 'normal',
  '--widget-drag-ghost-color': 'rgba(113, 119, 121, 0.5)',
  '--dashboard-text-color': 'rgba(0, 0, 0, 0.86)',
  '--goal-pill-background-color': 'rgba(0, 0, 0, 0.12)',
  '--positive-color': colors.Positive,
  '--negative-color': colors.Negative,

  // visualisation colors
  '--primary-visualisation-color': '#3fb4f9',
  '--neutral-color': neutralVisualisationColor,

  /* Geckometer */
  '--gauge-needle-color': '#8b9092',

  '--chart-threshold-color': neutralVisualisationColor,
  '--number-threshold-text-color': '#ffffff',

  /* Map widget */
  '--map-background-color': '#4a4a4a',
  '--map-single-point-color': '#b940ff',

  /* Geckobox */
  '--geckobox-grid-color': '#000000',

  /* Mobile footer */
  '--mobile-footer-background-color': colors.Black100,
  '--mobile-footer-text-color': '#FFFFFF',
};
