import { get } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericNumberTransform } from '../per-visualisation/number';
import { getDisplayValue } from '../shared/helpers';

import {
  getColumnFormat,
  getImageType,
  getImageValue,
  getValuesFromRow,
  UNIT_DURATIONS,
} from './helpers';

const sortDesc = ({ value: valueA }, { value: valueB }) => valueB - valueA;
const sortAsc = ({ value: valueA }, { value: valueB }) => valueA - valueB;

const leaderboardTransform = (payload, config) => {
  const data = get(payload, 'data.bauhausDataRequest.data');

  if (!data) {
    return {};
  }

  const { rows, columns } = data;

  const format = getColumnFormat(columns.slice(1));

  const items = rows.map(({ values }) => {
    const leaderboardEntry = getValuesFromRow(values, data.columns);
    const value =
      format.format === 'duration'
        ? leaderboardEntry[1] / UNIT_DURATIONS[format.unit]
        : leaderboardEntry[1];

    return {
      label: getDisplayValue(leaderboardEntry[0]),
      image: getImageValue(leaderboardEntry[0]),
      value,
      ...format,
    };
  });

  // We only expect a single sort_by for leaderboard
  const sortBy = get(config, 'bauhaus.sort_by[0]');

  if (sortBy?.direction === 'ASC') {
    items.sort(sortAsc);
  } else {
    items.sort(sortDesc);
  }

  return { imageType: getImageType(columns[0], config), items };
};

const transform = composeTransformers(
  leaderboardTransform,
  genericNumberTransform,
);

const applyConfig = (data, config) => {
  return { ...data, numberFormat: config.numberFormat };
};

export { applyConfig, transform };
