class DebouncePromise {
  push(promise) {
    this.current = promise;

    return new Promise((resolve, reject) => {
      promise.then(
        (res) => {
          if (this.current === promise) {
            resolve(res);
            this.current = null;
          }
        },
        (err) => {
          if (this.current === promise) {
            reject(err);
            this.current = null;
          }
        },
      );
    });
  }
}

export default DebouncePromise;
