import { composeTransformers } from '../compose-transformers';

import { getColumnFormat, getDatapointValue, getDataResponse } from './helpers';

const getColumnsOrder = (template) => {
  const orderedFields = [{ slot: 'content', fieldID: template.content.id }];
  if (template.icon) {
    orderedFields.push({ slot: 'icon', fieldID: template.icon.id });
  }
  if (template.date) {
    orderedFields.push({ slot: 'secondary', fieldID: template.date.id });
  }
  if (template.secondaryFields) {
    orderedFields.push(
      ...template.secondaryFields.map((f) => ({
        slot: 'secondary',
        fieldID: f.id,
      })),
    );
  }

  return orderedFields;
};

const getColumnType = (columnSlot, column) => {
  const columnType = getColumnFormat([column]);

  if (columnSlot.slot === 'icon') {
    if (column.__typename === 'BauhausDataColumnRecord') {
      columnType.format = 'image';
    } else {
      columnType.format = 'icon';
    }
  }

  // For datetime columns, we want to add some meta to tell the feed to display "relative time"
  // ie. "2 days ago" instead of "2nd Oct"
  if (columnType.format === 'datetime') {
    columnType.useRelativeTime = true;
  }

  return columnType;
};

const feedTransform = (_, config, __, data, integration) => {
  if (!data) {
    return {};
  }

  const integrationSlug = integration.slug;
  const template = config.bauhaus.data_list.template;
  const columnOrder = getColumnsOrder(template);

  /*
   * we create an array of the field keys and we find out which type they are
   */
  const columns = columnOrder.map((columnSlot) => {
    const index = data.columns.findIndex(({ id }) => id === columnSlot.fieldID);
    return {
      index,
      ...getColumnType(columnSlot, data.columns[index]),
    };
  });

  /*
   * we then map over the rows
   */
  const transformedData = data.rows.map((row) => {
    const values = row.values;

    /* and again, map over the field values so we get the same index as the column
     * check against the column in order to figure out when we have an icon
     * in order to return the right url
     *
     * else we just return the data point
     */
    return columns.map(({ format, index }) => {
      if (format === 'icon') {
        const iconFieldID = template.icon.id;
        const iconPath = `/bauhaus/feed-icons/${integrationSlug}/${iconFieldID}`;

        return `${iconPath}/${getDatapointValue(values[index])}.svg`;
      }
      if (format === 'image') {
        return values[index].image || null;
      }

      return getDatapointValue(values[index]);
    });
  });

  return {
    data: transformedData,
    columns,
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(getDataResponse, feedTransform);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data) => data;

export { applyConfig, transform };
