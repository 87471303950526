type ValidWindowTitle<T extends string> = T extends `${string}${
  | ' '
  | '-'
  | '_'}${string}`
  ? never
  : T;

/**
 * openWindow
 *
 * @param windowName Should not contain any space, underscore or hyphen for
 * ie9. (http://stackoverflow.com/a/9590948/2259520)
 * @return new Popup Window
 */
function openWindow<T extends string>(windowName?: ValidWindowTitle<T>) {
  return window.open(
    'about:blank',
    windowName || '',
    [
      'width=800',
      'height=700',
      'status=no',
      'menubar=no',
      'top=100',
      'left=100',
      'resizable=no',
      'scrollbars=yes',
      'dependent=yes',
      'dialog=yes',
    ].join(','),
  );
}

export { openWindow as open };
