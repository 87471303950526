import { getWindow } from '@Lib/global';

const TOAST_KEY = 'toastMessage';

const storeToast = (toast) => {
  getWindow().sessionStorage.setItem(TOAST_KEY, JSON.stringify(toast));
};

export const loadToastFromStorage = () => {
  // Loads any pending toast message state following a redirect
  const window = getWindow();
  try {
    const toast = window.sessionStorage.getItem(TOAST_KEY);
    if (!toast) {
      return undefined;
    }

    window.sessionStorage.removeItem(TOAST_KEY);

    return JSON.parse(toast);
  } catch {
    // Swallow error so that we don't throw when
    // session storage isn't available. This is
    // important because we call this function on
    // sharing urls and screens which might use
    // older browsers. It also happens when the
    // user disables browser storage.
  }

  return undefined;
};

// scheduleToast() is exported to gecko-js
export const scheduleToast = (toast) => {
  return storeToast(toast);
};
