import Serializer from './default';

class NumberSerializer extends Serializer {
  serialize(state) {
    const defaults = super.serialize(state);
    const { value, title, goal, comparison, numberFormat, indicators } = state;

    return {
      ...defaults,
      config: {
        template: {
          type: 'number',
          version: 2,
        },
        title,
        value,
        goal,
        comparison,
        numberFormat,
        indicators,
      },
    };
  }

  deserialize(json) {
    const defaults = super.deserialize(json);
    const {
      title,
      value,
      goal,
      comparison = {},
      numberFormat = {},
      indicators = {},
      progressIndicator,
      statusIndicator,
    } = json.config;

    return {
      ...defaults,
      type: 'number',
      value,
      title,
      goal,
      comparison,
      numberFormat,
      indicators,
      progressIndicator,
      statusIndicator,
    };
  }
}

export default NumberSerializer;
