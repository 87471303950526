import { gql } from '@apollo/client';
import { find } from 'lodash';

import { getWindow } from '../lib/global';
import apolloClient from '../services/concierge-service/apollo-client';

import { GRAPHQL_DASHBOARDS_WITH_LAYOUT_KEY } from './dashboard-constants';

// Maps graphql dashboards to a unified format, and stores them in the
// session storage, so that we can access them on the config pages.
export const saveDashboardsToStorage = (graphQLDashboards) => {
  const mappedDashboards = graphQLDashboards.map(
    ({ id, legacyId, layoutType }) => ({
      id,
      legacyId,
      layoutType,
    }),
  );

  getWindow().sessionStorage.setItem(
    GRAPHQL_DASHBOARDS_WITH_LAYOUT_KEY,
    JSON.stringify(mappedDashboards),
  );
};

// Accepts a legacy dashboard id and finds the graphql dashboard
// in the session storage.
export const getDashboardFromStorage = (legacyDashboardId) => {
  const dashboardsFromStore = getWindow().sessionStorage.getItem(
    GRAPHQL_DASHBOARDS_WITH_LAYOUT_KEY,
  );
  const dashboardList = JSON.parse(dashboardsFromStore) || {};

  const dashboard =
    find(dashboardList, {
      legacyId: legacyDashboardId,
    }) || {};

  return dashboard;
};

export const isContainerLayout = (graphqlDashboard) => {
  const { layoutType = {} } = graphqlDashboard;
  return layoutType.__typename === 'ContainerLayout';
};

export const INSTRUMENT_ID_FROM_LEGACY_KEY_QUERY = gql`
  query InstrumentIdByLegacyKey($legacyKey: ID!) {
    instrumentByLegacyKey(legacyKey: $legacyKey) {
      id
    }
  }
`;

/**
 * Takes a legacy widget key and returns the instrument ID.
 */
export const getInstrumentIdFromWidgetKey = async (widgetKey) => {
  const client = apolloClient.create();

  const response = await client.query({
    query: INSTRUMENT_ID_FROM_LEGACY_KEY_QUERY,
    variables: { legacyKey: widgetKey },
  });

  return response.data.instrumentByLegacyKey?.id;
};
