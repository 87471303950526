import { get, isEmpty, isEqual, isUndefined, merge } from 'lodash';

import { conform, trackEvent } from '@Tracking';
import {
  widgetConfigEdited,
  widgetGoalAdded,
  widgetGoalEdited,
  widgetGoalRemoved,
} from '@Tracking/events';

import * as instrumentDataActions from '../../../components/instrument/actions/instrument-data-actions';
import createAction from '../../../lib/redux/create-action';
import { update } from '../../../lib/widget-service';
import * as toastActions from '../../../toast/actions/toast-actions';

export const setLoading = createAction('EditWidget:SET_LOADING');
export const setConfig = createAction('EditWidget:SET_CONFIG');
export const setConfigAndData = createAction('EditWidget:SET_CONFIG_AND_DATA');
export const setServiceAccountId = createAction(
  'EditWidget:SET_SERVICE_ACCOUNT_ID',
);
export const changeRefreshInterval = createAction(
  'EditWidget:CHANGE_REFRESH_INTERVAL',
);

export const configUpdated = createAction('EditWidget:CONFIG_UPDATED');

export const handleGoalTracking = async (
  originalWidget,
  newConfig,
  integration,
) => {
  const { goal: originalGoal, comparison: originalComparision } =
    originalWidget.config;

  const { goal: newGoal, comparison: newComparision } = newConfig;

  const hasGoalValuesChanged =
    !isEqual(originalGoal, newGoal) ||
    !isEqual(originalComparision, newComparision);

  const hasOriginalGoalValues =
    !isUndefined(originalGoal) || !isEmpty(originalComparision);

  let hasGoalRemoved;

  if (newConfig?.type === 'number') {
    hasGoalRemoved = hasOriginalGoalValues && isUndefined(newComparision?.type);
  } else {
    hasGoalRemoved = hasOriginalGoalValues && isUndefined(newGoal);
  }

  if (hasGoalValuesChanged) {
    const {
      id,
      config: { type },
    } = originalWidget;

    const trackingDetails = {
      'Legacy widget ID': `${id}`,
      Visualisation: type,
      ...conform.integration(integration),
    };

    if (!hasOriginalGoalValues) {
      await trackEvent(widgetGoalAdded(trackingDetails));
      return;
    }

    if (hasGoalRemoved) {
      await trackEvent(widgetGoalRemoved(trackingDetails));
      return;
    }

    await trackEvent(widgetGoalEdited(trackingDetails));
  }
};

export const updateWidget =
  (widgetKey, dashboardId, configFromVizPreviewManager = {}) =>
  async (dispatch, getState) => {
    const {
      instrumentId,
      config,
      refresh_interval: refreshInterval,
      service_account_id: serviceAccountID,
      serviceName,
      transformedData,
    } = getState().editWidget;

    const configToSave = merge(config, configFromVizPreviewManager);

    dispatch(
      configUpdated({
        config: configToSave,
        data: get(transformedData, 'data'),
        widgetKey,
        refresh_interval: refreshInterval,
        service_account_id: serviceAccountID,
      }),
    );

    // For Dashies, we force the Instrument into a loading state
    dispatch(instrumentDataActions.setIsLoading({ instrumentId }));

    try {
      const widget = await update(widgetKey, {
        dashboard_id: dashboardId,
        config: configToSave,
        refresh_interval: refreshInterval,
        service_account_id: serviceAccountID,
        service_name: serviceName,
      });

      const integration = {
        name: widget.service.title,
        slug: widget.service.name,
      };

      trackEvent(
        widgetConfigEdited({
          'Legacy widget ID': `${widget.id}`,
          Visualisation: widget.config.type,
          ...conform.integration(integration),
        }),
      );

      await handleGoalTracking(widget, configToSave, integration);
    } catch (e) {
      dispatch(toastActions.showGenericErrorToast());
    }
  };
