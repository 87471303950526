import omit from 'lodash/omit';

import {
  graphQLToReduxConfig,
  reduxConfigToGraphQL,
} from '@Lib/graphql-legacy-config-mappers';

import {
  DataSourceConfigInput,
  InstrumentForEditingQuery,
  VisualizationInput,
} from '../generated/graphql';
import { toWrapOptionsInput } from '../visualisation/getWrap';

import type {
  SpreadsheetsVisualizationConfig,
  SpreadsheetsWidgetConfig,
} from './spreadsheets-types';

export type InstrumentForEditing = NonNullable<
  InstrumentForEditingQuery['instrument']
>;

export const dataSourceConfigInputFromWidgetConfig = (
  widgetConfig: SpreadsheetsWidgetConfig,
): DataSourceConfigInput => {
  const { fileId, worksheetId, config, legacyServiceAccountId } = widgetConfig;
  const { fieldRanges, hasHeader, isLoop } = config;
  return {
    spreadsheets: {
      fileId,
      worksheetId,
      columns: JSON.stringify(config.columns),
      series: JSON.stringify(config.series),
      labels: JSON.stringify(config.labels),
      value: JSON.stringify(
        config.type === 'leaderboard' ? config.values : config.value,
      ),
      xAxis: JSON.stringify(config.xAxis),
      fieldRanges: JSON.stringify(fieldRanges),
      hasHeader,
      isLoop,
      legacyServiceAccountId: legacyServiceAccountId
        ? legacyServiceAccountId.toString()
        : undefined,
    },
  };
};

export const vizInputFromWidgetConfig = (
  config: SpreadsheetsVisualizationConfig,
): VisualizationInput => {
  switch (config.type) {
    case 'number':
      return {
        number: {
          title: config.title || '',
          label: config.label,
          comparison: reduxConfigToGraphQL.mapComparison(config.comparison),
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          progressIndicator: reduxConfigToGraphQL.mapProgressIndicator(
            config.goal,
            config.comparison,
          ),
          statusIndicator:
            config.indicators &&
            reduxConfigToGraphQL.mapStatusIndicators(config.indicators),
        },
      };
    case 'geckometer':
      return {
        geckometer: {
          title: config.title || '',
          min: reduxConfigToGraphQL.mapConfigValue(
            config?.min?.value ? config.min.value : config._minDefault!,
          ),
          max: reduxConfigToGraphQL.mapConfigValue(
            config?.max?.value ? config.max.value : config._maxDefault!,
          ),
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            config.indicators &&
            reduxConfigToGraphQL.mapStatusIndicators(config.indicators),
        },
      };
    case 'line': {
      const legends = [];
      for (const legend of config.legends || []) {
        legends.push(legend || '');
      }

      return {
        line: {
          title: config.title || '',
          min:
            (config.minYAxis &&
              config.minYAxis !== '' &&
              reduxConfigToGraphQL.mapConfigValue(config.minYAxis)) ||
            undefined,
          max:
            (config.maxYAxis &&
              config.maxYAxis !== '' &&
              reduxConfigToGraphQL.mapConfigValue(config.maxYAxis)) ||
            undefined,
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            reduxConfigToGraphQL.mapChartGoalToStatusIndicators(config),
          legends,
        },
      };
    }
    case 'column': {
      const legends = [];
      for (const legend of config.legends || []) {
        legends.push(legend || '');
      }

      return {
        column: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            reduxConfigToGraphQL.mapChartGoalToStatusIndicators(config),
          legends,
        },
      };
    }
    case 'bar':
      return {
        bar: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          statusIndicator:
            reduxConfigToGraphQL.mapChartGoalToStatusIndicators(config),
        },
      };
    case 'leaderboard':
      return {
        leaderboard: {
          title: config.title || '',
          numberFormat: reduxConfigToGraphQL.mapNumberFormat(
            config.numberFormat,
          ),
          sortOrder: config.reverseSort ? 'ASCENDING' : 'DESCENDING',
        },
      };
    case 'table':
      return {
        table: {
          title: config.title || '',
          columnWidths: config.columnWidths,
          numberFormats: config.numberFormat
            ? config.numberFormat.map((numberFormat) => {
                const mappedFormat =
                  reduxConfigToGraphQL.mapNumberFormat(numberFormat);
                return mappedFormat || null;
              })
            : [],
          wrap: toWrapOptionsInput(config.wrap),
        },
      };
    case 'text':
      return {
        text: {
          title: config.title || '',
        },
      };
    default:
      throw new Error(`Couldn’t map visualizationInput from state to GraphQL`);
  }
};

export const widgetConfigFromInstrument = (
  instrument: InstrumentForEditing,
): SpreadsheetsWidgetConfig => {
  if (
    !instrument.dataSource?.config ||
    !('worksheetId' in instrument.dataSource.config)
  ) {
    throw new Error(
      'DataSource config not set when editing a spreadsheet instrument.',
    );
  }

  const dataSourceConfig = instrument.dataSource.config;

  const sharedConfig: Pick<
    SpreadsheetsWidgetConfig,
    'worksheetId' | 'fileId' | 'legacyServiceAccountId'
  > = {
    worksheetId: dataSourceConfig.worksheetId,
    fileId: dataSourceConfig.fileId,
    legacyServiceAccountId: Number(dataSourceConfig.legacyServiceAccountId),
  };

  const sharedVizConfig: Pick<
    SpreadsheetsVisualizationConfig,
    | 'columns'
    | 'series'
    | 'xAxis'
    | 'value'
    | 'labels'
    | 'hasHeader'
    | 'isLoop'
    | 'fieldRanges'
  > = {
    columns: dataSourceConfig.columns && JSON.parse(dataSourceConfig.columns),
    series:
      (dataSourceConfig.series && JSON.parse(dataSourceConfig.series)) ||
      undefined,
    xAxis:
      (dataSourceConfig.xAxis && JSON.parse(dataSourceConfig.xAxis)) ||
      undefined,
    value:
      (dataSourceConfig.value && JSON.parse(dataSourceConfig.value)) ||
      undefined,
    labels:
      (dataSourceConfig.labels && JSON.parse(dataSourceConfig.labels)) ||
      undefined,
    fieldRanges:
      (dataSourceConfig.fieldRanges &&
        JSON.parse(dataSourceConfig.fieldRanges)) ||
      undefined,
    hasHeader: !!dataSourceConfig.hasHeader,
    isLoop: !!dataSourceConfig.isLoop,
  };

  switch (instrument.__typename) {
    case 'Number':
      return {
        ...sharedConfig,
        config: {
          ...sharedVizConfig,
          type: 'number',
          title: instrument.title || '',
          label: instrument.label || '',
          comparison: graphQLToReduxConfig.mapComparison(instrument.comparison),
          indicators: graphQLToReduxConfig.mapStatusIndicators(
            instrument.statusIndicator,
          ),
          ...graphQLToReduxConfig.mapProgressIndicator(
            instrument.progressIndicator,
          ),
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
        },
      };
    case 'Geckometer': {
      const min = graphQLToReduxConfig.mapConfigValue(instrument?.min);
      const max = graphQLToReduxConfig.mapConfigValue(instrument?.max);

      return {
        ...sharedConfig,
        config: {
          ...sharedVizConfig,
          type: 'geckometer',
          title: instrument.title || '',
          min: { value: min },
          max: { value: max },
          _minDefault: min,
          _maxDefault: max,
          indicators: graphQLToReduxConfig.mapStatusIndicators(
            instrument.statusIndicator,
          ),
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
        },
      };
    }
    case 'Bar':
      return {
        ...sharedConfig,
        config: {
          ...sharedVizConfig,
          type: 'bar',
          title: instrument.title || '',
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
            instrument.statusIndicator,
          ),
        },
      };
    case 'Column':
      return {
        ...sharedConfig,
        config: {
          ...sharedVizConfig,
          type: 'column',
          title: instrument.title || '',
          legends: instrument.legends || [],
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
            instrument.statusIndicator,
          ),
        },
      };
    case 'Line': {
      const min = graphQLToReduxConfig.mapConfigValue(instrument?.min);
      const max = graphQLToReduxConfig.mapConfigValue(instrument?.max);

      return {
        ...sharedConfig,
        config: {
          ...sharedVizConfig,
          type: 'line',
          title: instrument.title || '',
          legends: instrument.legends || [],
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          minYAxis: min ? min.toString() : '',
          maxYAxis: max ? max.toString() : '',
          ...graphQLToReduxConfig.mapStatusIndicatorsToChartGoal(
            instrument.statusIndicator,
          ),
        },
      };
    }
    case 'Leaderboard':
      return {
        ...sharedConfig,
        config: {
          // leaderboards use `values` instead of `value`
          ...omit(sharedVizConfig, 'value'),
          values: sharedVizConfig.value,
          type: 'leaderboard',
          title: instrument.title || '',
          numberFormat: graphQLToReduxConfig.mapNumberFormat(
            instrument.numberFormat,
          ),
          reverseSort: instrument.sortOrder === 'ASCENDING',
          showImages: instrument.showImages ? instrument.showImages : undefined,
        },
      };
    case 'Table':
      return {
        ...sharedConfig,
        config: {
          ...sharedVizConfig,
          type: 'table',
          title: instrument.title || '',
          columnWidths: instrument.columnWidths,
          numberFormat: instrument.numberFormats?.map(
            (nf) => graphQLToReduxConfig.mapNumberFormat(nf) || null,
          ),
          showImages: instrument.showImages ? instrument.showImages : undefined,
          wrap: instrument.wrap ?? undefined,
        },
      };
    case 'Text':
      return {
        ...sharedConfig,
        config: {
          ...sharedVizConfig,
          type: 'text',
          title: instrument.title || '',
        },
      };
    default:
      throw new Error(
        'Visualisation type is not a valid spreadsheet visualisation',
      );
  }
};
