import { gql } from '@apollo/client';
import { cloneDeep, get, isEmpty, isUndefined, omit, set } from 'lodash';
import { matchPath } from 'react-router';

import { setTypedExtremum } from '@Lib/geckometer';
import createAction from '@Lib/redux/create-action';
import createErrorAction from '@Lib/redux/create-error-action';
import { trackSwitchVisualisation } from '@Lib/tracker';
import { getConfig } from '@Lib/widget-service';

import { getStateToRestore } from '../../actions/helpers';
import { router } from '../../router';
import apolloClient from '../../services/concierge-service/apollo-client';
import { deserialize } from '../../universal/serializers';
import datasetService from '../../universal/services/dataset-service';
import getVisualisationHelpers from '../components/get-visualisation-helpers';
import tableHelpers from '../components/table-form/helpers';
import { FORM_TYPES } from '../components/table-form/table-form-constants';
import { syncFiltersQuery } from '../lib/filters';
import groupFields from '../lib/group-fields';

import {
  debouncify,
  ensureQuery,
  getFieldTypes,
  getUpdatedTimeField,
  updateSortTableQuery,
} from './dataset-action-helpers';

// Progress Goals are used on our number visualisations
export const setProgressGoal = createAction('Dataset:SET_PROGRESS_GOAL');

// Goals are used on our line, column and bar visualisations
export const setGoal = createAction('Dataset:SET_GOAL');

export const datasetSet = createAction('Dataset:SET');
export const datasetSetError = createErrorAction('Dataset:SET_ERROR');
export const setCurrentTimeField = createAction(
  'Dataset:SET_CURRENT_TIME_FIELD',
);
export const setConfig = createAction('Dataset:SET_CONFIG');
export const resetConfig = createAction('Dataset:RESET_CONFIG');
export const saveState = createAction('Dataset:SAVE_STATE');
export const setTableType = createAction('Dataset:SET_TABLE_TYPE');
export const toggleFilePicker = createAction('Dataset:TOGGLE_FILE_PICKER');
export const resetQueryHistory = createAction('Dataset:RESET_QUERY_HISTORY');
export const updateColumnNumberFormat = createAction(
  'Dataset:UPDATE_COLUMN_NUMBER_FORMAT',
);
export const reorderColumn = createAction('Dataset:REORDER_COLUMN');

const VISUALISATION_TYPES = {
  NUMBER: 'number',
  GAUGE: 'geckometer',
  LINE: 'line',
  COLUMN: 'column',
  BAR: 'bar',
  LEADERBOARD: 'leaderboard',
  TABLE: 'table',
};

const AGGREGATES = {
  COUNT: 'count',
  SUM: 'sum',
  MIN: 'min',
  MAX: 'max',
  AVG: 'avg',
  LATEST: 'latest',
};

const TIMESPAN_UNITS = {
  MINTUE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const INT_DAT_VISUALISATION_DEFAULTS_QUERY = gql`
  query integrationDatasetVisualisationDefaults($id: ID!) {
    integrationDataset(id: $id) {
      __typename
      id
      workflow {
        __typename
        id
        visualisationDefaults {
          type
          aggregate
          fields
          timespanField
          timespan {
            unit
            quantity
          }
          groupBy
          bucketBy
        }
      }
    }
  }
`;

const updateCurrentTimeField = (query, type) => (dispatch, getState) => {
  const { fields, currentTimeField, fieldGroups } = getState().datasets;

  const timeField = getUpdatedTimeField(
    query,
    currentTimeField,
    type,
    fields,
    fieldGroups,
  );

  dispatch(setCurrentTimeField(timeField));
};

const getIntegrationDatasetIdFromPath = () => {
  const integrationDatasetsPathV4 =
    '/v4/dashboards/:dashboardId/integrationDatasets/:integrationDatasetId';
  const integrationDatasetsPathV5 =
    '/v5/dashboards/:dashboardId/new/integrationDatasets/:integrationDatasetId';

  const {
    location: { pathname: currentPath },
  } = router.getHistory();

  const matchV4 = matchPath(currentPath, {
    path: integrationDatasetsPathV4,
  });
  const matchV5 = matchPath(currentPath, {
    path: integrationDatasetsPathV5,
  });

  if (!matchV4 && !matchV5) return null;

  return (
    matchV4?.params?.integrationDatasetId || matchV5.params.integrationDatasetId
  );
};

// This is currently Integration Datasets-only functionality
const getVisualisationDefaults = async () => {
  const integrationDatasetId = getIntegrationDatasetIdFromPath();

  if (!integrationDatasetId) return {};

  const client = apolloClient.create();

  const result = await client.query({
    query: INT_DAT_VISUALISATION_DEFAULTS_QUERY,
    variables: {
      id: integrationDatasetId,
    },
  });

  const visualisationDefaults =
    get(result, 'data.integrationDataset.workflow.visualisationDefaults') || {};

  return visualisationDefaults;
};

const constructDefaultQuery = async (type, fieldGroups, config) => {
  let query;
  let error = null;

  // At the moment, due to the Datasets service being used for Integration Datasets, we
  // are able to apply certain defaults to our visualisation settings that are stored against
  // a particular Integration Datasets workflow. Here, we are checking if any such defaults exist
  // so that we can use them as a starting point. For regular (custom) Datasets, this step is irrelevant.
  const visualisationDefaults = await getVisualisationDefaults();

  const overrideDefaultVisualisation =
    VISUALISATION_TYPES[visualisationDefaults.type];
  const overrideDefaultAggregate = AGGREGATES[visualisationDefaults.aggregate];
  const overrideQueryFields = visualisationDefaults.fields;

  const overridenType = overrideDefaultVisualisation || config.type;
  const configWithOverride = { ...config, type: overridenType };

  // Build a default query based on the visualisation type (either overriden or not)
  try {
    query = ensureQuery(overridenType, fieldGroups, configWithOverride);
  } catch (e) {
    error = e;
  }

  // It's possible for us to set a "starting point" for our query, for example if we want to
  // start with a table visualisation with particular columns or a line chart with a particular
  // metric
  if (overrideQueryFields) {
    if (query.columns) {
      query = {
        ...query,
        columns: overrideQueryFields.map((field) => ({ field })),
      };
    } else if (query.metrics) {
      query = {
        ...query,
        metrics: overrideQueryFields.map((field) => ({
          aggregate: overrideDefaultAggregate || 'sum',
          field,
        })),
      };
    }
  }

  // If the `aggregate` is "count", it's a special case where we want the metric to be
  // the "Record count", this is constructed in the format below
  if (overrideDefaultAggregate === 'count') {
    query = {
      ...query,
      metrics: [{ aggregate: 'count', _aggregate: 'sum' }],
    };
  }

  const { timespanField, timespan } = visualisationDefaults;

  if (timespan) {
    query.filters = [
      {
        field: timespanField || 'date',
        since_start_of: {
          unit: TIMESPAN_UNITS[timespan.unit],
          quantity: timespan.quantity,
        },
        isTimespan: true,
      },
    ];
  }

  const { groupBy, bucketBy } = visualisationDefaults;

  if (groupBy) {
    query.x_axis = {
      field: groupBy,
      bucket_by: bucketBy ? TIMESPAN_UNITS[bucketBy] : undefined,
    };
  }

  return { query, config: configWithOverride, error };
};

const runInitalQuery = async (
  dataSetId,
  { query, type, timezone, fieldGroups, config },
) => {
  if (isEmpty(query)) return { query, queryResult: undefined };

  let updatedQuery = query;
  let queryResult;

  try {
    queryResult = await datasetService.queryDataSet(dataSetId, {
      query,
      type,
      timezone,
    });
  } catch (err) {
    // Should the query fail with `ErrResourceInvalid` then its likely that we're editing a
    // widget for which the schema has changed so that its incompatible.
    // So we'll regenerate a query for the new schema and drive on
    if (err.type === 'ErrResourceInvalid') {
      const defaultQuery = ensureQuery(type, fieldGroups, config);
      queryResult = await datasetService.queryDataSet(dataSetId, {
        query: defaultQuery,
        type,
        timezone,
      });

      updatedQuery = defaultQuery;
    } else {
      throw err;
    }
  }

  return { query: updatedQuery, queryResult };
};

const _initialise =
  (dataSetId, dashboardId, widgetQuery, timezone) =>
  async (dispatch, getState) => {
    // There's nothing to initialise if we don't have a `dataSetId` or a `dashboardId`
    if (!dataSetId || !dashboardId) return;

    const dataSet = await datasetService.fetchDataSet(dataSetId, {
      includesData: true,
    });

    const { fields } = dataSet;
    const fieldGroups = groupFields(fields);
    const { config: configFromState } = getState().datasets;
    const type = configFromState.type;

    dispatch(datasetSet({ timezone, showFilePicker: false }));

    // - If we're editing, we should already have a query to go from
    // - If it's new, we'll construct a default query based on the visualisation type
    //   and available fields
    const initialQuery = !isUndefined(widgetQuery)
      ? { query: widgetQuery, config: configFromState }
      : await constructDefaultQuery(type, fieldGroups, configFromState);

    let { query } = initialQuery;
    const { config, error } = initialQuery;

    const initialQueryResult = await runInitalQuery(dataSetId, {
      query,
      type: config.type,
      timezone,
      fieldGroups,
      config,
    });

    // After we've run the initial query, it's possible we'll have a new query back
    // This can happen if the query fails due to a schema change. Rather than failing here,
    // we want to just reform the query to make sense and carry on.
    ({ query } = initialQueryResult);
    const { queryResult } = initialQueryResult;

    dispatch(resetQueryHistory());

    const timeField = getUpdatedTimeField(
      query,
      null,
      type,
      fields,
      fieldGroups,
    );

    dispatch(
      datasetSet({
        config,
        dataSetId,
        dataSet,
        query,
        fields,
        fieldGroups,
        queryResult,
        error,
        currentTimeField: timeField,
        isLoading: false,
      }),
    );
  };

const debouncedQueryDataSet = debouncify((...args) =>
  datasetService.queryDataSet(...args),
);

const getTimespanError = (query, datasetsState) => {
  if (isEmpty(query)) {
    return null;
  }
  const { filters = [] } = query;
  const { fields, currentTimeField } = datasetsState;

  // check we're using valid timespan
  if (currentTimeField) {
    const timespanFilter = filters.find(({ isTimespan }) => isTimespan);
    // If !timespanFilter it means we're on an "all time" timespan and
    // don't have to check for an invalid timespan.
    if (timespanFilter) {
      const {
        since_start_of: { unit },
      } = timespanFilter;
      const currentField = fields[currentTimeField];

      if (currentField.type === 'date' && unit === 'hour') {
        const error = new Error(
          `${currentField.name} is a date field, and not compatible with hour-based timespans. Please select a new timespan.`,
        );
        error.type = 'ErrIncompatibleTimeFilter';
        return error;
      }
    }
  }

  return null;
};

export const _updateQueryAndConfig =
  (query, config, timezone) => async (dispatch, getState) => {
    const state = { error: null };
    if (query) {
      state.query = { ...query };
    }
    if (config) {
      state.config = { ...config };
    }

    dispatch(datasetSet(state));

    if (query) {
      const {
        dataSetId,
        fields,
        config: { type },
      } = getState().datasets;

      const timespanError = getTimespanError(query, getState().datasets);
      if (timespanError) {
        // we do not use datasetSetError here as it
        // will send an error to bugsnag
        dispatch(datasetSet({ error: timespanError }));
        return;
      }

      // Then go and update the query result
      const cleanQuery = syncFiltersQuery(query, fields, type);
      const queryResult = await debouncedQueryDataSet(dataSetId, {
        query: cleanQuery,
        type,
        timezone,
      });

      dispatch(datasetSet({ queryResult, query: cleanQuery }));
    }
  };

export const initConfig =
  (datasetId, dashboardId, timezone) => async (dispatch) => {
    try {
      dispatch(resetConfig());
      await dispatch(_initialise(datasetId, dashboardId, undefined, timezone));
    } catch (error) {
      const showFilePicker = error.type === 'ErrResourceNotFound';
      dispatch(datasetSetError(error, { showFilePicker, isLoading: false }));
    }
  };

/**
 * setEditInstrument is used when editing an instrument using GraphQL.
 * It imitates getWidgetConfig below except that the data is being
 * fetched using Apollo in a component and passed to the action.
 * TODO: Check if we need to setTypedExtremum like getWidgetConfig does.
 */
export const setEditInstrument =
  (config, dashboardId, timezone) => async (dispatch) => {
    try {
      dispatch(datasetSet(omit(config, 'query', 'dataSetId')));
      await dispatch(
        _initialise(config.dataSetId, dashboardId, config.query, timezone),
      );
    } catch (error) {
      const showFilePicker = error.type === 'ErrResourceNotFound';
      dispatch(datasetSetError(error, { showFilePicker, isLoading: false }));
    }
  };

export const getWidgetConfig =
  (widgetId, dashboardId, timezone) => async (dispatch) => {
    try {
      const json = await getConfig(widgetId);
      const config = deserialize('datasets', null, json);
      const { query, dataSetId, min, max } = config;

      const extendedConfig = {
        ...setTypedExtremum('min', min),
        ...setTypedExtremum('max', max),
        ...config,
      };

      dispatch(
        datasetSet({ config: omit(extendedConfig, 'query', 'dataSetId') }),
      );
      await dispatch(_initialise(dataSetId, dashboardId, query, timezone));
    } catch (error) {
      const showFilePicker = error.type === 'ErrResourceNotFound';
      dispatch(datasetSetError(error, { showFilePicker, isLoading: false }));
    }
  };

export const switchTableType = () => async (dispatch, getState) => {
  try {
    const {
      datasets: {
        fieldGroups,
        _previousSummaryQuery,
        _previousRawQuery,
        config: { tableType },
      },
    } = getState();

    let newQuery;

    if (tableType === FORM_TYPES.SUMMARIZED) {
      dispatch(setTableType(FORM_TYPES.RAW));

      newQuery =
        _previousRawQuery || tableHelpers.getDefaultQueryForRaw(fieldGroups);
    } else {
      dispatch(setTableType(FORM_TYPES.SUMMARIZED));

      newQuery =
        _previousSummaryQuery ||
        tableHelpers.getDefaultQueryForSummary(fieldGroups);
    }

    await dispatch(_updateQueryAndConfig(newQuery));
  } catch (error) {
    dispatch(
      datasetSetError(error, {
        queryResult: undefined,
      }),
    );
  }
};

export const update = (query, config) => async (dispatch, getState) => {
  const {
    query: currentQuery,
    config: currentConfig,
    fields,
    timezone,
  } = getState().datasets;

  const { type } = currentConfig;
  let newQuery, newConfig;

  if (query) {
    const { path, value } = query;

    newQuery = getVisualisationHelpers(type).updateQuery(
      currentQuery,
      path,
      value,
      fields,
    );

    dispatch(updateCurrentTimeField(newQuery, type));
  }

  // Merge and clone
  if (config) {
    const { path, value, multiPath } = config;
    const clonedConfig = cloneDeep(currentConfig);

    // Sometimes we have to update multiple fields in the config in one go. To do this pass an array like
    // { multiPath: [{ path: 'field1', value: 'value1' }, { path: 'field2', value: 'value2' }]
    if (multiPath) {
      newConfig = multiPath.reduce((cfg, { path: p, value: v } = {}) => {
        return set(cfg, p, v);
      }, clonedConfig);
    } else {
      newConfig = set(clonedConfig, path, value);
    }
  }

  try {
    await dispatch(_updateQueryAndConfig(newQuery, newConfig, timezone));
  } catch (error) {
    dispatch(datasetSetError(error));
  }
};

export const restoreState = (paths, defaults) => (dispatch, getState) => {
  const { config } = getState().datasets;
  const newConfig = getStateToRestore(config, paths, defaults);

  dispatch(datasetSet({ config: { ...config, ...newConfig } }));
};

export const refreshFile = () => async (dispatch, getState) => {
  // Get the query and dataSetId from the store and trigger an update
  const {
    query,
    dataSetId,
    config: { type },
    timezone,
  } = getState().datasets;

  const queryResult = await datasetService.queryDataSet(dataSetId, {
    query,
    type,
    timezone,
  });

  dispatch(datasetSet({ queryResult }));
};

export const switchVisualisationType = (type) => async (dispatch, getState) => {
  try {
    const {
      dataSetId,
      fieldGroups,
      fields,
      query: currentQuery,
      config: currentConfig,
      _previousQueries = {},
      timezone,
    } = getState().datasets;

    const { type: currentType, ...restConfig } = currentConfig;
    const config = { ...restConfig, type };
    const updatedPreviousQueries = cloneDeep(_previousQueries);
    const VisualisationHelpers = getVisualisationHelpers(type);

    // Keep the current query so we can switch back to it later
    updatedPreviousQueries[currentType] = cloneDeep(currentQuery);

    trackSwitchVisualisation('datasets', currentType, type);

    let error = null;
    let baseQuery;
    try {
      baseQuery =
        updatedPreviousQueries[type] || ensureQuery(type, fieldGroups, config);
    } catch (e) {
      error = e;
    }

    let query = undefined;
    if (baseQuery) {
      query = VisualisationHelpers.mapQuery(
        currentType,
        currentQuery,
        baseQuery,
        getFieldTypes(fieldGroups),
      );

      // ensureMappedQuery let's us amend the query when all the fields
      // have been mapped. It's useful when some query parts depend on
      // others. For example on a column chart the order_by depends
      // on the mapped metrics and x-axis.
      const ensureMappedQuery = VisualisationHelpers.ensureMappedQuery;
      if (ensureMappedQuery) {
        query = ensureMappedQuery(query, fields);
      }

      // syncFiltersQuery and updateCurrentTimeField are called for every query
      // update in _updateQueryAndConfig. The switchVisualisationType action is
      // the only place we don't use _updateQueryAndConfig to update the query,
      // so we have to call them here too.
      dispatch(updateCurrentTimeField(query, type));
      query = syncFiltersQuery(query, fields, type);
    }

    updatedPreviousQueries[type] = cloneDeep(query);

    const timespanError = getTimespanError(query, getState().datasets);

    dispatch(
      datasetSet({
        query,
        queryResult: undefined,
        config,
        error: error || timespanError,
        isLoading: true,
        _previousQueries: updatedPreviousQueries,
      }),
    );

    // Now update the queryResult
    let queryResult;
    if (!isEmpty(query) && !timespanError) {
      queryResult = await datasetService.queryDataSet(dataSetId, {
        query,
        type,
        timezone,
      });
    }

    dispatch(datasetSet({ queryResult, isLoading: false }));
  } catch (error) {
    dispatch(datasetSetError(error));
  }
};

export const changeTableColumnField =
  ({ index, value }) =>
  async (dispatch, getState) => {
    const { config = {} } = getState().datasets;
    let { numberFormat = [] } = config;

    numberFormat = [...numberFormat];
    numberFormat[index] = null;

    await dispatch(update({ path: `columns[${index}].field`, value }));

    // Wait for the new data to be fetched before removing number formatting
    // So there isn't a render where the number format update is applied
    // to the old data
    dispatch(datasetSet({ config: { ...config, numberFormat } }));
  };

export const changeColumnAggregate =
  ({ index, value }) =>
  async (dispatch, getState) => {
    const { query } = getState().datasets;
    const { columns = [], order_by: orderBy } = query;
    const newColumns = cloneDeep(columns);

    newColumns[index].aggregate = value;
    const newColumn = newColumns[index];

    if (newColumn.aggregate === newColumn._aggregate) {
      delete newColumns[index]._aggregate;
    }

    // Check if order_by is using the column where the aggregation has changed.
    // If yes, change also the aggregation in the order_by
    let newOrderByQuery = {};
    if (orderBy) {
      if (
        orderBy.field === columns[index].field &&
        orderBy.aggregate === columns[index].aggregate
      ) {
        newOrderByQuery = { order_by: { ...orderBy, aggregate: value } };
      } else {
        newOrderByQuery = { order_by: orderBy };
      }
    }

    const newQuery = { ...query, columns: newColumns, ...newOrderByQuery };
    await dispatch(_updateQueryAndConfig(newQuery));
  };

export const updateNumberFormat =
  (format = {}) =>
  (dispatch, getState) => {
    const { config: { numberFormat = {} } = {} } = getState().datasets;
    const newFormat = { ...numberFormat, ...format };

    dispatch(update(null, { path: 'numberFormat', value: newFormat }));
  };

export const sortTable = (columnIndex) => async (dispatch, getState) => {
  dispatch(datasetSet({ isContentLoading: true }));
  const { query } = getState().datasets;
  const newQuery = updateSortTableQuery(query, columnIndex);

  await dispatch(_updateQueryAndConfig(newQuery));
  dispatch(datasetSet({ isContentLoading: false }));
};

export const sortTableByField = (field) => async (dispatch, getState) => {
  dispatch(datasetSet({ isContentLoading: true }));

  const { query } = getState().datasets;
  const newQuery = { ...query, order_by: undefined };

  // Insertion order means no explicit ordering
  if (field !== 'order_by:insertion') {
    // If the field has an aggregate, we use the key "fieldName-aggregate"
    // in the dropdown. Otherwise it's just "fieldName".
    const [fieldName, aggregate] = field.split('-');

    const previousDirection = get(query, 'order_by.direction');
    newQuery.order_by = {
      field: fieldName,
      aggregate,
      direction: previousDirection || 'asc',
    };
  }

  try {
    await dispatch(_updateQueryAndConfig(newQuery));
  } catch (error) {
    dispatch(datasetSetError(error));
  }
  dispatch(datasetSet({ isContentLoading: false }));
};

export const setTableSortDirection =
  (direction) => async (dispatch, getState) => {
    dispatch(datasetSet({ isContentLoading: true }));

    const { query } = getState().datasets;
    const newQuery = { ...query };

    newQuery.order_by = {
      ...newQuery.order_by,
      direction,
    };

    try {
      await dispatch(_updateQueryAndConfig(newQuery));
    } catch (error) {
      dispatch(datasetSetError(error));
    }
    dispatch(datasetSet({ isContentLoading: false }));
  };

export const removeTableColumn = (index) => (dispatch, getState) => {
  const {
    query: { columns },
    config: { numberFormat = [] },
  } = getState().datasets;

  const newColumns = [...columns.slice(0, index), ...columns.slice(index + 1)];
  const newColumnsQuery = {
    path: 'columns',
    value: newColumns,
  };

  let newConfig;
  if (numberFormat.length) {
    // Delete numberFormat for deleted column
    const newFormat = [
      ...numberFormat.slice(0, index),
      ...numberFormat.slice(index + 1),
    ];

    newConfig = { path: 'numberFormat', value: newFormat };
  }

  dispatch(update(newColumnsQuery, newConfig));
};
