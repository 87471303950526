import { compact, isNumber } from 'lodash';

const transform = (payload) => {
  const rows = payload?.data?.bauhausDataRequest?.data?.rows;

  if (!rows) {
    return { points: { point: [] } };
  }

  let minSize = Infinity;
  let maxSize = 0;
  let points = rows.map(({ values }) => {
    const { latitude, longitude } = values[0];
    const size = values[1]?.decimal;

    if (!isNumber(latitude) || !isNumber(longitude) || !isNumber(size)) {
      return null;
    }

    minSize = Math.min(minSize, size);
    maxSize = Math.max(maxSize, size);

    return { latitude, longitude, size };
  });

  // Discard any invalid points
  points = compact(points);

  if (!points.length) {
    return { points: { point: [] } };
  }

  // Re-scale points into the 1-10 range based on relative sizes
  if (minSize < 1 || maxSize > 10) {
    const range = Math.max(1, maxSize - minSize);
    points = points.map((point) => {
      return {
        ...point,
        size: ((point.size - minSize) / range) * 9 + 1,
      };
    });
  }

  return { points: { point: points } };
};

export { transform };
