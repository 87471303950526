import {
  every,
  flatten,
  includes,
  isUndefined,
  last,
  omit,
  omitBy,
  some,
} from 'lodash';

import { getInputFromDuration } from '../../lib/humanise-number';
import { formatMessage } from '../../lib/i18n';
import * as bootstrapActions from '../actions/universal-bootstrap-actions';
import * as configActions from '../actions/universal-config-actions';
import * as saveActions from '../actions/universal-config-save-actions';
import * as queryActions from '../actions/universal-query-actions';
import * as socketActions from '../actions/universal-socket-actions';
import { getCompatibleMetrics, getFieldsByType } from '../graph';
import { findCustomFilter } from '../query-helpers';
import {
  areMetricsTypesCompatible,
  canTimespanCompare,
  FILTER_FIELD_TYPES,
  getMsFromTimespan,
  getTSComparisonOperatorForViz,
  getVisualisationsForMetric,
  MAX_NUMBER_OF_METRICS,
  TIMESPAN_OPERATORS,
  timespanSupportsPrevSameComparison,
} from '../ui-field-options';

import { ensureConfig } from './config-helpers';
import { transformData } from './other-universal-config-reducer-methods';
import uiOptionsReducer, {
  appendSelectedNodes,
  getAvailableMetrics,
} from './ui-options-reducer';
import uiQueryReducer, {
  getQueriesFromUiQuery,
  getQueryMetas,
  getUiQueryBasedOnOptions,
  resolveNodesCrossResource,
} from './ui-query-reducer';

export const initialState = {
  config: {
    type: 'number',
    queryMetas: [],
    timespanComparison: undefined,
  },
  subQueryResults: {},
  source: {},
  cancelledQueries: new Set(),
  queries: [],
  queryResults: [],
  queryIds: [],
  queuedData: [],
  queryFetchCursor: 0,
  uiQuery: {},
  uiOptions: {},
  transformedData: null,
  mainGraph: [],
  queriesFetchDone: [],
  entryResources: [],
  _filterModal: {},
  // previewError handle expected error from FE state and show them inline in
  // preview section. Whereas visualisationError are unexpected error rendered
  // into Toast
  error: null,
  previewError: null,
  showDataSourcePanel: false,
  preset: {},
  api: {},
  isPusherReady: false,
  isConfigReady: false,
  isPreviewOpen: false,
  hasNoServiceAccounts: false,
  queriesEnabled: true,
};

const setConfigComparison = (config) => {
  const { comparison: { startingValue } = {} } = config;
  const newConfig = {
    ...omit(config, 'comparison'),
  };

  if (newConfig.type === 'number' && !isUndefined(newConfig.goal)) {
    newConfig.comparison = omitBy(
      {
        type: 'goal',
        thresholdType: 'percentage',
        startingValue,
      },
      isUndefined,
    );
  }

  return newConfig;
};

const getTSComparisonForVisualisation = (vizType, currentOperator) => ({
  operator: getTSComparisonOperatorForViz(vizType, currentOperator),
  type: vizType === 'number' ? 'percentage' : undefined,
});

const getComparisonForNewTimespan = (comparison, operands, vizType) => {
  if (!comparison) {
    return undefined;
  }

  const [quantity, unit] = operands;
  const { operator } = comparison;

  if (operator !== TIMESPAN_OPERATORS.PREV_SAME_TIMESPAN) {
    return comparison;
  }

  if (timespanSupportsPrevSameComparison(unit, quantity)) {
    return comparison;
  }

  return {
    ...comparison,
    operator: getTSComparisonOperatorForViz(vizType),
  };
};

const getConfigForVisualisation = (config) => {
  // Get new config with correct goal
  const newConfig = setConfigComparison(config);
  const { timespanComparison, type } = newConfig;

  // Time comparison
  if (timespanComparison) {
    if (
      ['geckometer', 'column', 'bar', 'leaderboard', 'table'].includes(type)
    ) {
      newConfig.timespanComparison = undefined;
    } else {
      newConfig.timespanComparison = getTSComparisonForVisualisation(
        type,
        timespanComparison.operator,
      );
    }
  }

  return newConfig;
};

const getUpdatedConfig = (
  _config,
  uiQuery,
  subQueryResults,
  queryResults,
  queries,
  timezone,
) => {
  // Compute new metadata first
  const queryMetas = getQueryMetas(uiQuery, subQueryResults);
  // ensure query
  const config = ensureConfig(
    { ..._config, queryMetas },
    { queryResults, queries, timezone },
    uiQuery,
  );

  /*
   * We want to remove timespan comparison if a filter is custom
   * Because the custom type always remove previously set filters,
   * we can safely assume the first filter is the right one to check
   * for its type
   */
  if (!!findCustomFilter(uiQuery.filters)) {
    delete config.timespanComparison;
  }

  return config;
};

const hasCompatibleMetricTypes = (uiMetrics) => {
  if (uiMetrics.length === 1) return true;

  const allNodes = uiMetrics.map((m) => m.node);
  return areMetricsTypesCompatible(allNodes);
};

const updateVizType = (state, vizType) => {
  const uiOptions = uiOptionsReducer(
    state.uiOptions,
    queryActions.setVisualisationType({
      metric: state.uiQuery.metrics[0].node,
    }),
    state.mainGraph,
    state.entryResources,
    vizType,
  );
  const uiQuery = uiQueryReducer(
    state.uiQuery,
    queryActions.setVisualisationType({ uiOptions }),
    vizType,
  );
  const entryResources = uiQuery.metrics.map((m) => m.node.getRoot());

  return {
    ...state,
    uiOptions,
    uiQuery,
    entryResources,
    transformedData: initialState.transformedData,
    config: {
      ...state.config,
      type: vizType,
    },
  };
};

const universalConfigReducer = (state = initialState, action) => {
  const { type, payload } = action;
  let uiQuery,
    uiOptions,
    allFetchDone,
    transformedData,
    entryResources,
    queries,
    queryResults,
    queryIds,
    queriesFetchDone,
    config,
    configWithDefaults,
    newConfig,
    newState,
    previewError,
    newQueryResults,
    newQueriesFetchDone,
    maxTimePeriod,
    timespanComparison,
    timezone,
    title,
    vizType,
    numberFormat;

  switch (type) {
    case bootstrapActions.resetInitial.type:
      return initialState;

    case bootstrapActions.resetGraph.type:
      return {
        ...state,
        isConfigReady: false,
        previewError: initialState.previewError,
        error: initialState.error,
        mainGraph: initialState.mainGraph,
        uiOptions: initialState.uiOptions,
        uiQuery: initialState.uiQuery,
        subQueryResults: initialState.subQueryResults,
        queryResults: initialState.queryResults,
        transformedData: initialState.transformedData,
      };
    case bootstrapActions.setTimezone.type:
      return {
        ...state,
        timezone: payload,
      };
    case queryActions.setVisualisationType.type:
      ({ vizType } = payload);

      newState = updateVizType(state, vizType);

      newConfig = getUpdatedConfig(
        newState.config,
        newState.uiQuery,
        newState.subQueryResults,
        initialState.queryResults,
        newState.queries,
        newState.timezone,
      );

      newConfig = getConfigForVisualisation(newConfig, newState.uiQuery);

      return {
        ...newState,
        config: newConfig,
        _filterModal: {},
        queryResults: initialState.queryResults,
      };
    case bootstrapActions.setConfigReady.type:
      return { ...state, isConfigReady: true, isPreviewOpen: true };
    case configActions.setConfig.type:
      ({ queries = [], queryResults = [], timezone } = state);
      newConfig = {
        ...state.config,
        ...payload,
      };

      newConfig = ensureConfig(
        newConfig,
        {
          queryResults,
          queries,
          timezone,
        },
        state.uiQuery,
      );
      // Handle special case for number goal.
      newConfig = setConfigComparison(newConfig);

      return {
        ...state,
        config: newConfig,
        transformedData: transformData(
          queries,
          queryResults,
          newConfig,
          timezone,
        ),
      };
    case queryActions.updateNumberFormat.type: {
      numberFormat = state.config.numberFormat || {};
      transformedData = state.transformedData || {};

      return {
        ...state,
        config: {
          ...state.config,
          numberFormat: { ...numberFormat, ...payload },
        },
        /*
         * For some reason number formatting is stored in
         * two places. We should look at removing this
         * duplication and only store it in config.
         */
        transformedData: {
          ...transformedData,
          numberFormat: { ...numberFormat, ...payload },
        },
      };
    }
    case bootstrapActions.setQueryOptions.type:
      return {
        ...state,
        config: {
          ...state.config,
          queryOptions: payload,
        },
      };
    case socketActions.setPusherChannel.type:
      return {
        ...state,
        pusherChannel: payload,
      };
    case socketActions.setPusherReady.type:
      return {
        ...state,
        isPusherReady: true,
      };
    case bootstrapActions.setServiceAccounts.type:
      return {
        ...state,
        hasNoServiceAccounts: false,
        serviceAccounts: payload,
      };
    case bootstrapActions.setService.type:
      return {
        ...state,
        service: payload,
        source: { ...state.source, integration: payload.name },
        config: { ...state.config, integrationName: payload.name },
      };

    case bootstrapActions.setServiceAccountId.type:
      return {
        ...state,
        source: { ...state.source, service_account_id: `${payload}` },
      };
    case queryActions.setVisualisationError.type:
      return {
        ...state,
        visualisationError: action.error,
        visualisationNotificationType: 'error',
      };
    case bootstrapActions.setGraph.type:
      return { ...state, mainGraph: payload };
    case bootstrapActions.setAPISettings.type:
      return { ...state, api: payload };
    case bootstrapActions.setUiOptions.type:
      return {
        ...state,
        uiOptions: uiOptionsReducer(
          state.uiOptions,
          action,
          state.mainGraph,
          state.entryResources,
          state.config.type,
        ),
      };
    case bootstrapActions.setPrerequisiteOptions.type:
      return {
        ...state,
        uiOptions: uiOptionsReducer(state.uiOptions, action),
      };
    case configActions.setSource.type:
      return {
        ...state,
        source: payload,
        visualisationNotificationType: 'loading',
      };
    case bootstrapActions.setGlobalFiltersFromQueries.type:
    case bootstrapActions.setPrerequisitesFromQueries.type:
    case queryActions.setPrerequisites.type:
      return {
        ...state,
        uiQuery: uiQueryReducer(state.uiQuery, action),
      };
    case bootstrapActions.setRawQueries.type:
      return {
        ...state,
        queries: payload || initialState.queries,
      };
    case bootstrapActions.setInitialQueries.type: {
      let initialQuery = payload;
      const {
        config: { detailsMetricResource },
      } = state;

      if (detailsMetricResource) {
        entryResources = [detailsMetricResource];
      } else {
        // Store only the first one from raw query to detect a possible necessary
        // change of entry
        entryResources = [initialQuery[0].select[0].resource];
      }

      const vipFilters = getFieldsByType(
        state.mainGraph,
        FILTER_FIELD_TYPES,
        entryResources,
        state.config.type,
        (n) => n.is_vip,
      );

      uiQuery = uiQueryReducer(state.uiQuery, {
        ...action,
        payload: {
          queries: payload,
          graph: state.mainGraph,
          uiOptions: state.uiOptions,
          isTimespanComparisonOn: !isUndefined(state.config.timespanComparison),
          detailsMetricResource,
          vipFilters,
        },
      });

      const needChange =
        entryResources[0] !== uiQuery.metrics[0].node.getRoot();
      if (needChange) {
        entryResources[0] = uiQuery.metrics[0].node.getRoot();
      }
      // Reuse parsed value from uiQuery to get correct entryResources
      entryResources = uiQuery.metrics.map((m) => m.node.getRoot());

      uiOptions = uiOptionsReducer(
        state.uiOptions,
        {
          ...action,
          payload: uiQuery.metrics[0].node,
        },
        state.mainGraph,
        entryResources,
        state.config.type,
      );

      // Keep retro-compatibility with forced entryResources by switching
      if (needChange) {
        uiQuery = resolveNodesCrossResource(
          uiQuery,
          state.mainGraph,
          entryResources[0],
        );
        initialQuery = getQueriesFromUiQuery(
          uiQuery,
          state.mainGraph,
          entryResources,
          state.config.timespanComparison,
        );
      }
      uiOptions = appendSelectedNodes(uiOptions, uiQuery);

      return {
        ...state,
        uiQuery,
        queries: initialQuery,
        entryResources,
        uiOptions,
        config: getUpdatedConfig(
          state.config,
          uiQuery,
          state.subQueryResults,
          state.queryResults,
          state.queries,
          state.timezone,
        ),
      };
    }
    case configActions.updateSubQueryResults.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { subQueryResults } = state;
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const newSubQueryResults = { ...subQueryResults, ...payload };

      return {
        ...state,
        subQueryResults: newSubQueryResults,
        isLoadingSuggestions: false,
        config: getUpdatedConfig(
          state.config,
          state.uiQuery,
          newSubQueryResults,
          state.queryResults,
          state.queries,
          state.timezone,
        ),
      };
    case queryActions.setFilterForm.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { _filterModal: { field = {} } = {}, isLoadingSuggestions } = state;
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      let loading = isLoadingSuggestions;
      if (isLoadingSuggestions && field !== payload.field) {
        // Reset loading when changing field
        loading = false;
      }

      return {
        ...state,
        _filterModal: payload,
        isLoadingSuggestions: loading,
      };
    case configActions.recomputeQueries.type:
      queries = getQueriesFromUiQuery(
        state.uiQuery,
        state.mainGraph,
        state.entryResources,
        state.config.timespanComparison,
      );
      return { ...state, queries };
    case configActions.fetchVizDataStart.type:
      queries = getQueriesFromUiQuery(
        state.uiQuery,
        state.mainGraph,
        state.entryResources,
        state.config.timespanComparison,
      );
      return {
        ...state,
        queryFetchCursor: state.queryFetchCursor + 1,
        queryResults: [[]],
        queryIds: [],
        queries,
        queriesFetchDone: queries.map(() => false),
        isVisualisationDataComplete: false,
        visualisationNotificationType: 'loading',
      };
    case configActions.clearQueryResults.type:
      return {
        ...state,
        queryResults: [],
      };
    case configActions.setQueryIds.type:
      return {
        ...state,
        queryIds: payload,
      };
    case configActions.fetchVizDataSuccess.type:
      ({
        config,
        queries = [],
        queryResults = [],
        queriesFetchDone = [],
        queryIds,
        timezone,
        uiQuery,
      } = state);
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const i = queryIds.indexOf(payload.queryId);

      newQueryResults = [...queryResults];
      newQueriesFetchDone = [...queriesFetchDone];

      newQueryResults[i] = payload.data;
      newQueriesFetchDone[i] = payload.done;
      allFetchDone = every(newQueriesFetchDone, (f) => f === true);

      configWithDefaults = ensureConfig(
        config,
        {
          queryResults: newQueryResults,
          queries: state.queries,
          timezone: state.timezone,
        },
        uiQuery,
      );
      transformedData = transformData(
        queries,
        newQueryResults,
        configWithDefaults,
        timezone,
      );

      return {
        ...state,
        queryResults: newQueryResults,
        transformedData,
        config: configWithDefaults,
        queriesFetchDone: newQueriesFetchDone,
        isVisualisationDataComplete: allFetchDone,
        visualisationNotificationType: allFetchDone ? 'complete' : 'loading',
        visualisationError: null,
      };
    case socketActions.clearQueuedData.type:
      return {
        ...state,
        queuedData: initialState.queuedData,
      };
    case socketActions.queueFetchedData.type:
      return {
        ...state,
        queuedData: [...state.queuedData, payload],
      };
    case saveActions.isSaving.type:
      return {
        ...state,
        isSaving: payload,
      };
    case configActions.universalSetError.type:
      return {
        ...state,
        error: action.error,
        isConfigReady: true,
      };
    case queryActions.setMetricField.type: {
      const { field: metricField, index } = payload;

      let baseState = { ...state };
      // Switch visualisation type if current type is not supported and we've
      // got only one metric selected
      if (state.uiQuery.metrics.length === 1) {
        const availableVisualisations = getVisualisationsForMetric(metricField);
        if (!includes(availableVisualisations, state.config.type)) {
          const newType = availableVisualisations[0];

          baseState = updateVizType(state, newType);
        }
      }

      newConfig = { ...baseState.config };

      // Reset the widget title when the metric changes
      delete newConfig.title;

      entryResources = [...baseState.entryResources];
      entryResources[index] = metricField.getRoot();

      const vipFilters = getFieldsByType(
        state.mainGraph,
        FILTER_FIELD_TYPES,
        entryResources,
        state.config.type,
        (n) => n.is_vip,
      ).map((node) => ({ node, extra: {} }));

      uiQuery = uiQueryReducer(baseState.uiQuery, action, newConfig.type);
      uiQuery = { ...uiQuery, vipFilters };

      uiOptions = uiOptionsReducer(
        baseState.uiOptions,
        {
          ...action,
          payload: metricField,
        },
        baseState.mainGraph,
        entryResources,
        newConfig.type,
      );

      if (index === 0) {
        // Swap selected nodes with nodes from new MAIN resource (e.g. index 0)
        if (baseState.entryResources[0] !== entryResources[0]) {
          uiQuery = resolveNodesCrossResource(
            uiQuery,
            baseState.mainGraph,
            entryResources[0],
          );
        }

        // We need to filter out nodes that are not available anymore
        uiQuery = getUiQueryBasedOnOptions(uiQuery, uiOptions, newConfig.type);

        // Metric with null timespans need to disable any timespan comparison
        if (!uiQuery.timespans || !uiQuery.timespans[0].node) {
          newConfig.timespanComparison = undefined;
        }
      }

      return {
        ...baseState,
        uiQuery,
        uiOptions,
        config: getUpdatedConfig(
          newConfig,
          uiQuery,
          baseState.subQueryResults,
          baseState.queryResults,
          baseState.queries,
          baseState.timezone,
        ),
        entryResources,
      };
    }
    case queryActions.setTimeField.type:
    case queryActions.setGroupByCategory.type:
    case queryActions.setSplitBy.type:
    case queryActions.setOrderBy.type:
    case queryActions.setOrderByExtra.type:
    case queryActions.setMetricAggregate.type:
    case queryActions.removeFilter.type:
    case queryActions.setGroupByBucket.type:
    case queryActions.setFilter.type:
    case queryActions.setVIPFilter.type:
    case bootstrapActions.setGlobalFilter.type:
    case queryActions.setDetailsColumns.type:
    case queryActions.addDetailsColumn.type:
    case queryActions.removeDetailsColumn.type:
      uiQuery = uiQueryReducer(state.uiQuery, action, state.config.type);
      config = getUpdatedConfig(
        state.config,
        uiQuery,
        state.subQueryResults,
        state.queryResults,
        state.queries,
        state.timezone,
      );

      return {
        ...state,
        uiQuery,
        config,
      };
    case queryActions.setGlobalFilter.type:
    case queryActions.clearPrerequisites.type:
      uiQuery = uiQueryReducer(state.uiQuery, action, state.config.type);
      queries = getQueriesFromUiQuery(
        uiQuery,
        state.mainGraph,
        state.entryResources,
        state.config.timespanComparison,
      );

      return {
        ...state,
        uiQuery,
        queries,
      };
    case queryActions.setTimeOperands.type:
      uiQuery = uiQueryReducer(state.uiQuery, action, state.config.type);
      newConfig = getUpdatedConfig(
        state.config,
        uiQuery,
        state.subQueryResults,
        state.queryResults,
        state.queries,
        state.timezone,
      );
      ({ api: { max_time_period: maxTimePeriod } = {} } = state);
      ({ timespanComparison, type: vizType } = state.config);

      // Turn off comparison if time span is too big
      // otherwise ensure the operator is still valid
      timespanComparison = canTimespanCompare(maxTimePeriod, payload)
        ? getComparisonForNewTimespan(timespanComparison, payload, vizType)
        : undefined;

      return {
        ...state,
        uiQuery,
        config: {
          ...newConfig,
          timespanComparison,
        },
      };
    case queryActions.removeMetric.type:
      uiQuery = uiQueryReducer(state.uiQuery, action, state.config.type);

      return {
        ...state,
        uiQuery,
        config: getUpdatedConfig(
          state.config,
          uiQuery,
          state.subQueryResults,
          state.queryResults,
          state.queries,
          state.timezone,
        ),
        entryResources: [
          ...state.entryResources.slice(0, payload),
          ...state.entryResources.slice(payload + 1),
        ],
      };
    case queryActions.addMetric.type:
      newState = { ...state };

      if (MAX_NUMBER_OF_METRICS[state.config.type] <= 1) {
        newState = updateVizType(state, 'line');
      }

      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const {
        uiOptions: { metrics },
        uiQuery: { metrics: uiQueryMetrics },
      } = newState;
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const availableMetrics = flatten(
        getAvailableMetrics(metrics).map((g) => g.options),
      );
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { node: lastMetric } = last(uiQueryMetrics);
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      let compatibleMetrics;
      if (state.config.type !== 'table') {
        const { type: metricType } = lastMetric;
        compatibleMetrics = getCompatibleMetrics(
          availableMetrics,
          metricType,
          newState.config.type,
          newState.entryResources[0],
        );
      } else {
        compatibleMetrics = availableMetrics;
      }

      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const cursor = compatibleMetrics.indexOf(lastMetric);
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const nextMetric =
        compatibleMetrics[(cursor + 1) % compatibleMetrics.length]; // Take first if end of list
      newState.entryResources = [...state.entryResources, nextMetric.getRoot()];

      uiQuery = uiQueryReducer(
        newState.uiQuery,
        {
          ...action,
          payload: nextMetric,
        },
        newState.config.type,
      );

      return {
        ...newState,
        uiQuery,
        config: getUpdatedConfig(
          newState.config,
          uiQuery,
          newState.subQueryResults,
          newState.queryResults,
          newState.queries,
          newState.timezone,
        ),
      };
    case queryActions.fetchFilterSuggestionsStart.type:
      return {
        ...state,
        isLoadingSuggestions: true,
      };
    case queryActions.toggleDataSourcePanel.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { showDataSourcePanel, addingNewConnection } = payload;
      return {
        ...state,
        showDataSourcePanel,
        addingNewConnection,
      };

    case bootstrapActions.setNoServiceAccounts.type:
      return {
        ...state,
        hasNoServiceAccounts: true,
      };
    case configActions.setPreset.type:
      ({ queries, type: vizType, title, config = {} } = payload);

      return {
        ...state,
        config: { ...state.config, ...config, type: vizType, title },
        preset: { queries, type: vizType, title, config },
      };
    case configActions.setCancelledQueries.type:
      return {
        ...state,
        cancelledQueries: new Set([...state.cancelledQueries, ...payload]),
      };
    case queryActions.setTimespanComparisonOn.type:
      timespanComparison = getTSComparisonForVisualisation(state.config.type);

      return {
        ...state,
        config: {
          ...state.config,
          timespanComparison,
        },
      };
    case queryActions.setTimespanComparisonOff.type:
      return {
        ...state,
        config: {
          ...state.config,
          timespanComparison: undefined,
        },
      };
    case queryActions.setTimespanComparisonOperator.type: {
      return {
        ...state,
        config: {
          ...state.config,
          timespanComparison: {
            ...state.config.timespanComparison,
            operator: payload,
          },
        },
      };
    }
    case queryActions.setTimespanComparisonType.type:
      newConfig = ensureConfig(
        {
          ...state.config,
          timespanComparison: {
            ...state.config.timespanComparison,
            type: payload,
          },
        },
        {
          queryResults: state.queryResults,
          queries: state.queries,
          timezone: state.timezone,
        },
        state.uiQuery,
      );
      return {
        ...state,
        transformedData: transformData(
          state.queries,
          state.queryResults,
          newConfig,
          state.timezone,
        ),
        config: newConfig,
      };
    case queryActions.validate.type:
      ({
        api: { max_time_period: maxTimePeriod } = {},
        entryResources,
        config: { type: vizType },
      } = state);
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const {
        serviceAccounts: [
          {
            service: { title: serviceTitle },
          },
        ],
      } = state;

      // Clear existing error state on revalidate
      previewError = null;

      // Check for incompatible metric types
      if (
        vizType !== 'table' &&
        !hasCompatibleMetricTypes(state.uiQuery.metrics)
      ) {
        previewError = new Error(
          formatMessage('universal.error.yAxisTypeMismatch'),
        );
      } else if (
        vizType !== 'table' &&
        state.uiQuery.metrics.length > 1 &&
        some(state.uiQuery.metrics, ({ node: { primary_resource: pm } }) => {
          return pm !== entryResources[0];
        })
      ) {
        // Check for resources not matching entryResources
        previewError = new Error(
          formatMessage('universal.error.incompatibleResources'),
        );
      }

      // Check for not supported metrics for current visualisation
      if (
        some(
          state.uiQuery.metrics,
          ({ node: { supported_visualisations: sv } }) =>
            sv && !sv.includes(vizType),
        )
      ) {
        previewError = new Error(
          formatMessage('universal.error.incompatibleMetricForViz'),
        );
      }

      // Check if multiple metrics and details type
      if (
        state.uiQuery.metrics.length > 1 &&
        !previewError &&
        some(
          state.uiQuery.metrics,
          ({ node: { type: nt } }) => nt === 'details',
        )
      ) {
        previewError = new Error(
          formatMessage('universal.error.detailsMetricIncompatible'),
        );
      }

      // Check for timespans that exceed the API data limit
      if (
        maxTimePeriod &&
        state.uiQuery.timespans.some(
          (timespan) =>
            getMsFromTimespan(timespan.extra.operands) > maxTimePeriod,
        )
      ) {
        const timeLimit = getInputFromDuration(
          maxTimePeriod,
          'milliseconds',
          1,
          'days',
        );
        previewError = new Error(
          formatMessage('universal.error.timespanTooLarge', {
            service: serviceTitle,
            timeLimit: timeLimit.days,
          }),
        );
      }

      return {
        ...state,
        previewError,
        queryResults: previewError ? [] : state.queryResults,
        visualisationNotificationType: previewError
          ? null
          : state.visualisationNotificationType,
      };
    case configActions.updateColumnNumberFormat.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { index: formatIndex, format } = action.payload;
      ({ config, queries = [], queryResults = [], timezone } = state);

      numberFormat = state.config.numberFormat
        ? [...state.config.numberFormat]
        : [];
      numberFormat[formatIndex] = {
        ...numberFormat[formatIndex],
        ...format,
      };
      newConfig = {
        ...config,
        numberFormat,
      };

      return {
        ...state,
        config: newConfig,
        transformedData: transformData(
          queries,
          queryResults,
          newConfig,
          timezone,
        ),
      };

    case configActions.disableQueries.type:
      return {
        ...state,
        queriesEnabled: false,
      };
    default:
      return state;
  }
};

export default universalConfigReducer;
