import { chain, get, has, identity, isString, set } from 'lodash';

function prepareTranslators(rules) {
  return chain(rules)
    .map((v) => (isString(v) ? [v] : v)) // eslint-disable-line no-confusing-arrow
    .map(([sourceKey, translator, targetKey]) => [
      sourceKey,
      [translator || identity, targetKey || sourceKey],
    ])
    .value();
}

function apply(Rules = [], cfg = {}, lastCfg = {}, meta = {}) {
  const translators = prepareTranslators(Rules);

  return chain(translators)
    .filter(([sourcePath]) => has(cfg, sourcePath))
    .map(([sourcePath, [translator, targetPath]]) => [
      get(cfg, sourcePath),
      translator,
      targetPath,
    ])
    .map(([value, translator, targetPath]) => [
      targetPath,
      translator(value, get(lastCfg, targetPath), lastCfg, meta),
    ])
    .filter(([, value]) => value !== undefined)
    .reduce((memo, pair) => set(memo, ...pair), {})
    .value();
}

function translate(RuleSet, cfg, lastCfg) {
  if (!RuleSet[cfg.type]) {
    return {};
  }

  return apply(RuleSet[cfg.type], cfg, lastCfg, {});
}

function applyAndMerge(Rules, cfg, lastCfg, meta) {
  return { ...lastCfg, ...apply(Rules, cfg, lastCfg, meta) };
}

export { apply, applyAndMerge, translate };
