import * as Tracking from '@Tracking';

import { isGraphQLDashboard } from '../services/concierge-service/graphql-helpers';
import * as toastActions from '../toast/actions';
import * as userActions from '../user/actions/user-actions';

import getQueryClient from './get-query-client';
import getReduxStore from './get-redux-store';

const initApp = () => {
  const store = getReduxStore();
  const queryClient = getQueryClient();
  Tracking.setReduxStore(store);

  // On GraphQL dashboards we're loading the user and organization
  // in the actions when initializing the page.
  if (!isGraphQLDashboard()) {
    store.dispatch(userActions.initialiseNonGraphQLPage());
  }

  store.dispatch(toastActions.showScheduledToast());

  return {
    getStore: () => store,
    getQueryClient: () => queryClient,
  };
};

export default initApp;
