import { map, zipObject } from 'lodash';

export const mapSubQueryResult = (query, subQueryPayload) => {
  if (!subQueryPayload) return null;
  const selectPaths = map(query.select, ({ path }) => path);

  return map(subQueryPayload, (res) => {
    return zipObject(selectPaths, res);
  });
};
