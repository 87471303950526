const pickFormat = (type) => {
  switch (type) {
    case 'percentage':
      return 'percent';
    case 'money':
      return 'currency';
    case 'duration':
      return 'duration';
    default:
      return 'decimal';
  }
};

export { pickFormat };
