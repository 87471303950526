import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';

const scheduledPostSchemaProperties = {
  'Scheduled post ID': SchemaTypes.string.isRequired,
  'Scheduled post destination': SchemaTypes.string.isRequired,
};

export const scheduledPostCreated = defineEvent(
  'Scheduled Post - Created',
  scheduledPostSchemaProperties,
);

export const scheduledPostUpdated = defineEvent(
  'Scheduled Post - Updated',
  scheduledPostSchemaProperties,
);

export const scheduledPostDeleted = defineEvent(
  'Scheduled Post - Deleted',
  scheduledPostSchemaProperties,
);
