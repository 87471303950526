import * as toastActions from '../actions/toast-actions';
import * as toastConstants from '../toast-constants';

const initialState = {
  toast: undefined,
};

const toastLauncherReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case toastActions.showToast.type:
      return {
        ...state,
        toast: payload,
      };
    case toastActions.hideToast.type:
      return {
        ...state,
        toast: undefined,
      };
    case toastActions.showSuccessToast.type:
      return {
        ...state,
        toast: {
          type: toastConstants.SUCCESS,
          message: payload,
        },
      };
    case toastActions.showErrorToast.type:
      return {
        ...state,
        toast: {
          type: toastConstants.ERROR,
          message: payload,
        },
      };
    default:
      return state;
  }
};

export default toastLauncherReducer;
