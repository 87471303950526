/**
 * SessionTracker was created when moving away from gecko-js.
 * It's re-implementing the functionality from session-tracker.js
 * in gecko-js.
 *
 * When we introduced Snowplow we kept our legacy
 * mixpanel tracking around because there is still a lot of
 * work to be done around organising the Snowplow data to make
 * useful to everyone.
 */
import Cookies from 'js-cookie';

import { getNavigator, getWindow } from '../../lib/global';
import { fetchJSONWithoutCSRF } from '../../universal/fetch';

import { isTrackingEnabled } from './tracker-helpers';

class SessionTracker {
  constructor(sessionType, trackingProperties = {}) {
    this.sessionType = sessionType;
    this.trackingProperties = trackingProperties;
    this.sessionId = this.getSessionIdFromCookie();
  }

  getCookieName() {
    return `${SessionTracker.COOKIE_PREFIX}${this.sessionType}`;
  }

  userSuffixedCookieName() {
    const { gb_user_id: userId } = this.trackingProperties;
    return `${this.getCookieName()}_${userId}`;
  }

  // getCookie uses the new user prefixed cookie if that doesn't
  // exist it attempts the fallback to the default tracker cookie value
  getSessionIdFromCookie() {
    const userSuffixedCookie = Cookies.get(this.userSuffixedCookieName());

    if (userSuffixedCookie) {
      return userSuffixedCookie;
    }

    return Cookies.get(this.getCookieName());
  }

  start() {
    if (!isTrackingEnabled()) {
      return;
    }

    this.startLegacyTracking();
  }

  // Set cookie sets the cookie with the specified sessionId
  // if force creation is true it will set the cookie always
  setCookie(sessionId, forceCreation) {
    // All new tracker sessions use the new user prefixed cookie key
    const key = this.userSuffixedCookieName();

    if (forceCreation || !Cookies.get(key)) {
      Cookies.set(key, sessionId);
      return;
    }
  }

  // deleteExistingSessionCookies deletes all existing track session
  // cookies - if there are active sessions the cookie will be recreated
  // within the next timeout interval (currently 15s)
  deleteExistingSessionCookies() {
    const cookies = document.cookie.split('; ');

    for (const cookie of cookies) {
      const [key] = cookie.split('=');

      if (key && key.startsWith(SessionTracker.COOKIE_PREFIX)) {
        Cookies.remove(key);
      }
    }
  }

  startLegacyTracking(forceStart) {
    if (this.sessionId && !forceStart) {
      this.updateSession();
      return;
    }

    fetchJSONWithoutCSRF(`${getWindow().TRACKING_HOST}/session`, {
      method: 'POST',
      body: JSON.stringify({
        ...this.trackingProperties,
        cookie_enabled: getNavigator().cookieEnabled,
        session_type: this.sessionType,
      }),
    }).then(({ session_id: sessionId }) => {
      this.sessionId = sessionId;
      this.deleteExistingSessionCookies();
      this.setCookie(sessionId, true);
      this.startUpdateTimer();
    });
  }

  updateSession() {
    fetchJSONWithoutCSRF(
      `${getWindow().TRACKING_HOST}/sessions/${this.sessionId}`,
    )
      .then(() => {
        this.startUpdateTimer();
        this.setCookie(this.sessionId, false);
      })
      .catch(({ status }) => {
        if (status === 404) {
          this.startLegacyTracking(true);
        } else {
          this.startUpdateTimer();
        }
      });
  }

  startUpdateTimer() {
    setTimeout(() => {
      this.updateSession();
    }, SessionTracker.LEGACY_INTERVAL);
  }
}

SessionTracker.LEGACY_INTERVAL = 15 * 1e3;
SessionTracker.COOKIE_PREFIX = 'gb_tracking_graphq_';

export default SessionTracker;
