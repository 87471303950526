import { trackEvent } from '@Tracking';
import { userDeleted } from '@Tracking/events';

import { formatMessage } from '../../lib/i18n';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { track } from '../../lib/tracker';
import { deleteUser as deleteUserService } from '../../services/permission-service';
import * as toastActions from '../../toast/actions';

export const usersFetchStart = createAction('People:USERS_FETCH_START');
export const usersFetchSuccessful = createAction(
  'People:USERS_FETCH_SUCCESSFUL',
);
export const usersFetchFailed = createErrorAction('People:USERS_FETCH_FAILED');
export const deleteUserStart = createAction('People:DELETE_USER_START');
export const deleteUserSuccessful = createAction(
  'People:DELETE_USER_SUCCESSFUL',
);
export const deleteUserFailed = createAction('People:DELETE_USER_FAILED');

export const deleteUser =
  (groupId, userId, permission, source) => async (dispatch) => {
    try {
      dispatch(deleteUserStart({ userId }));
      // groupId is required until we have a new dedicated end point.
      await deleteUserService(groupId, userId);

      dispatch(deleteUserSuccessful({ userId }));
      dispatch(
        toastActions.showSuccessToast(
          formatMessage('people.deleteUserSuccessful'),
        ),
      );

      track('Deleted user', {
        properties: {
          source,
          target_user_permission: permission,
        },
      });
      trackEvent(userDeleted());
    } catch (e) {
      dispatch(deleteUserFailed({ userId }));
      dispatch(toastActions.showGenericErrorToast());
    }
  };
