import { values } from 'lodash';

import { LOCATIONS } from '@Tracking/constants';
import { DashboardContext } from '@Tracking/contexts';
import { TRACKING_LAYOUT_TYPES } from '@Tracking/contexts/dashboard';
import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';

import {
  VALID_THEMES,
  VALID_WIDGET_STYLES,
} from '../../dashboard/themes/theme-constants';

const sharedDashboardCreatedProps = {
  'Created dashboard type': SchemaTypes.oneOf(values(TRACKING_LAYOUT_TYPES))
    .isRequired,
  Location: SchemaTypes.oneOf([
    LOCATIONS.DashboardPickerOnDashboard,
    LOCATIONS.HeaderDashboardPicker,
  ]).isRequired,
};

export const dashboardCreated = defineEvent(
  'Dashboard - Created',
  sharedDashboardCreatedProps,
  DashboardContext,
);

export const dashboardCreatedWithoutPreviousDashboard = defineEvent(
  'Dashboard - Created',
  sharedDashboardCreatedProps,
);

export const dashboardChangedLayout = defineEvent(
  'Dashboard - Changed layout type',
  {
    'From layout': SchemaTypes.oneOf(values(TRACKING_LAYOUT_TYPES)).isRequired,
    'To layout': SchemaTypes.oneOf(values(TRACKING_LAYOUT_TYPES)).isRequired,
  },
  DashboardContext,
);

export const dashboardCopied = defineEvent(
  'Dashboard - Copied',
  {},
  DashboardContext,
);

export const dashboardCopiedSharingLink = defineEvent(
  'Dashboard - Copied sharing link',
  {
    Location: SchemaTypes.oneOf([
      LOCATIONS.DashboardShareMenu,
      LOCATIONS.AdminDashboards,
    ]).isRequired,
  },
  DashboardContext,
);

export const dashboardCreatedSharingLink = defineEvent(
  'Dashboard - Created sharing link',
  {},
  DashboardContext,
);

export const dashboardDeleted = defineEvent(
  'Dashboard - Deleted',
  {
    Location: SchemaTypes.oneOf([
      LOCATIONS.DashboardDeleteModal,
      LOCATIONS.AdminDashboards,
    ]).isRequired,
  },
  DashboardContext,
);

export const dashboardDeletedSharingLink = defineEvent(
  'Dashboard - Deleted sharing link',
  {},
  DashboardContext,
);

export const dashboardRegeneratedSharingLink = defineEvent(
  'Dashboard - Regenerated sharing link',
  {
    Location: SchemaTypes.oneOf([
      LOCATIONS.DashboardShareMenu,
      LOCATIONS.AdminDashboards,
    ]).isRequired,
  },
  DashboardContext,
);

export const dashboardSavedCustomization = defineEvent(
  'Dashboard - Saved customization',
  {
    Theme: SchemaTypes.oneOf(VALID_THEMES).isRequired,
    'Custom Theme Background Color': SchemaTypes.string,
    'Custom Theme Widget Style': SchemaTypes.oneOf(VALID_WIDGET_STYLES),
    'Scale to fit': SchemaTypes.bool.isRequired,
    'TV font': SchemaTypes.bool.isRequired,
    'Show footer': SchemaTypes.bool.isRequired,
    'Show clock': SchemaTypes.bool.isRequired,
    'Show logo': SchemaTypes.bool.isRequired,
    'Number of columns': SchemaTypes.number.isRequired,
  },
  DashboardContext,
);

export const dashboardTriggeredRemoteRefresh = defineEvent(
  'Dashboard - Triggered remote refresh',
  {},
  DashboardContext,
);
