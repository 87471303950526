import { isValidSelection } from '../selection-helpers';

const getDefaultConfig = (selections = []) => ({
  type: 'geckometer',
  numberFormat: {},
  value: selections[0],
});

const getSelectionsFromConfig = (config) => [
  [config.value[1], config.value[1]],
];

const isValid = (cfg) => {
  return isValidSelection(cfg.value);
};

const getSelectionTargets = (config, activeFieldPath) => {
  switch (activeFieldPath) {
    case 'value':
      return [-1];
    default:
      return [];
  }
};

const configMappingRules = {
  column: ['dataRange', 'numberFormat', 'title'],
  line: [
    'dataRange',
    ['minYAxis', (min) => (min ? { value: min } : undefined), 'min'], // eslint-disable-line no-confusing-arrow
    ['maxYAxis', (max) => (max ? { value: max } : undefined), 'max'], // eslint-disable-line no-confusing-arrow
    'numberFormat',
    'title',
  ],
  text: ['dataRange', 'title'],
  number: ['dataRange', 'numberFormat', 'indicators', 'title'],
  leaderboard: ['dataRange', 'numberFormat', 'title'],
  bar: ['dataRange', 'numberFormat', 'title'],
  table: ['dataRange', 'title'],
};

export default {
  getDefaultConfig,
  getSelectionsFromConfig,
  isValid,
  getSelectionTargets,
  configMappingRules,
};
