export const DATA_FETCH_ERROR = 'SalesforceConfig:DATA_FETCH_ERROR';
export const DATA_FETCH_START = 'SalesforceConfig:DATA_FETCH_START';
export const DATA_FETCH_SUCCESSFUL = 'SalesforceConfig:DATA_FETCH_SUCCESSFUL';
export const INIT_CONFIG_ERROR = 'SalesforceConfig:INIT_CONFIG_ERROR';
export const INIT_CONFIG_LOADING = 'SalesforceConfig:INIT_CONFIG_LOADING';
export const INIT_CONFIG_SUCCESSFUL = 'SalesforceConfig:INIT_CONFIG_SUCCESSFUL';
export const SET_STATE_PROPS = 'SalesforceConfig:SET_STATE_PROPS';
export const SWITCH_VISUALISATION_TYPE =
  'SalesforceConfig:SWITCH_VISUALISATION_TYPE';
export const UPDATE_CONFIG = 'SalesforceConfig:UPDATE_CONFIG';
export const WIDGET_CONFIG_FETCH_FAILED =
  'SalesforceConfig:WIDGET_CONFIG_FETCH_FAILED';
export const WIDGET_CREATION_START = 'SalesforceConfig:WIDGET_CREATION_START';
export const WIDGET_CREATION_FAILED = 'SalesforceConfig:WIDGET_CREATION_FAILED';
export const WIDGET_UPDATE_START = 'SalesforceConfig:WIDGET_UPDATE_START';
export const TOGGLE_TABLE_HEADERS = 'SalesforceConfig:TOGGLE_TABLE_HEADERS';
export const SET_PROGRESS_GOAL = 'SalesforceConfig:SET_PROGRESS_GOAL';
export const SET_GOAL = 'SalesforceConfig:SET_GOAL';
