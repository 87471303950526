export default [
  {
    id: 'ae2f6e5a-9ef5-46a6-879e-6c7138b3ea59',
    title: 'Reach on Instagram',
    description:
      'Number of people who saw your ads on Instagram at least once.',
    category: 'Performance',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'reach',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'publisher_platform',
            operator: 'in',
            operands: ['instagram'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '2f4d376d-b8ec-496e-8d8f-f060fa46a7c6',
    title: 'Reach on Facebook',
    description: 'Number of people who saw your ads on Facebook at least once.',
    category: 'Performance',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'reach',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'publisher_platform',
            operator: 'in',
            operands: ['facebook'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '534308e6-7ea0-41a1-837c-c407065cc327',
    title: 'Unique clicks (all)',
    description: 'Number of people who performed a click (all).',
    category: 'Performance',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'unique_clicks',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [1, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '580b2184-e9f2-49bc-b001-02cc78c017b0',
    title: 'Unique CTR (all)',
    description:
      'Percentage of times people saw your ad and performed a click (all).',
    category: 'Performance',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'unique_ctr',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [1, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: 'e9912cd3-3cf4-4a14-89fd-b5ba1ff4905d',
    title: 'Amount spent in last 7 days',
    description: 'Estimated total amount of money you’ve spent.',
    category: 'Costs',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'spend',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '4d9a5e29-ee6f-45ff-a1e3-e305bcc6f281',
    title: 'CPC on Instagram',
    description: 'Average cost for each click (all) on Instagram.',
    category: 'Costs',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'cpc',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'publisher_platform',
            operator: 'in',
            operands: ['instagram'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: 'ff40939e-5df6-40f9-89c6-17925ca511b0',
    title: 'CPC on Facebook',
    description: 'Average cost for each click (all) on Facebook.',
    category: 'Costs',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'cpc',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'publisher_platform',
            operator: 'in',
            operands: ['facebook'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: 'ba7dfd71-5435-479b-a820-a465a8b27d77',
    title: 'Cost per lead',
    description: 'Average cost of a lead.',
    category: 'Costs',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'cost_per_lead',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: 'b9977809-119b-435b-91de-97ce81df9a94',
    title: 'Number of completed registrations',
    description: 'Completed registrations that are attributed to your ads. ',
    category: 'Conversions',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'actions_complete_registration',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '9bb4e371-0dcd-4df8-8fc1-8b50274efc47',
    title: 'Number of purchases',
    description: 'Purchases that are attributed to your ads.',
    category: 'Conversions',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'actions_purchase',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '32336990-a7fd-448e-8c8e-bc84aa8fe791',
    title: 'Number of leads',
    description: 'Leads that are attributed to your ads.',
    category: 'Conversions',
    type: 'geckometer',
    icon: {
      type: 'icon',
      value: 'geckometer',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'actions_lead',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '983e8fe4-00a3-469d-997e-81a0574a2b24',
    title: 'Value of completed registrations',
    description: 'Value of all completed registrations attributed to your ads.',
    category: 'Conversions',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'action_values_complete_registration',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '9e7658e4-dd73-4aff-9293-ac7152d78b74',
    title: 'Value of purchases',
    description: 'Value of all purchases attributed to your ads.',
    category: 'Conversions',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'action_values_purchase',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: 'c00a5639-66d8-4781-94d2-f1debf35b8f8',
    title: 'Value of leads',
    description: 'Value of all leads attributed to your ads.',
    category: 'Conversions',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'action_values_lead',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '4f89d498-63c8-4282-8412-b501fdb414ff',
    title: 'Number of app installs',
    description: 'Mobile app installs attributed to your ads.',
    category: 'Campaign results',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'actions_mobile_app_install',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '12ab05f7-521b-4470-86fb-a71940abd096',
    title: 'Number of leads from a lead form',
    description: 'Leads collected through lead form ads/',
    category: 'Campaign results',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'actions_leadgen_other',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
  {
    id: '1f359c00-407e-4372-8ddf-d5328a071718',
    title: 'Number of 3-second video views',
    description: 'Times your video ads were watched for at least 3 seconds.',
    category: 'Campaign results',
    type: 'number',
    icon: {
      type: 'icon',
      value: 'number',
    },
    queries: [
      {
        select: [
          {
            resource: 'insights',
            path: 'actions_video_view',
            aggregate: 'sum',
          },
        ],
        filter: [
          {
            resource: 'insights',
            path: 'date_start',
            operator: 'timespan',
            operands: [7, 'day'],
          },
          {
            resource: 'insights',
            path: 'account_id',
            operator: 'in',
          },
        ],
      },
    ],
  },
];
