import { has, isUndefined } from 'lodash';

import { parseNumberBasedOnFormat } from '../../lib/parse-number';

function transform(
  payload,
  { goal, comparison, reverseGoalDirection } = {},
  timezone,
  initialContext = {},
) {
  const newContext = {};

  const { format } = initialContext;

  if (!isUndefined(goal)) {
    newContext.threshold = parseNumberBasedOnFormat(goal, format);
  }

  if (
    has(comparison, 'startingValue') &&
    !isUndefined(comparison.startingValue)
  ) {
    newContext.startingValue = parseNumberBasedOnFormat(
      comparison.startingValue.toString(),
      format,
    );
  }

  if (!isUndefined(reverseGoalDirection)) {
    newContext.reverseGoalDirection = reverseGoalDirection;
  }

  return { ...initialContext, ...newContext };
}

export { transform };
