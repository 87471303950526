import { chain, filter, isArray, isUndefined, last, result } from 'lodash';

import { update } from '../../data-sources/actions/data-sources-actions';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import {
  getIntegrationConnections,
  integrationConnectionsToSeviceAccounts,
} from '../../services/concierge-service/integration-connections';
import { getServices } from '../../services/management-service';
import { ERROR_TYPES } from '../components/services/spreadsheets-connector/types';

import fetchFiles from './fetch-files-action';

export const initDataSourceStart = createAction('InitDataSource:LOADING');
export const initDataSourceSuccessful = createAction('InitDataSource:INIT');
export const initDataSourceFailed = createErrorAction(
  'InitDataSource:INIT_ERROR',
);
export const fileFetchStart = createAction('InitDataSource:INITIAL_FILE_FETCH');

function getFreshServiceAccountId(serviceAccounts, serviceName) {
  const serviceAccount = filter(serviceAccounts, {
    service: { name: serviceName },
  });

  const serviceAccountId = result(last(serviceAccount), 'id');

  return serviceAccountId && parseInt(serviceAccountId, 10);
}

function getDefaultService(filteredServices, serviceAccounts) {
  return chain(filteredServices)
    .map((name) => {
      const saId = getFreshServiceAccountId(serviceAccounts, name);
      return [name, saId];
    })
    .find(([, id]) => !isUndefined(id))
    .thru((res) => (isArray(res) ? res : [])) // eslint-disable-line no-confusing-arrow
    .value();
}

const initDataSource =
  ({ services: filteredServices, serviceName, serviceType }) =>
  async (dispatch) => {
    dispatch(initDataSourceStart());
    if ('datasets' === serviceName) {
      // Datasets require none of this account nonsense, unfortunately it is hard
      // to skip it higher up since connectors do not have control of their own
      // destiny. FIDO.
      await fetchFiles('datasets', {})(dispatch);
      return;
    }

    try {
      const [services, integrationConnections] = await Promise.all([
        getServices(),
        getIntegrationConnections(filteredServices),
      ]);

      const serviceAccounts = integrationConnectionsToSeviceAccounts(
        integrationConnections,
      );

      // The Spreadsheets connection panel supports multiple services
      // e.g. gsheets, onedrive
      //
      // By default we want to show the first option that the user has
      // already connected to. Hopefully soon we can separate all this
      // out so this is handled inside the spreadsheets connection panel.
      const [_serviceName, serviceAccountId] = getDefaultService(
        filteredServices,
        serviceAccounts,
      );

      dispatch(
        initDataSourceSuccessful({
          serviceName: _serviceName,
          serviceAccounts,
          serviceAccountId,
        }),
      );

      dispatch(update(services));

      if (
        serviceAccountId &&
        serviceType !== 'universal' &&
        serviceType !== 'bauhaus' &&
        serviceName !== 'salesforce'
      ) {
        dispatch(fileFetchStart());
        await fetchFiles(_serviceName, { serviceAccountId })(dispatch);
      }
    } catch (error) {
      dispatch(
        initDataSourceFailed(error, {
          message: error.message,
          errorType: ERROR_TYPES.GENERIC,
        }),
      );
    }
  };

export default initDataSource;
