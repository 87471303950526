import { find } from 'lodash';

import createAction from '../../lib/redux/create-action';
import * as conciergeService from '../../services/concierge-service/screens';
import * as toastActions from '../../toast/actions';

// Actions
export const setView = createAction('ManageScreens:SET_VIEW');
export const openUpgradeModal = createAction(
  'ManageScreens:OPEN_UPGRADE_MODAL',
);
export const openModal = createAction('ManageScreens:OPEN_MODAL');
export const closeModal = createAction('ManageScreens:CLOSE_MODAL');
export const setScreens = createAction('ManageScreens:SET_SCREENS');

// Async actions
export const fetchScreensStart = createAction(
  'ManageScreens:FETCH_SCREENS_START',
);
export const fetchScreensSuccess = createAction(
  'ManageScreens:FETCH_SCREENS_SUCCESS',
);
export const fetchScreensFailed = createAction(
  'ManageScreens:FETCH_SCREENS_FAILED',
);

export const connectScreenStart = createAction(
  'ManageScreens:CONNECT_SCREEN_START',
);
export const connectScreenSuccess = createAction(
  'ManageScreens:CONNECT_SCREEN_SUCCESS',
);
export const connectScreenFailed = createAction(
  'ManageScreens:CONNECT_SCREEN_FAILED',
);

export const refreshScreenStart = createAction(
  'ManageScreens:REFRESH_SCREEN_START',
);
export const refreshScreenSuccess = createAction(
  'ManageScreens:REFRESH_SCREEN_SUCCESS',
);
export const refreshScreenFailed = createAction(
  'ManageScreens:REFRESH_SCREEN_FAILED',
);

export const refreshScreen = (id) => async (dispatch, getState) => {
  dispatch(refreshScreenStart(id));
  const {
    manageScreens: { screens },
  } = getState();

  try {
    const screen = find(screens, { id });

    if (!screen || !screen.content || !screen.content.id) {
      return;
    }

    if (screen.content.type === 'DASHBOARD') {
      await conciergeService.refreshDashboard(screen.content.id);
    } else if (screen.content.type === 'SHARING_LOOP') {
      await conciergeService.refreshSharingLoop(screen.content.id);
    }

    dispatch(refreshScreenSuccess(id));
  } catch (error) {
    dispatch(toastActions.showGenericErrorToast());
    dispatch(refreshScreenFailed(error));
  }
};

export const deleteScreenStart = createAction(
  'ManageScreens:DELETE_SCREEN_START',
);
export const deleteScreenSuccess = createAction(
  'ManageScreens:DELETE_SCREEN_SUCCESS',
);
export const deleteScreenFailed = createAction(
  'ManageScreens:DELETE_SCREEN_FAILED',
);

export const deleteScreen = (id) => async (dispatch) => {
  dispatch(deleteScreenStart(id));

  try {
    await conciergeService.deleteScreen(id);
    dispatch(deleteScreenSuccess(id));
  } catch (error) {
    dispatch(toastActions.showGenericErrorToast());
    dispatch(deleteScreenFailed(error));
  }
};

export const setScreenNameStart = createAction(
  'ManageScreens:SET_SCREEN_NAME_START',
);
export const setScreenNameSuccess = createAction(
  'ManageScreens:SET_SCREEN_NAME_SUCCESS',
);
export const setScreenNameFailed = createAction(
  'ManageScreens:SET_SCREEN_NAME_FAILED',
);

export const setScreenName = (id, name) => async (dispatch) => {
  dispatch(setScreenNameStart({ id, name }));

  try {
    await conciergeService.renameScreen(id, name);
    dispatch(setScreenNameSuccess({ id, name }));
  } catch (err) {
    dispatch(toastActions.showGenericErrorToast());
    dispatch(setScreenNameFailed(err));
  }
};

export const setScreenConnectionStart = createAction(
  'ManageScreens:SET_SCREEN_CONNECTION_START',
);
export const setScreenConnectionSuccess = createAction(
  'ManageScreens:SET_SCREEN_CONNECTION_SUCCESS',
);
export const setScreenConnectionFailed = createAction(
  'ManageScreens:SET_SCREEN_CONNECTION_FAILED',
);

export const setScreenConnection =
  (screenId, contentId, type, contentLegacyId) => async (dispatch) => {
    dispatch(
      setScreenConnectionStart({
        id: screenId,
        contentId,
        contentLegacyId,
        type,
      }),
    );

    try {
      const content = {
        type,
        id: contentId,
        legacyId: contentLegacyId,
      };

      await conciergeService.broadcastContentToScreen(screenId, content);

      dispatch(
        setScreenConnectionSuccess({
          id: screenId,
          contentId,
          contentLegacyId,
          type,
        }),
      );
    } catch (error) {
      dispatch(setScreenConnectionFailed(error));
    }
  };
