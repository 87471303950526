import { memoize, partial, without } from 'lodash';

import Pusher from '../../vendors/pusher';

import PusherAdapter from './pusher-adapter';

class PushManager {
  constructor(socket) {
    this.socket = socket;
    this.subscriptions = [];
  }

  bind(...args) {
    this.socket.connection.bind(...args);
  }

  unbind(...args) {
    this.socket.connection.unbind(...args);
  }

  subscribe(channelName, onPush) {
    let channel;
    if (this.subscriptions.indexOf(channelName) === -1) {
      this.subscriptions.push(channelName);
      channel = this.socket.subscribe(channelName);
      channel.bind_all(partial(onPush, channelName));
    }
  }

  unsubscribe(channelName) {
    this.socket.unsubscribe(channelName);
    this.subscriptions = without(this.subscriptions, channelName);
  }
}

PushManager.getInstance = memoize(() => {
  const socket = new PusherAdapter(Pusher);
  return new PushManager(socket);
});

export default PushManager;
