/* global API_HOST */
import { isUndefined } from 'lodash';

import {
  CONTAINER_AREA_KEY,
  WIDGET_POSITION_KEY,
} from '../dashboard/dashboard-constants';
import config from '../universal/config';
import fetchJSON from '../universal/fetch';
import { getDefaultSize } from '../visualisation/helpers';

import { getWindow } from './global';

const formatPatchedConfig = (config) => {
  const properties = Object.keys(config);

  return properties.reduce((acc, property) => {
    const shouldDeleteValue = isUndefined(config[property]);

    /*
     * Passing a property { '_x_delete': true } instructs
     * management api to completely delete x from the config
     */
    if (shouldDeleteValue) {
      return {
        ...acc,
        [`_${property}_delete`]: true,
      };
    }

    return {
      ...acc,
      [property]: config[property],
    };
  }, {});
};

const getInitialContainerArea = () => {
  const storedArea = getWindow().sessionStorage.getItem(CONTAINER_AREA_KEY);
  if (!storedArea) {
    return undefined;
  }

  const parsedArea = JSON.parse(storedArea);
  return {
    row_start: parsedArea.rowStart,
    column_start: parsedArea.columnStart,
    row_span: parsedArea.rowSpan,
    column_span: parsedArea.columnSpan,
  };
};

function create(body) {
  return fetchJSON(`${API_HOST}/widgets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(body),
  });
}

const getWidgetPosition = () => {
  const position = getWindow().sessionStorage.getItem(WIDGET_POSITION_KEY);

  return position ? JSON.parse(position) : {};
};

function createWithAutoPosition(data) {
  const { config = {}, config: { template: { type } = {} } = {} } = data;
  const [width, height] = getDefaultSize(type);

  return fetchJSON(`${API_HOST}/widgets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      ...getWidgetPosition(),
      pixel_width: width,
      pixel_height: height,
      ...data,
      config: {
        ...config,
        initial_container_area: getInitialContainerArea(),
      },
    }),
  });
}

function update(widgetId, data) {
  return fetchJSON(`${config.get().ManagementBaseURL}/widgets/${widgetId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
  });
}

function patchConfig(widgetId, config) {
  const formattedConfig = formatPatchedConfig(config);

  return fetchJSON(`${API_HOST}/widgets/${widgetId}/config`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(formattedConfig),
  });
}

function getConfig(widgetId) {
  return fetchJSON(`${API_HOST}/widgets/${widgetId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
  });
}

function copyToDashboard(widgetId, from, to, location) {
  // For Dashies, we are able to specify a location (ie. dashboard position) for the
  // copied widget to appear in
  const parseLocation = (locationToParse) => ({
    row_start: locationToParse.rowStart,
    column_start: locationToParse.columnStart,
    row_span: locationToParse.rowSpan,
    column_span: locationToParse.columnSpan,
  });

  const body = location
    ? JSON.stringify({
        config: { initial_container_area: parseLocation(location) },
      })
    : undefined;

  return fetchJSON(
    `${API_HOST}/dashboards/${from}/widgets/${widgetId}/copy?dashboard[]=${to}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body,
    },
  );
}

function updateLocation(widgetId, payload) {
  return fetchJSON(`${API_HOST}/widgets/${widgetId}/location`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  });
}

export {
  copyToDashboard,
  create,
  createWithAutoPosition,
  getConfig,
  patchConfig,
  update,
  updateLocation,
};
