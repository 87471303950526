import BauhausSerializer from './bauhaus';
import DatabaseSerializer from './database';
import DatasetsSerializer from './datasets';
import SalesforceSerializer from './salesforce';
import SpreadsheetsSerializer from './spreadsheets';
import UniversalSerializer from './universal';

export const TEMPLATES = {
  line: {
    type: 'line',
    version: 2,
  },
  column: {
    type: 'column',
    version: 1,
  },
  bar: {
    type: 'bar',
    version: 1,
  },
  geckometer: {
    type: 'geckometer',
    version: 1,
  },
  map: {
    type: 'map',
    version: 1,
  },
  number: {
    type: 'number',
    version: 2,
  },
  leaderboard: {
    type: 'leaderboard',
    version: 1,
  },
  table: {
    type: 'table',
    version: 1,
  },
  feed: {
    type: 'feed',
    version: 1,
  },
  funnel: {
    type: 'funnel',
    version: 1,
  },
};

export default function getSerializer(service) {
  switch (service) {
    case 'spreadsheets':
      return new SpreadsheetsSerializer();
    case 'salesforce':
      return new SalesforceSerializer();
    case 'integrationDatasets':
    case 'datasets':
      return new DatasetsSerializer();
    case 'database':
      return new DatabaseSerializer();
    case 'universal':
      return new UniversalSerializer();
    case 'bauhaus':
      return new BauhausSerializer();
    default:
      throw new Error(`No serializer for the service "${service}"`);
  }
}
