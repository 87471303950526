const updateById = (items, id, fn) =>
  items.map((item) => {
    if (item.id !== id) {
      return item;
    }

    return fn(item);
  });

export default updateById;
