import type { WrapOptionsInput } from '@Generated/graphql';

import type { WrapOptions } from '../lib/graphql-legacy-config-mappers';

type WrapOptionsWithName = WrapOptions & {
  __typename?: string;
};

export const toWrapOptionsInput = (
  currentOptions?: WrapOptionsWithName | null,
): WrapOptionsInput => {
  // When a widget is modified we always want to reset the autoDecision
  // as factors may have changed causing a change in whether we can wrap or not
  return currentOptions ? { mode: currentOptions?.mode } : { mode: 'AUTO' };
};
