type SupportedAuthStrategy =
  | 'cookie'
  | 'sharing_hash'
  | 'device_token'
  | 'public_hash';

type AuthenticationMethod = {
  strategy: SupportedAuthStrategy;
  value?: string;
};

type AuthStrategyCallback = () => void;

/**
 * AuthStrategy is essentially a global variable for storing the auth credentials we use for making requests
 * to our backends.
 *
 * This implementation predates contexts, and was probably the easiest way to ensure polecat was using the same
 * authentication details as gecko-js
 */
class AuthStrategy {
  private static instance = new AuthStrategy();

  /**
   * Access a global singleton of the auth strategy
   */
  public static getInstance = (): AuthStrategy => this.instance;

  private strategy?: SupportedAuthStrategy;
  private onAuthStrategySetListeners: AuthStrategyCallback[] = [];
  private value?: string;

  /**
   * Configure the auth strategy that all future API requests should use
   * @param details
   */
  set({ strategy, value }: AuthenticationMethod) {
    this.strategy = strategy;
    this.value = value;

    this.onAuthStrategySetListeners.forEach((cb) => cb());
  }

  /**
   * Returns any query string parameters required by the current authentication method.
   *
   * The parameters are already encoded and can be appended to an existing query string section
   * @returns
   */
  getQueryParam() {
    if (!this.strategy || this.strategy === 'cookie') {
      return null;
    }

    return `${this.strategy}=${this.value}`;
  }

  /**
   * Registers a callback that will be invoked when the page has chosen an auth strategy.
   *
   * This is useful in situations where we need to delay until (or retry after) an auth strategy
   * has been chosen because the backend API call will fail if there is no auth strategy.
   *
   * @param callback
   * @returns
   */
  onAuthStrategySet(callback: AuthStrategyCallback) {
    if (this.strategy) {
      callback();
    }

    this.onAuthStrategySetListeners.push(callback);

    return {
      destroy: () => {
        const i = this.onAuthStrategySetListeners.indexOf(callback);

        // Guard against `destroy()` being called several times
        if (i >= 0) {
          this.onAuthStrategySetListeners.splice(i, 1);
        }
      },
    };
  }
}

export default AuthStrategy;
