import { startsWith } from 'lodash';

// Support 3 or 6 character hex codes
// We don't support 4 or 8 character codes as we don't
// want semi transparent colours.
const HEX_CODE_REGEX = /^#?[0-9A-F]{3}([0-9A-F]{3})?$/i;

const isHexCode = (value) => HEX_CODE_REGEX.test(value);

// If the value is a valid hex code it is converted to
// a six digit hex code with a hash prefix.
const toHexCode = (value) => {
  if (!isHexCode(value)) {
    return null;
  }

  let hexCode = value;
  if (!startsWith(hexCode, '#')) {
    hexCode = `#${hexCode}`;
  }

  if (hexCode.length === 4) {
    hexCode = `#${hexCode[1]}${hexCode[1]}${hexCode[2]}${hexCode[2]}${hexCode[3]}${hexCode[3]}`;
  }

  return hexCode;
};

export { isHexCode, toHexCode };
