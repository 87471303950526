import LineChartSerializer from './line-chart';

class BarChartSerializer extends LineChartSerializer {}

BarChartSerializer.configDefaults = {
  template: {
    type: 'bar',
    version: 1,
  },
};

BarChartSerializer.configKeys = [
  'hasHeader',
  'title',
  'xAxis',
  'series',
  'goal',
  'reverseGoalDirection',
  'legends',
  'numberFormat',
];

export default BarChartSerializer;
