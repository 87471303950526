import { isNil, last, omitBy } from 'lodash';

import SchemaTypes from '../../schema-types';

const permissionMap = {
  power_user: 'admin',
};

const getEmailDomain = (email) => (email ? last(email.split('@')) : '');

export const formatUserPayload = ({
  job_role: jobRole,
  newsletter,
  permission,
  tracking_id: trackingId,
  id,
  email,
  signup_date: signupDate,
}) => {
  return {
    distinct_id: trackingId,
    'Newsletter subscriber': newsletter,
    Permission: permissionMap[permission] || permission,
    'User ID': `${id}`,
    'User signup date': signupDate,
    'User email domain': getEmailDomain(email),
    ...omitBy(
      {
        'Job role': jobRole,
      },
      isNil,
    ),
  };
};

const schema = {
  distinct_id: SchemaTypes.string.isRequired,
  'Job role': SchemaTypes.string,
  'Newsletter subscriber': SchemaTypes.bool.isRequired,
  Permission: SchemaTypes.oneOf(['read', 'admin', 'none']).isRequired,
  'User ID': SchemaTypes.string.isRequired,
  'User signup date': SchemaTypes.string, // it's possible for SSO invitees to have no signup date
  'User email domain': SchemaTypes.string.isRequired,
};

const resolve = ({ reduxState = {} } = {}) => {
  const { user: { user = {} } = {} } = reduxState;
  return formatUserPayload(user);
};

export default {
  schema,
  resolve,
};
