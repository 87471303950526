export default [
  {
    id: 'a169a5ea-8e18-4361-8d37-bc27e4437dea',
    title: 'Median first response time',
    description:
      'The median time that customers are waiting until they receive a response.',
    category: 'Time',
    type: 'number',
    icon: {
      type: 'text',
      value: '60s',
    },
    queries: [
      {
        select: [
          {
            resource: 'conversations',
            path: 'first_response_time',
            aggregate: 'median',
          },
        ],
        filter: [
          {
            resource: 'conversations',
            path: 'created_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
          {
            resource: 'conversations',
            path: 'inbound',
            operator: 'in',
            operands: [true],
            bound_to_metric: true,
          },
        ],
      },
    ],
  },
  {
    id: '3e38a063-3ac5-45dc-8d6a-88da871bd8a8',
    title: 'Median time to close',
    description:
      'The median time that it takes for a conversation to be closed.',
    category: 'Time',
    type: 'number',
    icon: {
      type: 'text',
      value: '60s',
    },
    queries: [
      {
        select: [
          {
            resource: 'conversations',
            path: 'time_to_close',
            aggregate: 'median',
          },
        ],
        filter: [
          {
            resource: 'conversations',
            path: 'first_action_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
        ],
      },
    ],
  },
  {
    id: '4e4d73dd-af08-4d1a-99ee-2b129e42c6e0',
    title: 'Top ticket solvers',
    description: 'Who is solving the most tickets.',
    category: 'Volume',
    type: 'leaderboard',
    icon: {
      type: 'icon',
      value: 'leaderboard',
    },
    queries: [
      {
        select: [
          {
            resource: 'conversations',
            path: 'assignee.name',
          },
          {
            resource: 'conversations',
            custom: 'closed_conversations',
            aggregate: 'count',
          },
        ],
        filter: [
          {
            resource: 'conversations',
            path: 'last_closed_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
          {
            resource: 'conversations',
            path: 'state',
            operator: 'in',
            operands: ['closed'],
            bound_to_metric: true,
          },
        ],
        group_by: [{ resource: 'conversations', path: 'assignee_id' }],
        order_by: [
          {
            resource: 'conversations',
            custom: 'closed_conversations',
            order: 'desc',
          },
        ],
      },
    ],
  },
  {
    id: '2c87a7fa-e816-4713-bb0b-93b122c334f8',
    title: 'New conversations',
    description: 'The number of conversations added within the last 7 days.',
    category: 'Volume',
    type: 'number',
    icon: {
      type: 'text',
      value: '123',
    },
    queries: [
      {
        select: [
          {
            resource: 'conversations',
            custom: 'created_conversations',
            aggregate: 'count',
          },
        ],
        filter: [
          {
            resource: 'conversations',
            path: 'created_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
          {
            resource: 'conversations',
            path: 'inbound',
            operator: 'in',
            operands: [true],
            bound_to_metric: true,
          },
        ],
      },
    ],
  },
  {
    id: '9a92c6de-dfe1-48fb-bb48-5c54f8f144c3',
    title: 'Unassigned conversations',
    description:
      'Number of conversations waiting for an agent to pick them up.',
    category: 'Volume',
    type: 'number',
    icon: {
      type: 'text',
      value: '123',
    },
    queries: [
      {
        select: [
          {
            resource: 'conversations',
            custom: 'created_conversations',
            aggregate: 'count',
          },
        ],
        filter: [
          {
            resource: 'conversations',
            path: 'created_at',
            operands: [7, 'day'],
            operator: 'timespan',
          },
          {
            resource: 'conversations',
            path: 'assignee_id',
            operator: 'is_null',
          },
          {
            resource: 'conversations',
            path: 'inbound',
            operator: 'in',
            operands: [true],
            bound_to_metric: true,
          },
        ],
      },
    ],
  },
  {
    id: '09846f84-fdf8-4f71-ac0b-6a8503d7f13f',
    title: 'Number of users',
    description: 'The total number of users on this account.',
    category: 'Volume',
    type: 'number',
    icon: {
      type: 'text',
      value: '123',
    },
    queries: [
      {
        select: [
          {
            resource: 'users',
            custom: 'current_users',
            aggregate: 'count',
          },
        ],
      },
    ],
  },
  {
    id: 'cb7065d1-443e-42f6-8558-42fa80de47f5',
    title: 'Number of companies',
    description: 'The total number of companies on this account.',
    category: 'Volume',
    type: 'number',
    icon: {
      type: 'text',
      value: '123',
    },
    queries: [
      {
        select: [
          {
            resource: 'companies',
            custom: 'current_companies',
            aggregate: 'count',
          },
        ],
      },
    ],
  },
];
