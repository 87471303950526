import { merge } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericTableTransform } from '../per-visualisation/table';

const pickFormat = (type) => {
  switch (type) {
    case 'percentage':
      return 'percent';
    case 'string':
      return undefined;
    case 'number':
      return 'decimal';
    default:
      return type;
  }
};

const injectFormats = (columnTypes) =>
  columnTypes.map((metric) => ({
    format: pickFormat(metric.type),
    unit: metric.unit,
  }));

const injectHeadings = (columns, headings, hasHeader) => {
  if (!hasHeader) {
    return columns;
  }

  return merge(
    columns,
    headings.map((heading) => ({
      heading,
    })),
  );
};

/**
 * Salesforce table transformer
 * @param {Object} data - Data that is returned from the backend for the table visualisation.
 * @param {Object[]} data.data - The visualisation data.
 * @param {Object[]} data.data[] - A data row.
 * @param {(string|number)} data.data[][] - The value of the field.
 * @param {Object[]} data.column_types - Describes the format of the column and the unit in case of a currency.
 * @param {string} data.column_types[].type - Describes the type of the column.
 * Can be `currency`, `number`, `percentage`, `date`, `datetime`, `string`
 * @param {string} [data.column_types[].unit] - If the type is `currency`, a unit will be given.
 * Units can be "USD" or other currency codes.
 * @param {Object[]} data.headings - An array with the field names.
 * @param {string} data.headings[] - The name of a field.
 */
const tableTransform = (data, { config = {} } = {}) => {
  if (!data) {
    return {};
  }

  const context = {};

  const {
    data: visualisationData = [],
    column_types: columnTypes = [],
    headings = [],
  } = data;
  const { hasHeader } = config;

  let columns = injectFormats(columnTypes);
  columns = injectHeadings(columns, headings, hasHeader);

  if (columns.length) {
    context.columns = columns;
  }

  context.data = visualisationData;

  return context;
};

const transform = composeTransformers(tableTransform, genericTableTransform);

export { transform };
