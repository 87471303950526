import localForage from 'localforage';

const HOURS_24 = 3.6e6 * 24;

function setItem(key, value) {
  const updatedAt = `${new Date()}`;
  return localForage
    .setItem(key, JSON.stringify({ value, updatedAt }))
    .then(JSON.parse)
    .catch((e) => {
      if (
        e.name === 'QuotaExceededError' ||
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      ) {
        localForage.clear();
      }
      throw e;
    });
}

function getItem(key) {
  return localForage
    .getItem(key)
    .then(JSON.parse)
    .then((data) => {
      if (data === null) {
        return null;
      }

      if (new Date(data.updatedAt) < Date.now() - HOURS_24) {
        localForage.removeItem(key);
        return null;
      }

      return data;
    });
}

function removeItem(key) {
  return localForage.removeItem(key);
}

export default { getItem, setItem, removeItem };
