import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { StatusIndicatorFormData } from '@Components/status-indicator-configuration/status-indicator-types';
import { STATUS_INDICATORS_ALLOWED_VISUALISATIONS } from '@Components/status-indicator-configuration/utils/constants';
import { fromManagementAPIStatusIndicatorFormat } from '@Components/status-indicator-configuration/utils/fromManagementAPIStatusIndicatorFormat';
import { fromNumberOrCell } from '@Components/status-indicator-configuration/utils/fromNumberOrCell';
import { toManagementAPIStatusIndicatorFormat } from '@Components/status-indicator-configuration/utils/toManagementAPIStatusIndicatorFormat';
import { toNumberOrCell } from '@Components/status-indicator-configuration/utils/toNumberOrCell';

import { Notification } from '../../../types/bauhaus';
import { Visualizations } from '../consts';
import type {
  Cell,
  ColumnRow,
  DynamicEditorComponent,
  Field,
  FieldKey,
  FieldRanges,
  LegacyRange,
  Range,
  RangeWithNoSize,
  SpreadsheetsVisualizationConfig,
  VectorRange,
  VisualizationType,
  Worksheet,
} from '../types';

import { areSeriesKeysStillValid } from './utils/areSeriesKeysStillValid';
import { calculateTransposeDirection } from './utils/calculateTransposeDirection';
import { createSpreadsheetMaps } from './utils/createSpreadsheetMaps';
import { createVisualizationConfig } from './utils/createVisualizationConfig';
import { extractFieldsFromLegacyConfig } from './utils/extractFieldsFromLegacyConfig';
import { getFieldsFromRanges } from './utils/getFieldsFromRanges';
import { getGeckometerMinMax } from './utils/getGeckometerMinMax';
import { getRangesFromConfig } from './utils/getRangesFromConfig';
import { getUpdatedSelections } from './utils/getUpdatedSelections';
import { getVectorRanges } from './utils/getVectorRanges';
import { isXAxisStillValid } from './utils/isXAxisStillValid';
import { suggestBestVisualization } from './utils/suggestBestVisualization';

export type RootState = {
  spreadsheetsDataSelection: SpreadsheetDataSelectionState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

/**
 * Represents the Spreadsheet widget configuration state. Currently the state is spread
 * across this store, and the spreadsheet-config-reducer (which has been deprecated)
 */
export interface SpreadsheetDataSelectionState {
  file: {
    /**
     * The set of cells within the worksheet
     */
    cells: Cell[];
  };
  /**
   * Represents state within the range selection screen
   */
  rangeSelection: {
    /**
     * The index of the selection currently being edited by the user. Used to help
     * sync up inputs & grid interactions
     */
    activeSelectionIndex: number;
    /*
     * hasHeader is shared between both views, it should probably be split
     * so that each view has its own hasHeader state. To do that we need to
     * populate widgetConfiguration state when clicking Continue on the
     * range selection, and populate the rangeSelection when clicking "Edit
     * Selection" on the config. We'll do this as a separate piece of work.
     */
    hasHeader: boolean;
    /**
     * The location that the grid/range selection should be scrolled to
     */
    scrolls: ColumnRow;
    /**
     * The set of selected ranges that have been made by the user
     */
    selections: Range[];

    /**
     * Has the user chosen to expand their series to the end of the selection?
     */
    endOfSeriesSelection?: 'extended' | 'declined' | 'offered';
  };
  widgetConfiguration: {
    /**
     * This is the user defined transposeDirection, which we use to override any default. If
     * this is undefined the transposeDirection is calculated (see selectors.widgetConfiguration.calculatedTransposeDirection)
     * otherwise we'll use this value and disregard any auto-calculation
     */
    transposeDirection?: 'rows' | 'columns';
    /**
     * The key of the selected fields, in the order they appear in the UI.
     */
    seriesKeys: FieldKey[];
    /**
     * The key of the field selected for x-axis.
     */
    xAxisKey?: FieldKey;
  };

  /** Which special config editor panel is active */
  activeDynamicEditor?: DynamicEditorComponent;

  /** State for the dynamic editor that handles status indicators */
  statusIndicators: {
    formState?: StatusIndicatorFormData;
    notification?: Notification;
  };
}

// Exported just for tests
export const PLACEHOLDER_SELECTION: Range = {
  start: [0, 0],
  end: [0, 0],
  size: [0, 0],
  firstCell: [0, 0],
};

const DIRECTIONS_INDICES = {
  ROWS: 0,
  COLUMNS: 1,
};

export const initialState: SpreadsheetDataSelectionState = {
  file: {
    cells: [],
  },
  rangeSelection: {
    activeSelectionIndex: 0,
    hasHeader: true,
    scrolls: [0, 0],
    selections: [PLACEHOLDER_SELECTION],
  },
  widgetConfiguration: {
    seriesKeys: [],
  },
  statusIndicators: {},
};

/**
 * This creates a Redux slice https://redux-toolkit.js.org/tutorials/typescript
 * which is used for both the reducer logic and a basic set of actions
 */
export const slice = createSlice({
  name: 'spreadsheetsDataSelection',
  initialState,
  reducers: {
    /**
     * Set which of the ranges should be marked as active indicated by
     * the one the user is currently editing and will be updated when the user
     * interacts with the spreadsheet preview
     */
    setActiveSelectionIndex: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<number>,
    ) => {
      state.rangeSelection.activeSelectionIndex = action.payload;
    },

    /**
     * Clear out all the current selections, just leaving a placeholder to be set
     */
    resetSelections: (state: SpreadsheetDataSelectionState) => {
      state.rangeSelection.selections = [PLACEHOLDER_SELECTION];
      state.rangeSelection.endOfSeriesSelection = undefined;
    },

    /**
     * Adds a new placeholder to the list of selections, that can then be updated
     */
    addSelectionPlaceholder: (state: SpreadsheetDataSelectionState) => {
      state.rangeSelection.selections.push(PLACEHOLDER_SELECTION);
    },

    /**
     * Updates the current active selection, note that while not the usual case
     * it is possible for the payload to contain an array of ranges. Under most
     * circumstances however this will have a length of 1
     */
    updateSelection: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<Range[] | RangeWithNoSize[]>,
    ) => {
      // TODO: Can we simply this helper function as it's crazy complex
      const { selections, endOfSeriesSelection } = getUpdatedSelections(
        action.payload,
        state.file.cells,
      );

      state.rangeSelection.endOfSeriesSelection = endOfSeriesSelection;
      state.rangeSelection.selections.splice(
        state.rangeSelection.activeSelectionIndex,
        1,
        ...selections,
      );
    },

    /**
     * Removes a selection at the given index
     */
    deleteSelection: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<number>,
    ) => {
      state.rangeSelection.selections.splice(action.payload, 1);
    },

    setSelectionEndOfSeries: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<
        | { extend?: 'declined' | 'offered' }
        | {
            extend: 'extended';
            direction: 'ROWS' | 'COLUMNS';
          }
      >,
    ) => {
      const { extend } = action.payload;
      state.rangeSelection.endOfSeriesSelection = extend;

      // If we're extending the selection we need modify the
      // currently selected one.
      if (extend === 'extended') {
        const selectionToExtend =
          state.rangeSelection.selections[
            state.rangeSelection.activeSelectionIndex
          ];

        const index = DIRECTIONS_INDICES[action.payload.direction];
        selectionToExtend.start[index] = -1;
        selectionToExtend.firstCell[index] = -1;
        selectionToExtend.size[index] = -1;
        selectionToExtend.end[index] = -1;
      }
    },

    /**
     * Sets the state of the store from the given config object recieved from the server
     */
    setFromConfig: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<{
        config: SpreadsheetsVisualizationConfig;
        fieldRanges: FieldRanges | undefined;
        notification?: Notification;
      }>,
    ) => {
      const cells = state.file.cells;
      const { config, fieldRanges, notification } = action.payload;

      state.rangeSelection.selections = fieldRanges?.ranges ?? [];
      state.rangeSelection.hasHeader =
        fieldRanges?.hasHeader ?? config.hasHeader;
      state.widgetConfiguration.transposeDirection =
        fieldRanges?.transposeDirection;
      state.widgetConfiguration.xAxisKey = fieldRanges?.xAxisKey;
      state.widgetConfiguration.seriesKeys = fieldRanges?.seriesKeys ?? [];

      state.statusIndicators.formState = fromManagementAPIStatusIndicatorFormat(
        config.indicators,
      );
      state.statusIndicators.notification = notification;

      // If we don't have the fieldRanges defined, which is the newer way of saving
      // user selection information, then we need to rehydrate this information
      // from the config instead
      if (!fieldRanges) {
        const userSelections = extractFieldsFromLegacyConfig(config, cells);

        state.rangeSelection.selections = getRangesFromConfig(config);
        state.widgetConfiguration = {
          ...state.widgetConfiguration,
          ...userSelections,
        };
      }
    },

    /**
     * Update the worksheet that is currently being used
     */
    selectWorksheet: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<{ cells: Cell[] }>,
    ) => {
      state.file.cells = action.payload.cells;
    },

    /**
     * Set wether the current selected ranges contain headers or not.
     */
    setHasHeader: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<boolean>,
    ) => {
      state.rangeSelection.hasHeader = action.payload;
    },

    /**
     * Set the scroll position of the spreadsheet
     */
    setScrollPosition: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<ColumnRow>,
    ) => {
      state.rangeSelection.scrolls = action.payload;
    },

    /**
     * Apply a transpose, either treating the spreadsheet as a regular table (column based)
     * or by transforming rows into columns
     */
    setTransposeDirection: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<'rows' | 'columns' | undefined>,
    ) => {
      state.widgetConfiguration.transposeDirection = action.payload;
    },

    /**
     * Sets the selected xAxis key. It can be used to set an xAxis that
     * has been made by the user or to set defaults to a visualization
     */
    selectXaxis: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<FieldKey>,
    ) => {
      state.widgetConfiguration.xAxisKey = action.payload;
    },

    /**
     * Sets the default xAxis/series key(s) for the selected visualisation type
     * if there are no selected keys, or the selected keys don't match a new range's keys
     */
    setVisualisationType: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<{ vizType: VisualizationType; fields: Field[] }>,
    ) => {
      const { vizType, fields } = action.payload;
      const {
        maxSeries,
        seriesFieldTypes,
        categoryFieldTypes = [],
      } = Visualizations[vizType];

      const selectedXAxisKey = state.widgetConfiguration.xAxisKey;
      const selectedSeriesKeys = state.widgetConfiguration.seriesKeys;

      const fieldsForXAxis = fields.filter((f) =>
        categoryFieldTypes.includes(f.dataType),
      );
      const fieldsForSeries = fields.filter((f) =>
        seriesFieldTypes.includes(f.dataType),
      );

      const hasValidXAxis = isXAxisStillValid(selectedXAxisKey, fieldsForXAxis);
      const hasValidSeriesKeys = areSeriesKeysStillValid(
        selectedSeriesKeys,
        fieldsForSeries,
      );

      if (!hasValidXAxis) {
        state.widgetConfiguration.xAxisKey = fieldsForXAxis[0]?.key;
      }

      if (!hasValidSeriesKeys) {
        state.widgetConfiguration.seriesKeys = fieldsForSeries
          .splice(0, maxSeries)
          .map((f) => f.key);
      }
    },

    /**
     * Sets the series at the given position to be the provided field key
     */
    selectSeries: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<{ fieldKey: FieldKey; position: number }>,
    ) => {
      const { fieldKey, position } = action.payload;

      // This will handle updates and new items
      state.widgetConfiguration.seriesKeys[position] = fieldKey;
    },
    /**
     * Sets all the selected series keys in one go - usually used
     * to apply defaults to a visualization
     */
    setMultipleSeries: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<FieldKey[]>,
    ) => {
      state.widgetConfiguration.seriesKeys = action.payload;
    },
    /**
     * Removes a series at the given index
     */
    removeSeries: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<number>,
    ) => {
      state.widgetConfiguration.seriesKeys.splice(action.payload, 1);
    },

    /**
     * Update the form state of status indicators, to ensure the form
     * and live previews track the current changes
     */
    onStatusIndicatorChange: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<StatusIndicatorFormData | undefined>,
    ) => {
      state.statusIndicators.formState = action.payload;
    },

    onStatusIndicatorSetNotification: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<Notification | undefined>,
    ) => {
      state.statusIndicators.notification = action.payload;
    },

    /**
     * Called whenever the user changes the cell selection on the configure view
     * Performs no action if there isn't a status indicator being edited
     */
    onStatusIndicatorCellChange: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<Range[]>,
    ) => {
      const formState = state.statusIndicators.formState;

      // We only want to process this event if we have something to edit;
      const thresholdName = formState?.editing;
      if (!thresholdName) return;

      // We only ever use the last cell within a range
      const end = action.payload[0]?.end;
      if (!end) return;

      formState[thresholdName] = fromNumberOrCell([end, end]);
    },

    openDynamicEditor: (
      state: SpreadsheetDataSelectionState,
      action: PayloadAction<DynamicEditorComponent>,
    ) => {
      state.activeDynamicEditor = action.payload;
    },
    closeDynamicEditor: (state: SpreadsheetDataSelectionState) => {
      delete state.activeDynamicEditor;
    },
  },
});

/**
 * Selectors to grab information about of the SpreadsheetDataSelection store
 */
export const selectors = {
  store: (state: RootState): SpreadsheetDataSelectionState =>
    state.spreadsheetsDataSelection,

  // TODO: Remove this once we have eliminated cross store requests
  spreadsheetStore: (state: RootState) => state.spreadsheets ?? {},

  /**
   * This is additional information that needs to be saved alongside the
   * visualization config when persisting the widget
   */
  serviceConfig: (state: RootState) => {
    const {
      fileId,
      worksheetId,
      service,
      warning,
      label,
      comparisonLabel,
      legacyServiceAccountId,
    } = selectors.spreadsheetStore(state);

    return {
      fileId,
      worksheetId,
      legacyServiceAccountId,
      service,
      warning,
      label,
      comparisonLabel,
    };
  },

  /**
   * Create a map to the cells for quick lookup of cell values by either
   * row/column or a combination
   */
  cellMap: (state: RootState) => {
    const cells = selectors.file.cells(state);
    return createSpreadsheetMaps(cells);
  },

  /**
   * Grabs the currently selected visualization type
   */
  vizType: (state: RootState): VisualizationType => {
    const { config } = selectors.spreadsheetStore(state);
    if (config.type) {
      return config.type;
    }

    return suggestBestVisualization(
      selectors.widgetConfiguration.fields.all(state),
    );
  },

  file: {
    store: (state: RootState) => selectors.store(state).file,

    /** Grabs the cells for the current worksheet */
    cells: (state: RootState) => selectors.file.store(state).cells,
  },

  rangeSelection: {
    store: (state: RootState) => selectors.store(state).rangeSelection,

    /** Grabs the avaliable worksheets */
    worksheets: (state: RootState): Worksheet[] =>
      Object.values(selectors.spreadsheetStore(state).worksheets ?? {}),

    /** Wether the selected ranges contains headers */
    hasHeader: (state: RootState): boolean =>
      selectors.rangeSelection.store(state).hasHeader,

    scrollPosition: (state: RootState): ColumnRow =>
      selectors.rangeSelection.store(state).scrolls,

    selections: {
      /** Grabs the current selections that have been made by the user */
      all: (state: RootState): Range[] => {
        // There is a race condition where this can be called before the store has been properly initialised
        const selections =
          selectors.rangeSelection.store(state).selections ?? [];

        // Filter out empty placeholders
        return selections.filter(
          (selection: Range) =>
            selection.start.length &&
            selection.end.length &&
            selection !== PLACEHOLDER_SELECTION,
        );
      },

      /**
       * Obtains selections that are 1 dimensional. They either represent a
       * single column or single row, rather than a 2D range
       */
      vectors: (state: RootState): VectorRange[] => {
        const selections = selectors.rangeSelection.selections.all(state);
        const map = selectors.cellMap(state);
        const transposeDirection =
          selectors.widgetConfiguration.transposeDirection(state);

        // Split the ranges up into smaller single column/row based ranges
        return getVectorRanges(selections, map, transposeDirection);
      },
    },

    /**
     * Whether the user has been offered/accepted extending the range based on selecting the last
     * cell in a row/column
     */
    endOfSeriesSelection: (state: RootState) =>
      selectors.rangeSelection.store(state).endOfSeriesSelection,

    selectionsWithPlaceholder: (state: RootState): Range[] =>
      selectors.rangeSelection.store(state).selections ?? [
        PLACEHOLDER_SELECTION,
      ],
  },

  widgetConfiguration: {
    store: (state: RootState) => selectors.store(state).widgetConfiguration,

    fields: {
      /**
       * Find all the avaliable fields that the user can select from for series/axis
       * dropdowns
       */
      all: (state: RootState): Field[] => {
        const vectors = selectors.rangeSelection.selections.vectors(state);
        const map = selectors.cellMap(state);
        return getFieldsFromRanges(
          vectors,
          map,
          selectors.rangeSelection.hasHeader(state),
        );
      },
      /**
       * Get fields group by their keys
       */
      byKey: (state: RootState): Record<FieldKey, Field> => {
        const allFields = selectors.widgetConfiguration.fields.all(state);
        const result: Record<FieldKey, Field> = {};
        allFields.forEach((field) => (result[field.key] = field));

        return result;
      },
      /**
       * No valid means there are no fields to choose for a selected vizType
       */
      hasNoValidFields: (state: RootState): boolean => {
        const allFields = selectors.widgetConfiguration.fields.all(state);
        const vizType = selectors.vizType(state);
        const { seriesFieldTypes, categoryFieldTypes } =
          Visualizations[vizType] ?? {};

        const hasNoValidSeries = !allFields.some((f) =>
          (seriesFieldTypes || []).includes(f.dataType),
        );

        if (!categoryFieldTypes) {
          return hasNoValidSeries;
        }

        const hasNoValidXAxis = !allFields.some((f) =>
          categoryFieldTypes.includes(f.dataType),
        );

        return hasNoValidSeries || hasNoValidXAxis;
      },
    },
    series: {
      /**
       * The field keys that have currently been selected by the user for series.
       */
      selectedKeys: (state: RootState): FieldKey[] => {
        return selectors.widgetConfiguration.store(state).seriesKeys;
      },

      /**
       * The fields that have currently been selected by the user for series. Note that
       * series can mean different things depending on the type of the visualization.
       */
      selectedFields: (state: RootState): Array<Field> => {
        const seriesKeys =
          selectors.widgetConfiguration.series.selectedKeys(state);
        const fields = selectors.widgetConfiguration.fields.byKey(state);

        return (
          seriesKeys
            .map((k) => fields[k])
            // Filter out undefined as it's possible fields and have been
            // removed when making changes to the selections. Currently we
            // don't update the seriesKeys when changing selections, so
            // it's possible for them to refer to a field that is no longer
            // part of the selected ranges.
            .filter((field) => field !== undefined) as Field[]
        );
      },
    },
    xAxis: {
      /**
       * The field key that has been selected for the x-axis
       */
      selectedKey: (state: RootState): FieldKey | undefined =>
        selectors.widgetConfiguration.store(state).xAxisKey,

      /**
       * The field that has been selected for the x-axis
       */
      selectedField: (state: RootState): Field | undefined => {
        const key = selectors.widgetConfiguration.xAxis.selectedKey(state);
        if (key === undefined) {
          return undefined;
        }

        return selectors.widgetConfiguration.fields.byKey(state)[key];
      },
    },
    /**
     * Dynamically determine the transpose direction of the Ranges, that is should we
     * be using a column based table layout, or rotating to use a row based table layout.
     * This is an internal function and you should probably prefer using `selectors.widgetConfiguration.transposeDirection`
     */
    calculatedTransposeDirection: (state: RootState) => {
      // If we don't have a transpose direction set, then derive one.
      const selections = selectors.rangeSelection.selections.all(state);
      return calculateTransposeDirection(selections);
    },

    /**
     * Indicates how we should slice up the data in multi-row or multi-column ranges to produce
     * fields of data.
     *
     * `columns`:  each column in a range is treated as a separate field,
     *    and values are read from the rows in the column
     * `rows`: each row in a range is treated as a separate field,
     *    and values are read from the columns in the row
     */
    transposeDirection: (state: RootState) => {
      const { transposeDirection } = selectors.widgetConfiguration.store(state);

      // Use the manually set transposeDirection if there is one
      if (transposeDirection) {
        return transposeDirection;
      }

      return selectors.widgetConfiguration.calculatedTransposeDirection(state);
    },

    /**
     * Return the current config object actively being worked upon from the old
     * Redux store. Ideally we want to stop using this and remove this function.
     */
    activeConfig: (state: RootState) => {
      const spreadsheets = selectors.spreadsheetStore(state);
      const { config, dynamicEditor } = spreadsheets;

      return isEmpty(dynamicEditor.config) ? config : dynamicEditor.config;
    },

    /**
     * This will obtain the config object from the old spreadsheets redux store.
     * Ideally we want to stop using this and remove this function.
     */
    baseConfig: (state: RootState): SpreadsheetsVisualizationConfig => {
      const cells = selectors.file.cells(state);
      const hasHeader = selectors.rangeSelection.hasHeader(state);

      const activeConfig = selectors.widgetConfiguration.activeConfig(state);

      // If we get rid of baseConfig, move this into config
      if (activeConfig?.type === 'geckometer') {
        const { min, max } = getGeckometerMinMax(activeConfig, cells);
        return {
          ...activeConfig,
          hasHeader,
          min,
          max,
        };
      }

      return { ...activeConfig, hasHeader };
    },

    /**
     * Obtain a visualization config that can be used to drive the visualization
     * in the preview area
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visualizationConfig: (state: RootState): any => {
      const activeConfig = selectors.widgetConfiguration.activeConfig(state);
      const cells = selectors.file.cells(state);
      const ranges = selectors.rangeSelection.selections.all(state);
      const vizType = selectors.vizType(state);
      const hasHeader = selectors.rangeSelection.hasHeader(state);
      const transposeDirection =
        selectors.widgetConfiguration.transposeDirection(state);
      const calculatedTransposeDirection =
        selectors.widgetConfiguration.calculatedTransposeDirection(state);

      const hasNoValidFields =
        selectors.widgetConfiguration.fields.hasNoValidFields(state);

      const xAxis = hasNoValidFields
        ? undefined
        : selectors.widgetConfiguration.xAxis.selectedField(state);

      const selectedSeries = hasNoValidFields
        ? []
        : selectors.widgetConfiguration.series.selectedFields(state);

      const seriesRanges: LegacyRange[] = selectedSeries.map(({ range }) => [
        range.start,
        range.end,
      ]);

      const xAxisRange: LegacyRange | undefined = xAxis && [
        xAxis.range.start,
        xAxis.range.end,
      ];

      // Augment the visualization config with our field ranges
      const config = {
        ...activeConfig,
        type: vizType,
        fieldRanges: {
          // Only save tranpose direction if overridden
          transposeDirection:
            transposeDirection !== calculatedTransposeDirection
              ? transposeDirection
              : undefined,
          xAxisKey: xAxis?.key,
          seriesKeys: selectedSeries.map((s) => s.key),
          hasHeader,
          ranges,
        },
      };

      if (STATUS_INDICATORS_ALLOWED_VISUALISATIONS.includes(vizType)) {
        config.indicators = toManagementAPIStatusIndicatorFormat(
          selectors.statusIndicators.formState(state),
        );
      }

      return createVisualizationConfig(
        config,
        vizType,
        seriesRanges,
        xAxisRange,
        hasHeader,
        cells,
      );
    },
  },

  statusIndicators: {
    store: (state: RootState) => selectors.store(state).statusIndicators,

    /**
     * The configuration for the status indicators.
     */
    formState: (state: RootState): StatusIndicatorFormData | undefined => {
      return selectors.statusIndicators.store(state).formState;
    },

    /**
     * Return the currently selected cell for status indicators
     * only returns the value when it should be highlighted
     */
    selectedCell: (state: RootState): ColumnRow | undefined => {
      const formState = selectors.statusIndicators.formState(state);
      const thresholdName = formState?.editing;
      if (!thresholdName) {
        return undefined;
      }

      const maybeCell = toNumberOrCell(formState[thresholdName]);
      if (Array.isArray(maybeCell)) {
        return maybeCell[0];
      }

      return undefined;
    },

    notification: (state: RootState) => {
      return selectors.statusIndicators.store(state).notification;
    },
  },

  /**
   * The dynamic editor connects up sidebar configure (e.g. goals, status indicators) with
   * a spreadsheet viewer on the right hand side
   */
  dynamicEditor: {
    /** Is the dynamic editor currently being used to edit some settings? */
    component: (state: RootState): DynamicEditorComponent | undefined =>
      selectors.store(state).activeDynamicEditor,

    /** Should we show the spreadsheet viewer to allow cell selection? */
    showSpreadsheetViewer: (state: RootState): boolean => {
      const component = selectors.dynamicEditor.component(state);
      if (component === 'STATUS_INDICATORS') {
        return Boolean(selectors.statusIndicators.formState(state)?.editing);
      }
      return Boolean(component);
    },
  },

  /** The index of the current active selection */
  activeSelectionIndex: (state: RootState) => {
    return selectors.store(state).rangeSelection.activeSelectionIndex;
  },
};

const {
  openDynamicEditor,
  closeDynamicEditor,
  setFromConfig,
  setActiveSelectionIndex,
  setHasHeader,
  setTransposeDirection,
  selectSeries,
  setMultipleSeries,
  selectXaxis,
  removeSeries,
  setVisualisationType,
  setScrollPosition,
  resetSelections,
  addSelectionPlaceholder,
  deleteSelection,
  setSelectionEndOfSeries,
  updateSelection,
  selectWorksheet,
  onStatusIndicatorChange,
  onStatusIndicatorSetNotification,
  onStatusIndicatorCellChange,
} = slice.actions;

/** Export name spaced actions */
export const actions = {
  file: {
    selectWorksheet,
  },
  setFromConfig,
  rangeSelection: {
    setActiveSelectionIndex,
    setHasHeader,
    setScrollPosition,
    resetSelections,
    addSelectionPlaceholder,
    deleteSelection,
    setSelectionEndOfSeries,
    updateSelection,
  },
  widgetConfiguration: {
    setTransposeDirection,
    selectSeries,
    setMultipleSeries,
    selectXaxis,
    removeSeries,
    setVisualisationType,
  },
  dynamicEditor: {
    open: openDynamicEditor,
    close: closeDynamicEditor,
  },
  statusIndicators: {
    onChange: onStatusIndicatorChange,
    setNotification: onStatusIndicatorSetNotification,
    onCellChange: onStatusIndicatorCellChange,
  },
};

export default slice.reducer;
