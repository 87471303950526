import { filter, forEach, isNil, map, times } from 'lodash';

export const getCellValue = (cell) => {
  if (!cell) {
    return null;
  }

  switch (cell.__typename) {
    case 'DatabaseBooleanValue':
      return cell.boolean;
    case 'DatabaseFloatValue':
      return cell.float;
    case 'DatabaseIntegerValue':
      return cell.integer;
    case 'DatabaseStringValue':
      return cell.string;
    case 'DatabaseTimestampValue':
      return cell.timestamp;
    default:
      return null;
  }
};

export const getValuesFromRow = (row) => {
  return map(row, getCellValue);
};

export const processSeries = (data) => {
  // Data should exist, have at least one item, with at least one data point
  if (isNil(data) || isNil(data[0]) || data[0].length < 2) {
    return [];
  }

  const noOfValues = data[0].length - 1;
  const processedSeries = times(noOfValues, () => []);
  // Strip out any data points with no x-axis value
  const filteredData = filter(data, (i) => !isNil(i[0]));

  // We need to transform from a single array of data into multiple arrays,
  // one for each series. So:
  // [[<datetime>, <value_1>, <value_2>], [<datetime>, <value_1>, <value_2>]]
  // becomes
  // [[<datetime>, <value_1>], [<datetime>, <value_1>]]
  // and
  // [[<datetime>, <value_2>], [<datetime>, <value_2>]]
  forEach(filteredData, (item) => {
    const [head, ...rest] = item;
    forEach(rest, (dataPoint, index) => {
      processedSeries[index].push([head, dataPoint]);
    });
  });

  return processedSeries;
};
