import Serializer from './default';

class TextSerializer extends Serializer {
  serialize(state) {
    const defaults = super.serialize(state);
    const { value, hasHeader, title, isLoop } = state;

    return {
      ...defaults,
      config: {
        template: {
          type: 'text',
          version: 2,
        },
        title,
        hasHeader,
        value,
        isLoop,
      },
    };
  }

  deserialize(json) {
    const defaults = super.deserialize(json);
    const { title, hasHeader, value, isLoop } = json.config;

    return {
      ...defaults,
      type: 'text',
      value,
      hasHeader,
      title,
      isLoop,
    };
  }
}

export default TextSerializer;
