const getPlaceholder = (value, format) => {
  switch (format) {
    case 'percent':
    case 'percentage':
      return `${value}%`;
    case 'currency':
    case 'money':
      return `$${value}`;
    default:
      return `${value}`;
  }
};

export { getPlaceholder };
