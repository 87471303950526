import { every } from 'lodash';

const initial = (str) => {
  const point = (str ? str.trim() : '').codePointAt(0);
  if (!isNaN(point)) {
    return String.fromCodePoint(point);
  }
  return '';
};

const getInitials = (firstName = '', lastName = '') => {
  return initial(firstName) + initial(lastName);
};

// This is only a basic check that just prevents some invalid
// email formats, this includes whitespace that is any one of
// newline, tab or carriage return before and after the @
const EMAIL_REGEX = /^[^@`\s\n\t\r]+@[^@\s\n\t\r]+$/;
const isValidEmail = (email) => EMAIL_REGEX.test(email);
const validateEmailAddresses = (addresses) => every(addresses, isValidEmail);

export { getInitials, isValidEmail, validateEmailAddresses };
