import 'isomorphic-fetch';
import { applyCSRF } from './lib/csrf';

// Fallback default but each service should customise these parameter
const POLLING_OPTS = {
  defaultPollInterval: 1000,
  maxNumberOfPolls: 60,
};

// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_request_options
type FetchOptions = Parameters<typeof fetch>[1];

function csrfFetch(url: string, opts?: FetchOptions) {
  return fetch(url, applyCSRF(opts));
}

class FetchError extends Error {
  type: string;
  status?: number;

  // Account for the 'other' options that are assigned
  [name: string]: unknown;

  constructor(
    message: string,
    status?: number,
    type?: string,
    other?: Record<string, unknown>,
  ) {
    super(message);

    Object.assign(this, other);
    this.name = 'FetchError';
    this.message = message;
    this.type = type || 'uncategorized';
    this.status = status;
  }

  toString(): string {
    return this.message || '';
  }
}

class MultipleFetchError extends FetchError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(errors: any, status: any, other?: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchErrors = errors.map(({ message, type, ...rest }: any) => {
      return new FetchError(message, status, type, rest);
    });
    const allMessages = fetchErrors.join(' ');

    super(allMessages, status, 'multiple', other);
    this.name = 'MultipleFetchError';

    this.errors = fetchErrors;
  }
}

function handleError(res: Response) {
  // Display the HTTP status error when we
  // cant get a friendly error from the response
  return res
    .json()
    .then(({ error, errors }) => {
      if (error) {
        const { message, type, ...other } = error;
        return new FetchError(message, res.status, type, other);
      }

      if (errors) {
        return new MultipleFetchError(errors, res.status);
      }

      throw new Error();
    })
    .catch(() => new FetchError(res.statusText, res.status))
    .then((err) => {
      throw err;
    });
}

function fetchAll(url: string, opts?: FetchOptions) {
  return csrfFetch(url, opts).then((res) => {
    if (res.status >= 200 && res.status < 300) {
      return res;
    }
    return handleError(res);
  });
}

function fetchAllJSON(url: string, opts?: FetchOptions) {
  return fetchAll(url, opts).then((res) => res.json());
}

function fetchJSON(url: string, opts?: FetchOptions) {
  return fetchAllJSON(url, opts).then((json) => json.data || json);
}

function fetchJSONWithoutCSRF(url: string, opts?: FetchOptions) {
  return fetch(url, opts)
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return res;
      }
      return handleError(res);
    })
    .then((res) => {
      if (res.headers.get('Content-Length') === '0') {
        return Promise.resolve({});
      }

      return res.json();
    });
}

// We made the assumption in `fetchJSON` that the presense of `data` in the response
// meant that we could discard everything else. This was wrong.
function fetchAllJSONWithCredentials(url: string, opts?: FetchOptions) {
  const finalOpts: FetchOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
    ...opts,
  };

  return fetchAllJSON(url, finalOpts);
}

function fetchAllWithCredentials(url: string, opts?: FetchOptions) {
  const finalOpts: FetchOptions = {
    method: 'GET',
    credentials: 'include',
    ...opts,
  };

  return fetchAll(url, finalOpts);
}

function fetchJSONWithCredentials(url: string, opts?: FetchOptions) {
  return fetchAllJSONWithCredentials(url, opts).then(
    (json) => json.data || json,
  );
}

type PollingOptions = {
  defaultPollInterval: number;
  maxNumberOfPolls: number;
  pollInterval?: number;
};

function pollJSON(
  url: string,
  opts: FetchOptions = {},
  pollingOpts: PollingOptions = POLLING_OPTS,
  counter = 0,
) {
  // default to 0 that will be overidden to default or Retry-After header
  let { pollInterval = 0 } = pollingOpts;
  const { defaultPollInterval, maxNumberOfPolls } = pollingOpts;
  const headers = { ...opts.headers, Accept: 'application/json' };

  return csrfFetch(url, {
    method: 'GET',
    credentials: 'include',
    ...opts,
    headers,
  }).then((res) => {
    if (res.status === 204) {
      if (counter >= maxNumberOfPolls) {
        throw new FetchError('Fetch limit exceeded.', res.status);
      }

      // Get next poll interval hint from the response header
      // This value is returned in milliseconds
      const retryHeader = res.headers.get('Retry-After');
      if (!!retryHeader) {
        pollInterval = parseInt(retryHeader, 10);
      }

      if (pollInterval === 0) {
        pollInterval = defaultPollInterval;
      }

      const newCounter = counter + 1;
      const newPollingOpts = { ...pollingOpts, pollInterval };

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(pollJSON(url, opts, newPollingOpts, newCounter));
        }, pollInterval);
      });
    }

    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    return handleError(res);
  });
}

export {
  csrfFetch,
  fetchAll,
  fetchAllJSON,
  fetchAllJSONWithCredentials,
  fetchAllWithCredentials,
  FetchError,
  fetchJSON as default,
  fetchJSONWithCredentials,
  fetchJSONWithoutCSRF,
  handleError,
  MultipleFetchError,
  POLLING_OPTS,
  pollJSON,
};
