import { setState } from './data-source-connector-actions';
import initDataSource from './init-data-source-action';

const initFlow =
  ({ dashboardId, groupId, services, serviceName, serviceType }) =>
  (dispatch) => {
    // Fetch data
    initDataSource({
      dashboardId,
      groupId,
      services,
      serviceName,
      serviceType,
    })(dispatch);
    // Show panel
    dispatch(setState({ isPanelVisible: true }));
  };

export default initFlow;
