import { isUndefined, omitBy } from 'lodash';

import { isValidSelection } from '../selection-helpers';

const getDefaultConfig = (selections = []) => ({
  type: 'number',
  comparison: {},
  numberFormat: {},
  value: selections[0],
});

const getSelectionsFromConfig = (config) => [
  [config.value[1], config.value[1]],
];

const isValid = (cfg) => {
  return isValidSelection(cfg.value);
};

// targets cells with values
const getSelectionTargets = ({ comparison: { type } = {} }) => {
  switch (type) {
    case 'sparkline':
      return [-1];
    case 'percentage':
    case 'number':
      return [0, -1];
    default:
      return [];
  }
};

// We map the goal direction from line, column and geckometer
// to a sensible goal type
const GOAL_TO_COMPARISON = [
  'goal',
  (goal, lastComparison = {}) => {
    if (!goal) return undefined;

    return omitBy(
      {
        type: 'goal',
        startingValue: lastComparison.startingValue,
      },
      isUndefined,
    );
  },
  'comparison',
];

const configMappingRules = {
  column: ['dataRange', 'goal', 'numberFormat', GOAL_TO_COMPARISON, 'title'],
  line: ['dataRange', 'goal', 'numberFormat', GOAL_TO_COMPARISON, 'title'],
  leaderboard: ['dataRange', 'numberFormat', 'title'],
  text: ['dataRange', 'title'],
  geckometer: ['dataRange', 'numberFormat', 'indicators', 'title'],
  bar: ['dataRange', 'goal', 'numberFormat', GOAL_TO_COMPARISON, 'title'],
  table: ['dataRange', 'title'],
};

export default {
  getDefaultConfig,
  getSelectionsFromConfig,
  isValid,
  getSelectionTargets,
  configMappingRules,
};
