export const LOCATIONS = {
  AdminInviteModal: 'Admin | Invite modal',
  AdminLoops: 'Admin | Loops',
  AdminDashboards: 'Admin | dashboards',
  DashboardAddWidgetDropdown: 'Dashboard | Add widget dropdown menu',
  DashboardPickerOnDashboard: 'Dashboard | Dashboard picker',
  DashboardShareMenu: 'Dashboard | Share menu',
  DashboardDeleteModal: 'Dashboard | Delete modal',
  HeaderDashboardPicker: 'Header | Dashboard picker',
  HeaderPeopleModal: 'Header | People modal',
  IntegrationsMainList: 'Integrations | Main list',
  IntegrationsRecentList: 'Integrations | Recent list',
  IntegrationsPopularList: 'Integrations | Popular list',
  Toast: 'Toast',
};

export const PERMISSIONS = {
  OWNER: 'owner',
  GROUP_ADMIN: 'group_admin',
  GROUP_VIEWER: 'group_read_only',
  DASHBOARD_VIEWER: 'dashboard_view_only',
};

export const VISUALISATION_TYPES = [
  'bar',
  'bullet',
  'clock',
  'column',
  'feed',
  'funnel',
  'geckometer',
  'highcharts',
  'image',
  'leaderboard',
  'line',
  'list',
  'map',
  'monitoring',
  'number',
  'pie',
  'rag',
  'ragcolumn',
  'table',
  'text',
];

export const LIMIT_GATE_TYPES = {
  User: 'User',
  Dashboard: 'Dashboard',
  Screen: 'Screen',
};

export const SCHEDULED_POST_DESTINATION_TYPES = {
  EMAIL: 'Email',
  SLACK: 'Slack',
};

export const STATUS_INDICATOR_TYPES = ['Success', 'Warning'];
