export type AbbreviationKeyType = 'T' | 'B' | 'M' | 'K' | 'none';

type Abbreviation = {
  abbreviation: AbbreviationKeyType | undefined;
  divider: number;
};

type Abbreviations = {
  [key in AbbreviationKeyType]: Abbreviation;
};

export const ABBREVIATIONS: Abbreviations = {
  T: {
    abbreviation: 'T', // trillions
    divider: 1e12,
  },
  B: {
    abbreviation: 'B', // billions
    divider: 1e9,
  },
  M: {
    abbreviation: 'M', // millions
    divider: 1e6,
  },
  K: {
    abbreviation: 'K', // thousands
    divider: 1e3,
  },
  none: {
    abbreviation: undefined, // disabled abbreviation
    divider: 1,
  },
};

export const getAbbreviation = (
  value: number,
  abbreviation?: AbbreviationKeyType,
): Abbreviation => {
  if (abbreviation !== undefined) {
    return ABBREVIATIONS[abbreviation];
  }

  if (value >= 1e12) {
    return ABBREVIATIONS.T;
  } else if (value >= 1e9) {
    return ABBREVIATIONS.B;
  } else if (value >= 1e6) {
    return ABBREVIATIONS.M;
  } else if (value >= 1e4) {
    // The abbreviation 'K' represents 1,000 (1e3),
    // but we use a divider of 10,000 (1e4) here.
    // This means 1,000 is displayed instead of 1K for better readability.
    return ABBREVIATIONS.K;
  }

  return ABBREVIATIONS.none;
};
