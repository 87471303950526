const isValid = () => true;

const getDefaultConfig = () => ({
  type: 'table',
  hasHeader: true,
});

const configMappingRules = {};

export default { isValid, getDefaultConfig, configMappingRules };
