import { getMainColumnField } from '../../universal/transformers/universal/helpers';

import {
  arrangeDurations,
  arrangeMinMax,
  ensureDetailsMetric,
  ensureDurations,
  ensureMinMax,
  ensureNumberFormat,
} from './ensure-config-helpers';

// We store and use a representation of the config in state that suits the
// config UI but not the visualisation expectation. For example, we store an
// object of values for a duration goal like { minutes: '134', seconds: '394' }.
// This function "transform" the config into correct format for the
// visualisation.
// This cannot live in the transformer as transformers should receive config
// + data to produce correct payload. It should not mutate the config. Also
// doing these steps of serializing/deserializing here offers a flexible way to
// map the config to the expected UI part.
export const arrangeConfig = (_config) => {
  let config = { ..._config };
  const mainColumn = getMainColumnField(config) || {};

  config = arrangeDurations(config, mainColumn);
  config = arrangeMinMax(config);

  return config;
};

// Ensure config make sure the config stays valid whenever any part of it is
// updated. For example switching visualisation type.
export const ensureConfig = (_config, transformCtx, uiQuery) => {
  let config = { ..._config };
  const mainColumn = getMainColumnField(config) || {};

  config = ensureMinMax(_config, transformCtx);
  config = ensureDetailsMetric(config, uiQuery);
  config = ensureDurations(config, mainColumn);
  config = ensureNumberFormat(config);

  return config;
};
