import { chain } from 'lodash';

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

function setOptGroupName(field) {
  let optGroupName = capitalizeFirstLetter(field.type);
  if (field.type === 'date' || field.type === 'datetime') {
    optGroupName = 'Date/Datetime';
  } else if (field.type === 'money') {
    optGroupName = `Money (${field.currency_code})`;
  }
  return { optGroupName, ...field };
}

function mergeFields(groups, types, isOptGrouped) {
  return chain(groups)
    .pick(types)
    .values()
    .flatten()
    .sortBy((f) => {
      if (isOptGrouped) {
        // sort by optGroupName and name/key
        return f.optGroupName + (f.name || f.key).toLowerCase();
      }
      return (f.name || f.key).toLowerCase();
    })
    .value();
}

function groupFields(fields) {
  return chain(fields)
    .map((field, key) => ({ key, ...field }))
    .map(setOptGroupName)
    .groupBy((field) => {
      if (field.type === 'date') {
        return 'datetime';
      }
      return field.type;
    })
    .tap((groups) => {
      groups.groupable = mergeFields(groups, ['datetime', 'string']);
      groups.numeric = mergeFields(
        groups,
        ['number', 'percentage', 'money', 'duration'],
        true,
      );
    })
    .value();
}

export default groupFields;
