import { has } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericNumberTransform } from '../per-visualisation/number';

import { pickFormat } from './helpers';

const numberTransform = (payload, timezone, initialContext = {}) => {
  if (!has(payload, 'values[0]')) {
    return {};
  }

  const { metric_type: metricType, metric_unit: metricUnit } = payload;
  const format = pickFormat(metricType);

  const visualisationConfig = {
    value: payload.values,
    format,
  };

  if (metricUnit) {
    visualisationConfig.unit = metricUnit;
  }

  return { ...initialContext, ...visualisationConfig };
};

const transform = composeTransformers(numberTransform, genericNumberTransform);

export { transform };
