import clientPlatformContext from '@Tracking/contexts/client-platform';
import organizationContext from '@Tracking/contexts/organization';
import userContext from '@Tracking/contexts/user';

/**
 * Shared contexts will be used for every event
 */
const SHARED_CONTEXTS = [
  clientPlatformContext,
  organizationContext,
  userContext,
];

const __getSchemaRequiredFields = (schemaTypes) =>
  Object.keys(schemaTypes).reduce(
    (acc, property) =>
      schemaTypes[property]().required ? [...acc, property] : acc,
    [],
  );

const __getSchemaProperties = (schemaTypes) =>
  Object.keys(schemaTypes).reduce(
    (acc, property) => ({
      ...acc,
      [property]: schemaTypes[property]().definition,
    }),
    {},
  );

const __combineContextSchemas = (contexts) =>
  contexts.reduce((schema, context) => ({ ...schema, ...context.schema }), {});

export function createJSONSchema(name, schemaTypes) {
  const required = __getSchemaRequiredFields(schemaTypes);
  const properties = __getSchemaProperties(schemaTypes);

  return {
    title: name,
    type: 'object',
    properties,
    required,
  };
}

function defineEvent(name, schemaTypes = {}, ...contexts) {
  if (!name) {
    throw new Error('ERROR: No event name.');
  }

  const allContexts = [...SHARED_CONTEXTS, ...contexts];

  const allSchemaTypes = {
    ...__combineContextSchemas(allContexts),
    ...schemaTypes,
  };

  return (event) => ({
    event,
    schema: createJSONSchema(name, allSchemaTypes),
    contextResolvers: allContexts.map((c) => c.resolve),
  });
}

export default defineEvent;
