import { isEmpty, isUndefined, keys, last, map, some } from 'lodash';

import { getStateToRestore, getStateToSave } from '../../actions/helpers';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import { getDefaultGoal } from '../../universal/transformers/universal/helpers';
import { GROUPED_TIMESPAN_OPTIONS, TIME_OPTION } from '../ui-field-options';
import {
  getCombinedFiltersValues,
  operatorRequiresValues,
} from '../universal-config-helpers';

import {
  bootstrapGraph,
  loadVIPFilters,
  setServiceAccounts,
} from './universal-bootstrap-actions';
import {
  fetchAndUpdateSubquery,
  setConfig,
  setSource,
  submit,
  universalSetError,
} from './universal-config-actions';

export const setDetailsColumns = createAction(
  'UniversalQuery:SET_DETAILS_COLUMNS',
);
export const addDetailsColumn = createAction(
  'UniversalQuery:ADD_DETAILS_COLUMN',
);
export const addMetric = createAction('UniversalQuery:ADD_METRIC');
export const fetchFilterSuggestionsStart = createAction(
  'UniversalQuery:FETCH_FILTER_SUGGESTIONS_START',
);
export const removeDetailsColumn = createAction(
  'UniversalQuery:REMOVE_DETAILS_COLUMN',
);
export const removeFilter = createAction('UniversalQuery:REMOVE_FILTER');
export const removeMetric = createAction('UniversalQuery:REMOVE_METRIC');
export const setFilter = createAction('UniversalQuery:SET_FILTER');
export const setFilterForm = createAction('UniversalQuery:SET_FILTER_FORM');
export const setGlobalFilter = createAction('UniversalQuery:SET_GLOBAL_FILTER');
export const setGroupByBucket = createAction(
  'UniversalQuery:SET_GROUP_BY_BUCKET',
);
export const setGroupByCategory = createAction(
  'UniversalQuery:SET_GROUP_BY_CATEGORY',
);
export const setMetricAggregate = createAction(
  'UniversalQuery:SET_METRIC_AGGREGATE',
);
export const setMetricField = createAction('UniversalQuery:SET_METRIC_FIELD');
export const setSplitBy = createAction('UniversalQuery:SET_SPLIT_BY');
export const setOrderBy = createAction('UniversalQuery:SET_ORDER_BY');
export const setOrderByExtra = createAction(
  'UniversalQuery:SET_ORDER_BY_EXTRA',
);
export const setTimeField = createAction('UniversalQuery:SET_TIME_FIELD');
export const setTimeOperands = createAction('UniversalQuery:SET_TIME_OPERANDS');
export const setTimespanComparisonOff = createAction(
  'UniversalQuery:SET_TIMESPAN_COMPARISON_OFF',
);
export const setTimespanComparisonOn = createAction(
  'UniversalQuery:SET_TIMESPAN_COMPARISON_ON',
);
export const setTimespanComparisonOperator = createAction(
  'UniversalQuery:SET_TIMESPAN_COMPARISON_OPERATOR',
);
export const setTimespanComparisonType = createAction(
  'UniversalQuery:SET_TIMESPAN_COMPARISON_TYPE',
);
export const setVIPFilter = createAction('UniversalQuery:SET_VIP_FILTER');
export const setVisualisationError = createErrorAction(
  'UniversalQuery:SET_VISUALISATION_ERROR',
);
export const setVisualisationType = createAction(
  'UniversalQuery:SET_VISUALISATION_TYPE',
);
export const toggleDataSourcePanel = createAction(
  'UniversalQuery:TOGGLE_DATA_SOURCE_PANEL',
);
export const validate = createAction('UniversalQuery:VALIDATE');
export const updateNumberFormat = createAction(
  'UniversalQuery:UPDATE_NUMBER_FORMAT',
);
export const setPrerequisites = createAction(
  'UniversalQuery:SET_PREREQUISITES',
);
export const clearPrerequisites = createAction(
  'UniversalQuery:CLEAR_PREREQUISITES',
);

export const switchVisualisationType = (type) => (dispatch, getState) => {
  const {
    universal: { config },
  } = getState();
  const { type: currentType } = config;

  if (currentType === type) {
    return;
  }

  dispatch(setVisualisationType({ vizType: type }));

  dispatch(submit());
};

export const updateMetric = (field, index) => async (dispatch) => {
  dispatch(setMetricField({ field, index }));
  await dispatch(loadVIPFilters(['vipFilters']));
  dispatch(submit());
};

export const updateGroupBy = (field) => (dispatch, getState) => {
  // In most cases, all "datetime" fields will be replaced by a single `TIME_OPTION`, however
  // sometimes if we are using custom fields, we will have "datetime" fields that aren't replaced
  // and in these cases, we still want to bucket
  if (field === TIME_OPTION || field.type === 'datetime') {
    const {
      uiQuery: { bucket },
    } = getState().universal;
    dispatch(setGroupByBucket(bucket));
  } else {
    // Fetch values if on a field with custom values
    if (field.type === 'enum') {
      dispatch(fetchAndUpdateSubquery(field));
    }
    dispatch(setGroupByCategory(field));
  }
  dispatch(submit());
};

export const updateAggregation = (aggregate, index) => (dispatch) => {
  dispatch(setMetricAggregate({ aggregate, index }));
  dispatch(submit());
};

export const addNewMetric = () => (dispatch) => {
  dispatch(addMetric());
  dispatch(submit());
};

export const removeMetricAtIndex = (index) => (dispatch) => {
  dispatch(removeMetric(index));
  dispatch(submit());
};

export const updateFilter = (index, rawValue) => (dispatch) => {
  // Map filter specific props to query filter props
  const value = {};

  if (rawValue.since_start_of) {
    const { quantity, unit } = rawValue.since_start_of;
    value.operator = 'timespan';
    value.operands = [quantity, unit];
  } else {
    const { values } = rawValue;
    value.operator = rawValue.operator;
    if (values && values.length) {
      value.operands = values.map((op) => op.key || op);
    }
  }

  dispatch(
    setFilter({
      index,
      field: rawValue.field,
      value,
    }),
  );
  dispatch(submit());
};

export const setGlobalFilterValue =
  (_value, index, combinedFilters) => async (dispatch, getState) => {
    if (combinedFilters) {
      const {
        uiQuery: { globalFilters = [] },
      } = getState().universal;
      getCombinedFiltersValues(combinedFilters, globalFilters).forEach(
        (payload) => {
          dispatch(setGlobalFilter(payload));
        },
      );
    }

    const value = { ..._value, is_global: true };
    dispatch(setGlobalFilter({ index, value }));
    try {
      await dispatch(bootstrapGraph([], [], true));
      dispatch(submit());
    } catch (err) {
      dispatch(universalSetError(err));
    }
  };

export const setVIPFilterValue =
  (_value, index, runSubmit = true) =>
  (dispatch) => {
    const value = _value ? { ..._value, is_vip: true } : {};
    dispatch(setVIPFilter({ index, value }));
    if (runSubmit) {
      dispatch(submit());
    }
  };

export const editFilter = (filterForm) => async (dispatch, getState) => {
  if (!keys(filterForm).length) {
    dispatch(setFilterForm({}));
    return;
  }

  const {
    _filterModal: {
      index: currentIndex,
      field: currentField,
      operator: currentOperator,
    } = {},
    subQueryResults,
  } = getState().universal;

  const { field } = filterForm;
  const fieldValues = field.getValues(subQueryResults);

  /**
   * Clear current input and values when:
   * - First time opening panel (filterForm.values is not yet set)
   * - Changing the filter field (index and field have changed)
   * - Changing to operator that does not use values (ie `is_null`/`is_not_null`)
   * - Changing to a contains operator from any other operator
   */

  if (
    !filterForm.values ||
    (currentIndex === filterForm.index && currentField !== filterForm.field) ||
    !operatorRequiresValues(filterForm.operator) ||
    some(
      ['contains', 'matches'],
      (operator) =>
        (filterForm.operator.includes(operator) &&
          currentOperator &&
          !currentOperator.includes(operator)) ||
        (!filterForm.operator.includes(operator) &&
          currentOperator &&
          currentOperator.includes(operator)),
    )
  ) {
    filterForm.values = [];
    filterForm.value = '';
  }

  // When setting a timefield, set default timespan option
  if (field.type === 'datetime' && !filterForm.since_start_of) {
    filterForm.since_start_of = GROUPED_TIMESPAN_OPTIONS[0][0];
  } else if (field.type !== 'datetime') {
    // When operator is not set or when changing to a field
    // that doesn't support current operator, default it to 'in'
    if (
      !filterForm.operator ||
      (!field.supports_null_values && filterForm.operator.includes('null')) ||
      (!field.supports_contains_operators &&
        filterForm.operator.includes('contains'))
    ) {
      filterForm.operator = 'in';
    }

    if (field.restrict_single_value) {
      filterForm.operator = 'in';

      // When setting single value filter, set default option
      if (!filterForm.values.length && fieldValues) {
        filterForm.values = [fieldValues[0]];
      }
    }

    // Boolean override
    if (field.type === 'boolean') {
      filterForm.operator = 'in';

      if (!filterForm.values.length) {
        filterForm.values = [true];
      }
    }
  }

  dispatch(setFilterForm(filterForm));

  if (field.hasValues() && !fieldValues) {
    dispatch(fetchFilterSuggestionsStart());
    await dispatch(fetchAndUpdateSubquery(field));

    // Set default value for single value filter
    if (field.restrict_single_value) {
      const newValues = field.getValues(getState().universal.subQueryResults);

      // It's possible filter values have returned empty. For now we don't
      // handle it in the UI, but check here to prevent throwing error.
      if (newValues && newValues.length) {
        dispatch(
          setFilterForm({
            ...filterForm,
            values: [newValues[0]],
          }),
        );
      }
    }
  }
};

export const closeFilterConfig = () => (dispatch) => {
  dispatch(setFilterForm({}));
};

export const removeFilterFromQuery = (index) => (dispatch) => {
  dispatch(removeFilter(index));
  dispatch(submit());
};

export const updateTimespanOperands = (value) => (dispatch) => {
  const { quantity, unit } = value;
  const operands = [quantity, unit];

  dispatch(setTimeOperands(operands));
  dispatch(submit());
};

export const updateSplitBy = (field) => (dispatch) => {
  dispatch(setSplitBy(field));
  if (!isEmpty(field)) {
    // Fetch values if on a field with custom values
    dispatch(fetchAndUpdateSubquery(field));
  }
  dispatch(submit());
};

export const updateOrderBy = (field) => (dispatch) => {
  dispatch(setOrderBy(field));
  dispatch(submit());
};

export const updateOrderByExtra = (extra) => (dispatch) => {
  dispatch(setOrderByExtra(extra));
  dispatch(submit());
};

export const saveState = (paths) => (dispatch, getState) => {
  const { config } = getState().universal;
  const newConfig = getStateToSave(config, paths);

  dispatch(setConfig(newConfig));
};

export const restoreState = (paths, defaults) => (dispatch, getState) => {
  const { config } = getState().universal;
  const newConfig = getStateToRestore(config, paths, defaults);

  dispatch(setConfig(newConfig));
};

export const restoreStateGoal = () => (dispatch, getState) => {
  const {
    queryResults = [],
    config,
    uiQuery: { metrics: [{ node: { type } = {} } = {}] = [] } = {},
  } = getState().universal;
  const newConfig = getStateToRestore(config, ['goal']);

  // Compute and apply a default goal if never been set and we've got data
  if (isUndefined(newConfig.goal) && queryResults[0].length) {
    const defaultGoal = getDefaultGoal(queryResults[0], type);
    const defaults = {
      goal: `${defaultGoal}`,
    };
    dispatch(restoreState(['goal'], defaults));
  } else {
    dispatch(setConfig(newConfig));
  }
};

export const setServiceAccountId = (id) => (dispatch, getState) => {
  const { source } = getState().universal;
  dispatch(setSource({ ...source, service_account_id: `${id}` }));
};

export const switchServiceAccount = (id) => async (dispatch) => {
  dispatch(setServiceAccountId(id));
  try {
    await dispatch(bootstrapGraph(['globalFilters'], ['globalFilters'], true));
    dispatch(submit());
  } catch (err) {
    dispatch(universalSetError(err));
  }
};

export const updateServiceAccounts = (serviceAccounts) => async (dispatch) => {
  dispatch(setServiceAccounts(serviceAccounts));

  const { id } = last(serviceAccounts);
  dispatch(setServiceAccountId(id));
  try {
    await dispatch(bootstrapGraph(['globalFilters'], ['globalFilters'], true));
    dispatch(submit());
  } catch (err) {
    dispatch(universalSetError(err));
  }
};

export const updateTimespanComparison =
  ({ active, operator }) =>
  (dispatch) => {
    if (active === true) {
      dispatch(setTimespanComparisonOn());
    }

    if (active === false) {
      dispatch(setTimespanComparisonOff());
    }

    if (operator) {
      dispatch(setTimespanComparisonOperator(operator));
    }

    // TODO: We should be able to only run the query when active is true
    // and simply remove comparison from state on the setTimespanComparisonOff action
    dispatch(submit());
  };

export const setDetailsColumnNodes = (nodes) => (dispatch) => {
  dispatch(setDetailsColumns(nodes));
  dispatch(submit());
};

export const addNewDetailsColumn = (col) => (dispatch) => {
  dispatch(addDetailsColumn(col));
  dispatch(submit());
};

export const removeDetailsColumnAtIndex = (index) => (dispatch) => {
  dispatch(removeDetailsColumn(index));
  dispatch(submit());
};

export const updatePrerequisites = (values) => (dispatch, getState) => {
  const {
    uiQuery: { prerequisites: prerequisiteNodes },
  } = getState().universal;

  // Sanity check that we have the same number of nodes and values
  // If we don't, something has gone wrong
  if (values.length !== prerequisiteNodes.length) {
    throw new Error('Incorrect number of prerequisite values / nodes');
  }

  const newPrerequisiteNodes = map(prerequisiteNodes, (node, index) => {
    return {
      ...node,
      extra: {
        ...node.extra,
        operands: [values[index]],
      },
    };
  });

  dispatch(setPrerequisites(newPrerequisiteNodes));

  dispatch(submit());
  dispatch(bootstrapGraph([], [], true));
};
