import { get } from 'lodash';

import themes from '../../';

const getBackgroundColor = (themeName) => {
  const theme = get(themes, themeName, {});

  return theme['--background-color'];
};

export default getBackgroundColor;
