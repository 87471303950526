import { composeTransformers } from '../compose-transformers';
import { transform as genericBarTransform } from '../per-visualisation/bar';

import {
  durationTransform,
  legendTransform,
  transformBarOrColumnChart,
} from './shared';

function processedGroupedBySeries(_, __, ___, context) {
  const { series, x_axis: { type } = {} } = context;

  /*
   * for column chart, when grouping by field dimension, we need to get rid
   * of the first entry of values.
   *
   * Before:
   * ['beth', 1],
   * ['bill', 5],
   * ['bob', 3],
   *
   * After:
   * [1, 5, 3]
   */
  const processedSeries =
    !!series && type !== 'datetime'
      ? series.map((serie) => ({
          ...serie,
          data: serie.data.map((value) => value.slice(1)).flat(),
        }))
      : series;

  return {
    ...context,
    series: processedSeries,
  };
}

const transform = composeTransformers(
  transformBarOrColumnChart('vertical'),
  genericBarTransform,
  durationTransform,
  processedGroupedBySeries,
  legendTransform,
);

const applyConfig = (data, config) => {
  return { ...data, numberFormat: config.numberFormat };
};

export { applyConfig, transform };
