import { get } from 'lodash';

import { buildUrlWithParams } from '../lib/url';
import apolloClient from '../services/concierge-service/apollo-client';
import { getError } from '../services/concierge-service/errors';
import { Integration as IntegrationQuery } from '../services/concierge-service/graphql/integration-query.graphql';

const windowCallbackHandler = (cb) => (_, __, message) => cb(message);

const openBroadcastChannel = (onSuccess, onError) => {
  let broadcastChannel;

  // Due to a `cross-origin-opener-policy: "same-origin"` property being set on our third party integration auth flows this is clearing the window.opener object.
  // As a fallback we are also opening a broadcast channel to listen to message events to communicate between the main window and popup window.
  // Note: As of 23/11/2021, Safari does not support the Broadcast Channel API or the cross-origin-opener-policy and we will rely on window.opener to auth on Safari
  if (window.BroadcastChannel) {
    broadcastChannel = new BroadcastChannel('OAUTH');

    broadcastChannel.onmessage = (message) => {
      const { status, accountId, errorMsg } = message.data;

      if (status === 'SUCCESS') {
        onSuccess(accountId);
      }

      if (status === 'ERROR') {
        onError(new Error(errorMsg));
      }
    };
  }

  return broadcastChannel;
};

const cleanupWhenFinished = (popup, broadcastChannel, onError, isPending) => {
  if (popup.opener === window && popup.closed) {
    onError(new Error('Window closed'));
    if (broadcastChannel) {
      broadcastChannel.close();
    }
    return;
  }

  if (!isPending()) {
    window.OAuthCallbacks = undefined;
    popup.close();
    if (broadcastChannel) {
      broadcastChannel.close();
    }
    return;
  }

  setTimeout(
    () => cleanupWhenFinished(popup, broadcastChannel, onError, isPending),
    200,
  );
};

function openAuthPopup(url, popup) {
  let pending = true;
  let broadcastChannel;
  const isPending = () => pending;

  setPopupFlag();

  return new Promise((resolve, reject) => {
    window.OAuthCallbacks = {
      succeed: windowCallbackHandler(resolve),
      fail: windowCallbackHandler((reason) => reject(new Error(reason))),
    };

    popup.location = url;

    broadcastChannel = openBroadcastChannel(resolve, reject);
    cleanupWhenFinished(popup, broadcastChannel, reject, isPending);
  }).finally((value) => {
    pending = false;
    return value;
  });
}

const buildEmbassyAuthUrl = (baseUrl, { serviceAccountId, formData } = {}) => {
  const params = {};

  if (serviceAccountId) {
    params.service_account_id = serviceAccountId;
  }

  if (formData) {
    Object.entries(formData).forEach(([key, value]) => {
      params[`extra[${key}]`] = value;
    });
  }

  return buildUrlWithParams(baseUrl, params);
};

/**
 * auth - initialises an auth flow in a separate browser window. it returns a promise
 * that resolves to the newly created connection ID when the auth flow is successful
 * and rejects when either the authorisation failed or the user manually closed
 * the auth window.
 *
 * @param {object} popup a browser window (as returned from window.open())
 * @param {object} service the service object for the integration we want to connect to
 * @param {number} service.id the numerical service ID (we should stop using this and use the slug instead)
 * @param {string} service.name what is now called the integration slug
 * @param {"null"|"basic"|"oauth"|"oauth2"} service.auth_type the method the service uses for authentication
 * @param {object} formData extra data required to connect to the integration
 * @return {Promise<number>} Resolves to the new connection ID.
 */
export async function auth(popup, service, formData, serviceAccountId) {
  const client = apolloClient.create();

  let url;

  try {
    // If we can fetch the integration from Concierge that means it's supported
    // by Embassy. In which case provides us the base URL for the oAuth popup
    const integrationResponse = await client.query({
      query: IntegrationQuery,
      variables: { slug: service.name },
    });

    url = buildEmbassyAuthUrl(
      get(integrationResponse, 'data.integration.authUrl'),
      { serviceAccountId, formData },
    );
  } catch (e) {
    const error = getError(e);

    // If Concierge returns any other kind of error then it's a real system error
    // sp we should abandon the auth flow and show an error message.
    popup.close();
    throw new Error(error.message);
  }

  return openAuthPopup(url, popup);
}

// sets a flag to let the oauth callback know that it's running in a popup
function setPopupFlag() {
  localStorage.setItem(
    'embassy-oauth-flow-inside-popup',
    Date.now().toString(),
  );
}
