import {
  isAccountOwner,
  isDashboardViewOnly,
  isGroupAdmin,
  isGroupViewer,
} from './user-permissions';

/* global, Tracker */
const track = () => {
  // deprecated
};

const hasBeenInit = () => {
  return window.Tracker.hasBeenInit();
};

const init = (user, identify, convertToBackboneModel) => {
  window.Tracker.init(user, identify, undefined, convertToBackboneModel);
};

const isTrackingEnabled = () => {
  return window.Tracker.isTrackingEnabled();
};

const trackSwitchVisualisation = (serviceName, from, to, extra = {}) => {
  track('Config - Switch visualisation', {
    properties: {
      from_viz: from,
      to_viz: to,
      service_name: serviceName,
      ...extra,
    },
  });
};

const userPermissionConstants = {
  OWNER: 'owner',
  GROUP_ADMIN: 'group_admin',
  GROUP_VIEWER: 'group_read_only',
  DASHBOARD_VIEWER: 'dashboard_view_only',
};

const getUserPermissionString = (user) => {
  if (isAccountOwner(user)) {
    return userPermissionConstants.OWNER;
  }

  if (isGroupAdmin(user)) {
    return userPermissionConstants.GROUP_ADMIN;
  }

  if (isGroupViewer(user)) {
    return userPermissionConstants.GROUP_VIEWER;
  }

  if (isDashboardViewOnly(user)) {
    return userPermissionConstants.DASHBOARD_VIEWER;
  }

  return undefined;
};

/*
 * Create a unique ID string
 * Found on: https://stackoverflow.com/a/44078785/2409858
 */
const generateUniqueId = () =>
  Math.random().toString(36).substring(2) + new Date().getTime().toString(36);

export {
  generateUniqueId,
  getUserPermissionString,
  hasBeenInit,
  init,
  isTrackingEnabled,
  track,
  trackSwitchVisualisation,
  userPermissionConstants,
};
