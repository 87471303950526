import { findIndex } from 'lodash';
import moment from 'moment';

import { TIME_DIRECTIONS } from '../../bauhaus/bauhaus-constants';
import { TIMESPAN_OPERATORS } from '../../universal-config/ui-field-options';

export const QUARTER_HINTS = [
  '1st Jan - 31st Mar',
  '1st Apr - 30th Jun',
  '1st Jul - 30th Sep',
  '1st Oct - 31st Dec',
];

const Q4 = 10;
const Q3 = 7;
const Q2 = 4;
const Q1 = 1;

export const getQuarter = (currentDate) => {
  // +1 because Jan is 0
  const currentMonth = currentDate.getMonth() + 1;

  // we use find with an array of the first month for each quarter and look for
  // the first value that is greater or equal
  const quarter = findIndex([Q4, Q3, Q2, Q1], (value) => currentMonth >= value);

  // and reverse to get quarter number
  return 4 - quarter - 1;
};

export const getTimespanLabel = (
  unit,
  quantity,
  offset = 0,
  direction = '',
) => {
  switch (unit) {
    case 'hour':
      if (quantity === 1) return 'This hour';
      return `Past ${quantity} hours`;
    case 'day':
      if (quantity === 1 && direction === TIME_DIRECTIONS.FUTURE) {
        return 'Tomorrow';
      }
      if (offset === 1 && quantity === 1) return 'Yesterday';
      if (quantity === 1) return 'Today';
      return `Past ${quantity} days`;
    case 'week':
      if (quantity === 1 && direction === TIME_DIRECTIONS.FUTURE) {
        return 'Next week';
      }
      if (offset === 1 && quantity === 1) return 'Last week';
      if (quantity === 1) return 'This week';
      return `Past ${quantity} weeks`;
    case 'month':
      if (quantity === 1 && direction === TIME_DIRECTIONS.FUTURE) {
        return 'Next month';
      }
      if (offset === 1 && quantity === 1) return 'Last month';
      if (quantity === 1) return 'This month';
      return `Past ${quantity} months`;
    case 'quarter':
      if (quantity === 1) return 'This quarter';
      return `Past ${quantity} quarters`;
    case 'year':
      if (quantity === 1 && direction === TIME_DIRECTIONS.FUTURE) {
        return 'Next year';
      }
      if (quantity === 1) return 'This year';
      return `Past ${quantity} years`;
    default:
      return 'All time';
  }
};

export const getTimespanComparisonLabel = (unit, quantity, operator) => {
  if (operator === TIMESPAN_OPERATORS.PREV_SAME_TIMESPAN) {
    switch (unit) {
      case 'day':
        return 'same day last week';
      case 'month':
        return 'same month last year';
      case 'quarter':
        return 'same quarter last year';
      default:
        return null;
    }
  }

  switch (unit) {
    case 'hour':
      if (quantity === 1) return 'previous hour';
      return `previous ${quantity} hours`;
    case 'day':
      if (quantity === 1) return 'previous day';
      return `previous ${quantity} days`;
    case 'week':
      if (quantity === 1) return 'previous week';
      return `previous ${quantity} weeks`;
    case 'month':
      if (quantity === 1) return 'previous month';
      return `previous ${quantity} months`;
    case 'quarter':
      if (quantity === 1) return 'previous quarter';
      return `previous ${quantity} quarters`;
    case 'year':
      if (quantity === 1) return 'last year';
      return `previous ${quantity} years`;
    default:
      return 'All time';
  }
};

export const getTimespanHint = (unit, quantity, offset = 0, direction = '') => {
  switch (unit) {
    case 'day':
      // Tomorrow
      if (quantity === 1 && direction === TIME_DIRECTIONS.FUTURE) {
        return moment().add(1, 'days').format('dddd');
      }
      // Yesterday
      if (quantity === 1 && offset === 1) {
        return moment().subtract(1, 'days').format('ddd D MMM');
      }
      if (quantity === 1) return moment().format('dddd');
      return `Since ${moment()
        .subtract(quantity - 1, 'days')
        .format('ddd D MMM')}`;
    case 'week':
      if (quantity === 1 && direction === TIME_DIRECTIONS.FUTURE) {
        return 'Next Mon - Sun';
      }
      if (quantity === 1 && offset === 1) {
        return formatLastWeek();
      }
      if (quantity === 1) return 'Mon - Sun';
      return `Since ${moment()
        .startOf('week')
        .subtract(quantity - 1, 'weeks')
        .add(1, 'day') // moment weeks start on sunday (we start on monday)
        .format('ddd D MMM')}`;
    case 'month':
      if (quantity === 1 && direction === TIME_DIRECTIONS.FUTURE) {
        return moment().add(1, 'month').format('MMMM');
      }
      if (quantity === 1 && offset === 1) {
        return formatLastMonth();
      }
      if (quantity === 1) return moment().format('MMMM');

      return `${moment()
        .subtract(quantity - 1, 'months')
        .format('MMMM')} - ${moment().format('MMMM')}`;
    case 'quarter':
      if (quantity === 1) return QUARTER_HINTS[getQuarter(new Date())];
      return `Q${moment()
        .subtract(quantity - 1, 'quarters')
        .format('Q YYYY')} - Q${moment().format('Q YYYY')}`;
    case 'year': {
      let year = moment().subtract(quantity - 1, 'years');
      if (direction === TIME_DIRECTIONS.FUTURE) {
        year = moment().add(quantity, 'years');
      }
      return `Since 1st January ${year.format('YYYY')}`;
    }
    default:
      return '';
  }
};

const formatLastWeek = () => {
  const daysToLastMonday = moment().day() + 6;
  const lastMonday = moment().subtract(daysToLastMonday, 'days').startOf('day');
  const lastSunday = lastMonday.clone().add(6, 'days');
  return `${lastMonday.format('MMMM Do')} - ${lastSunday.format('MMMM Do')}`;
};

const formatLastMonth = () => {
  const firstDayOfLastMonth = moment().subtract(1, 'months').startOf('month');
  const lastDayOfLastMonth = moment().subtract(1, 'months').endOf('month');
  return `${firstDayOfLastMonth.format(
    'MMMM Do',
  )} - ${lastDayOfLastMonth.format('MMMM Do')}`;
};
