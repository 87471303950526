import { v4 as uuidv4 } from 'uuid';

import PushManager from '../../lib/push-manager';
import createAction from '../../lib/redux/create-action';
import { extendableBuiltinGen } from '../../universal';
import * as widgetDataActions from '../../widget-data/actions/widget-data-actions';

import { fetchVizDataSuccess, runQuery } from './universal-config-actions';
import { setVisualisationError } from './universal-query-actions';

export const setPusherChannel = createAction(
  'UniversalSocket:SET_PUSHER_CHANNEL',
);
export const setPusherReady = createAction('UniversalSocket:SET_PUSHER_READY');
export const queueFetchedData = createAction(
  'UniversalSocket:QUEUE_FETCHED_DATA',
);
export const clearQueuedData = createAction(
  'UniversalSocket:CLEAR_QUEUED_DATA',
);

export class PusherError extends extendableBuiltinGen(Error) {
  constructor(message) {
    super(message); // extendablebuiltingen is buggy and does not set message
    this.message = message;
  }
}

const isCurrentQuery = (getState, data) => {
  const { queryIds } = getState().universal;
  return queryIds.indexOf(data.query_id) !== -1;
};

const isCancelledQuery = (getState, data) => {
  const { cancelledQueries } = getState().universal;
  return cancelledQueries.has(data.query_id);
};

const getEditedWidgetKey = (getState) => {
  const { widgetMenu, universal } = getState();
  const { widgetKey } = widgetMenu;
  const { isPreviewOpen } = universal;
  return isPreviewOpen && widgetKey ? widgetKey : undefined;
};

const handlePushEvent = (_, eventName, data) => (dispatch, getState) => {
  switch (eventName) {
    case 'pusher:subscription_succeeded':
      dispatch(setPusherReady());
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { isConfigReady } = getState().universal;
      if (isConfigReady) {
        dispatch(runQuery());
      }
      return;
    case 'query_data':
      if (isCurrentQuery(getState, data)) {
        dispatch(
          fetchVizDataSuccess({
            data: data.data,
            done: data.done,
            queryId: data.query_id,
          }),
        );
      } else if (!isCancelledQuery(getState, data)) {
        dispatch(
          queueFetchedData({
            data: data.data,
            done: data.done,
            queryId: data.query_id,
          }),
        );
      }
      return;
    case 'query_error':
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { message, type } = data.error;
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const err = new Error(message);

      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const isImporting = type === 'ErrImportPending';
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const importingWidgetKey = isImporting && getEditedWidgetKey(getState);
      if (importingWidgetKey) {
        dispatch(widgetDataActions.dataError(data.error, importingWidgetKey));
      } else if (isCurrentQuery(getState, data)) {
        dispatch(setVisualisationError(err));
      } else if (!isCancelledQuery(getState, data)) {
        dispatch(
          queueFetchedData({
            error: err,
            queryId: data.query_id,
          }),
        );
      }
      return;
    default:
      return;
  }
};

const _connectToPusher = (channel, dispatch) => {
  try {
    const pushManager = PushManager.getInstance();

    pushManager.subscribe(channel, (...args) => {
      dispatch(handlePushEvent(...args));
    });
  } catch (e) {
    const { message } = e;
    throw new PusherError(`Fetch visualization data error: ${message}`);
  }
};

export const startPusherChannel = (userId, dashboardId) => (dispatch) => {
  // The dashboardId isn't needed in the channel name anymore,
  // but some BE services still expect a number to be present
  // in the place where the dashboardId is. Since RODs don't
  // have numerical IDs we use "0" instead.
  const isLegacyId =
    typeof dashboardId === 'number' || !dashboardId.startsWith('dash_');
  const dashId = isLegacyId ? dashboardId : '0';

  const channel = `private-universal-${userId}-${dashId}-${uuidv4()}`;
  dispatch(setPusherChannel(channel));
  _connectToPusher(channel, dispatch);
};
