import { LAYOUT_TYPES } from '../dashboard/dashboard-layout/constants';

export const getComparisonValue = (values = [], { valueOption }) => {
  switch (valueOption) {
    case 'previousValue':
      return values[values.length - 2];
    case 'firstValue':
    default:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const [first] = values;
      return first;
  }
};

export const DEFAULT_SIZE = [230, 230];

export const getDefaultSize = (type) => {
  switch (type) {
    case 'leaderboard':
      return [230, 470];
    case 'number':
    case 'geckometer':
    case 'text':
      return [230, 230];
    case 'line':
    case 'column':
    case 'bar':
    case 'table':
    case 'feed':
    case 'funnel':
      return [470, 230];
    default:
      return DEFAULT_SIZE;
  }
};

export const getMinimumHeight = (type) => {
  switch (type) {
    case 'text':
    case 'image':
    case 'number':
      return 0.5;
    default:
      return 1;
  }
};

export const getHumanReadableName = (type) => {
  const name = {
    bar: 'Bar Chart',
    clock: 'Clock',
    column: 'Column Chart',
    geckometer: 'Gauge',
    funnel: 'Funnel',
    leaderboard: 'Leaderboard',
    line: 'Line Chart',
    number: 'Number',
    map: 'Map',
    table: 'Table',
    feed: 'Feed',
    text: 'Text',
    rag: 'RAG',
    ragcolumn: 'RAG Column',
    pie: 'Pie Chart',
    highcharts: 'Highcharts',
    list: 'List',
    monitoring: 'Monitoring',
    bullet: 'Bullet Chart',
    image: 'Image',
  }[type];

  if (name) {
    return name;
  }

  throw new Error(
    `The visualisation type '${type}' has no human readable name.
Be sure to add one in src/visualisation/helpers.js`,
  );
};

export const isHalfHeight = (height) => height < 230;

export const isSmallDimension = (dimension) => dimension < 470;
export const isMediumDimension = (dimension) => dimension < 710;
export const isLargeDimension = (dimension) => dimension < 950;

export const getLayoutForVizSettings = (dashboardLayoutType, isTvMode) => {
  if (dashboardLayoutType === LAYOUT_TYPES.ContainerLayout) {
    return 'containerLayout';
  }
  if (isTvMode) {
    return 'tvMode';
  }
  return 'regular';
};

export const defaultMaxYAxis = (format) => (format === 'percent' ? 0.1 : 10);
