import { LDClient } from 'launchdarkly-js-client-sdk';
import { createContext } from 'react';

import FeatureFlagClientCreator from './feature-flag-client-creator';

// We provide a minimal stubbed implementation of the LDclient while we initialize the actual client
// The LDCLient interface is quite large, so instead of stubbing everything out,
//  we create a new type of just the functions we use.
export type FeatureFlagClient = Pick<
  LDClient,
  'variation' | 'allFlags' | 'on' | 'off'
> & {
  // When true this indicates we haven't finished loading flag rules from LaunchDarkly
  isPendingClient?: boolean;
};

type FeatureFlagClientCreatorContext = {
  ffClientCreator: FeatureFlagClientCreator;
  ffClient: FeatureFlagClient;
};

const FeatureFlagContext = createContext({} as FeatureFlagClientCreatorContext);

export default FeatureFlagContext;
