import React, { createContext, useContext, useEffect, useState } from 'react';

const CurrentTimeContext = createContext<number | undefined>(undefined);

type CurrentTimeProviderProps = {
  children?: React.ReactNode;
};

export function CurrentTimeProvider({ children }: CurrentTimeProviderProps) {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setNow(Date.now());
    }, 1000);

    return () => {
      window.clearInterval(intervalId);
    };
  });

  return (
    <CurrentTimeContext.Provider value={now}>
      {children}
    </CurrentTimeContext.Provider>
  );
}

export function useCurrentTime() {
  return useContext(CurrentTimeContext);
}
