export const SUCCESS = 'Toast:SUCCESS';
export const ERROR = 'Toast:ERROR';
export const ADDED_SCREEN = 'Toast:ADDED_SCREEN';
export const FOOTER_SWITCHED_ON = 'Toast:FOOTER_SWITCHED_ON';
export const DEMOTED_TO_DASHBOARD_VIEW_ONLY =
  'Toast:DEMOTED_TO_DASHBOARD_VIEW_ONLY';
export const SHARING_LINK_COPIED = 'Toast:SHARING_LINK_COPIED';
export const REGENERATED_SHARING_LINK = 'Toast::REGENERATED_SHARING_LINK';
export const REGENERATED_LOOP_SHARING_LINK =
  'Toast::REGENERATED_LOOP_SHARING_LINK';
export const CREATED_LOOP = 'Toast::CREATED_LOOP';
export const ADD_A_GOAL = 'Toast::ADD_A_GOAL';
export const DASHBOARD_TEMPLATE_CREATE_ERROR =
  'Toast::DASHBOARD_TEMPLATE_CREATE_ERROR';
export const WIDGET_COPIED = 'Toast::WIDGET_COPIED';
