/**
 * Cache Key Resolver
 *
 * This lib exposes a function that takes a GraphQL object and returns
 * a cache key. A cache key is the string that is used to reference
 * an item in the Apollo cache. By default it takes the form of
 *
 * typename:id
 *
 * But this behaviour can be customised for a specific type.
 * To do so, add a resolver function to the resolvers object
 * for whatever type you'd like to customise.
 */

import { KeyFieldsFunction } from '@apollo/client/cache/inmemory/policies';

const instrumentResolver: KeyFieldsFunction = (object) =>
  `Instrument:${object.id}`;
const dashboardResolver: KeyFieldsFunction = (object) =>
  `Dashboard:${object.id}`;

const resolvers: Record<string, KeyFieldsFunction> = {
  Clock: instrumentResolver,
  Label: instrumentResolver,
  Image: instrumentResolver,
  Line: instrumentResolver,
  LegacyLine: instrumentResolver,
  Geckometer: instrumentResolver,
  Number: instrumentResolver,
  Column: instrumentResolver,
  Bar: instrumentResolver,
  Leaderboard: instrumentResolver,
  Table: instrumentResolver,
  Feed: instrumentResolver,
  Text: instrumentResolver,
  Map: instrumentResolver,
  Rag: instrumentResolver,
  Bullet: instrumentResolver,
  PieChart: instrumentResolver,
  Funnel: instrumentResolver,
  Highcharts: instrumentResolver,
  List: instrumentResolver,
  Monitoring: instrumentResolver,
  ContainerDashboard: dashboardResolver,
  LegacyDashboard: dashboardResolver,
};

const cacheKeyResolver: KeyFieldsFunction = (object, ...args) => {
  if (object.__typename && resolvers[object.__typename]) {
    return resolvers[object.__typename](object, ...args);
  }

  if (!object.id) {
    return false;
  }

  return `${object.__typename}:${object.id}`;
};

export default cacheKeyResolver;
