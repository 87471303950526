import { reject } from 'lodash';

import {
  deleteUserSuccessful,
  usersFetchSuccessful,
} from '../actions/people-actions';

const initialState = {
  visible: false,
  view: null,
  manageViewTab: null,
  addresses: '',
  error: null,
  working: false,
  users: null,
};

const peopleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case usersFetchSuccessful.type:
      return {
        ...state,
        users: payload,
      };

    case deleteUserSuccessful.type:
      return {
        ...state,
        users: reject(state.users, { id: payload.userId }),
      };

    default:
      return state;
  }
};

export default peopleReducer;
