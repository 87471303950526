import { isEmpty, mapValues, reduce } from 'lodash';

import { getGeckometerDefaultMinMax } from '../../lib/geckometer';
import { getSelectedCells } from '../../universal/spreadsheet-helpers';
import {
  getGeckometerValue,
  getNumericCells,
  getSelectionFormat,
} from '../../universal/transformers/spreadsheet/helpers';

const INDEX_BASED = 1; // API returns spreadsheet cells with index = 1

const extractMeta = (worksheet, spreadsheet) => {
  let state = { loading: false };

  if (worksheet) {
    state = {
      ...state,
      worksheetId: worksheet.id,
      cells: worksheet.cells,
      bounds: mapValues(worksheet.bounds, (val) => {
        return val + INDEX_BASED; // 1-indexed backend data
      }),
    };
  }

  if (spreadsheet) {
    state = {
      ...state,
      fileId: spreadsheet.id,
      title: spreadsheet.title,
      service: spreadsheet.service,
      legacyServiceAccountId: spreadsheet.legacy_service_account_id,
      worksheets: spreadsheet.worksheets.reduce(
        (result, sheet) => ({ ...result, [sheet.id]: sheet }),
        {},
      ),
    };
  }

  if (!(spreadsheet || worksheet)) {
    state = {
      ...state,
      title: '',
      isFileMissing: true,
      worksheets: {},
    };
  }

  return state;
};

const toValidMinMax = (config, state) => {
  const { value, type } = config;
  const { cells } = state;

  if (isEmpty(cells) || type !== 'geckometer') {
    return {
      ...config,
      _minDefault: '',
      _maxDefault: '',
    };
  }

  const item = getGeckometerValue(value, cells);
  const [selectedCells] = getSelectedCells(cells, [value]);
  const numericCells = getNumericCells(selectedCells);
  const [format] = getSelectionFormat(numericCells);

  return { ...config, ...getGeckometerDefaultMinMax(item, format) };
};

const toValidConfig = (state) => {
  const { config } = state;

  // If the config is uninitialised then leave it as is.
  if (isEmpty(config)) {
    return config;
  }

  // Apply the toValid function in order.
  const toValidFunctions = [toValidMinMax];

  return reduce(
    toValidFunctions,
    (intermediateConfig, fn) => {
      return fn.call(this, intermediateConfig, state);
    },
    config,
  );
};

export { extractMeta, toValidConfig, toValidMinMax };
