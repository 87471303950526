import { isUndefined } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericNumberTransform } from '../per-visualisation/number';

import { getFormat, getMainColumnField, parseNumberValue } from './helpers';

const numberTransform = (
  payload,
  config = {},
  timezone,
  initialContext = {},
) => {
  if (!payload) {
    return {};
  }
  const numberColumn = getMainColumnField(config);
  const format = getFormat(numberColumn, config);
  const {
    queryMetas: [{ select: [selectMeta = {}] = [] } = {}] = [],
    timespanComparison,
  } = config;
  const isTimespanComparisonOn = !isUndefined(timespanComparison);

  // In case of timespancomparison we receive 2 array. The first one is actual
  // value and second is previous period
  const wrappedValue = isTimespanComparisonOn ? payload[0] : payload;
  const value = parseNumberValue(wrappedValue);

  const visualisationConfig = {
    value,
    ...format,
  };

  // Only process time comparison if main value is not null
  if (value && payload[1] && isTimespanComparisonOn) {
    const parsedComparisonValue = parseNumberValue(payload[1]);

    // Comparison value is set as first element by default
    if (parsedComparisonValue) {
      visualisationConfig.value = [...parsedComparisonValue, ...value];
    } else {
      visualisationConfig.value = [null, ...value]; // Just put null in if it's null or undefined
    }

    if (timespanComparison.type === 'percentage') {
      visualisationConfig.comparison = {
        type: 'percentage',
      };
    } else if (timespanComparison.type === 'difference') {
      visualisationConfig.comparison = {
        type: 'number',
      };
    }

    if (selectMeta.hasReverseComparison) {
      visualisationConfig.comparison = {
        ...visualisationConfig.comparison,
        reversed: true,
      };
    }
  }

  return { ...initialContext, ...visualisationConfig };
};

const transform = composeTransformers(numberTransform, genericNumberTransform);

export { transform };
