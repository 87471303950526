import hsluv from 'hsluv';

const generateAutoWidgetColor = (backgroundColor) => {
  const [hue, saturation, lightness] = hsluv.hexToHsluv(backgroundColor);

  // Saturation maxes out at 70
  const widgetSaturation = Math.min(saturation, 70);

  // Widget lightness is 20 unless the background lightness is also around 20
  let widgetLightness = 20;
  if (lightness < 30 && lightness > 15) {
    widgetLightness = lightness - 10;
  }

  return hsluv.hsluvToHex([hue, widgetSaturation, widgetLightness]);
};

export default generateAutoWidgetColor;
