import update from 'immutability-helper';
import { get, has, isUndefined, omitBy } from 'lodash';

import { getStateToSave } from '../../actions/helpers';
import * as datasetActions from '../actions/dataset-actions';
import getVisualisationHelpers from '../components/get-visualisation-helpers';
import { FORM_TYPES } from '../components/table-form/table-form-constants';

const initialState = {
  config: { type: 'line' },
  error: null,
  currentTimeField: null,
  isLoading: true,
  isRetrieving: false,
  isSaving: false,
  showFilePicker: false,
};

const updateState = (oldState, newState) => {
  if (has(newState, 'config') || has(newState, 'queryResult')) {
    const actualConfig = get(newState, 'config', oldState.config);
    const actualQueryResult = get(
      newState,
      'queryResult',
      oldState.queryResult,
    );
    const { type } = actualConfig;
    return {
      ...newState,
      config: getVisualisationHelpers(type).ensureConfig(
        actualConfig,
        actualQueryResult,
      ),
    };
  }
  return newState;
};

const datasetReducer = (state = initialState, action) => {
  switch (action.type) {
    case datasetActions.datasetSet.type: {
      const newState = updateState(state, action.payload);
      return {
        ...state,
        ...newState,
      };
    }

    case datasetActions.datasetSetError.type: {
      const newState = updateState(state, action.payload);
      return {
        ...state,
        ...newState,
        error: action.error,
      };
    }

    case datasetActions.setConfig.type: {
      const { config, queryResult } = state;
      const { type } = config;
      const newConfig = { ...config, ...action.payload };

      return {
        ...state,
        config: getVisualisationHelpers(type).ensureConfig(
          newConfig,
          queryResult,
        ),
      };
    }

    case datasetActions.setCurrentTimeField.type: {
      // We store the time field that's currently selected in the time dropdown.
      // We can't rely on the query for this, because if using "all time",
      // no timespan filter is applied. We use this to prevent adding
      // new filters with the field from the time section.
      return {
        ...state,
        currentTimeField: action.payload,
      };
    }

    case datasetActions.resetConfig.type: {
      const { config, queryResult } = state;
      const { type } = config;

      return {
        ...state,
        currentTimeField: null,
        config: getVisualisationHelpers(type).ensureConfig(
          { type },
          queryResult,
        ),
      };
    }

    case datasetActions.saveState.type: {
      const newConfig = getStateToSave(state.config, action.payload);

      return {
        ...state,
        config: {
          ...state.config,
          ...newConfig,
        },
      };
    }

    case datasetActions.resetQueryHistory.type: {
      const newState = { ...state };

      delete newState._previousRawQuery;
      delete newState._previousSummaryQuery;

      return {
        ...newState,
        _previousQueries: {},
      };
    }

    case datasetActions.toggleFilePicker.type:
      return {
        ...state,
        showFilePicker: action.payload,
      };

    case datasetActions.setTableType.type: {
      const currentTableType = state.config.tableType;
      const nextTableType = action.payload;
      const previousQuery =
        nextTableType === FORM_TYPES.SUMMARIZED
          ? '_previousRawQuery'
          : '_previousSummaryQuery';

      return {
        ...state,
        query: undefined,
        config: {
          ...state.config,
          tableType: nextTableType,
          numberFormat: state[`_${nextTableType}NumberFormat`],
        },
        [previousQuery]: state.query,
        [`_${currentTableType}NumberFormat`]: state.config.numberFormat,
        [`_${nextTableType}NumberFormat`]: undefined,
      };
    }

    case datasetActions.updateColumnNumberFormat.type: {
      const { index, format } = action.payload;
      const { config } = state;
      let numberFormat = state.config.numberFormat;

      numberFormat = numberFormat ? [...numberFormat] : [];
      numberFormat[index] = {
        ...numberFormat[index],
        ...format,
      };

      return {
        ...state,
        config: {
          ...config,
          numberFormat,
        },
      };
    }

    case datasetActions.reorderColumn.type: {
      const { columns } = state.query;
      const { numberFormat, tableType } = state.config;
      const { column_types: columnTypes, headings } = state.queryResult;
      const { sourceIndex, targetIndex } = action.payload;
      const column = columns[sourceIndex];
      const currentFormat = numberFormat[sourceIndex] || null;

      let qrSourceIndex = sourceIndex;
      let qrTargetIndex = targetIndex;

      // Offset for the `group_by` column
      // in our `queryResult`
      if (FORM_TYPES.SUMMARIZED === tableType) {
        qrSourceIndex++;
        qrTargetIndex++;
      }

      const heading = headings[qrSourceIndex];
      const columnType = columnTypes[qrSourceIndex];

      return update(state, {
        query: {
          columns: {
            $splice: [
              [sourceIndex, 1],
              [targetIndex, 0, column],
            ],
          },
        },
        config: {
          numberFormat: {
            $splice: [
              [sourceIndex, 1],
              [targetIndex, 0, currentFormat],
            ],
          },
        },
        queryResult: {
          column_types: {
            $splice: [
              [qrSourceIndex, 1],
              [qrTargetIndex, 0, columnType],
            ],
          },
          headings: {
            $splice: [
              [qrSourceIndex, 1],
              [qrTargetIndex, 0, heading],
            ],
          },
          data: {
            $apply: (rows) => {
              return rows.map((row) => {
                const item = row[qrSourceIndex];
                row.splice(qrSourceIndex, 1);
                row.splice(qrTargetIndex, 0, item);
                return row;
              });
            },
          },
        },
      });
    }

    case datasetActions.setProgressGoal.type: {
      const { config, queryResult } = state;
      const { type } = config;
      const { progressGoal, startingValue } = action.payload;
      const newConfig = {
        ...config,
        comparison: omitBy(
          { type: 'goal', thresholdType: 'percentage', startingValue },
          isUndefined,
        ),
        goal: isFinite(progressGoal) ? progressGoal.toString() : progressGoal,
      };

      return {
        ...state,
        config: getVisualisationHelpers(type).ensureConfig(
          newConfig,
          queryResult,
        ),
      };
    }

    case datasetActions.setGoal.type: {
      const { config, queryResult } = state;
      const { type } = config;

      const { goal } = action.payload;

      const newConfig = {
        ...config,
        // If there is a valid goal our backend widget config only accepts the value as a string
        goal: isFinite(goal) ? goal.toString() : goal,
      };

      return {
        ...state,
        config: getVisualisationHelpers(type).ensureConfig(
          newConfig,
          queryResult,
        ),
      };
    }

    default:
      return state;
  }
};

export default datasetReducer;
