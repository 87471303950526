import { capitalize, isNil, omitBy, values } from 'lodash';
import { matchPath } from 'react-router';

import SchemaTypes from '@Tracking/schema-types';

import { router } from '../../../router';
import {
  TrackingPropsLegacyIdQuery,
  TrackingPropsQuery,
} from '../../../services/concierge-service/graphql/dashboard-tracking-props-query.graphql';

export const TRACKING_LAYOUT_TYPES = {
  ClassicLayout: 'Classic',
  FixedLayout: 'Fixed',
  ContainerLayout: 'Container',
};

const DASHBOARD_ROUTES = [
  '/edit/dashboards/:dashboardId',
  '/v4/dashboards/:dashboardId',
  '/v5/dashboards/:dashboardId',
];

const getDashboardId = () => {
  const {
    location: { pathname: currentPath },
  } = router.getHistory();

  let match;
  for (let i = 0; i < DASHBOARD_ROUTES.length; i++) {
    match = matchPath(currentPath, {
      path: DASHBOARD_ROUTES[i],
    });

    if (match) {
      break;
    }
  }

  if (match) {
    return match.params.dashboardId;
  }
  return null;
};

const dashboardContext = {
  schema: {
    'Dashboard ID': SchemaTypes.string.isRequired,
    'Legacy dashboard ID': SchemaTypes.string.isRequired,
    'Dashboard type': SchemaTypes.oneOf(values(TRACKING_LAYOUT_TYPES))
      .isRequired,
    'Classicboard column count': SchemaTypes.number,
    'Fixieboard grid size': SchemaTypes.oneOf(['Dense', 'Standard']),
  },
  resolve: async ({ apolloClient }) => {
    const dashboardId = getDashboardId();

    if (!dashboardId) {
      return {};
    }

    const isLegacyId = !dashboardId.startsWith('dash_');
    let query = TrackingPropsQuery;
    let variables = { id: dashboardId };

    if (isLegacyId) {
      query = TrackingPropsLegacyIdQuery;
      variables = { legacyId: dashboardId };
    }

    try {
      const { data } = await apolloClient.query({
        query,
        variables,
      });

      const { dashboard } = data;

      if (!dashboard) {
        throw new Error('No dashboard returned from Apollo');
      }

      return {
        'Dashboard ID': dashboard.id,
        'Legacy dashboard ID': `${dashboard.legacyId}`,
        'Dashboard type':
          TRACKING_LAYOUT_TYPES[dashboard.layoutType.__typename],
        ...omitBy(
          {
            'Classicboard column count': dashboard.layoutType.columnCount,
            'Fixieboard grid size':
              dashboard.layoutType.grid &&
              capitalize(dashboard.layoutType.grid),
          },
          isNil,
        ),
      };
    } catch (e) {
      if (isLegacyId) {
        return { 'Legacy dashboard ID': `${dashboardId}` };
      }
      return { 'Dashboard ID': dashboardId };
    }
  },
};

export default dashboardContext;
