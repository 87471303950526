import componentPicker from '../lib/component-picker';
import { datasets, salesforce, spreadsheets } from '../universal/services';

const SERVICES = {
  gsheets: spreadsheets,
  salesforce,
  datasets,
};

function servicePicker(serviceName) {
  return componentPicker(SERVICES, serviceName, spreadsheets);
}

export default servicePicker;
