export const LAYOUT_TYPES = {
  ClassicLayout: 'ClassicLayout',
  FixedLayout: 'FixedLayout',
  ContainerLayout: 'ContainerLayout',
} as const;

export const GRAPHQL_LAYOUT_TYPES_TRACKING = {
  ClassicLayout: 'classic',
  ContainerLayout: 'container',
  FixedLayout: 'wonderwall',
} as const;
