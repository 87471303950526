import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import style from './icon.scss';

const FONTS = {
  FONT_AWESOME: 'fa',
  GECKO_FONT: 'gecko',
} as const;

const FONT_WEIGHTS = {
  FA_REGULAR: 'far',
  FA_SOLID: 'fas',
  FA_LIGHT: 'fal',
} as const;

type Props = {
  name: string;
  font?: (typeof FONTS)[keyof typeof FONTS];
  fontWeight?: (typeof FONT_WEIGHTS)[keyof typeof FONT_WEIGHTS];
  light?: boolean;
  muted?: boolean;
};

const Icon = React.forwardRef<HTMLSpanElement, Props>(
  (
    {
      name,
      muted = false,
      light = false,
      font = FONTS.FONT_AWESOME,
      fontWeight = FONT_WEIGHTS.FA_SOLID,
    },
    ref,
  ) => {
    const className = classNames(
      {
        [`${fontWeight} fa-${name}`]: font === FONTS.FONT_AWESOME,
        [`${style['gecko-font']} ${style[`gecko-${name}`]}`]:
          font === FONTS.GECKO_FONT,
      },
      { [style.muted]: !!muted },
      { [style.light]: !!light },
      { [style.default]: !light && !muted },
    );
    return (
      <span
        ref={ref}
        className={className}
        aria-hidden="true"
        data-testid={`icon-${name}`}
      />
    );
  },
);

Icon.displayName = 'Icon';

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  font: PropTypes.oneOf([FONTS.FONT_AWESOME, FONTS.GECKO_FONT]),
  fontWeight: PropTypes.oneOf([
    FONT_WEIGHTS.FA_REGULAR,
    FONT_WEIGHTS.FA_SOLID,
    FONT_WEIGHTS.FA_LIGHT,
  ]),
  light: PropTypes.bool,
  muted: PropTypes.bool,
};

const IconWithEnums = Object.assign(Icon, { FONTS, FONT_WEIGHTS });

export default IconWithEnums;
