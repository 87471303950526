import { FRAME_HEIGHT, FRAME_WIDTH } from './box-layout-constants';

export const applyRatioToWidgetLayout = (layout = {}, ratio) => ({
  ...layout,
  pixel_width: (layout.pixel_width + 10) * ratio - 10,
  pixel_height: (layout.pixel_height + 10) * ratio - 10,
  top: layout.top * ratio,
  left: layout.left * ratio,
});

const applyRatioToFrameLayout = (layout = {}, ratio) => {
  const transformedLayout = {};
  if (layout.size) {
    transformedLayout.size = {
      width: (layout.size.width + 10) * ratio - 10,
      height: (layout.size.height + 10) * ratio - 10,
    };
  }
  if (layout.position) {
    transformedLayout.position = {
      ...layout.position,
      top: layout.position.top * ratio,
      left: layout.position.left * ratio,
    };
  }
  return transformedLayout;
};

export const convertFrameLayoutToGrid = (layout, columns) => {
  const ratio = 8 / columns;

  return applyRatioToFrameLayout(layout, ratio);
};

export const convertFramePositionFromGrid = (position, columns) => {
  const ratio = 1 / (8 / columns);

  return applyRatioToFrameLayout({ position }, ratio).position;
};

export const convertWidgetLayoutToGrid = (layout, dashboard) => {
  const { columns, toggles = [] } = dashboard;

  if (!toggles.includes('grid')) {
    return layout;
  }

  const ratio = 8 / columns;

  return applyRatioToWidgetLayout(layout, ratio);
};

export const convertWidgetLayoutFromGrid = (layout, dashboard) => {
  const { columns, toggles = [] } = dashboard;

  if (!toggles.includes('grid')) {
    return layout;
  }

  const ratio = 1 / (8 / columns);

  return applyRatioToWidgetLayout(layout, ratio);
};

export const isWidgetOutOfFrame = ({
  pixel_width: width,
  pixel_height: height,
  top,
  left,
}) => {
  return width + left > FRAME_WIDTH || height + top > FRAME_HEIGHT;
};
