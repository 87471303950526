import { chain, head, isMatch, isNull, map, negate } from 'lodash';

import { greedilyParseFloat } from '../../spreadsheet-helpers';

import { getSelectionFormat, isDatetime, isNumber } from './helpers';

const isNotNull = negate(isNull);
// Helpers common to visualisations like the line and bar chart

function getLabelsType(selection) {
  if (isDatetime(selection)) {
    return 'datetime';
  }

  if (isNumber(selection)) {
    return 'number';
  }

  return 'standard';
}

function getDefaultLegendName(id) {
  const alpha = String.fromCharCode(65 + id);
  return `Series ${alpha}`;
}

function isFirstCellHeader(firstCell, indexHeader) {
  if (!indexHeader || isNull(firstCell)) {
    return true; // Free Range selection always first cell as header
  }
  return isMatch(firstCell, indexHeader);
}

function getSeriesName(firstCell = {}, id, labels, hasFirstCellHeader) {
  if (labels && labels[id]) {
    return labels[id];
  }

  if (hasFirstCellHeader && firstCell) {
    if (firstCell.type === 'datetime' && firstCell.human) {
      return firstCell.human;
    }

    if (firstCell.value) {
      return firstCell.value;
    }
  }

  return getDefaultLegendName(id);
}

function getSeriesValues(cells) {
  return cells.map((cell) => {
    if (!cell) return null;

    return greedilyParseFloat(cell.value);
  });
}

function getSeries(allSelectedCells, headerIndices, labels) {
  return chain(allSelectedCells)
    .zip(headerIndices)
    .map(([cells, headerIndex], id) => {
      const firstCell = head(cells);

      const hasFirstCellHeader =
        headerIndices && isFirstCellHeader(firstCell, headerIndex);

      const targetCells = hasFirstCellHeader ? cells.slice(1) : cells;
      const [type, currency] = getSelectionFormat(
        targetCells.filter(isNotNull),
      );
      const data = getSeriesValues(targetCells);
      const name = getSeriesName(firstCell, id, labels, hasFirstCellHeader);

      return { name, data, type, currency };
    })
    .filter((series) => !series.data.every(Number.isNaN)) // Skip a series if every value is a non-number
    .map((series) => {
      let { data } = series;

      data = data.map((value) => (Number.isNaN(value) ? null : value)); // If there is a non-number replace it with null to signal skipping this in the visualisation

      return { ...series, data };
    })
    .value();
}

function getLabels(selectedCells, headerIndices) {
  const hasFirstCellHeader =
    headerIndices && isFirstCellHeader(head(selectedCells), headerIndices);

  const labelCells = hasFirstCellHeader
    ? selectedCells.slice(1)
    : selectedCells;
  const type = getLabelsType(labelCells);
  const labels = map(labelCells, 'value');

  return { labels, type };
}

export { getLabels, getSeries, getSeriesName };
