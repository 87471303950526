import { getColumnLetter } from '../../helpers/selection-helpers';
import type { VectorRange } from '../../types';

/**
 * Convert a range into spreadsheet notation
 */
export function getRangeNotation(range: VectorRange) {
  const [colStart, rowStart] = range.start;
  const [colEnd, rowEnd] = range.end;

  // Entire row selection (omit the column letters)
  if (colStart === -1 || colEnd === -1) {
    return `${rowStart}:${rowEnd}`;
  }

  const startLetter = getColumnLetter(colStart);
  const endLetter = getColumnLetter(colEnd);

  // Entire column selection (omit the row numbers)
  if (rowStart === -1 || rowEnd === -1) {
    return `${startLetter}:${endLetter}`;
  }

  return `${startLetter}${rowStart + 1}:${endLetter}${rowEnd + 1}`;
}
