import { translate } from '../../lib/config-mapper';
import createAction from '../../lib/redux/create-action';
import createErrorAction from '../../lib/redux/create-error-action';
import {
  getSpreadsheet,
  getWorksheet,
} from '../../universal/services/spreadsheet-service';
import getVisualisationHelpers from '../helpers/get-visualisation-helpers';
import {
  convertSelectionRangesToColumns,
  convertSelectionRangesToRows,
} from '../helpers/selection-helpers';
import {
  actions,
  selectors,
} from '../reducer/spreadsheet-data-selection-slice';

export const doSwitchVisualisationType = createAction(
  'SpreadsheetConfig:SWITCH_VISUALISATION_TYPE',
);

export const worksheetFetched = createAction('Spreadsheet:WORKSHEET_FETCHED');
export const worksheetFetchFailed = createErrorAction(
  'Spreadsheet:WORKSHEET_FETCH_FAILED',
);
export const loading = createAction('Spreadsheet:LOADING');

export const hydrateConfigureView = createAction(
  'Spreadsheet:HYDRATE_CONFIGURE_VIEW',
);

export const reorderTableColumns = createAction(
  'Spreadsheet:REORDER_TABLE_COLUMNS',
);

export const fetchWorksheet = (fileId, worksheetId) => async (dispatch) => {
  const worksheet = await getWorksheet(fileId, worksheetId);
  dispatch(worksheetFetched({ worksheet }));
  dispatch(actions.file.selectWorksheet(worksheet));
};

export const fetchFile = (fileId, worksheetId) => async (dispatch) => {
  dispatch(loading());

  try {
    const spreadsheet = await getSpreadsheet(fileId, 0);

    // if there is no worksheetId take the first from the worksheets array
    if (!worksheetId) {
      const { worksheets } = spreadsheet;
      const { id } = worksheets[0];

      worksheetId = id;
    }

    const worksheet = await getWorksheet(fileId, worksheetId);

    dispatch(
      worksheetFetched({
        worksheet,
        spreadsheet,
      }),
    );
    dispatch(actions.file.selectWorksheet(worksheet));
  } catch (error) {
    dispatch(worksheetFetchFailed(error));
  }
};

export const refreshFile = () => (dispatch, getState) => {
  const { fileId, worksheetId } = getState().spreadsheets;
  dispatch(fetchFile(fileId, worksheetId));
};

export const switchVisualisationType = (type) => (dispatch, getState) => {
  const state = getState();
  const {
    spreadsheets: { config: currentConfig, previousConfigs },
  } = state;
  const selections = selectors.rangeSelection.selections.all(state);
  const lastConfigForType = previousConfigs[type];

  if (currentConfig.type === type) return;

  const visualisationHelpers = getVisualisationHelpers(type);

  const transposeDirection =
    selectors.widgetConfiguration.transposeDirection(state);
  const selectionsArray =
    transposeDirection === 'columns'
      ? convertSelectionRangesToColumns(selections)
      : convertSelectionRangesToRows(selections);

  const defaults = visualisationHelpers.getDefaultConfig([...selectionsArray]);

  const mappedValues = translate(
    visualisationHelpers.configMappingRules,
    currentConfig,
    lastConfigForType,
  );

  const config = {
    ...defaults,
    ...lastConfigForType,
    ...mappedValues,
  };

  dispatch(doSwitchVisualisationType(config));
};

export const changeFile = (fileId) => fetchFile(fileId);
