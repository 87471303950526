const CONTACT_PROPERTY_VALUES_QUERY_TEMPLATE = `{ "select": [ { "resource": "contact_property_values", "path": "label" }, { "resource": "contact_property_values", "path": "value" } ],
     "filter": [ { "resource": "contact_property_values", "path": "name", "operator": "in", "operands": ["<%= key %>"] } ] }`;
const DEAL_PROPERTY_VALUES_QUERY_TEMPLATE = `{ "select": [ { "resource": "deal_property_values", "path": "label" }, { "resource": "deal_property_values", "path": "value" } ],
     "filter": [ { "resource": "deal_property_values", "path": "name", "operator": "in", "operands": ["<%= key %>"] } ] }`;
const COMPANY_PROPERTY_VALUES_QUERY_TEMPLATE = `{ "select": [ { "resource": "company_property_values", "path": "label" }, { "resource": "company_property_values", "path": "value" } ],
     "filter": [ { "resource": "company_property_values", "path": "name", "operator": "in", "operands": ["<%= key %>"] } ] }`;

export default {
  resources: [
    {
      name: 'owners',
      private: true,
      primary_key: 'ownerId',
      fields: [{ key: 'fullName', name: 'Owners', type: 'string' }],
    },
    {
      name: 'deals',
      human_readable_name: 'deals',
      rel: [
        {
          res: 'pipelines',
          name: 'deal_pipeline',
        },
        {
          res: 'stages',
          name: 'stage',
        },
      ],
      countable: true,
      details: {
        name: 'Created deals overview',
        category: 'Deal numbers',
        default_columns: [
          {
            primary_resource: 'deals',
            key: 'properties.dealname.value',
          },
          {
            primary_resource: 'deals',
            key: 'properties.amount_in_home_currency.value',
          },
          {
            primary_resource: 'deals',
            key: 'stage',
          },
          {
            primary_resource: 'deals',
            key: 'owner',
          },
        ],
        supported_visualisations: ['table'],
        rules: {
          time_fields: [null],
          optimisation_order: [
            { primary_resource: 'deals', key: 'properties.createdate.value' },
          ],
        },
      },
      fields: [
        {
          custom: 'created_deals',
          name: 'Created deals',
          category: 'Deal numbers',
          base: { resource: 'deals' },
          rules: {
            time_fields: [
              { primary_resource: 'deals', key: 'properties.createdate.value' },
            ],
          },
        },
        {
          custom: 'closed_deals',
          name: 'Closed deals',
          category: 'Deal numbers',
          base: { resource: 'deals' },
          rules: {
            time_fields: [
              { primary_resource: 'deals', key: 'properties.closedate.value' },
            ],
          },
        },
        {
          custom: 'all_deals',
          name: 'All deals',
          category: 'Deal numbers',
          base: { resource: 'deals' },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'stage',
          name: 'Stage',
          category: 'Deal properties',
          type: 'string',
          resource: 'stages',
          foreign_key: 'properties.dealstage.value',
          target_key: 'label',
          query_template_for_values:
            '{"select":[{"path":"stageId","resource":"stages"},{"path":"label","resource":"stages"},{"path":"pipeline.label","resource":"stages"}]}',
          formatValue: (value) => {
            return `${value.label} (${value['pipeline.label']})`;
          },
        },
        {
          key: 'properties.dealname.value',
          name: 'Deal name',
          category: 'Deal properties',
          type: 'string',
          resource: 'deal_properties',
          exclude_from_filters: true,
          exclude_from_groupby: true,
        },
        {
          key: 'deal_pipeline',
          name: 'Pipeline',
          type: 'string',
          category: 'Deal properties',
          resource: 'pipelines',
          foreign_key: 'properties.pipeline.value',
          is_vip: true,
          target_key: 'label',
        },
        {
          key: 'stage_closed_won',
          name: 'Won',
          type: 'boolean',
          category: 'Stage properties',
          resource: 'stages',
          foreign_key: 'stage.closedWon',
          target_key: 'closedWon',
          exclude_from_filters: true,
          exclude_from_groupby: true,
        },
        {
          key: 'owner',
          name: 'Owner',
          type: 'string',
          category: 'Deal properties',
          resource: 'owners',
          foreign_key: 'properties.hubspot_owner_id.value',
          target_key: 'fullName',
        },
        {
          key: 'properties.amount_in_home_currency.value',
          name: 'Deal value in company currency',
          type: 'currency',
        },
        {
          custom: 'value_started_deals',
          name: 'Value of created deals',
          category: 'Deals value',
          base: {
            resource: 'deals',
            path: 'properties.amount_in_home_currency.value',
          },
          rules: {
            time_fields: [
              { primary_resource: 'deals', key: 'properties.createdate.value' },
            ],
          },
        },
        {
          custom: 'value_closed_deals',
          name: 'Value of closed deals',
          category: 'Deals value',
          base: {
            resource: 'deals',
            path: 'properties.amount_in_home_currency.value',
          },
          rules: {
            time_fields: [
              { primary_resource: 'deals', key: 'properties.closedate.value' },
            ],
          },
        },
        {
          custom: 'value_closed_won_deals',
          name: 'Value of closed won deals',
          category: 'Deals value',
          base: {
            resource: 'deals',
            path: 'properties.amount_in_home_currency.value',
          },
          rules: {
            filters: [
              {
                operands: [true],
                operator: '=',
                primary_resource: 'stages',
                key: 'closedWon',
              },
            ],
            time_fields: [
              { primary_resource: 'deals', key: 'properties.closedate.value' },
            ],
          },
        },
        {
          custom: 'value_all_deals',
          name: 'Value of all deals',
          category: 'Deals value',
          base: {
            resource: 'deals',
            path: 'properties.amount_in_home_currency.value',
          },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
      ],
      custom_fields_queries: [
        {
          query: {
            select: [
              {
                path: 'label',
                resource: 'deal_properties',
              },
              {
                path: 'name',
                resource: 'deal_properties',
              },
              {
                path: 'type',
                resource: 'deal_properties',
              },
            ],
            filter: [
              {
                resource: 'deal_properties',
                path: 'showInQueryEditor',
                operator: '=',
                operands: [true],
              },
            ],
          },
          field_templates: [
            {
              key: 'properties.<%= name %>.value',
              name: '<%= label %>',
              type: '<%= type %>',
              category: 'Deal properties',
              exclude_from_metrics: true,
              query_template_for_values: DEAL_PROPERTY_VALUES_QUERY_TEMPLATE,
              formatKey: (value) => value.value,
              formatValue: (value) => value.label,
            },
          ],
        },
      ],
    },
    {
      name: 'contacts',
      human_readable_name: 'contacts',
      rel: [
        {
          res: 'contact_lifecyclestages',
          name: 'lifecycle_stage',
        },
      ],
      countable: true,
      fields: [
        {
          key: 'lifecycle_stage',
          name: 'Lifecycle stage',
          category: 'Contact properties',
          type: 'string',
          resource: 'contact_lifecyclestages',
          foreign_key: 'properties.lifecyclestage.value',
          target_key: 'label',
        },
        {
          key: 'leads_metric',
          custom: 'leads_metric',
          name: 'Leads',
          base: { resource: 'contacts' },
          category: 'Contacts in lifecycle stage',
          rules: {
            filters: [
              {
                operands: ['lead'],
                operator: 'in',
                key: 'lifecycle_stage',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          key: 'marketing_qualified_leads',
          custom: 'marketing_qualified_leads',
          name: 'Marketing qualified leads',
          base: { resource: 'contacts' },
          category: 'Contacts in lifecycle stage',
          rules: {
            filters: [
              {
                operands: ['marketingqualifiedlead'],
                operator: 'in',
                key: 'lifecycle_stage',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          key: 'sales_qualified_leads',
          custom: 'sales_qualified_leads',
          name: 'Sales qualified leads ',
          base: { resource: 'contacts' },
          category: 'Contacts in lifecycle stage',
          rules: {
            filters: [
              {
                operands: ['salesqualifiedlead'],
                operator: 'in',
                key: 'lifecycle_stage',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          key: 'subscribers',
          custom: 'subscribers',
          name: 'Subscribers',
          base: { resource: 'contacts' },
          category: 'Contacts in lifecycle stage',
          rules: {
            filters: [
              {
                operands: ['subscriber'],
                operator: 'in',
                key: 'lifecycle_stage',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          key: 'evangelists',
          custom: 'evangelists',
          name: 'Evangelists',
          base: { resource: 'contacts' },
          category: 'Contacts in lifecycle stage',
          rules: {
            filters: [
              {
                operands: ['evangelist'],
                operator: 'in',
                key: 'lifecycle_stage',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          key: 'opportunities',
          custom: 'opportunities',
          name: 'Opportunities',
          base: { resource: 'contacts' },
          category: 'Contacts in lifecycle stage',
          rules: {
            filters: [
              {
                operands: ['opportunity'],
                operator: 'in',
                key: 'lifecycle_stage',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          key: 'customers',
          custom: 'customers',
          name: 'Customers',
          base: { resource: 'contacts' },
          category: 'Contacts in lifecycle stage',
          rules: {
            filters: [
              {
                operands: ['customer'],
                operator: 'in',
                key: 'lifecycle_stage',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          key: 'lead_status',
          name: 'Lead status',
          type: 'string',
          category: 'Contact properties',
          resource: 'contacts',
          hidden: true,
          foreign_key: 'properties.hs_lead_status.value',
        },
        {
          custom: 'new_leads',
          name: 'New leads',
          base: { resource: 'contacts' },
          category: 'Leads',
          rules: {
            filters: [
              {
                operands: ['NEW'],
                operator: 'in',
                key: 'lead_status',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          custom: 'open_leads',
          name: 'Open leads',
          base: { resource: 'contacts' },
          category: 'Leads',
          rules: {
            filters: [
              {
                operands: ['OPEN'],
                operator: 'in',
                key: 'lead_status',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          custom: 'in_progress_leads',
          name: 'In progress leads',
          base: { resource: 'contacts' },
          category: 'Leads',
          rules: {
            filters: [
              {
                operands: ['IN_PROGRESS'],
                operator: 'in',
                key: 'lead_status',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          custom: 'connected_leads',
          name: 'Connected leads',
          base: { resource: 'contacts' },
          category: 'Leads',
          rules: {
            filters: [
              {
                operands: ['CONNECTED'],
                operator: 'in',
                key: 'lead_status',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          custom: 'open_deals_leads',
          name: 'Open deal leads',
          base: { resource: 'contacts' },
          category: 'Leads',
          rules: {
            filters: [
              {
                operands: ['OPEN_DEAL'],
                operator: 'in',
                key: 'lead_status',
                primary_resource: 'contacts',
              },
            ],
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          custom: 'created_contacts',
          name: 'Created contacts',
          category: 'Contacts',
          base: { resource: 'contacts' },
          rules: {
            time_fields: [{ primary_resource: 'contacts', key: 'addedAt' }],
          },
        },
        {
          custom: 'unassigned_contacts',
          name: 'Unassigned contacts',
          category: 'Contacts',
          base: { resource: 'contacts' },
          rules: {
            time_fields: [null],
            filters: [
              {
                primary_resource: 'contacts',
                key: 'owner',
                operator: 'is_null',
              },
            ],
          },
        },
        {
          custom: 'assigned_contacts',
          name: 'Assigned contacts',
          category: 'Contacts',
          base: { resource: 'contacts' },
          rules: {
            time_fields: [null],
            filters: [
              {
                primary_resource: 'contacts',
                key: 'owner',
                operator: 'is_not_null',
              },
            ],
          },
        },
        {
          custom: 'active_contacts',
          name: 'Active contacts',
          category: 'Contacts',
          base: { resource: 'contacts' },
          rules: {
            time_fields: [null],
            filters: [
              {
                primary_resource: 'contacts',
                key: 'status',
                operator: 'in',
                operands: ['active'],
              },
            ],
          },
        },
        {
          custom: 'all_contacts',
          name: 'All contacts',
          category: 'Contacts',
          base: { resource: 'contacts' },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'status',
          name: 'Status',
          type: 'string',
          category: 'Contact properties',
          resource: 'owners',
          hidden: true,
          foreign_key: 'properties.hs_content_membership_status.value',
        },
        {
          key: 'addedAt',
          name: 'Create Date',
          type: 'datetime',
          category: 'Contact properties',
        },
        {
          key: 'owner',
          name: 'Owner',
          type: 'string',
          category: 'Contact properties',
          resource: 'owners',
          foreign_key: 'properties.hubspot_owner_id.value',
          target_key: 'fullName',
          supports_null_values: true,
        },
      ],
      custom_fields_queries: [
        {
          query: {
            select: [
              {
                path: 'label',
                resource: 'contact_properties',
              },
              {
                path: 'name',
                resource: 'contact_properties',
              },
              {
                path: 'type',
                resource: 'contact_properties',
              },
            ],
            filter: [
              {
                resource: 'contact_properties',
                path: 'showInQueryEditor',
                operator: '=',
                operands: [true],
              },
            ],
          },
          field_templates: [
            {
              key: 'properties.<%= name %>.value',
              name: '<%= label %>',
              type: '<%= type %>',
              category: 'Contact properties',
              exclude_from_metrics: true,
              query_template_for_values: CONTACT_PROPERTY_VALUES_QUERY_TEMPLATE,
              formatKey: (value) => value.value,
              formatValue: (value) => value.label,
            },
          ],
        },
      ],
    },
    {
      name: 'companies',
      human_readable_name: 'companies',
      rel: [
        {
          res: 'company_lifecyclestages',
          name: 'lifecyclestage',
        },
      ],
      countable: true,
      details: {
        name: 'Created companies list',
        category: 'Companies',
        default_columns: [
          {
            primary_resource: 'companies',
            key: 'properties.name.value',
          },
        ],
        aggregated: true,
        supported_visualisations: ['feed'],
        rules: {
          time_fields: [null],
          optimisation_order: [
            {
              primary_resource: 'companies',
              key: 'properties.createdate.value',
            },
          ],
        },
      },
      fields: [
        {
          key: 'lifecyclestage',
          name: 'Lifecycle Stage',
          category: 'Company properties',
          type: 'string',
          resource: 'company_lifecyclestages',
          foreign_key: 'properties.lifecyclestage.value',
          target_key: 'label',
        },
        {
          custom: 'created_companies',
          name: 'Created companies',
          category: 'Companies',
          base: { resource: 'companies' },
          rules: {
            time_fields: [
              {
                primary_resource: 'companies',
                key: 'properties.createdate.value',
              },
            ],
          },
        },
        {
          custom: 'closed_companies',
          name: 'Closed companies',
          category: 'Companies',
          base: { resource: 'companies' },
          rules: {
            time_fields: [
              {
                primary_resource: 'companies',
                key: 'properties.closedate.value',
              },
            ],
          },
        },
        {
          custom: 'all_companies',
          name: 'All companies',
          category: 'Companies',
          base: { resource: 'companies' },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'owner',
          name: 'Owner',
          type: 'string',
          category: 'Company properties',
          resource: 'owners',
          foreign_key: 'properties.hubspot_owner_id.value',
          target_key: 'fullName',
        },
        {
          key: 'properties.name.value',
          name: 'Company name',
          type: 'string',
          category: 'Company properties',
          resource: 'company_properties',
          exclude_from_filters: true,
          exclude_from_groupby: true,
        },
      ],
      custom_fields_queries: [
        {
          query: {
            select: [
              {
                path: 'label',
                resource: 'company_properties',
              },
              {
                path: 'name',
                resource: 'company_properties',
              },
              {
                path: 'type',
                resource: 'company_properties',
              },
            ],
            filter: [
              {
                resource: 'company_properties',
                path: 'showInQueryEditor',
                operator: '=',
                operands: [true],
              },
              {
                resource: 'company_properties',
                path: 'name',
                operator: '!=',
                operands: ['lifecyclestage'],
              },
            ],
          },
          field_templates: [
            {
              key: 'properties.<%= name %>.value',
              name: '<%= label %>',
              type: '<%= type %>',
              category: 'Company properties',
              exclude_from_metrics: true,
              query_template_for_values: COMPANY_PROPERTY_VALUES_QUERY_TEMPLATE,
              formatKey: (value) => value.value,
              formatValue: (value) => value.label,
            },
          ],
        },
      ],
    },
    {
      name: 'calls',
      human_readable_name: 'calls',
      countable: true,
      fields: [
        {
          custom: 'created_calls',
          name: 'Created calls',
          category: 'Calls',
          base: { resource: 'calls' },
          rules: {
            time_fields: [{ primary_resource: 'calls', key: 'timestamp' }],
          },
        },
        {
          custom: 'all_calls',
          name: 'All calls',
          category: 'Calls',
          base: { resource: 'calls' },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'timestamp',
          name: 'Date',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'meetings',
      human_readable_name: 'meetings',
      countable: true,
      fields: [
        {
          custom: 'created_meetings',
          name: 'Created meetings',
          category: 'Meetings',
          base: { resource: 'meetings' },
          rules: {
            time_fields: [{ primary_resource: 'meetings', key: 'timestamp' }],
          },
        },
        {
          custom: 'all_meetings',
          name: 'All meetings',
          category: 'Meetings',
          base: { resource: 'meetings' },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          key: 'timestamp',
          name: 'Date',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'stages',
      human_readable_name: 'stages',
      primary_key: 'stageId',
      fields: [
        {
          key: 'label',
          name: 'Label',
          type: 'string',
          hidden: true,
        },
        {
          key: 'closedWon',
          name: 'Won',
          category: 'Deal properties',
          type: 'boolean',
        },
      ],
    },
    {
      name: 'company_lifecyclestages',
      human_readable_name: 'Lifecycle Stage',
      primary_key: 'value',
      fields: [{ key: 'label', name: 'Label', type: 'string', hidden: true }],
      private: true,
    },
    {
      name: 'contact_lifecyclestages',
      human_readable_name: 'Lifecycle Stage',
      primary_key: 'value',
      fields: [{ key: 'label', name: 'Label', type: 'string', hidden: true }],
      private: true,
    },
    {
      name: 'pipelines',
      human_readable_name: 'pipelines',
      primary_key: 'pipelineId',
      fields: [
        {
          key: 'label',
          name: 'Label',
          type: 'string',
          hidden: true,
        },
      ],
    },
    {
      name: 'account',
      private: true,
      fields: [],
    },
    {
      name: 'contact_properties',
      private: true,
      fields: [],
    },
    {
      name: 'contact_property_values',
      private: true,
      fields: [],
    },
    {
      name: 'deal_properties',
      private: true,
      fields: [],
    },
    {
      name: 'deal_property_values',
      private: true,
      fields: [],
    },
    {
      name: 'company_properties',
      private: true,
      fields: [],
    },

    {
      name: 'company_property_values',
      private: true,
      fields: [],
    },
  ],
  initialQueries: {
    number: {
      query: {
        select: [
          {
            resource: 'deals',
            aggregate: 'count',
            custom: 'all_deals',
          },
        ],
      },
    },
  },
  options: [
    {
      keys: ['currency'],
      query: {
        select: [
          {
            resource: 'account',
            path: 'currency',
          },
          {
            resource: 'account',
            path: 'timeZone',
          },
        ],
      },
    },
  ],
};
