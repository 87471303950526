import Serializer from './default';

class LeaderboardSerializer extends Serializer {
  serialize(state) {
    const { values, labels, hasHeader, reverseSort, title, numberFormat } =
      state;

    return {
      ...super.serialize(state),
      config: {
        template: {
          type: 'leaderboard',
          version: 1,
        },
        title,
        values,
        labels,
        hasHeader,
        reverseSort,
        numberFormat,
      },
    };
  }

  deserialize(json) {
    const defaults = super.deserialize(json);

    const { title, values, labels, hasHeader, reverseSort, numberFormat } =
      json.config;

    return {
      type: 'leaderboard',
      ...defaults,
      title,
      values,
      labels,
      hasHeader,
      reverseSort,
      numberFormat,
    };
  }
}

export default LeaderboardSerializer;
