import color from 'color';
import { isEmpty, pickBy } from 'lodash';

import themes from '../../../themes';

const { dashiesDark } = themes;

const getDerivedColors = (textColor) => {
  const baseColor = color(textColor);

  if (!textColor) return {};

  return {
    secondaryTextColor: baseColor.alpha(0.8).string(),
    chartAxisColor: baseColor.alpha(0.2).string(),
    neutralColor: baseColor.alpha(0.15).string(),
    opaqueTextColor: baseColor.alpha(1).string(),
  };
};

const getCustomColorsVariables = (customColors) => {
  const { secondaryTextColor, chartAxisColor, neutralColor, opaqueTextColor } =
    getDerivedColors(customColors.textColor);

  const colorVariables = {
    '--dashboard-background-color': customColors.dashboardBackgroundColor,
    '--container-background-color': customColors.containerBackgroundColor,
    '--title-color': customColors.textColor,
    '--text-color': customColors.textColor,
    '--secondary-text-color': secondaryTextColor,
    '--neutral-color': neutralColor,
    '--positive-color': customColors.positiveColor,
    '--negative-color': customColors.negativeColor,
    '--caution-color': customColors.cautionColor,
    '--primary-visualisation-color': customColors.mainVisualizationColor,
    '--chart-axis-color': chartAxisColor,
    '--chart-threshold-color': neutralColor,
    '--map-background-color': customColors.textColor,
    '--gauge-needle-color': opaqueTextColor,
    '--series2-color': customColors.series2Color,
    '--series3-color': customColors.series3Color,
    '--series4-color': customColors.series4Color,
    '--series5-color': customColors.series5Color,
    '--series6-color': customColors.series6Color,
    '--series7-color': customColors.series7Color,
    '--series8-color': customColors.series8Color,
    '--series9-color': customColors.series9Color,
  };

  // Remove CSS variable if the value is empty. That way
  // it will use the color from the dark theme instead.
  const cleanedColorVariables = pickBy(colorVariables, (c) => !isEmpty(c));

  return {
    ...dashiesDark,
    ...cleanedColorVariables,
  };
};

export default getCustomColorsVariables;
