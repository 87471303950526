import { chain, flatten, get, includes, some, uniq, values } from 'lodash';

import * as bootstrapActions from '../../actions/universal-bootstrap-actions';
import * as queryActions from '../../actions/universal-query-actions';
import { getFieldsByType, getMetricFields, Node } from '../../graph';
import {
  DETAILS_TYPES,
  FILTER_FIELD_TYPES,
  GROUP_BY_CATEGORY_TYPES,
  GROUP_BY_TIME_TYPES,
  groupMetrics,
  ORDER_BY_TYPES,
  TIMESPAN_TYPES,
} from '../../ui-field-options';

const METRIC_TYPES = [
  'count',
  'int',
  'duration',
  'percent',
  'currency',
  'float',
  'details',
];

export const initialState = {};

// Group by category and sort alphabetically
const groupFieldsByCategory = (fields) => {
  // Custom categories from metadata are ordered first because
  // when looking for their index CATEGORIES_ORDER it returns -1
  const CATEGORIES_ORDER = [undefined, Node.DATETIME, Node.CUSTOM_FIELD];
  return values(
    chain(fields)
      .sortBy(['name'])
      .sortBy(({ category }) => CATEGORIES_ORDER.indexOf(category))
      .groupBy('category')
      .value(),
  );
};

const canNodeGroupBy = (predicate) => (node) =>
  predicate(node) && !node.exclude_from_groupby;

const getMetricOptions = (graph, metricsOrder) => {
  const metrics = getMetricFields(graph, METRIC_TYPES);
  return groupMetrics(metrics, metricsOrder);
};

const getGroupByOptions = (
  mainGraph,
  entryResources,
  vizType,
  predicate = () => true,
) => {
  let fields;
  switch (vizType) {
    case 'line':
      fields = getFieldsByType(
        mainGraph,
        GROUP_BY_TIME_TYPES,
        entryResources,
        vizType,
        canNodeGroupBy(predicate),
      );
      break;
    case 'leaderboard':
    case 'table':
      fields = getFieldsByType(
        mainGraph,
        GROUP_BY_CATEGORY_TYPES,
        entryResources,
        vizType,
        canNodeGroupBy(predicate),
      );
      break;
    case 'bar':
    case 'column':
      fields = getFieldsByType(
        mainGraph,
        [...GROUP_BY_CATEGORY_TYPES, ...GROUP_BY_TIME_TYPES],
        entryResources,
        vizType,
        canNodeGroupBy(predicate),
      );
      break;
    default:
      return undefined;
  }

  return groupFieldsByCategory(fields);
};

const getSplitByOptions = (
  mainGraph,
  entryResources,
  vizType,
  predicate = () => true,
) => {
  switch (vizType) {
    case 'line':
    case 'column':
      return groupFieldsByCategory(
        getFieldsByType(
          mainGraph,
          GROUP_BY_CATEGORY_TYPES,
          entryResources,
          vizType,
          canNodeGroupBy(predicate),
        ),
      );
    default:
      return undefined;
  }
};

const getFilterOptions = (
  mainGraph,
  entryResources,
  vizType,
  predicate = () => true,
) => {
  // TODO: We need to check here if it's a prerequisite, rather than an SA filter

  return groupFieldsByCategory(
    getFieldsByType(
      mainGraph,
      FILTER_FIELD_TYPES,
      entryResources,
      vizType,
      (node) =>
        !node.exclude_from_filters &&
        !node.is_global &&
        !node.is_vip &&
        predicate(node),
    ),
  );
};

const getDetailsOptions = (mainGraph, entryResources, vizType) => {
  switch (vizType) {
    case 'table':
      return groupFieldsByCategory(
        getFieldsByType(
          mainGraph,
          DETAILS_TYPES,
          entryResources,
          vizType,
          (n) => !n.exclude_from_details,
        ),
      );
    default:
      return undefined;
  }
};

const getOrderByOptions = (mainGraph, entryResources, vizType) => {
  switch (vizType) {
    case 'table':
      return groupFieldsByCategory(
        getFieldsByType(
          mainGraph,
          ORDER_BY_TYPES,
          entryResources,
          vizType,
          (n) => !n.exclude_from_details,
        ),
      );
    default:
      return undefined;
  }
};

const uiOptionsReducer = (
  state = initialState,
  action,
  mainGraph,
  entryResources,
  vizType,
) => {
  const { type, payload } = action;

  switch (type) {
    case bootstrapActions.setUiOptions.type: {
      const metricCategories = action.payload;
      const metrics = getMetricOptions(mainGraph, metricCategories);

      return {
        ...state,
        metrics,
      };
    }
    case queryActions.setVisualisationType.type: {
      const filterAssociatedFields = get(
        action,
        'payload.metric.filter_associated_fields',
      );

      // Only groupBy and splitBy options can change when switching visualisation
      const groupBy = getGroupByOptions(
        mainGraph,
        entryResources,
        vizType,
        filterAssociatedFields,
      );
      const splitBy = getSplitByOptions(
        mainGraph,
        entryResources,
        vizType,
        filterAssociatedFields,
      );
      const filters = getFilterOptions(
        mainGraph,
        entryResources,
        vizType,
        filterAssociatedFields,
      );

      return {
        ...state,
        groupBy,
        splitBy,
        filters,
      };
    }
    case bootstrapActions.setPrerequisiteOptions.type:
      return {
        ...state,
        prerequisites: payload,
      };
    case bootstrapActions.setInitialQueries.type:
    case queryActions.setMetricField.type: {
      let groupBy, detailsColumns, orderBy;

      const {
        payload: {
          time_fields: metricTimeFields,
          type: metricType,
          filter_associated_fields: filterAssociatedFields,
        } = {},
      } = action;

      const timespans =
        metricTimeFields ||
        getFieldsByType(mainGraph, TIMESPAN_TYPES, entryResources, vizType);
      const splitBy = getSplitByOptions(
        mainGraph,
        entryResources,
        vizType,
        filterAssociatedFields,
      );

      if (metricType === 'details') {
        groupBy = [];
        detailsColumns = getDetailsOptions(mainGraph, entryResources, vizType);
        orderBy = getOrderByOptions(mainGraph, entryResources, vizType);
      } else {
        groupBy = getGroupByOptions(
          mainGraph,
          entryResources,
          vizType,
          filterAssociatedFields,
        );
        detailsColumns = [];
      }
      const filters = getFilterOptions(
        mainGraph,
        entryResources,
        vizType,
        filterAssociatedFields,
      );

      return {
        ...state,
        timespans: [timespans],
        groupBy,
        splitBy,
        filters,
        detailsColumns,
        orderBy,
      };
    }
    default:
      return state;
  }
};

export const getAvailableMetrics = (metrics, currentMetric) => {
  if (
    !currentMetric ||
    includes(flatten(metrics.map((g) => g.options)), currentMetric)
  ) {
    return metrics;
  }

  // Add current metric in it's own group if it doesn't exist (this can happen when we
  // add a rule to remove a metric and you edit a widget with the deprecated metric)
  return [...metrics, { options: [currentMetric] }];
};

export const getAvailableFilters = (
  groupedFilters,
  uiQueryFilters = [],
  currentFilter,
) => {
  const exclusionGroups = chain(uiQueryFilters)
    .flatten()
    .filter(
      (f) =>
        f.node && f.node !== currentFilter && f.node.filter_exclusion_groups,
    )
    .reduce((res, f) => [...res, ...f.node.filter_exclusion_groups], [])
    .value();

  const currentFilters = uiQueryFilters.map((filter) => filter.node);

  // Remove already used filter nodes but keep current one when editing
  return chain(groupedFilters)
    .map((filters) => {
      return filters.filter(
        (f) =>
          (currentFilter && currentFilter === f) ||
          !(
            currentFilters.includes(f) ||
            (f.filter_exclusion_groups
              ? some(exclusionGroups, (e) =>
                  f.filter_exclusion_groups.includes(e),
                )
              : false)
          ),
      );
    })
    .filter((filters) => !!filters.length)
    .value();
};

export const appendSelectedNodes = (uiOptions, uiQuery) => {
  if (!uiQuery.timespans || !uiQuery.timespans[0]) {
    return uiOptions;
  }

  // Timespan only has one group, so just amend timespans[0]
  return {
    ...uiOptions,
    timespans: [uniq([...uiOptions.timespans[0], uiQuery.timespans[0].node])],
  };
};

export default uiOptionsReducer;
