import * as billingStatusActions from '../actions/billing-status-actions';

const initialState = {
  loading: true,
  checkout: {},
};

const billingStatusReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case billingStatusActions.setBillingStatus.type:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case billingStatusActions.setCheckoutError.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const checkout = state.checkout;
      return {
        ...state,
        checkout: { ...checkout, error: payload },
      };
    default:
      return state;
  }
};

export default billingStatusReducer;
