import { makeSelections } from '../../../spreadsheets-config/helpers/selection-helpers';
import type { ColumnRow, Range } from '../../../spreadsheets-config/types';

export const toNumberOrCell = (
  value: undefined | string,
): number | undefined | [ColumnRow, ColumnRow] => {
  if (typeof value !== 'string') {
    return undefined;
  }

  // Single cell reference, eg. A1, ZZ324
  if (/[a-z]+\d+/i.test(value)) {
    const selection: Range[] = makeSelections(value);
    if (selection.length > 0) {
      return [selection[0].start, selection[0].end];
    }
  }

  const parsed = parseFloat(value);
  if (!isNaN(parsed)) {
    return parsed;
  }

  return undefined;
};
