import { get } from 'lodash';

import { usesOAuth } from '../../../lib/service';
import * as dataSourceConnectorActions from '../../actions/data-source-connector-actions';

const USERNAME_FIELD = {
  name: 'user',
  label: 'Username',
  type: 'input',
  help: 'Username for HTTP basic authentication',
};
const PASSWORD_FIELD = {
  name: 'password',
  label: 'Password',
  type: 'password',
  help: 'Password for HTTP basic authentication',
};

const initialState = {
  fields: null,
};

function determineConnectionFields(service) {
  const { auth_type: authType, options } = service;
  const fields = get(options, 'connectScreen.fields', []);

  if ('basic' === authType && !fields.length) {
    return [USERNAME_FIELD, PASSWORD_FIELD];
  }

  return fields;
}

const defaultDataSourceConnectorReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case dataSourceConnectorActions.fetchServiceSuccessful.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { service } = payload;
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const fields = determineConnectionFields(service);

      return {
        ...state,
        service,
        fields,
        usesOAuth: usesOAuth(service),
      };
    case dataSourceConnectorActions.fetchServiceFailed.type:
    case dataSourceConnectorActions.createConnectionFailed.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { payload: errorMessage } = action;

      return {
        ...state,
        errorMessage,
      };
    case dataSourceConnectorActions.createConnectionSuccessful.type:
      return {
        ...state,
        errorMessage: undefined,
      };
    default:
      return state;
  }
};

export default defaultDataSourceConnectorReducer;
