export const ONE_SECOND = 1;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const THREE_MINUTES = 3 * ONE_MINUTE;
export const FIVE_MINUTES = 5 * ONE_MINUTE;
export const TEN_MINUTES = 10 * ONE_MINUTE;
export const FIFTEEN_MINUTES = 15 * ONE_MINUTE;
export const THIRTY_MINUTES = 30 * ONE_MINUTE;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const THREE_HOURS = 3 * ONE_HOUR;
export const SIX_HOURS = 6 * ONE_HOUR;
export const TWELVE_HOURS = 12 * ONE_HOUR;
export const TWENTY_FOUR_HOURS = 24 * ONE_HOUR;

/*
 * Calculation of numRequests:
 * We assume that there are 4400 requests when polling every minute.
 *
 * 3m: 4400/3 = 1500 requests/minute
 * 1hr: 3600/60 = 100 requests/minute
 *
 * The requests per minute are also rounded up
 * to the nearest hundredth
 */
const REFRESH_INTERVALS = [
  { label: 'minute', value: ONE_MINUTE, numRequests: 4400 },
  { label: '3 minutes', value: THREE_MINUTES, numRequests: 1500 },
  { label: '5 minutes', value: FIVE_MINUTES, numRequests: 900 },
  { label: '10 minutes', value: TEN_MINUTES, numRequests: 500 },
  { label: '15 minutes', value: FIFTEEN_MINUTES, numRequests: 350 },
  { label: '30 minutes', value: THIRTY_MINUTES, numRequests: 200 },
  { label: 'hour', value: ONE_HOUR, numRequests: 100 },
  { label: '3 hours', value: THREE_HOURS, numRequests: 30 },
  { label: '6 hours', value: SIX_HOURS, numRequests: 15 },
  { label: '12 hours', value: TWELVE_HOURS, numRequests: 10 },
  { label: '24 hours', value: TWENTY_FOUR_HOURS, numRequests: 5 },
];

export default REFRESH_INTERVALS;
