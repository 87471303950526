import { connect } from 'react-redux';

import { init } from '../actions/tv-page-actions';

import TvPagePinComponent from './tv-page-pin-component';

const TvPagePinConnector = connect((s) => s.tvPage, { onLoad: init })(
  TvPagePinComponent,
);

export default TvPagePinConnector;
