import { isValidSelection } from '../selection-helpers';

const getDefaultConfig = (selections = []) => {
  return {
    type: 'leaderboard',
    reverseSort: false,
    labels: selections[0],
    values: selections[1],
  };
};

const getSelectionsFromConfig = (config) => [config.labels, config.values];

const isValid = ({ labels }) => {
  return isValidSelection(labels);
};

const headerPaths = () => {
  return ['labels', 'values'];
};

const configMappingRules = {
  column: ['dataRange', 'numberFormat', 'title'],
  line: ['dataRange', 'numberFormat', 'title'],
  text: ['dataRange', 'title'],
  number: ['dataRange', 'numberFormat', 'title'],
  geckometer: ['dataRange', 'numberFormat', 'title'],
  bar: ['dataRange', 'numberFormat', 'title'],
  table: ['dataRange', 'title'],
};

export default {
  getDefaultConfig,
  getSelectionsFromConfig,
  isValid,
  headerPaths,
  configMappingRules,
};
