import { Component } from 'react';

class EmptyComponent extends Component {
  render() {
    return null;
  }
}

export default function componentPicker(
  components,
  name,
  DefaultComponent = EmptyComponent,
) {
  if (components[name]) {
    return components[name];
  }

  return DefaultComponent;
}

export { EmptyComponent };
