export type INSTRUMENT_MENU_VIEW_STATES = (typeof VIEWS)[keyof typeof VIEWS];

export const VIEWS = {
  MENU: 'MENU',
  CLOSED: 'CLOSED',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  COPY: 'COPY',
  STATUS_INDICATORS: 'STATUS_INDICATORS',
  NUMBER_FORMATTING: 'NUMBER_FORMATTING',
  VISUALIZATION_OPTIONS: 'VISUALIZATION_OPTIONS',
} as const;
