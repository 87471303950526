import { ColumnRow } from '../../../spreadsheets-config/types';
import {
  ManagementAPIStatusIndicatorConfig,
  StatusIndicatorFormData,
  ThresholdName,
} from '../status-indicator-types';

import { toNumberOrCell } from './toNumberOrCell';

/**
 * Convert the StatusIndicatorFormData shape to the one used by
 * Management API for widget config
 */
export function toManagementAPIStatusIndicatorFormat(
  statusIndicator: StatusIndicatorFormData | undefined,
): ManagementAPIStatusIndicatorConfig | undefined {
  if (!statusIndicator) {
    return undefined;
  }

  const [upperKey, lowerKey] = getUpperLowerKeys(statusIndicator);

  const thresholds = transformFormStateToThresholds({
    lowerKey,
    lowerValue: toNumberOrCell(statusIndicator[lowerKey]),
    upperKey,
    upperValue: toNumberOrCell(statusIndicator[upperKey]),
  });

  if (thresholds.length === 0) {
    return undefined;
  }

  return {
    thresholds,
    _currentUpperState: upperKey === 'success' ? 'positive' : 'warning',
  };
}

const getUpperLowerKeys = (
  statusIndicator?: StatusIndicatorFormData,
): [ThresholdName, ThresholdName] => {
  if (statusIndicator?.successDirection === 'above') {
    return ['success', 'warning'];
  }

  return ['warning', 'success'];
};

const transformFormStateToThresholds = ({
  lowerKey,
  lowerValue,
  upperKey,
  upperValue,
}: {
  lowerKey: 'success' | 'warning';
  upperKey: 'success' | 'warning';
  lowerValue: number | undefined | [ColumnRow, ColumnRow];
  upperValue: number | undefined | [ColumnRow, ColumnRow];
}): ManagementAPIStatusIndicatorConfig['thresholds'] => {
  const upperState = upperKey === 'success' ? 'positive' : 'warning';
  const lowerState = lowerKey === 'success' ? 'positive' : 'warning';

  let thresholds: ManagementAPIStatusIndicatorConfig['thresholds'] = [];

  if (lowerValue !== undefined) {
    thresholds = [lowerState, lowerValue];
  }

  thresholds.push('neutral');

  if (upperValue !== undefined) {
    thresholds.push(upperValue, upperState);
  }

  // We have no thresholds to set so empty the array
  if (thresholds.length === 1) {
    return [];
  }

  return thresholds;
};
