import { getWindow } from '@Lib/global';

import { CONTAINER_AREA_KEY } from '../dashboard/dashboard-constants';
import { graphql } from '../generated/gql';

export const createInstrumentMutation = graphql(`
  mutation InstrumentCreate(
    $dashboardId: ID!
    $dataSourceInput: DataSourceInput!
    $visualizationInput: VisualizationInput!
    $areaInput: AreaInput
  ) {
    instrumentCreate(
      dashboardId: $dashboardId
      dataSourceInput: $dataSourceInput
      visualizationInput: $visualizationInput
      areaInput: $areaInput
    ) {
      instrument {
        __typename
        id
      }
    }
  }
`);

export interface SpaceOnDashboard {
  rowStart: number;
  rowSpan: number;
  columnStart: number;
  columnSpan: number;
}

export const getInitialContainerArea = (): SpaceOnDashboard | undefined => {
  const sessionStorage = getWindow().sessionStorage;
  const storedArea =
    sessionStorage && sessionStorage.getItem(CONTAINER_AREA_KEY);

  if (!storedArea) {
    return undefined;
  }

  return JSON.parse(storedArea);
};
