import { isFinite, mapKeys, partial, snakeCase } from 'lodash';

import config from '../config';
import fetchJSON, { FetchError, pollJSON } from '../fetch';

const POLLING_OPTS = {
  defaultPollInterval: 10000,
  maxNumberOfPolls: 30,
};

class SpreadsheetError extends FetchError {}

function fetchList(serviceName, { serviceAccountId = '', query = '' } = {}) {
  const { SpreadsheetsBaseURL } = config.get();

  return fetchJSON(
    `${SpreadsheetsBaseURL}/services/${serviceName}/files?title=${query}&service_account_id=${serviceAccountId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    },
  );
}

function refreshSpreadsheet(fileId, maxAge = 3600, requestHeaders = {}) {
  const { SpreadsheetsBaseURL } = config.get();
  const headers = { ...requestHeaders, Accept: 'application/json' };

  return fetchJSON(
    `${SpreadsheetsBaseURL}/files/${fileId}/refresh?max_age=${maxAge}`,
    {
      method: 'POST',
      credentials: 'include',
      headers,
    },
  );
}

function getSpreadsheet(fileId, maxAge = Infinity) {
  const { SpreadsheetsBaseURL } = config.get();

  if (isFinite(maxAge)) {
    return refreshSpreadsheet(fileId, maxAge).then(
      partial(getSpreadsheet, fileId),
    );
  }

  return pollJSON(
    `${SpreadsheetsBaseURL}/files/${fileId}`,
    undefined,
    POLLING_OPTS,
  ).then((json) => json.data);
}

function getWorksheet(fileId, worksheetId, requestHeaders = {}) {
  const { SpreadsheetsBaseURL } = config.get();

  return pollJSON(
    `${SpreadsheetsBaseURL}/files/${fileId}/worksheets/${worksheetId}`,
    { headers: requestHeaders },
    POLLING_OPTS,
  ).then((json) => json.data);
}

function obtainUUID(serviceName, fileOptions) {
  const { SpreadsheetsBaseURL } = config.get();
  const { id } = fileOptions;
  const options = { ...fileOptions, spreadsheetId: id };
  const body = mapKeys(options, (_, key) => snakeCase(key));

  return fetchJSON(`${SpreadsheetsBaseURL}/services/${serviceName}/files`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function get(cfg, requestHeaders) {
  const {
    config: { worksheet_id: worksheetId, file_id: fileId },
  } = cfg;

  return getWorksheet(fileId, worksheetId, requestHeaders).catch(
    ({ message, type, status }) => {
      throw new SpreadsheetError(message, status, type);
    },
  );
}

function getConfig({ config: cfg }) {
  return Promise.resolve(cfg);
}

export {
  fetchList,
  get,
  getConfig,
  getSpreadsheet,
  getWorksheet,
  obtainUUID,
  POLLING_OPTS,
  refreshSpreadsheet,
  SpreadsheetError,
};
