export default {
  '--background-color': '#2C1F33',
  '--widget-background-color': '#3C2E47',
  '--widget-active-background-color': '#433450',
  '--selected-widget-header-color': '#614F6F',

  /* Number widget */
  '--number-threshold-text-color': '#3C2E47',

  /* Bar chart */
  /* this should be consolidated with the linechart one above */
  '--chart-threshold-color': '#493B54',

  /* Map widget */
  '--map-subunit-color': '#594D62',
  '--map-single-point-color': '#E5C653',
};
