import { get } from 'lodash';

import { transformers } from '../../../universal';
import * as widgetMenuActions from '../../../widget-menu/actions/widget-menu-actions';
import * as editWidgetActions from '../actions/edit-widget-actions';

const initialState = {
  widgetKey: undefined,
  serviceName: undefined,
  refresh_interval: undefined,
  config: undefined,
  loadingData: false,
};

const getTimeStamp = () => new Date().toISOString();
const defaultApplyConfig = (d) => d;
const getApplyConfig = (serviceName, vizType) =>
  transformers[serviceName][vizType].applyConfig || defaultApplyConfig;
const getTransformer = (serviceName, vizType) =>
  transformers[serviceName][vizType].transform;

const editWidgetReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case editWidgetActions.setLoading.type: {
      return {
        ...state,
        loadingData: payload,
      };
    }

    case widgetMenuActions.openEditWidget.type: {
      if (!payload) return state;

      const { widget, data, instrumentId } = payload;

      const {
        widgetKey,
        refresh_interval: refreshInterval,
        service_account_id: serviceAccountID,
        config,
        serviceName,
        widgetType,
      } = widget;

      return {
        widgetKey,
        refresh_interval: refreshInterval,
        service_account_id: serviceAccountID,
        serviceName,
        widgetType,
        config,
        transformedData: data,
        instrumentId,
      };
    }

    case editWidgetActions.setConfig.type: {
      const { config, serviceName, transformedData } = state;
      const newConfig = {
        ...config,
        ...payload,
      };
      const applyConfig = getApplyConfig(serviceName, newConfig.type);
      let data = get(transformedData, 'data', {});
      data = applyConfig(data, newConfig);

      return {
        ...state,
        loadingData: false,
        config: {
          ...config,
          ...payload,
        },
        transformedData: {
          data,
          updatedAt: getTimeStamp(),
        },
      };
    }

    case editWidgetActions.setConfigAndData.type: {
      const { config, data } = payload;
      const { serviceName } = state;

      const transform = getTransformer(serviceName, config.type);
      const { error = null } = data;

      return {
        ...state,
        loadingData: false,
        config,
        error,
        transformedData: {
          data: error ? {} : transform(data, config),
          updatedAt: getTimeStamp(),
        },
      };
    }

    case editWidgetActions.setServiceAccountId.type: {
      return {
        ...state,
        service_account_id: payload,
      };
    }

    case editWidgetActions.changeRefreshInterval.type: {
      if (!payload) return state;

      return {
        ...state,
        refresh_interval: payload,
      };
    }

    case widgetMenuActions.closeMenu.type: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export { editWidgetReducer as default, initialState };
