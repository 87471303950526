import { map } from 'lodash';

import { getSelectedCells } from '../../spreadsheet-helpers';
import { composeTransformers } from '../compose-transformers';
import { transform as textVisualisationTransform } from '../per-visualisation/text';

const textTransform = ({ cells }, { value }, _, initialContext = {}) => {
  const [selectedCells] = getSelectedCells(cells, [value]);
  const values = map(selectedCells, 'value');

  if (!values.length) {
    return initialContext;
  }

  const context = { value: values };

  return { ...initialContext, ...context };
};

const transform = composeTransformers(
  textTransform,
  textVisualisationTransform,
);

export { transform };
