import type { Field } from '../../types';

export function areSeriesKeysStillValid(
  selectedSeriesKeys: number[],
  fieldsForSeries: Field[],
): boolean {
  let hasValidKeys = true;
  const hasSelectedKeys = selectedSeriesKeys.length > 0;

  if (hasSelectedKeys) {
    hasValidKeys = selectedSeriesKeys.every((selectedSeriesKey) =>
      fieldsForSeries.some((seriesField) => {
        // Ensure that the current key is still one of those suitable
        return selectedSeriesKey === seriesField.key;
      }),
    );

    hasValidKeys;

    return hasValidKeys;
  }

  return hasSelectedKeys;
}
