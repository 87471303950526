import { filter } from 'lodash';

import * as dataSourceConnectorActions from '../../data-source-connector/actions/data-source-connector-actions';
import * as initDataSourceActions from '../../data-source-connector/actions/init-data-source-action';
import * as pickerActions from '../actions/picker-actions';

const initialState = {
  selected: null,
  files: [],
  errorMessage: null,
  folder: null,
  _cachedFiles: [],
};

const filterFiles = (state, attribute, query) => {
  let { _cachedFiles: files = [] } = state;
  const { folder } = state;

  if (folder) {
    files = filter(files, { folder });
  }

  return files.filter((file) => {
    const value = file[attribute] || '';

    return value.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });
};

const filterWithinFolder = (state, folder) => {
  const { files, _cachedFiles } = state;

  if (!folder) {
    return _cachedFiles;
  }

  return filter(files, { folder });
};

const filePickerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case pickerActions.fetchFilesStart.type:
      return {
        ...state,
        errorMessage: null,
        isFetching: true,
      };
    case pickerActions.fetchFilesSuccess.type:
    case initDataSourceActions.initDataSourceSuccessful.type:
    case dataSourceConnectorActions.accountAddSuccessful.type:
    case dataSourceConnectorActions.switchAccountSuccessful.type:
    case dataSourceConnectorActions.switchServiceSuccessful.type: {
      const { files } = payload;
      const newState = {
        ...state,
        errorMessage: null,
        importErrorMessage: null,
        importErrorType: null,
        selected: null,
        isFetching: false,
        folder: null,
        files: [],
        query: null,
        _cachesFiles: [],
      };

      if (files) {
        newState.files = files;
        newState._cachedFiles = files;
      }

      return newState;
    }
    case pickerActions.fetchFilesFailed.type:
      return {
        ...state,
        files: [],
        errorMessage: payload.message,
        isFetching: false,
      };
    case pickerActions.filter.type:
      // eslint-disable-next-line no-case-declarations -- Disabled by codemod when new recommended rulesets introduced
      const { attribute, query } = payload;

      return {
        ...state,
        files: filterFiles(state, attribute, query),
        query,
      };
    case pickerActions.folderSelected.type: {
      const { folder } = payload;
      return {
        ...state,
        folder,
        errorMessage: null,
        files: filterWithinFolder(state, folder),
      };
    }
    case pickerActions.obtainUUIDError.type:
      return {
        ...state,
        errorMessage: null,
        importErrorMessage: payload.message,
        importErrorType: payload.type,
      };
    case pickerActions.clearImportError.type:
      return {
        ...state,
        importErrorMessage: null,
        importErrorType: null,
      };
    default:
      return state;
  }
};

export default filePickerReducer;
