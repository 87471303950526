import * as tvPagActions from '../actions/tv-page-actions';

export const initialState = {
  error: undefined,
  device: {
    pusherChannel: undefined,
    token: undefined,
  },
  code: undefined,
};

const tvPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case tvPagActions.setCode.type:
      return {
        ...state,
        code: payload,
        error: undefined,
      };

    case tvPagActions.setDevice.type:
      return {
        ...state,
        device: payload,
      };

    case tvPagActions.setError.type:
      return {
        ...state,
        code: undefined,
        error: payload,
      };

    default:
      return state;
  }
};

export default tvPageReducer;
