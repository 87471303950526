import * as colors from '../../../src/ui/style/colors.js';

const neutralVisualisationColor = '#39393B';

export default {
  '--background-color': '#101214',
  '--title-color': 'rgba(255, 255, 255, 0.92)',
  '--text-color': 'rgba(255, 255, 255, 0.86)',
  '--secondary-text-color': 'rgba(255, 255, 255, 0.6)',
  '--widget-background-color': '#2a2a2a',
  '--widget-active-background-color': '#303030',
  '--chart-axis-color': 'rgba(255, 255, 255, 0.13)',
  '--chart-axis-color-strong': 'rgba(255, 255, 255, 0.2)',
  '--selected-widget-header-color': '#4D4F50',
  '--dragger-color':
    'rgba(255, 255, 255, 0.05)' /* This is the guide colour when dragging a widget */,
  '--widget-loader-color':
    'rgba(255, 255, 255, 0.05)' /* Color of the loading indicator in the visualisation */,
  '--widget-shimmer-color': 'rgba(255, 255, 255, 0.09)',
  '--widget-drag-blend-mode': 'screen',
  '--widget-drag-ghost-color': 'rgba(113, 119, 121, 0.5)',
  '--dashboard-text-color': 'rgba(255, 255, 255, 0.86)',
  '--goal-pill-background-color': 'rgba(255, 255, 255, 0.12)',
  '--positive-color': colors.Positive,
  '--negative-color': colors.NegativeOnDark,
  '--caution-color': colors.Warning,

  // visualisation colors
  '--primary-visualisation-color': '#3fb4f9',
  '--neutral-color': neutralVisualisationColor,

  /* Geckometer */
  '--gauge-needle-color': '#e1e1e1',

  '--chart-threshold-color': neutralVisualisationColor,
  '--number-threshold-text-color': '#2A2A2A',

  /* Map widget */
  '--map-background-color': '#ffffff',
  '--map-single-point-color': '#38cdff',

  /* Geckobox */
  '--geckobox-grid-color': '#FFFFFF',

  /* Mobile footer */
  '--mobile-footer-background-color': '#FFFFFF',
  '--mobile-footer-text-color': colors.Black100,
};
