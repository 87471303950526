import { defaultTo } from 'lodash';

import BarChartHelpers from './components/bar-chart-form/helpers';
import ColumnChartHelpers from './components/column-chart-form/helpers';
import GeckometerHelpers from './components/geckometer-form/helpers';
import LeaderboardHelpers from './components/leaderboard-form/helpers';
import LineChartHelpers from './components/line-chart-form/helpers';
import NumberHelpers from './components/number-form/helpers';
import TableHelpers from './components/table-form/helpers';

const DATA_METHODS = {
  line: LineChartHelpers,
  column: ColumnChartHelpers,
  bar: BarChartHelpers,
  number: NumberHelpers,
  leaderboard: LeaderboardHelpers,
  geckometer: GeckometerHelpers,
  table: TableHelpers,
};

const getVisualisationHelpers = (visualisation) =>
  defaultTo(DATA_METHODS[visualisation], LineChartHelpers);

export default getVisualisationHelpers;
