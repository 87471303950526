import { mergeQueryResults } from '../../universal/lib/queries-universal';
import { universal as universalTransformer } from '../../universal/transformers';

import { arrangeConfig } from './config-helpers';

export const transformData = (queries, queryResults, config, timezone) => {
  const { type: vizType } = config;

  return universalTransformer[vizType].transform(
    mergeQueryResults(queries, queryResults, vizType),
    { ...arrangeConfig(config), queries },
    timezone,
  );
};
