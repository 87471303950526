import { get, isArray, isFinite } from 'lodash';

import { EMPTY_SELECTION } from '../spreadsheets-config-constants';

const _sanitizeProgressGoal = (config) => {
  if (get(config, 'comparison.type') !== 'goal') {
    return config;
  }

  if (isFinite(config.goal) || isArray(config.goal)) {
    return config;
  }

  const sanitized = { ...config };
  delete sanitized.goal;
  sanitized.comparison = {};

  return sanitized;
};

const _sanitizeNumberComparison = (config) => {
  const type = get(config, 'comparison.type');

  if (!['number', 'percentage', 'sparkline'].includes(type)) {
    return config;
  }

  const value = get(config, 'comparison.value');

  if (value === EMPTY_SELECTION) {
    return {
      ...config,
      comparison: {},
    };
  }

  if (isFinite(value) || isArray(value)) {
    return config;
  }

  return {
    ...config,
    comparison: {},
  };
};

const sanitizers = [_sanitizeProgressGoal, _sanitizeNumberComparison];

/**
 * sanitizeConfig takes a config object and runs it
 * through a series of sanitizer functions. Each sanitizer
 * gets applied and the return value of the first sanitizer
 * gets passed to the next sanitizer and so on.
 */
const sanitizeConfig = (config) =>
  sanitizers.reduce((newConfig, sanitizer) => sanitizer(newConfig), config);

export default sanitizeConfig;
