import actionErrorHandler from '../../action-error-handler';

const handleError = () => (next) => (action) => {
  const { error } = action;

  if (error) {
    actionErrorHandler(__DEV__, error);
  }

  return next(action);
};

export default handleError;
