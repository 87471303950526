import Serializer from './default';

class GeckometerSerializer extends Serializer {
  serialize(state) {
    const defaults = super.serialize(state);

    const {
      value,
      title,
      goal,
      min,
      max,
      numberFormat,
      reverseGoalDirection,
      indicators,
    } = state;

    return {
      ...defaults,
      config: {
        template: {
          type: 'geckometer',
          version: 1,
        },
        title,
        value,
        goal,
        min,
        max,
        numberFormat,
        reverseGoalDirection,
        indicators,
      },
    };
  }

  deserialize(json) {
    const defaults = super.deserialize(json);

    const {
      title,
      value,
      goal,
      min,
      max,
      numberFormat,
      reverseGoalDirection,
      indicators,
      statusIndicator,
    } = json.config;

    return {
      ...defaults,
      type: 'geckometer',
      value,
      title,
      goal,
      min,
      max,
      numberFormat,
      reverseGoalDirection,
      indicators,
      statusIndicator,
    };
  }
}

export default GeckometerSerializer;
