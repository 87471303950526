import columnChartVisualisationHelpers from './column-chart-helpers';

const getDefaultConfig = (selections = []) => {
  const [xAxis, ...series] = selections;

  return {
    type: 'bar',
    goal: undefined,
    numberFormat: {},
    xAxis,
    series,
  };
};

const getSelectionsFromConfig = (config) => [config.xAxis, ...config.series];

const isValid = columnChartVisualisationHelpers.isValid;
const headerPaths = columnChartVisualisationHelpers.headerPaths;
const headerColours = columnChartVisualisationHelpers.headerColours;

const configMappingRules = {
  ...columnChartVisualisationHelpers.configMappingRules,
  column: [
    'dataRange',
    'goal',
    'reverseGoalDirection',
    'numberFormat',
    'title',
  ],
  line: ['dataRange', 'goal', 'reverseGoalDirection', 'numberFormat', 'title'],
};

export default {
  getDefaultConfig,
  getSelectionsFromConfig,
  isValid,
  headerPaths,
  headerColours,
  configMappingRules,
};
