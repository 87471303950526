export default {
  resources: [
    {
      name: 'deals',
      human_readable_name: 'deals',
      countable: true,
      primary_key: 'id',
      details: {
        name: 'New deal overview',
        category: 'Deals',
        default_columns: [
          {
            primary_resource: 'deals',
            key: 'title',
          },
          {
            primary_resource: 'deals',
            key: 'value',
          },
          {
            primary_resource: 'deals',
            key: 'stage',
          },
          {
            primary_resource: 'deals',
            key: 'user',
          },
        ],
        supported_visualisations: ['table'],
        rules: {
          time_fields: [null],
          optimisation_order: [
            {
              primary_resource: 'deals',
              key: 'add_time',
            },
          ],
        },
      },
      fields: [
        {
          custom: 'all_deals',
          name: 'All deals',
          base: { resource: 'deals' },
          category: 'Deals',
          additional_info: {
            content: 'All deals ever added',
          },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          custom: 'created_deals',
          name: 'Started deals',
          base: { resource: 'deals' },
          category: 'Deals',
          additional_info: {
            content: 'Deals added during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'deals', key: 'add_time' }],
          },
        },
        {
          custom: 'open_deals',
          name: 'Open deals',
          base: { resource: 'deals' },
          category: 'Deals',
          additional_info: {
            content:
              'Deals added to the "Open" stage during the selected time period',
          },
          rules: {
            filters: [
              {
                key: 'status',
                operator: 'in',
                operands: ['open'],
                primary_resource: 'deals',
              },
            ],
            time_fields: [{ primary_resource: 'deals', key: 'add_time' }],
          },
        },
        {
          custom: 'won_deals',
          name: 'Won deals',
          base: { resource: 'deals' },
          category: 'Deals',
          additional_info: {
            content: 'Deals with "Won time" during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'deals', key: 'won_time' }],
          },
        },
        {
          custom: 'lost_deals',
          name: 'Lost deals',
          base: { resource: 'deals' },
          category: 'Deals',
          additional_info: {
            content: 'Deals with "Lost time" during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'deals', key: 'lost_time' }],
          },
        },
        {
          custom: 'close_deals',
          name: 'Closed deals',
          base: { resource: 'deals' },
          category: 'Deals',
          additional_info: {
            content: 'Deals with a close date during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'deals', key: 'close_time' }],
          },
        },
        {
          key: 'title',
          name: 'Title',
          type: 'string',
          exclude_from_filters: true,
          exclude_from_groupby: true,
        },
        {
          key: 'value',
          name: 'Value',
          type: 'currency',
        },
        {
          custom: 'value_all_deals',
          name: 'Value of all deals',
          base: { resource: 'deals', path: 'value' },
          category: 'Value of deals',
          additional_info: {
            content: 'Value of all deals ever created',
          },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
        },
        {
          custom: 'value_started_deals',
          name: 'Value of started deals',
          base: { resource: 'deals', path: 'value' },
          category: 'Value of deals',
          additional_info: {
            content: 'Value of deals created during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'deals', key: 'add_time' }],
          },
        },
        {
          custom: 'value_open_deals',
          name: 'Value of open deals',
          base: { resource: 'deals', path: 'value' },
          category: 'Value of deals',
          additional_info: {
            content:
              'Value of deals added in the "Open" stage during the selected time period',
          },
          rules: {
            filters: [
              {
                key: 'status',
                operator: 'in',
                operands: ['open'],
                primary_resource: 'deals',
              },
            ],
            time_fields: [{ primary_resource: 'deals', key: 'add_time' }],
          },
        },
        {
          custom: 'value_won_deals',
          name: 'Value of won deals',
          base: { resource: 'deals', path: 'value' },
          category: 'Value of deals',
          additional_info: {
            content:
              'Value of deals with "Won time" during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'deals', key: 'won_time' }],
          },
        },
        {
          custom: 'value_lost_deals',
          name: 'Value of lost deals',
          base: { resource: 'deals', path: 'value' },
          category: 'Value of deals',
          additional_info: {
            content:
              'Value of deals with "Lost time" during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'deals', key: 'lost_time' }],
          },
        },
        {
          custom: 'value_close_deals',
          name: 'Value of closed deals',
          base: { resource: 'deals', path: 'value' },
          category: 'Value of deals',
          additional_info: {
            content:
              'Value of deals wth a close date during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'deals', key: 'close_time' }],
          },
        },
        {
          key: 'status',
          name: 'Status',
          type: 'enum',
          values: [
            { key: 'open', value: 'Open' },
            { key: 'won', value: 'Won' },
            { key: 'lost', value: 'Lost' },
            { key: 'deleted', value: 'Deleted' },
            { key: 'all_not_deleted', value: 'All not deleted' },
          ],
        },
        {
          key: 'user',
          name: 'Owner',
          type: 'string',
          resource: 'users',
          foreign_key: 'user_id.value',
          target_key: 'name',
          equivalents: {
            activities: 'user',
          },
        },
        {
          key: 'pipeline',
          name: 'Pipeline',
          type: 'string',
          resource: 'pipelines',
          foreign_key: 'pipeline_id',
          target_key: 'name',
          is_vip: true,
          exclude_from_details: true,
        },
        {
          key: 'person',
          name: 'Contact person',
          type: 'string',
          resource: 'persons',
          foreign_key: 'person_id.value',
          target_key: 'name',
          equivalents: {
            activities: 'person',
          },
        },
        {
          key: 'stage',
          name: 'Stage',
          type: 'string',
          resource: 'stages',
          foreign_key: 'stage_id',
          target_key: 'name',
        },
        {
          key: 'add_time',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'update_time',
          name: 'Update time',
          type: 'datetime',
        },
        {
          key: 'won_time',
          name: 'Won time',
          type: 'datetime',
        },
        {
          key: 'lost_time',
          name: 'Lost time',
          type: 'datetime',
        },
        {
          key: 'close_time',
          name: 'Deal closed on',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'pipelines',
      human_readable_name: 'pipelines',
      primary_key: 'id',
      fields: [
        {
          key: 'name',
          name: 'Name',
          type: 'string',
        },
        {
          key: 'add_time',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'update_time',
          name: 'Updated at',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'users',
      human_readable_name: 'users',
      private: true,
      primary_key: 'id',
      fields: [
        {
          key: 'name',
          name: 'Name',
          type: 'string',
        },
        {
          key: 'created',
          name: 'Created',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'persons',
      human_readable_name: 'persons',
      private: true,
      primary_key: 'id',
      fields: [
        {
          key: 'name',
          name: 'Name',
          type: 'string',
        },
        {
          key: 'add_time',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'update_time',
          name: 'Updated at',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'stages',
      human_readable_name: 'stages',
      private: true,
      primary_key: 'id',
      fields: [
        {
          key: 'name',
          name: 'Name',
          type: 'string',
        },
        {
          key: 'add_time',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'update_time',
          name: 'Updated at',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'activities',
      human_readable_name: 'activities',
      rel: [
        {
          res: 'deals',
          name: 'deal',
        },
      ],
      countable: true,
      primary_key: 'id',
      fields: [
        {
          custom: 'all_activities',
          name: 'All activities',
          base: { resource: 'activities' },
          category: 'Activities',
          additional_info: {
            content: 'All activities ever added',
          },
          rules: {
            time_fields: [null],
          },
          supported_visualisations: ['number', 'geckometer'],
          filter_associated_fields: (node) =>
            node.primary_resource === 'activities',
        },
        {
          custom: 'created_activities',
          name: 'Added activities',
          base: { resource: 'activities' },
          category: 'Activities',
          additional_info: {
            content: 'Activities added during the selected time period',
          },
          rules: {
            time_fields: [{ primary_resource: 'activities', key: 'add_time' }],
          },
          filter_associated_fields: (node) =>
            node.primary_resource === 'activities',
        },
        {
          custom: 'completed_activities',
          name: 'Completed activities',
          base: { resource: 'activities' },
          category: 'Activities',
          additional_info: {
            content: 'Activities completed during the selected time period',
          },
          rules: {
            time_fields: [
              { primary_resource: 'activities', key: 'marked_as_done_time' },
            ],
          },
          filter_associated_fields: (node) =>
            node.primary_resource === 'activities',
        },
        {
          key: 'done',
          name: 'Done',
          type: 'boolean',
        },
        {
          key: 'type',
          name: 'Type',
          type: 'string',
          resource: 'activity_types',
          foreign_key: 'type',
          target_key: 'name',
        },
        {
          key: 'user',
          name: 'Activity owner',
          type: 'string',
          resource: 'users',
          foreign_key: 'user_id',
          target_key: 'name',
          equivalents: {
            deals: 'user',
          },
        },
        {
          key: 'deal.user',
          name: 'Associated deal owner',
          type: 'string',
          resource: 'users',
          foreign_key: 'deal.user_id.value',
          target_key: 'name',
          equivalents: {
            deals: 'user',
          },
        },
        {
          key: 'person',
          name: 'Activity contact person',
          type: 'string',
          resource: 'persons',
          foreign_key: 'person_id',
          target_key: 'name',
          equivalents: {
            deals: 'person',
          },
        },
        {
          key: 'deal.person',
          name: 'Associated deal contact person',
          type: 'string',
          resource: 'persons',
          foreign_key: 'deal.person_id.value',
          target_key: 'name',
          equivalents: {
            deals: 'person',
          },
        },
        {
          key: 'deal',
          name: 'Deal',
          type: 'string',
          resource: 'deals',
          foreign_key: 'deal_id',
          target_key: 'title',
        },
        {
          key: 'add_time',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'deal.add_time',
          name: 'Associated deal created at',
          type: 'datetime',
        },
        {
          key: 'update_time',
          name: 'Activity updated time',
          type: 'datetime',
        },
        {
          key: 'deal.update_time',
          name: 'Associated deal updated time',
          type: 'datetime',
        },
        {
          key: 'marked_as_done_time',
          name: 'Completed time',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'activity_types',
      human_readable_name: 'activity types',
      primary_key: 'key_string',
      fields: [
        {
          key: 'name',
          name: 'Name',
          type: 'string',
        },
        {
          key: 'add_time',
          name: 'Created at',
          type: 'datetime',
        },
        {
          key: 'update_time',
          name: 'Updated at',
          type: 'datetime',
        },
      ],
    },
    {
      name: 'user_settings',
      fields: [],
    },
  ],
  initialQueries: {
    number: {
      query: {
        select: [
          {
            resource: 'deals',
            aggregate: 'count',
            custom: 'all_deals',
          },
        ],
      },
    },
  },
  options: [
    {
      keys: ['currency'],
      query: {
        select: [
          {
            resource: 'user_settings',
            path: 'default_currency',
          },
        ],
      },
    },
  ],
};
