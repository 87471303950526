export const GRID_ROWS = 12;
export const GRID_COLUMNS = 12;

export const MIN_CONTAINER_ROW_SPAN = 1;
export const MIN_CONTAINER_ROW_START = 1;
export const MIN_CONTAINER_COLUMN_SPAN = 1;
export const MIN_CONTAINER_COLUMN_START = 1;

export const CONTAINER_GRID_CELLS = 12;

export const DASHBOARD_GRID_GAP = 16;

export const DASHBOARD_WIDTH = 1920;

// Drag & Drop
export const DRAG_TYPES = {
  CONTAINER: 'container',
  FRAME: 'frame',
  SINGLE_FRAME_CONTAINER: 'singleFrameContainer',
};

export const CONTAINER_MENU_VIEWS = {
  OPEN: 'open',
  CLOSED: 'closed',
};

export const CONTAINER_GROUP_ORIENTATIONS = {
  AUTO: 'AUTO',
  COLUMN: 'COLUMN',
  ROW: 'ROW',
};
