import React from 'react';

import { IntercomContext } from './intercom-provider';

export const withIntercomContext = (WrappedComponent) => {
  const wrappedDisplayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const withConsumer = (props) => (
    <IntercomContext.Consumer>
      {(intercom) => <WrappedComponent intercomContext={intercom} {...props} />}
    </IntercomContext.Consumer>
  );

  withConsumer.displayName = `withIntercomContext(${wrappedDisplayName})`;

  return withConsumer;
};

export default IntercomContext.Consumer;
