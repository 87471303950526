import { map, unzip } from 'lodash';

import { composeTransformers } from '../compose-transformers';
import { transform as genericBarTransform } from '../per-visualisation/bar';

import {
  DEFAULT_LABEL,
  ensureTimespan,
  getEnumHumanValue,
  getFormat,
  getMainColumnField,
  getMultilineScatterSeries,
  getMultilineSeriesAndLabels,
} from './helpers';

const pickXAxisType = (type) => {
  switch (type) {
    case 'datetime':
      return type;
    default:
      return 'standard';
  }
};

const barTransform = (payload, config = {}, timezone, initialContext = {}) => {
  if (!payload || !payload.length) {
    return {};
  }

  const isMultiSeries = payload && payload[0].length > 2;
  let labels, data, series;

  const { queryMetas = [], queries = [] } = config;
  const [
    {
      filter: [{ operands: [qty, unitTs] = [] } = {}] = [],
      group_by: [{ bucket_by: bucket } = {}] = [],
    } = {},
  ] = queries;
  const [{ select: selectMeta = [] } = {}] = queryMetas;
  const [selectXAxis = {}] = selectMeta;
  const type = pickXAxisType(selectXAxis.type);

  const orientation = config.type === 'bar' ? 'horizontal' : 'vertical';

  let fullData = payload;

  if (!isMultiSeries) {
    if (type === 'datetime') {
      if (qty && unitTs) {
        fullData = ensureTimespan(fullData, timezone, qty, unitTs, bucket);
      }
    }

    [labels, data] = unzip(fullData);
    series = [{ data }];
  } else {
    if (type === 'datetime') {
      // Set labels
      [labels, data] = unzip(payload);

      // Visualisation supports scatter with datetime, but not with categorical data
      series = getMultilineScatterSeries(payload, selectMeta);

      if (type === 'datetime') {
        if (qty && unitTs) {
          series = series.map((s) => {
            return {
              ...s,
              data: ensureTimespan(s.data, timezone, qty, unitTs, bucket),
            };
          });
        }
      }
    } else {
      ({ labels, series } = getMultilineSeriesAndLabels(payload, selectMeta));
    }

    // We only want to support a max of 5 series
    series = series.slice(0, 5);
  }

  const barColumn = getMainColumnField(config);
  const format = getFormat(barColumn, config);

  const formattedLabels = map(labels, (label) => {
    const formattedLabel = label || DEFAULT_LABEL;
    if (selectXAxis.type === 'enum') {
      return getEnumHumanValue(formattedLabel, selectXAxis.values);
    }
    return formattedLabel;
  });

  const context = {
    series,
    x_axis: {
      labels: formattedLabels,
      type,
    },
    y_axis: {
      ...format,
    },
    orientation,
    format: format.format,
  };

  return { ...initialContext, ...context };
};

const transform = composeTransformers(barTransform, genericBarTransform);

export { transform };
