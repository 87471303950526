import { findIndex, includes } from 'lodash';

export const operatorRequiresValues = (operator) =>
  includes(
    ['in', 'not_in', 'matches', 'not_matches', 'contains', 'not_contains'],
    operator,
  );

export const isDetailsMetricSelected = (uiQuery) => {
  const { metrics: [{ node: { type: metricType } = {} } = {}] = [] } = uiQuery;
  return metricType === 'details';
};

export const getCombinedFiltersValues = (combinedFilters, globalFilters) => {
  return combinedFilters.map((combinedFilter) => {
    const value = {
      operands: [combinedFilter.value],
      operator: 'in',
      is_global: true,
      is_combined: true,
    };
    const index = findIndex(globalFilters, ({ node }) => {
      return (
        node.primary_resource === combinedFilter.primary_resource &&
        node.key === combinedFilter.key
      );
    });
    return { index, value };
  });
};
