import createAction from '../../lib/redux/create-action';

export const openMenu = createAction('InstrumentMenu:OPEN_MENU');
export const closeMenu = createAction('InstrumentMenu:CLOSE_MENU');

export const openUniversalConfig = createAction(
  'InstrumentMenu:OPEN_UNIVERSAL_CONFIG',
);
export const openDeleteModal = createAction('InstrumentMenu:OPEN_DELETE_MODAL');
export const closeDeleteModal = createAction(
  'InstrumentMenu:CLOSE_DELETE_MODAL',
);

export const openCopyModal = createAction('InstrumentMenu:OPEN_COPY_MODAL');

export const openInstrumentStatusIndicators = createAction(
  'InstrumentMenu:OPEN_INSTRUMENT_STATUS_INDICATORS',
);
export const setStatusIndicators = createAction(
  'InstrumentMenu:SET_STATUS_INDICATORS',
);
export const openInstrumentNumberFormatting = createAction(
  'InstrumentMenu:OPEN_INSTRUMENT_NUMBER_FORMATTING',
);
export const openVisualizationOptions = createAction(
  'InstrumentMenu:OPEN_VISUALIZATION_OPTIONS',
);
export const setNumberFormat = createAction('InstrumentMenu:SET_NUMBER_FORMAT');
export const updateNumberFormat = createAction(
  'InstrumentMenu:UPDATE_NUMBER_FORMAT',
);
