import { getWindow } from './global';

const getSharingURL = (sharingHash) => {
  const {
    SHARING_HOST,
    location: { protocol, host },
  } = getWindow();
  return `${protocol}//${SHARING_HOST || host}/dashboards/${sharingHash}`;
};

export { getSharingURL };
