import { gql } from '@apollo/client';

import { graphql } from '../../generated/gql';
import { ConnectionFragmentFragment } from '../../generated/graphql';

import apolloClient from './apollo-client';

export const connectionFragment = gql`
  fragment ConnectionFragment on IntegrationConnection {
    id
    legacyId
    name
    credentialsRevokedAt
    integration {
      name
      slug
      authType
    }
  }
`;
const integrationConnectionsBySlugsQuery = graphql(`
  query IntegrationConnectionsBySlugsConciergeService($slugs: [String!]!) {
    integrationConnectionsByIntegrationSlugs(integrationSlugs: $slugs) {
      ...ConnectionFragment
    }
  }
`);

const allIntegrationConnectionsQuery = graphql(`
  query AllIntegrationConnectionsConciergeService {
    integrationConnections {
      ...ConnectionFragment
    }
  }
`);

export interface LegacyServiceAccount {
  id: number;
  name: string;
  service: {
    name?: string;
    title?: string;
    auth_type?: string;
  };
}

/**
 * Map the integration connections from Concierge to the format
 * they were stored in in the redux store before we used GraphQL
 */
export const integrationConnectionsToSeviceAccounts = (
  connections: ConnectionFragmentFragment[],
): LegacyServiceAccount[] => {
  return connections.map((connection) => {
    return {
      id: connection.legacyId,
      name: connection.name,
      service: {
        name: connection.integration?.slug,
        title: connection.integration?.name,
        auth_type: connection.integration?.authType || undefined,
      },
    };
  });
};

export const getIntegrationConnections = async (
  integrationNames?: string[],
): Promise<ConnectionFragmentFragment[]> => {
  const client = apolloClient.create();

  if (integrationNames) {
    const { data: bySlugData } = await client.query({
      query: integrationConnectionsBySlugsQuery,
      variables: { slugs: integrationNames || [] },
    });

    return bySlugData.integrationConnectionsByIntegrationSlugs;
  }

  const { data } = await client.query({
    query: allIntegrationConnectionsQuery,
  });
  return data.integrationConnections;
};
