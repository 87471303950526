const getDefaultConfig = () => {
  return {
    type: 'geckometer',
    numberToDisplay: 'grandTotal',
  };
};

const isValid = (cfg) => {
  const {
    config: { yAxis },
  } = cfg;
  return !!yAxis;
};

const configMappingRules = {
  line: ['yAxis', 'numberFormat'],
  column: ['yAxis', 'numberFormat'],
  bar: ['yAxis', 'numberFormat'],
  number: ['yAxis', 'numberFormat'],
  leaderboard: ['yAxis', 'numberFormat'],
};

export default { getDefaultConfig, isValid, configMappingRules };
