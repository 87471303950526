import { QueryHookOptions, useQuery } from '@apollo/client';
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core';

import { graphql } from '../generated/gql';

export const instrumentQuery = graphql(`
  query InstrumentForEditing($id: ID!) {
    instrument(id: $id) {
      __typename
      id
      title
      notification {
        __typename
        id
        destination {
          __typename
          ... on NotificationDestinationSlack {
            __typename
            connectionId
            channelId
          }
        }
      }
      dataSource {
        integration {
          name
          slug
        }
        config {
          ... on BauhausDataSourceConfig {
            bauhausQuery: query {
              metrics
              comparison
              dataList
              filters
              groupBy
              splitBy
              scopes
              sortBy
              timespan
            }
            legacyServiceAccountId
          }
          ... on CustomPollingDataSourceConfig {
            method
            format
            url
            encryptedSecret
            refreshIntervalSeconds
          }
          ... on CustomPushDataSourceConfig {
            pushKey
          }
          ... on DatasetsDataSourceConfig {
            datasetId
            query
          }
          ... on HeirloomDataSourceConfig {
            heirloomWidgetType
            config
            legacyServiceAccountId
          }
          ... on IntegrationDatasetsDataSourceConfig {
            datasetId
            query
            integrationDatasetId
            legacyServiceAccountId
          }
          ... on InquisitorDataSourceConfig {
            legacyServiceAccountId
            queries
            queryMetas
            timespanComparison
            detailsMetricResource
          }
          ... on DatabaseDataSourceConfig {
            databaseId
            query
            refreshIntervalSeconds
          }
          ... on SalesforceDataSourceConfig {
            legacyServiceAccountId
            reportId
            refreshIntervalSeconds
            xAxis
            yAxis
            columns
            hasHeader
            numberToDisplay
            series
          }
          ... on SpreadsheetsDataSourceConfig {
            legacyServiceAccountId
            fileId
            worksheetId
            hasHeader
            xAxis
            series
            columns
            labels
            value
            isLoop
            fieldRanges
          }
        }
      }
      ... on Number {
        comparison {
          __typename
          ... on PercentageComparison {
            positiveDirection
            label
            value
          }
          ... on NumberComparison {
            positiveDirection
            label
            value
          }
          ... on DynamicComparison {
            label
          }
          ... on SparklineComparison {
            value
          }
        }
        label
        numberFormat {
          ...NumberFormatFragment
        }
        progressIndicator {
          targetValue {
            ... on FloatValue {
              value
            }
            ... on SpreadsheetCellReference {
              cell
            }
          }
          startingValue {
            ... on FloatValue {
              value
            }
            ... on SpreadsheetCellReference {
              cell
            }
          }
        }
        statusIndicator {
          ...StatusIndicatorFragment
        }
      }
      ... on Geckometer {
        min {
          ... on FloatValue {
            value
          }
          ... on SpreadsheetCellReference {
            cell
          }
        }
        max {
          ... on FloatValue {
            value
          }
          ... on SpreadsheetCellReference {
            cell
          }
        }
        numberFormat {
          ...NumberFormatFragment
        }
        statusIndicator {
          ...StatusIndicatorFragment
        }
      }
      ... on Line {
        comparisonStyleSeriesIndex
        min {
          ... on FloatValue {
            value
          }
          ... on SpreadsheetCellReference {
            cell
          }
        }
        max {
          ... on FloatValue {
            value
          }
          ... on SpreadsheetCellReference {
            cell
          }
        }
        numberFormat {
          ...NumberFormatFragment
        }
        statusIndicator {
          ...StatusIndicatorFragment
        }
        legends
      }
      ... on Column {
        numberFormat {
          ...NumberFormatFragment
        }
        statusIndicator {
          ...StatusIndicatorFragment
        }
        legends
      }
      ... on Bar {
        numberFormat {
          ...NumberFormatFragment
        }
        statusIndicator {
          ...StatusIndicatorFragment
        }
      }
      ... on Leaderboard {
        sortOrder
        numberFormat {
          ...NumberFormatFragment
        }
        showImages
      }
      ... on Table {
        hasHeader
        columnWidths
        numberFormats {
          abbreviation
          decimalPlacesStyle
          decimalPlacesPrecision
          unitPlacement
          unitValue
        }
        showImages
        wrap {
          mode
          autoDecision
        }
      }
      ... on Rag {
        ragType
      }
      ... on Map {
        mapType
        mapFocus
      }
      ... on Funnel {
        numberFormat {
          ...NumberFormatFragment
        }
      }
    }
  }
`);

const useGetInstrumentForEditing = (
  instrumentId?: string,
  options: QueryHookOptions<
    ResultOf<typeof instrumentQuery>,
    VariablesOf<typeof instrumentQuery>
  > = {},
) => {
  return useQuery(instrumentQuery, {
    variables: { id: instrumentId || '' },
    fetchPolicy: 'network-only',
    skip: !Boolean(instrumentId),
    ...options,
  });
};

export default useGetInstrumentForEditing;
