import { findDOMNode } from 'react-dom';

function dispatchEvent(element, name, detail) {
  const event = document.createEvent('CustomEvent');

  event.initCustomEvent(name, true, false, detail);
  // eslint-disable-next-line react/no-find-dom-node -- Disabled by codemod when new recommended rulesets introduced
  findDOMNode(element).dispatchEvent(event);
}

export { dispatchEvent };
