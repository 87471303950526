import {
  chain,
  compact,
  every,
  findIndex,
  flatten,
  head,
  isNull,
  isUndefined,
  max,
  reduce,
} from 'lodash';

const supportsMultiSeries = (vizType) =>
  ['line', 'column', 'table'].indexOf(vizType) !== -1;

export const getMetricsOrder = (queries) =>
  queries.map((q) =>
    q.select.map((m) => (isUndefined(m.multi_index) ? 0 : m.multi_index + 1)),
  );

export const mergeQueryResults = (queries = [], queryResults, vizType) => {
  if (!queryResults || every(queryResults, isNull)) {
    return null;
  }

  // No need to process all queryresults if only one contains data
  const compactedQueryResults = compact(queryResults);
  if (compactedQueryResults.length === 1) {
    return compactedQueryResults[0];
  }

  if (!supportsMultiSeries(vizType)) {
    return queryResults;
  }

  const order = getMetricsOrder(queries);
  const resSize = max(flatten(order)) + 1;
  // Create placeholder for all points with x values like:
  // [[x1, null, null], [x2, null, null], [x3, null, null]]
  const mergedResults = chain(compactedQueryResults)
    .map((q) => q.map((d) => head(d)))
    .flatten()
    .reject(isUndefined)
    .uniq()
    .map((x) => {
      const d = new Array(resSize).fill(null);
      d[0] = x;
      return d;
    })
    .value();

  return reduce(
    queryResults,
    (res, queryResult, i) => {
      if (!queryResult) {
        return res;
      }
      const indices = order[i];

      queryResult.forEach((qrRow) => {
        if (!isNull(qrRow)) {
          const index = findIndex(res, (row) => row[0] === qrRow[0]);

          indices.forEach((j, k) => (res[index][j] = qrRow[k]));
        }
      });

      return res;
    },
    mergedResults,
  );
};
