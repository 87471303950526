import {
  chain,
  conformsTo,
  intersection,
  isEqual,
  isString,
  uniqBy,
  without,
} from 'lodash';

import {
  getTimespanHint,
  getTimespanLabel,
} from '@Lib/timespan-helpers/timespan-helper';

import {
  getInputFromDuration as _getInputFromDuration,
  parseDurationInput,
  toMs,
  UNIT_MEASURES,
} from '../lib/humanise-number';

export const UNIVERSAL_VISUALISATIONS = [
  'number',
  'geckometer',
  'line',
  'column',
  'bar',
  'leaderboard',
  'table',
  'feed',
];

// Cannot use PropTypes checker outside of React environment.
// This replicate a pure JS checker
export const isDurationInput = (input) =>
  conformsTo(input, {
    days: isString,
    hours: isString,
    minutes: isString,
  });

export const isEmptyDurationInput = (input) =>
  isEqual(input, {
    days: '',
    hours: '',
    minutes: '',
  });

export const TIMESPAN_TYPES = ['datetime'];
export const GROUP_BY_CATEGORY_TYPES = ['string', 'enum'];
export const GROUP_BY_TIME_TYPES = ['datetime'];
export const FILTER_FIELD_TYPES = [
  'custom',
  'string',
  'enum',
  'datetime',
  'boolean',
];
export const DETAILS_TYPES = ['string', 'enum', 'datetime', 'boolean'];
export const ORDER_BY_TYPES = ['datetime'];

export const AGGREGATE_OPTIONS = [
  { name: 'Avg', fullName: 'Average', key: 'avg' },
  { name: 'Med', fullName: 'Median', key: 'median' },
  { name: 'Max', fullName: 'Maximum', key: 'max' },
  { name: 'Min', fullName: 'Minimum', key: 'min' },
  { name: 'Sum', fullName: 'Sum', key: 'sum' },
];

export const TIMESPAN_OPERATORS = {
  TIMESPAN: 'timespan',
  PREV_TIMESPAN: 'prev_timespan',
  PREV_SAME_TIMESPAN: 'prev_same_timespan',
  PREV_PARTIAL_TIMESPAN: 'prev_partial_timespan',
};

export const TIME_OPTION = { key: 'time', name: 'Time', category: 'Datetime' };
export const ALL_OPTION = { key: '__universal_default', value: 'All' };
export const TIMESPAN_CUSTOM_OPTION = {
  key: '__universal_custom_ts',
  label: 'Custom period...',
};
export const TIME_COMPARISON_OPTION = {
  key: '__universal_time_comparison',
  label: 'Compare to previous period',
};

export const GROUPED_TIMESPAN_OPTIONS = [
  [
    { unit: 'day', quantity: 7 },
    { unit: 'day', quantity: 14 },
    { unit: 'day', quantity: 28 },
    { unit: 'day', quantity: 30 },
    { unit: 'day', quantity: 90 },
  ],
  [
    { unit: 'day', quantity: 1 },
    { unit: 'week', quantity: 1 },
    { unit: 'month', quantity: 1 },
    { unit: 'quarter', quantity: 1 },
    { unit: 'year', quantity: 1 },
  ],
];

export const getGroupedTimespanOptionsWithHint = () => {
  return GROUPED_TIMESPAN_OPTIONS.map((g) =>
    g.map((opt) => ({
      ...opt,
      label: getTimespanLabel(opt.unit, opt.quantity),
      hint: getTimespanHint(opt.unit, opt.quantity),
    })),
  );
};

export const MAX_NUMBER_OF_METRICS = {
  line: 9,
  column: 3,
  bar: 1,
  number: 1,
  leaderboard: 1,
  geckometer: 1,
  table: 5,
};

export const TYPE_TO_GROUP = {
  duration: 'Duration',
  datetime: 'Date/Datetime',
  int: 'Number',
  float: 'Number',
  count: 'Number',
  percent: 'Percentage',
  currency: 'Currency',
};

const TIMESPAN_TRANSFORM = {
  week: { toUnit: 'day', multiplier: 7 },
  month: { toUnit: 'day', multiplier: 31 },
  quarter: { toUnit: 'day', multiplier: 31 * 3 },
};

const TYPE_ORDER = [
  'Number',
  'Currency',
  'Percentage',
  'Duration',
  'Date/Datetime',
];

export const groupMetrics = (options, categoryOrder = []) => {
  const typeOrderStartIndex = categoryOrder.length;
  return chain(options)
    .groupBy((opt) => opt.category || TYPE_TO_GROUP[opt.type] || opt.type)
    .map((val, name) => ({ name, options: val }))
    .sortBy((g) => {
      const catIndex = categoryOrder.indexOf(g.name);
      return catIndex > -1
        ? catIndex
        : typeOrderStartIndex + TYPE_ORDER.indexOf(g.name);
    })
    .value();
};

export const areMetricsTypesCompatible = (metrics) => {
  if (metrics.length === 1) return true;

  const uniqTypes = uniqBy(metrics, ({ type }) => TYPE_TO_GROUP[type]);
  return uniqTypes.length === 1;
};

export const groupCustomFields = (options) => {
  return chain(options)
    .groupBy((opt) => opt.isCustomField && opt.isCustomField())
    .map((val, isCustomGroup) => ({
      name: isCustomGroup === 'true' ? 'Custom fields' : null,
      options: val,
    }))
    .sortBy((g) => [null, 'Custom fields'].indexOf(g.name))
    .value();
};

export const getInputFromDuration = (duration, unit) => {
  // Universal UI always want to show D H and M inputs
  return _getInputFromDuration(duration, unit, 3, 'minutes');
};

export const getMsFromTimespan = (operands) => {
  let [quantity, unit] = operands;

  // Convert week and month timespans to days
  if (TIMESPAN_TRANSFORM[unit]) {
    quantity = quantity * TIMESPAN_TRANSFORM[unit].multiplier;
    unit = TIMESPAN_TRANSFORM[unit].toUnit;
  }

  return toMs(quantity, `${unit}s`);
};

export const durationToDays = (quantity, unit) =>
  getMsFromTimespan([quantity, unit]) / UNIT_MEASURES.days;

export const getMaxQuantityForUnit = (value, unit) => {
  if (!value) return null;
  const transform = TIMESPAN_TRANSFORM[unit];

  if (transform) {
    return Math.floor(
      parseDurationInput({ milliseconds: value }, `${transform.toUnit}s`) /
        transform.multiplier,
    );
  }

  return Math.floor(parseDurationInput({ milliseconds: value }, `${unit}s`));
};

export const vizSupportsTimespanComparison = (viz) =>
  ['number', 'line'].includes(viz);

export const timespanSupportsPrevSameComparison = (unit, quantity) =>
  quantity === 1 && ['day', 'month', 'quarter'].includes(unit);

export const canTimespanCompare = (maxTimePeriod, operands = []) => {
  if (!maxTimePeriod || !operands.length) return true;

  const totalTime = getMsFromTimespan(operands) * 2;
  return totalTime <= maxTimePeriod;
};

export const getTSComparisonOperatorForViz = (vizType, currentOperator) => {
  if (currentOperator === TIMESPAN_OPERATORS.PREV_SAME_TIMESPAN) {
    return TIMESPAN_OPERATORS.PREV_SAME_TIMESPAN;
  }

  return vizType === 'number'
    ? TIMESPAN_OPERATORS.PREV_PARTIAL_TIMESPAN
    : TIMESPAN_OPERATORS.PREV_TIMESPAN;
};

export const getVisualisationsForMetric = (metric) => {
  let viz = [...UNIVERSAL_VISUALISATIONS];
  const { type, supported_visualisations: sv } = metric;

  // Details logic to only support table and feed
  if (type === 'details') {
    viz = ['table', 'feed'];
  } else {
    viz = without(viz, 'feed');
  }

  if (sv) {
    viz = intersection(sv, viz);
  }

  return viz;
};

export const canFormatNumberForType = (type) => {
  return ['int', 'float', 'count', 'percent', 'currency'].indexOf(type) > -1;
};
