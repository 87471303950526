import { last } from 'lodash';
import moment from 'moment-timezone';

const truncate = (unit, tVal) => {
  if (unit === 'week') {
    // Go back to the most recent Monday.
    tVal.day('Monday');
    // truncate to midnight
    truncate('day', tVal);
  } else {
    tVal.startOf(unit);
  }
};

const getTimespan = (tz, quantity, unit) => {
  // Subtract 1 so that "1 day ago" just means "the most recent
  // midnight", and "2 days ago" is midnight yesterday.
  const offset = quantity - 1;

  const now = tz ? moment.tz(tz) : moment();
  const start = now.clone();
  let end;
  truncate(unit, start);

  switch (unit) {
    case 'minute':
    case 'hour':
    case 'day':
    case 'month':
    case 'quarter':
    case 'year':
      start.subtract(offset, `${unit}s`);
      // Second is the smallest unit we support for now. Removing a second allow
      // us to cover exactly the quantity
      end = start.clone().add(quantity, `${unit}s`).subtract(1, 'seconds');
      break;
    case 'week':
      // Go back in time 7 days per week.
      start.subtract(offset * 7, 'days');
      end = start
        .clone()
        .add(quantity * 7, `days`)
        .subtract(1, 'seconds');
      break;
    default:
      throw new Error('timespan unit not supported');
  }

  return [start, end];
};

/*
 * getBucketRange
 *
 * Given a unit and a quantity (e.g. 1, "week") returns a
 * start and end timestamp. The end timestamp will be
 * "now" rounded to the nearest bucket.
 *
 * example:
 * getBucketRange('Europe/London', 1, 'week', 'day')
 *
 * result:
 * [2017-01-01T00:00:00, 2017-01-07T00:00:00]
 */
const getBucketRange = (tz, quantity, unit, bucket = 'day') => {
  const [start, end] = getTimespan(tz, quantity, unit);
  truncate(bucket, end);
  return [start.format(), end.format()];
};

/*
 * getBuckets
 *
 * Given a unit and a quantity (e.g. 1, "week") returns an
 * array of timestamps that are the result of splitting
 * that duration into buckets. The last item in the
 * series will be "now" rounded to the nearest bucket.
 *
 * example:
 * getBuckets('Europe/London', 1, 'week', 'day')
 *
 * result:
 * [
 *   2017-01-01T00:00:00,
 *   2017-01-02T00:00:00,
 *   2017-01-03T00:00:00,
 *   2017-01-04T00:00:00,
 *   2017-01-05T00:00:00,
 *   2017-01-06T00:00:00,
 *   2017-01-07T00:00:00,
 * ]
 */
const getBuckets = (tz, quantity, unit, bucket = 'day') => {
  const [start, end] = getTimespan(tz, quantity, unit);
  truncate(bucket, end);

  const buckets = [start];

  while (last(buckets).isBefore(end)) {
    const nevValue = last(buckets).clone().add(1, `${bucket}s`);
    buckets.push(nevValue);
  }

  if (start.isSame(end)) {
    buckets.push(end);
  }

  return buckets.map((b) => b.format());
};

export default getTimespan;
export { getBucketRange, getBuckets, getTimespan, truncate };
