import { omit } from 'lodash';

const cleanTransformedData = (transformedData) =>
  omit(transformedData, [
    'threshold',
    'startingValue',
    'comparison',
    'reverseGoalDirection',
  ]);

export default cleanTransformedData;
