export default {
  '--background-color': '#191E30',
  '--widget-background-color': '#2E354C',
  '--widget-active-background-color': '#343c55',
  '--selected-widget-header-color': '#444C67',

  /* Number widget */
  '--number-threshold-text-color': '#2E354C',

  /* Bar chart */
  /* this should be consolidated with the linechart one above */
  '--chart-threshold-color': '#383F58',

  /* Map widget */
  '--map-subunit-color': '#4D5366',
  '--map-single-point-color': '#55E46C',
};
