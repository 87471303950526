import { get, isEmpty } from 'lodash';

import {
  formatDurationValue,
  getColumnFormat,
  getDisplayValue,
  getValuesFromRow,
  getXAxisFormat,
  processSeries,
  processSplitSeries,
} from './helpers';

export const transformBarOrColumnChart = (orientation) => (payload, config) => {
  const data = get(payload, 'data.bauhausDataRequest.data');

  if (!data) {
    return {};
  }

  const { rows, columns } = data;

  if (rows.length === 0) {
    return {
      orientation,
      series: [],
    };
  }

  const yAxisFormat = getColumnFormat(data.columns.slice(1), data.rows);
  const xAxisFormat = getXAxisFormat(columns[0]);
  const rowValues = rows.map(({ values }) =>
    getValuesFromRow(values, data.columns),
  );

  if (xAxisFormat === 'datetime') {
    rowValues.reverse();
  }

  const hasSplitBy = !isEmpty(get(config, 'bauhaus.split_by', []));

  const series = hasSplitBy
    ? processSplitSeries(rowValues, 5)
    : processSeries(rowValues);

  const labels = get(series, '[0].data', []).map((s) => getDisplayValue(s[0]));

  return {
    format: yAxisFormat.format,
    orientation,
    series,
    x_axis: {
      labels,
      type: xAxisFormat,
    },
    y_axis: { ...yAxisFormat },
  };
};

export const legendTransform = (payload, config, _, initialContext) => {
  const metrics = get(config, 'bauhaus.metrics', []);

  if (metrics.length <= 1) {
    return initialContext;
  }

  const columns = get(payload, 'data.bauhausDataRequest.data.columns', []);
  const series = get(initialContext, 'series', []);

  const seriesWithLabels = series.map((s, i) => ({
    ...s,
    name: columns[i + 1].displayName,
  }));

  return {
    ...initialContext,
    series: seriesWithLabels,
  };
};

export const durationTransform = (_, __, ___, initialContext) => {
  const { series, y_axis: yAxis, threshold } = initialContext;

  if (isEmpty(initialContext) || isEmpty(series)) {
    return initialContext;
  }

  const { format: yAxisFormat, unit: yAxisUnit } = yAxis;

  if (yAxisFormat !== 'duration') {
    return initialContext;
  }

  const transformedSeries = series.map((serie) => {
    const data = serie.data.map(([key, value]) => [
      key,
      formatDurationValue(value, yAxisUnit),
    ]);

    return {
      ...serie,
      data,
    };
  });

  return {
    ...initialContext,
    series: transformedSeries,
    /*
     * the threshold is saved in seconds, we must convert to ms if we
     * want to reuse the existing formatDurationValue helper
     */
    threshold: threshold && formatDurationValue(threshold * 1000, yAxisUnit),
  };
};
