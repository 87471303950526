import { isArray, isFinite, isUndefined, last, map, reduce } from 'lodash';

import { parseNumberBasedOnFormat } from '../../lib/parse-number';
import {
  getSelectedCells,
  greedilyParseFloat,
} from '../../spreadsheet-helpers';

const DECIMAL = ['decimal'];
const DATETIME = ['datetime'];
const PERCENT = ['percent'];

function isCurrency(selections) {
  return (
    selections.length && selections.every((cell) => cell.type === 'currency')
  );
}

function isSameCurrency(selections) {
  const { currency } = selections[0];
  return (
    selections.length && selections.every((cell) => cell.currency === currency)
  );
}

function isPercentage(selections) {
  return (
    selections.length &&
    selections.every(
      (selection) =>
        selection.type === 'percentage' || selection.type === 'percent',
    )
  );
}

function isNumber(selections) {
  return (
    selections.length &&
    selections.every((selection) => selection.type === 'number')
  );
}

function isDatetime(selections) {
  return (
    selections.length &&
    selections.every((selection) => selection.type === 'datetime')
  );
}

function getMixedInputValue(cells, inputValue, format) {
  let value = inputValue;
  let parseFormat = format;

  const isSelected = isArray(value);
  if (isSelected) {
    const selectedCells = getSelectedCells(cells, [value]);
    if (!selectedCells[0][0]) {
      return undefined;
    }
    value = selectedCells[0][0].value;

    // Special case selections that are percentages as they are stored
    // divided by 100! For example 12% is stored as 0.12.
    if (format === 'percent') {
      parseFormat = 'decimal';
    }
  }

  return parseNumberBasedOnFormat(value, parseFormat);
}

function getSelectionFormat(selections) {
  if (isDatetime(selections)) {
    return DATETIME;
  }

  if (isPercentage(selections)) {
    return PERCENT;
  }

  if (isCurrency(selections) && isSameCurrency(selections)) {
    const { currency } = selections[0];
    return ['currency', currency];
  }

  return DECIMAL;
}

function getNumericCells(selectedCells) {
  return reduce(
    selectedCells,
    (acc, item) => {
      const numericValue = greedilyParseFloat(item.value);

      if (isFinite(numericValue)) {
        acc.push({ ...item, value: numericValue });
      }

      return acc;
    },
    [],
  );
}

function getGeckometerValue(value, cells) {
  const [selectedCells] = getSelectedCells(cells, [value]);
  const numericCells = getNumericCells(selectedCells);
  const values = map(numericCells, 'value');

  return last(values);
}

// if the cell relative to the series headers is missing
// they will not get included in the "aligned series"
// as `indexXAxis` will not index the missing cell
// and subsequently alignSeries won't include the headers,
// so here we insert a cell at the indices calculated by
// headerXAxisIndices
export const padXAxis = (cells, indices) => {
  let padding = [];
  if (!isUndefined(indices)) {
    const head = cells[0];
    if (head.row !== indices.row || head.column !== indices.column) {
      padding = [indices];
    }
  }
  return [...padding, ...cells];
};

const getStartOfSelection = (selection) => {
  let [[startCol, startRow]] = selection;
  if (startCol === -1) {
    startCol = 0;
  }
  if (startRow === -1) {
    startRow = 0;
  }

  return [startCol + 1, startRow + 1];
};

export const indexSeries = (cells, selection) => {
  const [startCol, startRow] = getStartOfSelection(selection);

  return cells.reduce((memo, cell) => {
    memo[cell.column - startCol + cell.row - startRow] = cell;
    return memo;
  }, {});
};

export const alignSeries = (indexedXAxis, indexedSeries) => {
  return indexedSeries.map((series) => {
    return indexedXAxis.map((index) => series[index] || null);
  });
};

export const indexXAxis = (cells, selection) => {
  const [startCol, startRow] = getStartOfSelection(selection);
  return cells.map((cell) => cell.column - startCol + cell.row - startRow);
};

export {
  getGeckometerValue,
  getMixedInputValue,
  getNumericCells,
  getSelectionFormat,
  isDatetime,
  isNumber,
  isPercentage,
};
