import { groupBy } from 'lodash';

import * as dataSourceConnectorActions from '../../data-source-connector/actions/data-source-connector-actions';
import * as initDataSourceActions from '../../data-source-connector/actions/init-data-source-action';

const groupServiceAccounts = (serviceAccounts, state) => {
  const newServiceAccounts = groupBy(serviceAccounts, (sa) => sa.service.name);

  return { ...state, ...newServiceAccounts };
};

const initialState = {};
const serviceAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case initDataSourceActions.initDataSourceSuccessful.type:
    case dataSourceConnectorActions.accountAddSuccessful.type:
      return groupServiceAccounts(action.payload.serviceAccounts, state);
    default:
      return state;
  }
};

export default serviceAccountsReducer;
