import { composeTransformers } from '../../compose-transformers';
import { transform as goalTransform } from '../../shared/goal';
import { transform as numberFormatTransform } from '../../shared/number-format';

import { transform as yAxisMinMaxTransform } from './yaxisminmax';

const transform = composeTransformers(
  numberFormatTransform,
  goalTransform,
  yAxisMinMaxTransform,
);

export { transform };
