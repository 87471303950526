import { Visualizations } from '../../consts';
import type { Field, VisualizationType } from '../../types';

import { isSingleCellSelected } from './isSingleCellSelected';

const visualizationPreferenceOrder: VisualizationType[] = [
  'line',
  'column',
  'bar',
  'number',
  'geckometer',
  'leaderboard',
  'table',
  'text',
];

/**
 * Determine the most suitable visualization that we want to suggest to the user
 */
export const suggestBestVisualization = (
  fields: Field[],
): VisualizationType => {
  // Text is the most leniant visualization so return that if nothing else will fit
  if (fields.length === 0) {
    return 'text';
  }

  const isSingleCell = isSingleCellSelected(fields.map((f) => f.range));

  const suggestedVisualization = visualizationPreferenceOrder.find(
    (vizType) => {
      const { seriesFieldTypes, categoryFieldTypes, singleCellSupport } =
        Visualizations[vizType];

      // If we have just a single cell to choose from, but the visualization
      // needs more than 1 cell then skip it now
      if (isSingleCell && !singleCellSupport) {
        return false;
      }

      // If we have just a single Field to choose from, then any visualization
      // that requires both a series and a category is going to look odd so
      // is probably not the best visualization
      if (fields.length === 1 && seriesFieldTypes && categoryFieldTypes) {
        return false;
      }

      const hasSuitableSeries = fields.some((f) =>
        seriesFieldTypes.includes(f.dataType),
      );

      if (!hasSuitableSeries) {
        return false;
      }

      // This is an optional field for some visualizations
      if (categoryFieldTypes) {
        const suitableCategories = fields.filter((f) =>
          categoryFieldTypes.includes(f.dataType),
        );

        if (suitableCategories.length === 0) {
          return false;
        }
      }

      return vizType;
    },
  );

  // Text is the most leniant visualization so return that if nothing else will fit
  return suggestedVisualization || 'text';
};
