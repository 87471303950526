import { isUndefined } from 'lodash';

function transform(
  payload,
  { comparison } = {},
  timezone,
  initialContext = {},
) {
  const context = {};

  if (!isUndefined(comparison)) {
    context.comparison = comparison;
  }

  return { ...initialContext, ...context };
}

export { transform };
