const getDefaultConfig = () => {
  return {
    type: 'line',
  };
};

const isValid = (cfg) => {
  const { config: { xAxis, yAxis } = {} } = cfg;

  return !!(xAxis && yAxis);
};

const configMappingRules = {
  column: [
    'xAxis',
    'yAxis',
    'series',
    'goal',
    'reverseGoalDirection',
    'numberFormat',
  ],
  bar: ['xAxis', 'yAxis', 'goal', 'reverseGoalDirection', 'numberFormat'],
  number: [
    'yAxis',
    'goal',
    'numberFormat',
    [
      'comparison.thresholdType',
      (type) => 'lower' === type,
      'reverseGoalDirection',
    ],
  ],
  leaderboard: ['xAxis', 'yAxis', 'numberFormat'],
  geckometer: ['yAxis', 'numberFormat'],
};

export default { getDefaultConfig, isValid, configMappingRules };
