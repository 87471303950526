import { pick } from 'lodash';

import config from '../universal/config';
import { fetchJSONWithCredentials } from '../universal/fetch';

const UPDATEABLE_PROPS = [
  'title',
  'timezone',
  'columns',
  'public',
  'sharing',
  'css',
  'theme',
  'country',
  'scale_when_fullscreen',
  'tv_mode',
  'devices',
  'toggles',
  'customization',
];

function updateDashboard(dashboardId, props) {
  const URL = `${config.get().ManagementBaseURL}/dashboards/${dashboardId}`;
  const body = pick(props, UPDATEABLE_PROPS);

  // Coerce `sharing: "n"` to `sharing: false`
  // ----------
  // There is a bug in gecko-models with how `sharing` is handled
  // and this is a temporary fix on the front-end so that we can
  // ship Customization
  const sharing = body.sharing && body.sharing !== 'n';
  const bodyWithCoercedSharing = { ...body, sharing };
  // ----------

  return fetchJSONWithCredentials(URL, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyWithCoercedSharing), // Remove when `sharing` bug is fixed
  });
}

// Only devices, timezone, country, sharing, toggles, theme and customization
// are supported by this endpoint at present.
function patchDashboard(
  dashboardId,
  { devices, timezone, country, sharing, toggles, theme, customization },
) {
  const URL = `${config.get().ManagementBaseURL}/dashboards/${dashboardId}`;
  const body = {
    devices,
    timezone,
    country,
    sharing,
    toggles,
    theme,
    customization,
  };

  return fetchJSONWithCredentials(URL, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

function updateDashboardTitle(dashboardId, title) {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/dashboards/${dashboardId}/update_title`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ title }),
    },
  );
}

function regenerateSharingLink(dashboardId) {
  const URL = `${
    config.get().ManagementBaseURL
  }/dashboards/${dashboardId}/regenerate_sharing`;

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
  });
}

const deleteDashboard = (dashboardId) => {
  return fetchJSONWithCredentials(
    `${config.get().ManagementBaseURL}/dashboards/${dashboardId}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

function copyDashboard(groupId, dashboard) {
  const { id: dashboardId, title: dashboardName } = dashboard;
  const URL = `${
    config.get().ManagementBaseURL
  }/groups/${groupId}/dashboards/${dashboardId}/copy`;
  const body = JSON.stringify({
    title: `${dashboardName} copy`,
    group_id: groupId,
  });

  return fetchJSONWithCredentials(URL, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  });
}

export {
  copyDashboard,
  deleteDashboard,
  patchDashboard,
  regenerateSharingLink,
  updateDashboard,
  updateDashboardTitle,
};
