import { LOCATIONS, PERMISSIONS } from '@Tracking/constants';
import defineEvent from '@Tracking/define-event';
import SchemaTypes from '@Tracking/schema-types';

export const PERMISSION_SCHEMA = SchemaTypes.oneOf(
  Object.values(PERMISSIONS),
).isRequired;

export const invitationResent = defineEvent('User - Invitation resent');

export const invitationSent = defineEvent('User - Invitation sent', {
  Location: SchemaTypes.oneOf([
    LOCATIONS.AdminInviteModal,
    LOCATIONS.DashboardShareMenu,
    LOCATIONS.HeaderPeopleModal,
  ]).isRequired,
});

export const userDeleted = defineEvent('User - Deleted');

export const permissionsChanged = defineEvent('User - Permission changed', {
  'From permission': PERMISSION_SCHEMA,
  'To permission': PERMISSION_SCHEMA,
});
