import { flatten, get, some } from 'lodash';

import {
  BAUHAUS_SERVICES,
  UNIVERSAL_SERVICES,
} from '../data-sources/data-sources-helpers';

// Return if any value from attributes match query in a service object
// (e.g Search service in integration page)
const matches = (attributes, query) => (service) => {
  const q = query.toLowerCase();

  return some(attributes, (attribute) => {
    const values = get(service, attribute);
    if (!values) {
      return false;
    }

    return some(flatten([values]), (value) => {
      return String(value).toLowerCase().indexOf(q) !== -1;
    });
  });
};

const usesOAuth = (service) => ['oauth', 'oauth2'].includes(service.auth_type);

const isUniversal = (serviceName) =>
  [...UNIVERSAL_SERVICES, 'inquisitor'].includes(serviceName);

const isBauhaus = (serviceName) =>
  [...BAUHAUS_SERVICES, 'bauhaus'].includes(serviceName);

export { isBauhaus, isUniversal, matches, usesOAuth };
