import { Cell, Range } from '../../types';

/**
 * Creates an array of numbers from start to end, inclusive
 *
 * @param start
 * @param end
 */
const createSequence = (start: number, end: number) => {
  if (start === end) {
    return [start];
  }

  const range = [];
  for (let i = start; i <= end; i += 1) {
    range.push(i);
  }
  return range;
};
const rangeIndexToCellIndex = (n: number) => n + 1;
const cellIndextoRangeIndex = (n: number) => n - 1;

/**
 * Ensures the range starts in the top/left of the worksheet, and ends
 * in the bottom/right
 *
 * @param r
 * @returns
 */
const normalizeRange = (r: Range): Range => {
  const [colStart, rowStart] = r.start;
  const [colEnd, rowEnd] = r.end;

  const leftMostColumn = Math.min(colStart, colEnd);
  const rightMostColumn = Math.max(colStart, colEnd);

  const topMostRow = Math.min(rowStart, rowEnd);
  const bottomMostRow = Math.max(rowStart, rowEnd);

  return {
    ...r,
    start: [leftMostColumn, topMostRow],
    end: [rightMostColumn, bottomMostRow],
  };
};

/**
 * This is a wrapper around worksheet cells that makes it easier to extract information
 * about cells covered by a range.
 *
 * In particular, this abstraction makes sure we're correctly handling conversion of frontend
 * column/row indexing (0-based) to backend column/row indexing (1-based), as `Range` values
 * always use 0-based indexing.
 */
export interface CellMap {
  resolveColumnsInRange: (r: Range) => number[];
  resolveRowsInRange: (r: Range) => number[];
  getPopulatedCellsByRange: (r: Range) => Cell[];
}

/**
 * Builds the cell map for an array of cells.
 *
 * It is assumed that these cells have come from the spreadsheets backend and are using
 * 1-based indexing for columns/rows
 */
export const createSpreadsheetMaps = (cells: Cell[]): CellMap => {
  const cellsMap = new Map<string, Cell>();
  const columnsMap = new Map<number, number[]>();
  const rowsMap = new Map<number, number[]>();

  cells.forEach((cell) => {
    const cellKey = `${cell.column}_${cell.row}`;
    cellsMap.set(cellKey, cell);

    if (!columnsMap.has(cell.column)) {
      columnsMap.set(cell.column, []);
    }

    if (!rowsMap.has(cell.row)) {
      rowsMap.set(cell.row, []);
    }

    columnsMap.get(cell.column)!.push(cell.row);
    rowsMap.get(cell.row)!.push(cell.column);
  });

  const lookupCell = (col: number, row: number) => {
    return cellsMap.get(`${col}_${row}`);
  };

  return {
    /**
     * Gets the indicies of columns covered by the specified range, suitable for
     * constructing a `Range`.
     *
     * If the range covers an entire row (ie. range.start = [-1, ...]), then
     * this function will return a span that covers from the first column to the
     * column of the last populated cell in that range
     */
    resolveColumnsInRange: (r: Range): number[] => {
      const range = normalizeRange(r);

      const [leftMostColumn, topMostRow] = range.start;
      const [rightMostColumn, bottomMostRow] = range.end;

      // If we're dealing with an exact selection within the worksheet, we can calculate the upper/lower
      // index from the range itself, and interpolate the values inbetween
      if (leftMostColumn !== -1) {
        return createSequence(leftMostColumn, rightMostColumn);
      }

      // This will store the column index in the same 1 based indexing that the spreadsheet uses
      // IMPORTANT: convert this to zero-based indexing before passing back to the caller
      let maxColumn = 1;

      // If we've gotten here, then the user has selected entire row(s) by clicking the row header,
      // so we need to calculate which row has the most number of columns
      //
      // In most cases this function will be called with a `VectorRange`, so there should only
      // be a single row, but we handle the "many rows" case to be on the safe side
      for (let row = topMostRow; row <= bottomMostRow; row += 1) {
        const rowCellIndex = rangeIndexToCellIndex(row);
        const cellsInRow = rowsMap.get(rowCellIndex);

        if (cellsInRow === undefined) {
          continue;
        }

        maxColumn = Math.max(maxColumn, ...cellsInRow);
      }

      return createSequence(0, cellIndextoRangeIndex(maxColumn));
    },

    /**
     * Gets the indicies of rows covered by the specified range, suitable for
     * constructing a `Range`.
     *
     * If the range covers an entire column (ie. range.start = [..., -1]), then
     * this function will return a span that covers from the first row to the
     * row of the last populated cell in that range
     */
    resolveRowsInRange: (r: Range): number[] => {
      const range = normalizeRange(r);

      const [leftMostColumn, topMostRow] = range.start;
      const [rightMostColumn, bottomMostRow] = range.end;

      // If we're dealing with an exact selection within the worksheet, we can calculate the upper/lower
      // index from the range itself, and interpolate the values inbetween
      if (topMostRow !== -1) {
        return createSequence(topMostRow, bottomMostRow);
      }

      // This will store the row index in the same 1 based indexing that the spreadsheet uses
      // IMPORTANT: convert this to zero-based indexing before passing back to the caller
      let maxRow = 1;

      // If we've gotten here, then the user has selected entire row(s) by clicking the row header,
      // so we need to calculate which row has the most number of columns
      //
      // In most cases this function will be called with a `VectorRange`, so there should only
      // be a single row, but we handle the "many rows" case to be on the safe side
      for (let col = leftMostColumn; col <= rightMostColumn; col += 1) {
        const cellsInColumn = columnsMap.get(rangeIndexToCellIndex(col));
        if (cellsInColumn === undefined) {
          continue;
        }

        maxRow = Math.max(maxRow, ...cellsInColumn);
      }

      return createSequence(0, cellIndextoRangeIndex(maxRow));
    },

    /**
     * Get all cells covered by the specified range
     *
     * Cells will be ordered first by column number ASC, then row number ASC.
     *
     * Note that the returned cells will have row/column indicies using BACKEND 1-based indexing
     *
     * @param r The range you want cells from
     * @returns All non-empty cells in the range
     */
    getPopulatedCellsByRange: (r: Range): Cell[] => {
      const selectedCells: Cell[] = [];

      const [colStart, rowStart] = r.start;
      const [colEnd, rowEnd] = r.end;

      // Be careful about the direction that selections were made in
      // 99% of the time it will be left -> right and top -> bottom, but a user may have accidentally
      // dragged from right -> left or bottom -> top
      const leftMostColumn = Math.min(colStart, colEnd);
      const rightMostcolumn = Math.max(colStart, colEnd);
      const topMostRow = Math.min(rowStart, rowEnd);
      const bottomMostRow = Math.max(rowStart, rowEnd);

      // This implies you're selecting the entire worksheet, which isn't something we support...?
      if (leftMostColumn === -1 && topMostRow === -1) {
        return cells;
      }

      // Handle the case where the range was made by selecting a row header
      if (leftMostColumn === -1) {
        for (let row = topMostRow; row <= bottomMostRow; row += 1) {
          const rowCellIndex = rangeIndexToCellIndex(row);

          const rowCells = rowsMap.get(rowCellIndex);
          if (rowCells === undefined) {
            continue;
          }

          rowCells.forEach((columnCellIndex) => {
            const cell = lookupCell(columnCellIndex, rowCellIndex);
            if (cell !== undefined) {
              selectedCells.push(cell);
            }
          });
        }

        return selectedCells;
      }

      // Handle the case where the range was made by selecting column headers
      if (topMostRow === -1) {
        for (let col = leftMostColumn; col <= rightMostcolumn; col += 1) {
          const colCellIndex = rangeIndexToCellIndex(col);

          const colCells = columnsMap.get(colCellIndex);
          if (colCells === undefined) {
            continue;
          }

          colCells.forEach((rowCellIndex) => {
            const cell = lookupCell(colCellIndex, rowCellIndex);
            if (cell !== undefined) {
              selectedCells.push(cell);
            }
          });
        }

        return selectedCells;
      }

      // Fallback to iterating through all of the cells covered by the range
      for (let col = leftMostColumn; col <= rightMostcolumn; col += 1) {
        for (let row = topMostRow; row <= bottomMostRow; row += 1) {
          const cell = lookupCell(
            rangeIndexToCellIndex(col),
            rangeIndexToCellIndex(row),
          );

          // Cells is a sparse map, and does not contain entries for cells that are empty
          if (cell !== undefined) {
            selectedCells.push(cell);
          }
        }
      }

      return selectedCells;
    },
  };
};
