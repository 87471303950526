import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { once } from 'lodash';

import AuthStrategy from '../../lib/auth-strategy';
import { getCSRFToken } from '../../universal/lib/csrf';

import cacheConfig from './apollo-cache-config';

const fetchWithAuthQueryString = (_, options) => {
  let uri = window.GRAPHQL_ENDPOINT;
  const authQueryString = AuthStrategy.getInstance().getQueryParam();
  if (authQueryString) {
    uri += `?${authQueryString}`;
  }
  return fetch(uri, options);
};

const httpLink = new HttpLink({
  fetch: fetchWithAuthQueryString,
  credentials: 'include',
});

const csrfTokenLink = new ApolloLink((operation, forward) => {
  const token = getCSRFToken();
  if (token) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'X-CSRF-Token': token,
      },
    }));
  }

  return forward(operation);
});

const versionLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-Polecat-Version': __APPSHA__,
    },
  }));

  return forward(operation);
});

const create = once(() => {
  const cache = new InMemoryCache(cacheConfig);

  return new ApolloClient({
    link: from([csrfTokenLink, versionLink, httpLink]),
    cache,
  });
});

export default {
  create,
};
