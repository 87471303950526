import type { DataType } from './types';

interface VisualizationsMeta {
  number: VisualizationMeta;
  geckometer: VisualizationMeta;
  line: VisualizationMeta;
  column: VisualizationMeta;
  bar: VisualizationMeta;
  leaderboard: VisualizationMeta;
  table: VisualizationMeta;
  text: VisualizationMeta;
}

interface VisualizationMeta {
  name: string;
  maxSeries: number;
  seriesLabel: string;
  // What types of field does this visualization support in it's series?
  seriesFieldTypes: DataType[];
  categoryFieldTypes?: DataType[];
  categoryLabel?: string;
  singleCellSupport: boolean;
}

/**
 * Define some useful per visualization constants
 */
export const Visualizations: VisualizationsMeta = {
  number: {
    name: 'Number',
    maxSeries: 1,
    seriesLabel: 'value',
    seriesFieldTypes: ['number', 'currency', 'percentage'],
    singleCellSupport: true,
  },
  geckometer: {
    name: 'Gauge',
    maxSeries: 1,
    seriesLabel: 'value',
    seriesFieldTypes: ['number', 'currency', 'percentage'],
    singleCellSupport: true,
  },
  line: {
    name: 'Line',
    maxSeries: 9,
    seriesLabel: 'series',
    seriesFieldTypes: ['number', 'currency', 'percentage'],
    categoryLabel: 'x-axis',
    categoryFieldTypes: ['number', 'datetime', 'text'],
    singleCellSupport: false,
  },
  column: {
    name: 'Column',
    maxSeries: 5,
    seriesLabel: 'series',
    seriesFieldTypes: ['number', 'currency', 'percentage'],
    categoryLabel: 'x-axis',
    categoryFieldTypes: ['number', 'datetime', 'text'],
    singleCellSupport: false,
  },
  bar: {
    name: 'Bar',
    maxSeries: 1,
    seriesLabel: 'value',
    seriesFieldTypes: ['number', 'currency', 'percentage'],
    categoryLabel: 'label',
    categoryFieldTypes: ['datetime', 'text'],
    singleCellSupport: false,
  },
  leaderboard: {
    name: 'Leaderboard',
    maxSeries: 1,
    seriesLabel: 'value',
    seriesFieldTypes: ['number', 'currency', 'percentage'],
    categoryLabel: 'label',
    categoryFieldTypes: ['datetime', 'text'],
    singleCellSupport: false,
  },
  table: {
    name: 'Table',
    maxSeries: 1000,
    seriesLabel: 'column',
    seriesFieldTypes: ['number', 'currency', 'percentage', 'text', 'datetime'],
    singleCellSupport: false,
  },
  text: {
    name: 'Text',
    maxSeries: 1,
    seriesLabel: 'value',
    seriesFieldTypes: ['number', 'currency', 'percentage', 'text', 'datetime'],
    singleCellSupport: true,
  },
};
