import { chain, isFinite, isMatch, partialRight, zip } from 'lodash';

import {
  getSelectedCells,
  getSelectionHeaderIndices,
  greedilyParseFloat,
  stableItemSort,
} from '../../spreadsheet-helpers';
import { composeTransformers } from '../compose-transformers';
import { transform as numberFormatTransform } from '../shared/number-format';

import { alignSeries, indexSeries, indexXAxis } from './helpers';

const toItem = ([{ value: label }, valueCell]) => {
  const { value = '', type, currency } = valueCell || {};
  const parsedValue = greedilyParseFloat(value);
  const item = { label };

  if (isFinite(parsedValue)) {
    item.value = parsedValue;
  }

  if (type === 'currency') {
    item.unit = currency;
    item.format = 'currency';
  }

  if (type === 'percentage') {
    item.format = 'percent';
  }

  return item;
};

const skipHeader = (hasHeader, indexHeader, firstCell) => {
  if (!hasHeader) return false;
  return indexHeader && isMatch(firstCell, indexHeader);
};

const leaderboardTransform = (
  { cells },
  { values, labels, hasHeader, reverseSort },
  timezone,
  initialContext = {},
) => {
  if (!labels) {
    return {};
  }

  const selected = getSelectedCells(cells, [labels, values]);
  const headerIndices = getSelectionHeaderIndices([labels, values], selected);

  const [labelCells, valueCells] = selected;

  // Build a corresponding index of our value and label cells
  const indexedLabels = indexXAxis(labelCells, labels);
  const indexedValues = indexSeries(valueCells, values);

  // Match our labels up with our value cells as blanks are not returned
  // As leaderboard will only ever have a single series of values pluck
  // only the first array index
  const alignedValues = alignSeries(indexedLabels, [indexedValues])[0];

  const selectedWithoutHeader = chain([labelCells, alignedValues])
    .zip(headerIndices)
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .map(([cells, headerIndex]) => {
      const [firstCell, ...rest] = cells;
      if (skipHeader(hasHeader, headerIndex, firstCell)) {
        return rest;
      }
      return cells;
    })
    .value(); // Omit header cells

  const items = chain(zip(...selectedWithoutHeader))
    .filter(([label]) => !!label) // Remove items with no label
    .map(toItem)
    .thru(partialRight(stableItemSort, reverseSort))
    .value();

  return { ...initialContext, items };
};

const transform = composeTransformers(
  leaderboardTransform,
  numberFormatTransform,
);

export { transform };
