import { once } from 'lodash';

/*
 * importUniversalConfig
 *
 * Loads the entire universal "Edit from dashboard" experience
 * asynchronously while ensuring that the bundle is only ever
 * requested once, even if it is requested in multiple
 * places simultaneously.
 *
 * PLEASE USE THIS INSTEAD OF IMPORTING THE
 * UNIVERSAL CONFIG ENTRY FILE DIRECTLY.
 */
const __importUniversalCompactConfig = () => import('./entry');
const importUniversalCompactConfig = once(__importUniversalCompactConfig);

export default importUniversalCompactConfig;
