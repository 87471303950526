import { find, isUndefined } from 'lodash';

// We currently have multiple ways of representing a user on the client side,
// depending on which end point we use to get the user/users.
// So we need to check for permissions in multiple ways!

const isAccountOwner = (user) => {
  if (isUndefined(user)) {
    return false;
  }

  if (!isUndefined(user.owner)) {
    return user.owner;
  }

  return !!user.is_admin;
};

const isGroupAdmin = (user) => {
  if (isUndefined(user)) {
    return false;
  }

  if (!isUndefined(user.group_memberships)) {
    return !!find(user.group_memberships, ({ role }) => role === 'admin');
  }

  if (!isUndefined(user.is_admin)) {
    return user.permission === 'power_user';
  }

  return user.permission === 'admin';
};

const isGroupViewer = (user) => {
  if (isUndefined(user)) {
    return false;
  }

  if (!isUndefined(user.group_memberships)) {
    return !!find(user.group_memberships, ({ role }) => role === 'read_only');
  }

  return user.permission === 'read_only';
};

const isDashboardViewOnly = (user) => {
  if (isUndefined(user)) {
    return false;
  }

  return !(isAccountOwner(user) || isGroupAdmin(user) || isGroupViewer(user));
};

export { isAccountOwner, isDashboardViewOnly, isGroupAdmin, isGroupViewer };
