import { map, unzip } from 'lodash';

import { transform as goalTransform } from '../../transformers/shared/goal';
import {
  composeApplyConfigTransformers,
  composeTransformers,
} from '../compose-transformers';
import cleanTransformedData from '../shared/clean-transformed-data';

import { getValuesFromRow, processSeries } from './helpers';

/*
 * For multi-series we need to add column names to
 * the series. This function takes the columns and
 * returns a formatter that can be passed to series.map()
 */
export const createMultiSeriesFormatter = (columns) => (series, i) => {
  const [, values] = unzip(series);

  return {
    data: values,
    name: columns[i + 1].name,
  };
};

const formatSeries = (series) => {
  const [, values] = unzip(series);

  return { data: values };
};

const columnTransform = ({ data }) => {
  if (!data || !data.databaseQuery) {
    return {};
  }

  const { columns } = data.databaseQuery;

  const seriesData = map(data.databaseQuery.rows, ({ values }) =>
    getValuesFromRow(values),
  ).filter(([label]) => label !== null);

  // Pull the labels out of the unprocessed series data
  const [labels = []] = unzip(seriesData);

  // process each series and return an array of series ([[], [], ...)
  const processedSeries = processSeries(seriesData);
  const isMulti = processedSeries.length > 1;
  const seriesFormatter = isMulti
    ? createMultiSeriesFormatter(columns)
    : formatSeries;

  const series = map(processedSeries, seriesFormatter);

  const xAxis = data.databaseQuery.columns[0] || {};

  return {
    series,
    x_axis: {
      labels,
      type: xAxis.dataType === 'TIMESTAMP' ? 'datetime' : 'standard',
    },
    y_axis: { format: 'decimal' }, // We only support decimal values right now
    format: 'decimal',
  };
};

/*
 * transform
 *
 * This is the full transformer that takes the high
 * fidelity raw data from our backend services and
 * transforms it into a low fidelity data payload
 * that our visualisations expect.
 *
 * rawData + widgetConfig => transform => transformedData
 */
const transform = composeTransformers(columnTransform, goalTransform);

/*
 * applyConfig
 *
 * This is a lightweight transformer that doesn't require
 * the high fidelity raw data. This can be used in cases
 * where an update to config can affect the widget data
 * in a way that is completely disconnected from the
 * raw data. For example applying a fixed goal.
 *
 * transformedData1 + widgetConfig => transform => transformedData2
 */
const applyConfig = (data, config) => {
  const cleanedData = cleanTransformedData(data);
  return composeApplyConfigTransformers(goalTransform)(cleanedData, config);
};

export { applyConfig, transform };
