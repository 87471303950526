import { get } from 'lodash';

export const ERROR_TYPES = {
  UNKNOWN: 'UNKNOWN',
  QUERY_FAILED: 'QUERY_FAILED',
  CONNECTION_FAILED: 'CONNECTION_FAILED',
  TIMEOUT: 'TIMEOUT',
  CANCELED: 'CANCELED',
  INVALID_QUERY: 'INVALID_QUERY',
  DANGEROUS_USER_PERMISSIONS: 'DANGEROUS_USER_PERMISSIONS',
  VALUE_OUT_OF_RANGE: 'VALUE_OUT_OF_RANGE',
  NOT_FOUND: 'NOT_FOUND',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  QUERY_RESPONSE_TOO_LARGE: 'QUERY_RESPONSE_TOO_LARGE',
};

/**
 * Apollo server can return two kinds of errors:
 *
 * 1. A GraphQL error - in this case the HTTP status code is 2xx
 * 2. A Network error - in this case the HTTP status code is 4xx or 5xx
 *
 * Ideally the HTTP status code for expected errors should always be
 * 2xx for a GraphQL response but the library we use has some strange
 * behaviour.
 *
 * If an error is thrown and the response also contains some data it
 * the HTTP status will be 2xx but if the response data is null then
 * the HTTP status will be 5xx.
 *
 * This helper method abstracts that and returns the same type of error
 * object regardless of the HTTP status.
 */
export const getError = (error) => {
  return (
    get(error, 'graphQLErrors[0]') ||
    get(error, 'networkError.result.errors[0]')
  );
};

/*
 * getApolloErrorType
 *
 * Apollo errors can contain both network errors and graphQL errors.
 * This helper function wraps up the complexity and returns an
 * error type string constant for all types of Apollo error.
 */
export const getApolloErrorType = (apolloError) => {
  const { graphQLErrors } = apolloError;

  /*
   * There can be multiple graphQL errors in a
   * single Apollo error. For now we can assume
   * that we only care about the first one.
   */
  if (graphQLErrors && graphQLErrors.length) {
    return ERROR_TYPES[graphQLErrors[0].type] || ERROR_TYPES.UNKNOWN;
  }

  /*
   * We might need to handle different kinds of
   * network error individually in the future
   * but for now we can convert them all to
   * UNKNOWN
   */
  return ERROR_TYPES.UNKNOWN;
};
